import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GetdataService {

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;

  getdata(sql):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"user/getlogdata",{sql:sql})
  }

  getuser():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"user/userData")
  }
  
  // getTableDataLog(tableName){
  //   return this.httpclient.post<any>(this.baseurl+ "user/getTableDataLog", {tableName:tableName})
  // }

  getTableDataLog(tableName,hrFiles,hrFiles1){
    return this.httpclient.post<any>(this.baseurl+ "user/getTableDataLog", {tableName:tableName,hrFiles:hrFiles,hrFiles1:hrFiles1})
  }
  getCategoryWiseTableData(totalFilter){
    return this.httpclient.post<any>(this.baseurl + "user/tableByCategory", {totalFilter:totalFilter})
  }
}

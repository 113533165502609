import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-serverstatus',
  templateUrl: './serverstatus.component.html',
  styleUrls: ['./serverstatus.component.css']
})
export class ServerstatusComponent implements OnInit {
server1:string=""
server2:string=""
server1list:any[]=[]
server2list:any[]=[]
  constructor(public loginser:LoginService) { }

  ngOnInit() {
    this.getstatus()
  }


  getstatus(){
    this.server1list=[]
    this.server2list=[]
    this.loginser.getserstatus().subscribe((data:any[])=>{
      if(data.length>0){
        this.server1=data[0].serverid
        for(let i=0;i<data.length;i++){
          if(data[i].serverid==this.server1){
            this.server1list.push(data[i])
          }
          else{
            this.server2=data[i].serverid
            this.server2list.push(data[i])
          }
        }
      }
    })
  }

}

import { Component, OnInit } from "@angular/core";
import { stateFormModel } from "src/app/model/master/state.formmodel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { FormGroup, FormBuilder } from "@angular/forms";
import { environment } from "src/environments/environment";
import { CategoryService } from "src/app/services/masters/category.service";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { MessageService, ConfirmationService } from "primeng/api";
import { EventService } from "src/app/services/masters/event.service";
import { CityService } from "src/app/services/masters/city.service";
import { CountryService } from "src/app/services/masters/country.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { DomSanitizer } from "@angular/platform-browser";
import { PermissionModel } from "src/app/model/master/Permission.model";

@Component({
  selector: "app-story-archive",
  templateUrl: "./story-archive.component.html",
  styleUrls: ["./story-archive.component.css"],
})
export class StoryArchiveComponent implements OnInit {
  show: boolean = false;
  showDownload: boolean = false;
  showDocumentSearch: boolean = false;
  searchdata: stateFormModel[] = [];
  permissionlist: PermissionModel[];
  storylist: StoryFormModel[] = [];
  fullstorylist: StoryFormModel;
  Searchbyfield: string;
  DocumentUpdated: boolean = false;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Story_Edit_Form: FormGroup;
  storyinformation: StoryFormModel;
  showDocumentDownload: boolean = false;
  viewDocument: boolean = false;
  // fullDocumentpathlist: DocumentUploadFormModel
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalDocumentpath = "assets/image/audio.jpg";
  glbDelete: number = 0;
  glbupdate: number = 0;
  fileContent: string = "";
  text: string;
  display: boolean;
  storyedit: boolean = false;
  selectedstory: StoryFormModel;
  viewStory: boolean = false;
  headline: string;
  attachment: string;
  selectedAttachmentlist: attachmentFormModel[] = [];
  attachmentlist: attachmentFormModel[] = [];
  DataFont: string;
  storyfont: string;
  headlineFont: string;
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  adnvacesrchfields: string;
  totalwords: string;
  category: string;
  source: string;
  storydate: string;
  character: string;
  subcategory: string;
  author: string;
  keywords: string;
  filesize: string;
  eventdate: string;
  eventname: string;
  rating: string;
  country: string;
  state: string;
  city: string;
  location: string;
  constructor(
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private subcategoryService: SubCategoryService,
    private countryservice: CountryService,
    private cityservice: CityService,
    private eventservice: EventService,
    private messageservice: MessageService,
    private storyservice: StoryserviceService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    // console.log("Server path is", this.ApiServerPath);
    this.checkpermissions();
    this.getcategorydata();
    this.CreateStoryEditForm();
    this.Searchbyfield = environment.globalsearchtext;
    this.adnvacesrchfields = environment.glbsearchstring;
    if (this.Searchbyfield == null) {
      this.Searchbyfield = "";
    }
    if (this.Searchbyfield == "") {
      if (this.adnvacesrchfields.length < 1) {
        this.getAllStory();
      } else {
        this.advancesearch(this.adnvacesrchfields);
      }
    } else {
      this.SearchByFields();
    }
    //this.getAllStory()
    this.selectedstory = new StoryFormModel();
    this.storyinformation = new StoryFormModel();
    this.fullstorylist = new StoryFormModel();

    //  this.selectedAttachmentlist=new attachmentFormModel()
  }

  CreateStoryEditForm() {
    this.Story_Edit_Form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      HeadLine: [null],
      itemkeyword: [null],
      itemrating: [null],
      itemid: [null],
      Story: [null],
      itemfilename: [null],
      ogpath: [null],
    });
  }

  EditStory(storylist) {
    this.selectedstory = storylist;
    this.storyedit = true;
    this.updateStory(storylist);
  }

  getdataforedit(id) {
    this.storyservice.getstory(id).subscribe((data: any[]) => {
      if (data != null) {
        this.EditStory(data[0]);
      }
    });
  }

  updateStory(storylist) {
    var dm = new DOMParser();
    var x = dm.parseFromString(storylist.fullstory, "text/xml");
    this.getSubcategorydata(storylist.categoryid);
    this.Story_Edit_Form.setValue({
      CategoryId: storylist.categoryid,
      SubCategoryId: storylist.subcategoryid,
      Story: x.getElementsByTagName("BodyText")[0].childNodes[0].nodeValue,
      HeadLine: storylist.itemcaption,
      itemkeyword: storylist.itemkeyword,
      itemrating: storylist.itemrating,
      itemid: storylist.itemid,
      itemfilename: storylist.itemfilename,
      ogpath: storylist.OriginalPath,
    });
  }
  SaveAfterUpdate(formval) {
    this.storyinformation = formval.value;
    this.storyservice
      .updateStory(this.storyinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          //alert('Merry Christmas')
          this.Story_Edit_Form.reset();
          this.getAllStory();
        }
      });
  }

  // function is not working
  SearchAllStory(formvalues) {
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }

    this.storyservice.SearchAllStory(formvalues).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.storylist = data;
      } else {
        this.storylist = [];
      }
    });
  }
  SearchByFields() {
    if (this.Searchbyfield != null) {
      var srchtxt = this.ExtractSearchingKeyword(this.Searchbyfield);
      this.storyservice.SearchByFields(srchtxt).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.storylist = data;
          this.fullstory(this.storylist[0].itemid);
        } else {
          this.storylist = [];
        }
      });
    } else {
      this.getAllStory();
    }
  }
  fullstory(id) {
    this.storyservice.getstory(id).subscribe((data: any[]) => {
      console.log("All data is", data[0]);
      if (data != null) {
        this.headline = data[0].itemcaption;
        this.text = "";
        this.text = data[0].fullstory;
        this.totalwords = data[0].TotalWords;
        this.storydate = data[0].Udate;
        this.category = data[0].categoryname;
        this.source = data[0].Source;
        this.character = data[0].TotalWords;
        this.subcategory = data[0].subcategoryname;
        this.author = data[0].itemauthor;
        this.keywords = data[0].itemkeyword;
        this.filesize = data[0].itemsize;
        this.eventdate = data[0].eventdate1;
        this.eventname = data[0].eventname;
        this.rating = data[0].itemrating;
        this.country = data[0].countryname;
        this.state = data[0].statename;
        this.city = data[0].cityname;
        this.location = data[0].location;
        // this.Textread(data[0].itemfilename,data[0].HiresPath)
      }
    });

    // this.headlineFont=storylist.cssclass
    // this.storyfont=storylist.cssclass
    //   this.fullstorylist = storylist
    //   this.headline=storylist.itemcaption
    //   this.ratingitem = storylist.itemrating
    //  this.Textread(storylist.itemfilename,storylist.HiresPath)
    //     this.viewStory=true
    //   this.getAttachment(storylist.itemid,this.attachmentlist)
  }

  close() {
    this.viewStory = false;
  }
  showDialog() {
    this.display = true;
  }

  advanceSearch() {
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
  }

  getBackground(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  getAllStory() {
    this.display = false;
    this.storyservice.getAllstory().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.storylist = data;
        this.fullstory(this.storylist[0].itemid);
        //this.DataFont=data[0].cssclass
      }
    });
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  Textread(filename, filepath) {
    this.storyservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }
  DeleteStory(itemid) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "storyarchivekey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.storyservice.deletestory(itemid).subscribe((data: any[]) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getAllStory();
            //  if(this.glbDelete==0){
            //    this.getTodayDocument()
            //  }else{
            //    this.getAllDocument()
            //  }
          }
        });
      },
      reject: () => {},
    });
  }

  getAttachment(parentid, attachmentlist) {
    // this.selectedAttachmentlist=attachmentlist
    // document.getElementById("markslider").innerHTML +=;
    this.storyservice.getAttachment(parentid).subscribe((data: any) => {
      if (data != null) {
        this.selectedAttachmentlist = data;
        //alert(data)
      }
    });
  }

  advancesearch(srchtxt) {
    this.hideadvncsrch();
    environment.glbsearchstring = srchtxt;
    this.storyservice.advancesearch(srchtxt).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.storylist = data;
        this.fullstory(this.storylist[0].itemid);
      } else {
        this.storylist = [];
      }
    });
  }

  public highlight() {
    if (!this.Searchbyfield) {
      return this.text;
    }
    if (this.text != "" && this.text != undefined) {
      return this.text.replace(
        new RegExp(this.Searchbyfield, "gi"),
        (match) => {
          return '<span class="highlightText">' + match + "</span>";
        }
      );
    }
  }

  downloadAttachment(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "document/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        //this.downloadentry(itemid)
      });
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 16) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 17) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 18) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
    }
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }
}

import { Component, OnInit } from "@angular/core";
import { ExportFormModel } from "src/app/model/master/exportModel";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { ExportService } from "src/app/services/masters/export.service";

@Component({
  selector: "app-export-config",
  templateUrl: "./export-config.component.html",
  styleUrls: ["./export-config.component.css"],
})
export class ExportConfigComponent implements OnInit {
  exportList: ExportFormModel[] = [];
  exportinformation: ExportFormModel;
  ConfigurationForm: FormGroup;
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  // CountryId: string;
  Header: string = "Add Device";
  constructor(
    private exportservice: ExportService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getDevices();
    this.CreateExportForm();
  }

  getDevices() {
    this.exportservice.getAllDevices().subscribe((data: any[]) => {
      if (data != null) {
        this.exportList = data;
      }
    });
  }
  CreateExportForm() {
    this.ConfigurationForm = this.formBuilder.group({
      DeviceName: [null, [Validators.required, this.removeSpaces]],
      OutputPath: [null, [Validators.required, this.removeSpaces]],
      id: [null],
      userid: [null],
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }

  SaveDevices(formval) {
    this.exportinformation = formval.value;
    this.exportinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.exportinformation.id > 0) {
      // this.exportinformation.OutputPath=this.exportinformation.OutputPath.replace('\\','\\')
      // this.exportinformation.OutputPath=this.exportinformation.OutputPath.replace("\\","\\\\")
      this.exportservice
        .checkduplicate(this.exportinformation)
        .subscribe((data: any) => {
          if (data != null) {
            this.exportservice
              .UpdateDevices(this.exportinformation)
              .subscribe((data: any) => {
                this.getDevices();
                this.ConfigurationForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Update successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Device Already Exist",
            });
          }
        });
    } else {
      this.exportservice
        .checkduplicate(this.exportinformation)
        .subscribe((data: any) => {
          if (data != null) {
            this.exportservice
              .CreateDevice(this.exportinformation)
              .subscribe((data: any) => {
                this.getDevices();
                this.ConfigurationForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Device  Already Exist",
            });
          }
        });
    }
  }

  Update(exportList: ExportFormModel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Device";
    this.ConfigurationForm.setValue({
      DeviceName: exportList.device_name,
      OutputPath: exportList.output_path,
      id: exportList.id,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeleteDevice(id) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "exportconfigkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.exportservice.DeleteDevice(id).subscribe((data: any[]) => {
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.getDevices();
          this.ConfigurationForm.reset();
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.ConfigurationForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Device";
  }
}

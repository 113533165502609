import { Injectable } from '@angular/core';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AudiouplaodService {

  baseurl=environment.ApiUrl

  AudioFormModel:audiouploadModel=new audiouploadModel()

  constructor(private httpclient:HttpClient) { }
  AddNewAudio(audiouploadinfo:audiouploadModel):Observable<audiouploadModel[]>{
    return this.httpclient.post<audiouploadModel[]>(this.baseurl + "audioupload/insertaudiodata",audiouploadinfo)
  }
  getaudiometadata(nme):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/audiometadata",{itemfilename:nme})
  }
  GetAllAudio():Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.get<any[]>(this.baseurl+"audioupload/getallaudio")
  }
  getTodayAudio():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"audioupload/getallaudio")
  }
  SearchByFields(Searchstring):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/SearchByFields",{Searchstring:Searchstring})
  }

  // getTodayAudio():Observable<any[]>{
  //   return this.httpclient.get<any[]>(this.baseurl+"audioupload/SearchByFields")
  // }

  UpdateAudio(audioinfo:audiouploadModel):Observable<audiouploadModel[]>{
    // console.log("audioinfo",audioinfo);
    
    return this.httpclient.post<audiouploadModel[]>(this.baseurl+"audioupload/updateAudio",audioinfo)
  }

  SearchAllAudio(audiouploadinfo:audiouploadModel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/SearchAudio",audiouploadinfo)
  }

  GetDownloadedAudioData(itemid:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/getdownloadAudiodata",{itemid:itemid})
  }
  deleteAudio(itemid:number):Observable<audiouploadModel[]>{
    return this.httpclient.post<audiouploadModel[]>(this.baseurl + "audioupload/deleteAudio",{itemid:itemid})
  }
  Testdownload(attachmentpath){
    return this.httpclient.post(this.baseurl+"audioupload/testdownload",{attachmentpath:attachmentpath})
  }

  downloadentry(itemid,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/downloadentry",{itemid:itemid,userid:userid})
  }
  
  

  deletezip(filename):Observable<any[]>{
    return this.httpclient.post<any>(this.baseurl+"audioupload/deletezip",{cartname:filename})
  }
  deletemultipleimg(itemid):Observable<any[]>{
    // console.log("itemid",itemid);
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/deletemultipleimg",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  downloadmultiplefn(arr):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"audioupload/downloadmultiplefn",{arr:arr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId},{responseType: "blob"})
  }

  deleteAudioRstr(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/deleterestoredaudio",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  restoreAudio(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"audioupload/restore",{itemid:itemid})
  }


  addtocart(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocart",{cartname:cartname,itemid:itemid,itemtype:itemtype,userid:userid,carttype:carttype})
  }

  addtocartmul(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocartmul",{cartname:cartname,itemid:itemid,itemtype:itemtype,userid:userid,carttype:carttype})
  }
  getResults(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/getautodata",{userid:userid})
  }

  bindcart(itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/bindcart",{itemtype:itemtype,userid:userid,carttype:carttype})
  }
}




import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { userformmodel } from 'src/app/model/master/user.form.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseurl=environment.ApiUrl
  UserFormModel:userformmodel=new userformmodel()
  constructor(private httpclient:HttpClient) { }

  updateProfile(userinfo:userformmodel):Observable<any[]>{
  return this.httpclient.post<any[]>(this.baseurl+"profile/UpdateUser",userinfo)
  }


  getuserdata(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"profile/getuserdata",{userid:userid})
  }

}

 import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable()
export class EventService {
  Eventformmodel:Eventformmodel=new Eventformmodel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
   //baseurl="http://localhost:5000/event";
   

   getAllEvent():Observable<Eventformmodel[]>{
    return this.httpclient.get<Eventformmodel[]>(this.baseurl+"event");
   }
   insertEvent(eventinfo:Eventformmodel):Observable<Eventformmodel[]>{
     return this.httpclient.post<Eventformmodel[]>(this.baseurl+"event/insertevent",eventinfo)
   }
   
   updateEvent(eventinfo:Eventformmodel):Observable<Eventformmodel[]>{
     return this.httpclient.post<Eventformmodel[]>(this.baseurl+"event/updateevent",eventinfo)
   }
   
   deleteevent(eventid:number):Observable<Eventformmodel[]>{
     return this.httpclient.post<Eventformmodel[]>(this.baseurl+"event/deleteevent",{eventid:eventid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
   }

   checkduplicate(eventinfo:Eventformmodel):Observable<Eventformmodel[]>{
    return this.httpclient.post<Eventformmodel[]>(this.baseurl+"event/checkduplicate",eventinfo)
  }
  
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-treedropdown',
  templateUrl: './treedropdown.component.html',
  styleUrls: ['./treedropdown.component.css']
})
export class TreedropdownComponent implements OnInit {
items:any[]=[]
  constructor() { }

  ngOnInit() {
    this.items=this.alldata
  }

  @Input()
  alldata:any[]=[]

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes['alldata']) {
  //     if (this.alldata.length>0) {
  //       this.items=this.alldata
  //     }

  //   }
  // }
  ngAfterViewInit() {
      if (this.alldata.length>0) {
        this.items=this.alldata
      }
  }

  binddata(data){
    this.items=data
  }

}

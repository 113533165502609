import { Component, OnInit } from "@angular/core";
import { CityFormModel } from "src/app/model/master/city.form.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { stateFormModel } from "src/app/model/master/state.formmodel";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { CityService } from "src/app/services/masters/city.service";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { CountryFormModel } from "src/app/model/master/country.formmodel";
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-company-master',
  templateUrl: './company-master.component.html',
  styleUrls: ['./company-master.component.css']
})
export class CompanyMasterComponent implements OnInit {

  Header: string = "Company Master";
  companyMasterform: FormGroup;
  userId = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
  allCompanies:any[]=[];
  selectedFiles: any[] = [];
  imagepath = environment.CommpanyBgImagePath; 
  ApiServerPath = environment.ApiServerPath;

  constructor(private cityservice: CityService,private dashboardservice: DashboardService, private messageService: MessageService) {}

  ngOnInit() {
    this.getAllCompanies();
  }
  
  getAllCompanies(){
    // console.log("userod",this.userId);
    this.dashboardservice.GetAllCompanies(this.userId).subscribe((data:any) => {
      this.allCompanies = data;
      // console.log("all comanies data",this.allCompanies);
    });
  }
  


  onFileSelected(event: any, company: any, imageType: string) {
    const file: File = event.target.files[0];
    if (file) {
        const watermarkImage = "company_bg_images/"+company.CompanyNames+"/"+event.target.files[0].name;
        const bgImage = "company_bg_images/"+event.target.files[0].name;
            this.selectedFiles.push({ 
                file: imageType === 'bgImage' ? file : null, 
                watermarkFile: imageType === 'watermarkImage' ? file : null,
                companyId: company.TreeViewId ,
                slectFileKey:imageType === 'watermarkImage'?'watermark':'bgimage',
                slectedFilePath:imageType === 'watermarkImage'?watermarkImage:bgImage,
                companyNames: company.CompanyNames,
            });

        this.messageService.add({
            key: "message",
            severity: "info",
            summary: "File Selected",
            detail: `File selected for company ${company.CompanyNames}, ${imageType}: ${file.name}`,
        });
    }
}
// onFileSelected(event: any, company: any, imageType: string) {
//   const file: File = event.target.files[0];
//   if (file) {
//       const filePath = `${this.imagepath}${company.CompanyNames}/${file.name}`;
//       const index = this.selectedFiles.findIndex(f => f.companyId === company.TreeViewId);

//       if (index !== -1) {
//           // Update the existing entry based on image type
//           if (imageType === 'bgImage') {
//               this.selectedFiles[index].file = file;
//               this.selectedFiles[index].slectedFilePath = filePath; 
//           } else if (imageType === 'watermarkImage') {
//               this.selectedFiles[index].watermarkFile = file;
//               this.selectedFiles[index].slectedFilePath = filePath; 
//           }
//       } else {
//           // Add new entry for the company
//           this.selectedFiles.push({ 
//               companyId: company.TreeViewId,
//               file: imageType === 'bgImage' ? file : null,
//               watermarkFile: imageType === 'watermarkImage' ? file : null,
//               slectFileKey: imageType === 'watermarkImage' ? 'watermark' : 'bgimage',
//               slectedFilePath: filePath,
//           });
//       }

//       this.messageService.add({
//           key: "message",
//           severity: "info",
//           summary: "File Selected",
//           detail: `File selected for company ${company.CompanyNames}, ${imageType}: ${file.name}`,
//       });
//   }
// }

onSubmit() {
  // Check if any files were selected
  if (this.selectedFiles.length === 0) {
      this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "No Files",
          detail: "No files selected for upload",
      });
      return;
  }

  const userId = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
  
  // Loop through selected files and upload each one
  this.selectedFiles.forEach(selectedFile => {
      const formData = new FormData();
      
      if (selectedFile.file) {
          formData.append('file', selectedFile.file);
      }
      
      if (selectedFile.watermarkFile) {
          formData.append('file', selectedFile.watermarkFile);
      }
      formData.append("slectFileKey",selectedFile.slectFileKey);
      formData.append("slectedFilePath",JSON.stringify(this.selectedFiles));
      formData.append('companyId', selectedFile.companyId);
      formData.append('userId', userId);
      // formData.append('companyname', selectedFile.companyNames);

      // Upload the form data
      this.dashboardservice.uploadCompanyImage(formData,selectedFile.companyNames, selectedFile.slectFileKey).subscribe(response => {
          this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success",
              detail: `Image uploaded successfully for company ID ${selectedFile.companyId}`,
          });
      }, error => {
          this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Error",
              detail: `Failed to upload images for company ID ${selectedFile.companyId}`,
          });
      });
  });

  // Clear selected files after upload
  this.selectedFiles = [];
}

addNewCompany(){
  // console.log("add company called");
  this.dashboardservice.addnewCompany();
}

EditCompanyShortName(){
  this.dashboardservice.editCompanyShortName(this.compshortname,this.companyName).subscribe((data:any) => {
    this.editShortName = false;
    this.getAllCompanies();
  })
}

compshortname: string = '';
companyName: string = ''; 
editShortName: boolean = false;
openEditNameDialog(companyName: string){
  this.companyName = companyName;
  this.compshortname = this.compshortname;
  this.editShortName = true;
}

onCancel() {
  this.editShortName = false;
}

  
}

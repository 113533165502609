import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  private nameSource = new BehaviorSubject<string>('');
  data = this.nameSource.asObservable()
  
  

  constructor() { }

  dataType(dataType: string) {
    console.log("sarvice data",dataType);
    this.nameSource.next(dataType);
  }

 
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purging',
  templateUrl: './purging.component.html',
  styleUrls: ['./purging.component.css']
})
export class PurgingComponent implements OnInit {
agencyimg:number=20
agencystory:number=20
imagearch:number=20
videoarch:number=20
audioarch:number=20
docarch:number=20
storyarch:number=20
  constructor() { }

  ngOnInit() {
  }

}

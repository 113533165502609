import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ExportFormModel } from 'src/app/model/master/exportModel';

@Injectable({
  providedIn: 'root'
})
export class ExportService {


  Export:ExportFormModel=new ExportFormModel();
  baseurl=environment.ApiUrl
  constructor(private httpclient:HttpClient) { }

  getAllDevices():Observable<ExportFormModel[]>{
    return this.httpclient.get<ExportFormModel[]>(this.baseurl+"Export/");
  }
  CreateDevice(exportinfo:ExportFormModel):Observable<ExportFormModel[]>{
    return this.httpclient.post<ExportFormModel[]>(this.baseurl+ "Export/AddDevice",exportinfo);
  }
  UpdateDevices(exportinfo:ExportFormModel):Observable<ExportFormModel[]>{
    return this.httpclient.post<ExportFormModel[]>(this.baseurl+"Export/UpdateDevice",exportinfo)
  }
  DeleteDevice(id:number):Observable<ExportFormModel[]>{
    return this.httpclient.post<ExportFormModel[]>(this.baseurl+"Export/DeleteDevice",{DeviceId:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  checkduplicate(exportinfo:ExportFormModel):Observable<ExportFormModel[]>{
    return this.httpclient.post<ExportFormModel[]>(this.baseurl+ "Export/checkduplicate",exportinfo);
  }
}

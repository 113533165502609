import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { cartmodel } from "src/app/model/file/cartmodel";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { AgencyService } from "src/app/services/files/agency.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mysharecart",
  templateUrl: "./mysharecart.component.html",
  styleUrls: ["./mysharecart.component.css"],
})
export class MysharecartComponent implements OnInit {
  cartlist: cartmodel[] = [];
  cartlist1: cartmodel[] = [];
  agencydiv: number = 1;
  archievediv: number = 0;
  permissionlist: PermissionModel[];
  downloadcartbtn: number = 0;
  ApiServerPath;
  cols = [
    { field: "cartname", header: "Name" },
    //{ field: 'itemname', header: 'Item Name' },
    { field: "assignbyname", header: "Share By" },
    { field: "assigntoname", header: "Share To" },
  ];
  constructor(
    public imageservice: AgencyService,
    private imageservice1: ImageuploadService,
    public router: Router
  ) {}

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.checkpermissions();
    this.getagencycartlist();
    //this.getarchievecartlist()
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 61) {
        this.downloadcartbtn = 1;
      }
    }
  }

  getagencycartlist() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.myassignedcartlist(userid).subscribe((data: any[]) => {
      if (data != null) {
        this.cartlist = data;
      }
    });
  }
  getarchievecartlist() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.myassignedcartlist(userid).subscribe((data: any[]) => {
      if (data != null) {
        this.cartlist1 = data;
      }
    });
  }

  showarchievediv() {
    const el = document.querySelector(".tabview1");
    el.children[1].className = "activetab";
    el.children[0].className = "";
    this.agencydiv = 0;
    this.archievediv = 1;
  }
  showagencydv() {
    const el = document.querySelector(".tabview1");
    el.children[0].className = "activetab";
    el.children[1].className = "";
    this.archievediv = 0;
    this.agencydiv = 1;
  }

  bindcartdata(id, type) {
    if (type == "agency") {
      window.open("#/agencyimages;cid=" + id, "_self");
    } else if ((type = "archieve")) {
      window.open("#/imageUpload;cid=" + id, "_self");
    }
  }

  getcartdata(id, uid) {
    this.router.navigate(["/cart", id + "~" + uid]);
  }

  downloadcart(data1) {
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    y["style"].display = "block";
    this.imageservice1
      .downloadcart(data1.toString())
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadzipflder(data);
        }
      });
  }

  //   downloadzipflder(filename) {
  //     var cartname=filename
  //     filename=filename+".zip"
  //     this.imageservice1.testzipdownload(filename).subscribe((data: any) => {

  //       if (data != null) {
  //         var b64Data1 = data.split(";")[1]
  //         const byteCharacters = atob(b64Data1);

  //         const byteNumbers = new Array(byteCharacters.length);
  //         for (let i = 0; i < byteCharacters.length; i++) {
  //           byteNumbers[i] = byteCharacters.charCodeAt(i);
  //         }
  //         const byteArray = new Uint8Array(byteNumbers);
  //         const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
  //         //let byteCharacters = atob(data);

  //         var url = window.URL.createObjectURL(blob);
  //         var a = document.createElement('a');
  //         document.body.appendChild(a);
  //         a.setAttribute('style', 'display: none');
  //         a.href = url;
  //         a.download = "Assets";
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //         a.remove(); // remove the element
  //       }
  // this.imageservice1.deletezip(cartname).subscribe((data:any[])=>{

  // })
  // var x = document.querySelector(".downloadinggif1")
  //     x["style"].display = "none";
  //     var y = document.querySelector(".downloadback")
  //     y["style"].display = "none";

  //     })
  //   }

  downloadzipflder(filename) {
    var cartname = filename;
    //filename = filename + ".zip"
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + filename;
    // this.imageservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    // this.imageservice1.deletezip(cartname).subscribe((data: any[]) => {

    // })

    // })
  }
}

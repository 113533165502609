import { Component, OnInit } from '@angular/core';
import { StoryserviceService } from 'src/app/services/files/storyservice.service';
import { StoryFormModel } from 'src/app/model/file/storyformModel';
import { DomSanitizer } from '@angular/platform-browser';
import { AgencyService } from 'src/app/services/files/agency.service';
import { strictEqual } from 'assert';

@Component({
  selector: 'app-agencystory',
  templateUrl: './agencystory.component.html',
  styleUrls: ['./agencystory.component.css']
})
export class AgencystoryComponent implements OnInit {
  showadvcsrch: boolean = false
  Searchbyfield:string
  storylist:StoryFormModel[]=[]
  fullstorylist:StoryFormModel
  ratingitem: any
  headline:string
  text:string
  viewStory:boolean=false
  totalwords:string
  category:string
  source:string
  storydate:string
  takelist:any[]
  takecount:number=0
  takeno:string="Take"
  constructor(private storyservice:AgencyService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getAllStory()
    this.fullstorylist=new StoryFormModel()
  }


  
SearchByFields() {
  if (this.Searchbyfield != null) {
    this.storyservice.SearchByFields(this.Searchbyfield).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.storylist = data
        this.fullstory(this.storylist[0])
      } else {
        this.storylist = []
        this.headline=""
  //this.ratingitem = storylist.itemrating
  this.text=""
  this.totalwords=""
  this.storydate=""
  this.category=""
  this.source=""
  this.takecount=0
      }
    })
  }
  else {
    this.getAllStory()
  }

}

getAllStory() {
    
  this.storyservice.getAllstory().subscribe((data: any[]) => {
    if (data.length > 0) {
      this.storylist = data
      //this.DataFont=data[0].cssclass
      this.fullstory(this.storylist[0])
    }
  })
}

fullstory(storylist){

  // this.headlineFont=storylist.cssclass
  // this.storyfont=storylist.cssclass
  this.fullstorylist = storylist
  this.headline=storylist.Heading
  this.text=""
  this.text=storylist.Story
  this.text=this.text.replace(new RegExp("\r\n", 'g'), "<br/>")
  this.totalwords=storylist.TotalWords
  this.storydate=storylist.Udate
  this.category=storylist.Category
  this.source=storylist.sourcename
  
  if(storylist.takelist !=null){
    this.takecount=1
    this.takelist=storylist.takelist.split(',')
  }
  else{
    this.takecount=0
  }
  
 //this.Textread(storylist.itemfilename,storylist.HiresPath)
    //this.viewStory=true
  //this.getAttachment(storylist.itemid,this.attachmentlist)

}

gettakestory(e){
  var x=e.originalEvent.target.text;
  var y=e.originalEvent.target.innerText;
  x=x.replace(y,'');
  this.storyservice.gettakedata(x.replace(' ','')).subscribe((data:any[])=>{
    if(data.length>0){
      this.headline=data[0].Heading
  //this.ratingitem = storylist.itemrating
  this.text=data[0].Story
  this.text=this.text.replace(new RegExp("\r\n", 'g'), "<br/>")
  this.totalwords=data[0].TotalWords
  this.storydate=data[0].Udate
  this.category=data[0].Category
  this.source=data[0].sourcename
    }
  })
}

close(){
  this.viewStory=false
}

//function is not working
Textread(filename,filepath){
  this.storyservice.getTextFileContend(filename,filepath).subscribe((data:any)=>{
    if(data!=null){
    this.text=data
    }
  })
  }

  getBackground(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  //function is not working 
  SearchAllStory(formvalues){
    if(formvalues.itemkeyword!=null){
      formvalues.itemkeyword=formvalues.itemkeyword.replace(new RegExp(",", 'g'), " ")
    }
    this.hideadvncsrch()
    if (formvalues.CategoryId == 'null') {
      formvalues.CategoryId = null
      formvalues.SubCategoryId = null
    }
  
    this.storyservice.SearchAllStory(formvalues).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.storylist = data
        this.fullstory(this.storylist[0])
      }
      else {
        this.storylist = []
        this.headline=""
  //this.ratingitem = storylist.itemrating
  this.text=""
  this.totalwords=""
  this.storydate=""
  this.category=""
  this.source=""
  this.takecount=0
      }
    })
  }

  advanceSearch(){
    this.showadvcsrch = true
    var el=document.querySelector(".my-float")
    el["style"].display="block"
  }

  hideadvncsrch(){
    this.showadvcsrch=false
    var el=document.querySelector(".my-float")
    el["style"].display="none"
  }

  public highlight() {
    if(!this.Searchbyfield) {
        return this.text;
    }
    return this.text.replace(new RegExp(this.Searchbyfield, "gi"), match => {
        return '<span class="highlightText">' + match + '</span>';
    });
}
}

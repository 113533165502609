import { Component, OnInit } from "@angular/core";
import { Childcategoryformmodel } from "src/app/model/master/childCategory.form.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { ChildCategoryService } from "src/app/services/masters/child-category.service";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";

@Component({
  selector: "app-subsubcategory",
  templateUrl: "./subsubcategory.component.html",
  styleUrls: ["./subsubcategory.component.css"],
})
export class SubsubcategoryComponent implements OnInit {
  subcategorylist: Subcategoryformmodel[] = [];
  childcategorylist: Childcategoryformmodel[] = [];
  subcategoryinformation: Childcategoryformmodel;
  SubSubCategoryForm: FormGroup;
  categorylist: categoryformmodel[] = [];
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  Header: string = "Add Sub Category";
  isSubmitted = false;
  constructor(
    private childCategoryService: ChildCategoryService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getChildCatgorydata();
    this.CreateSubSubCategoryForm();
    this.getcategoryDropdown();
    this.getSubCategoryDropdown();
  }

  getChildCatgorydata() {
    this.childCategoryService
      .getAllChildCategory()
      .subscribe((data: Childcategoryformmodel[]) => {
        if (data != null) {
          this.childcategorylist = data;
          console.log(this.childcategorylist);
        }
      });
  }
  CreateSubSubCategoryForm() {
    this.SubSubCategoryForm = this.formBuilder.group({
      ChildCategoryName: [null, [Validators.required, this.removeSpaces]],
      ChildcategoryId: [null],
      CategoryId: [null, [Validators.required]],
      SubCategoryId: [null, [Validators.required]],
      userid: [JSON.parse(localStorage.getItem("userdetails"))[0].UserId],
    });
    this.SubSubCategoryForm.patchValue({ CategoryId: 0, SubCategoryId: 0 });
  }

  savesubcategoryinfo() {
    console.log(" this.SubSubCategoryForm", this.SubSubCategoryForm.value);

    if (this.SubSubCategoryForm.valid) {
      this.childCategoryService
        .checkduplicate(this.SubSubCategoryForm.value)
        .subscribe((data: any) => {
          console.log("checkduplicate", data);

          if (data.length > 0) {
            this.messageService.add({
              key: "message",
              severity: "warn",
              summary: "Warning Message",
              detail: "Child category already exist...",
            });
          } else {
            if (this.SubSubCategoryForm.value.ChildcategoryId > 0) {
              console.log("update");
              this.childCategoryService
                .UpdateChildCategory(this.SubSubCategoryForm.value)
                .subscribe((data: any) => {
                  console.log("update", data);
                });
            } else {
              console.log("insert");
              this.childCategoryService
                .CreateChildCategory(this.SubSubCategoryForm.value)
                .subscribe((data: any) => {
                  if (data.length > 0) {
                    this.messageService.add({
                      key: "message",
                      severity: "success",
                      summary: "Success Message",
                      detail: "Child category created...",
                    });
                  } else {
                    this.messageService.add({
                      key: "message",
                      severity: "warn",
                      summary: "Warning Message",
                      detail: "Child category creattion failed..",
                    });
                  }
                });
            }
          }
        });
    }

    // if (this.subcategoryinformation.SubCategoryId > 0) {
    //   if(this.subcategoryinformation.CategoryId==0){
    //     this.messageService.add({ key: 'message', severity:'warn', summary: 'Warning Message', detail:'Please select CategoryId'});
    //   }else{
    //     this.childCategoryService.checkduplicate(this.subcategoryinformation).subscribe((data:any)=>{
    //       if(data.length<1){
    //       this.childCategoryService.UpdateChildCategory(this.subcategoryinformation).subscribe((data: any) => {
    //         this.getChildCatgorydata()
    //         this.SubSubCategoryForm.reset()
    //         this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'update successfully' });
    //         this.ButtonStatus = "Save"
    //         this.SubSubCategoryForm.patchValue({ CategoryId: 0, SubCategoryId : 0 })
    //       });
    //     }else{
    //       this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'SubCategory Already Exist' });

    //     }
    //     })
    // }
    // }
    //   else {
    //     if(this.subcategoryinformation.CategoryId==0){
    //       this.messageService.add({ key: 'message', severity:'warn', summary: 'Warning Message', detail:'Please select CategoryId'});
    //     }else{
    //       this.childCategoryService.checkduplicate(this.subcategoryinformation).subscribe((data:any)=>{
    //         if(data.length<1){
    //     this.childCategoryService.CreateChildCategory(this.subcategoryinformation).subscribe((data: any) => {
    //       this.getChildCatgorydata()
    //       this.SubSubCategoryForm.reset()
    //       this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });
    //       this.SubSubCategoryForm.patchValue({ CategoryId: 0, SubCategoryId : 0 })
    //     });
    //   }else{
    //     this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'SubCategory Already Exist' });
    //   }
    // })
    //   }
    // }
  }

  getcategoryDropdown() {
    this.childCategoryService
      .getCategoryDropdown()
      .subscribe((data: categoryformmodel[]) => {
        if (data != null) {
          this.categorylist = data;
        }
      });
  }
  getSubCategoryDropdown() {
    this.childCategoryService
      .getSubCategoryDropdown()
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  updateSubcategory(subcategorylist: Childcategoryformmodel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete SubCategory";
    this.SubSubCategoryForm.setValue({
      SubCategoryName: subcategorylist.SubCategoryName,
      SubCategoryId: subcategorylist.SubCategoryId,
      CategoryId: subcategorylist.CategoryId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeleteSubCategory(SubCategoryId: number) {
    console.log("delete", SubCategoryId);

    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "subcategorykey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.childCategoryService
          .DeleteChildCategory(SubCategoryId)
          .subscribe((data: any[]) => {
            this.getChildCatgorydata();
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.SubSubCategoryForm.reset();
            this.SubSubCategoryForm.patchValue({
              CategoryId: 0,
              SubCategoryId: 0,
            });
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  clear() {
    this.SubSubCategoryForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Sub Category";
    this.SubSubCategoryForm.patchValue({ CategoryId: 0, SubCategoryId: 0 });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentUploadFormModel } from 'src/app/model/file/documentUploadModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DailypagesService {

  documentuplaodmodel:DocumentUploadFormModel=new DocumentUploadFormModel()
  constructor(private httpclient:HttpClient) { }

  baseurl=environment.ApiUrl;
  
   GetAllDocument(limit):Observable<DocumentUploadFormModel[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
     return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl+"dailypages/getAllDocument",{limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,edpermission:edper.trim().substring(0,edper.trim().length-1)})
   }
   SearchByFields(Searchstring,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/SearchByFields",{Searchstring:Searchstring,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,edpermission:edper.trim().substring(0,edper.trim().length-1)})
  }

  GetDownloadedDocumentData(itemid:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getdownloadDocumentdata",{itemid:itemid})
  }
  //this API is not working
  Testdownload(filename,attachmentpath):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"dailypages/testdownload",{documentname:filename,attachmentpath:attachmentpath},{responseType: "blob"})
  }

  downloadentry(itemid,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/downloadentry",{itemid:itemid,userid:userid})
  }
  getdocumentdata(itemid,path):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getdocument",{itemid:itemid,path:path,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  getdailypagescount():Observable<any[]>{
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getdailypagescount",{edpermission:edper.trim().substring(0,edper.trim().length-1)})

  }
  

  advancesearch(srchtxt,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/newadvancesearch",{srchtxt:srchtxt,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,edpermission:edper.trim().substring(0,edper.trim().length-1)})
  }

 

  getncount(srchtxt,tabstr):Observable<any[]>{
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getncount",{Searchstring:srchtxt,tabstr:tabstr,edpermission:edper.trim().substring(0,edper.trim().length-1)})
  }

  
  getacount(srchtxt,tabstr):Observable<any[]>{
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getacount",{srchtxt:srchtxt,tabstr:tabstr,edpermission:edper.trim().substring(0,edper.trim().length-1)})
  }

  downloadmultipleimg(imageid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/downloadmultipleimg",{imageid:imageid})
  }
  
  downloadmultiplefn(arr):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"dailypages/downloadmultiplefn",{arr:arr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId},{responseType: "blob"})
  }

  getalledition():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"edition")
  }
  getalleditionforsearch():Observable<any[]>{
    var editionper=JSON.parse(localStorage.getItem("editionpermissions"))
    var edper=""
    for(let i=0;i<editionper.length;i++){
      edper +=editionper[i].edition_id+","
    }
    return this.httpclient.post<any[]>(this.baseurl+"dailypages/getalleditions",{edpermission:edper.trim().substring(0,edper.trim().length-1)})
  }
}

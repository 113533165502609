import { Component, OnInit } from "@angular/core";
import { bysubjectService } from "src/app/services/masters/bysubject.service";
import { bysubjectModel } from "src/app/model/master/bysubject.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-bysubject",
  templateUrl: "./bysubject.component.html",
  styleUrls: ["./bysubject.component.css"],
})
export class BysubjectComponent implements OnInit {
  bysubjectlist: bysubjectModel[] = [];
  bysubjectinformation: bysubjectModel;
  bysubjectForm: FormGroup;
  msgs: Message[] = [];
  Header: string = "Add By Subject";
  ButtonStatus: string = "Save";
  id: string;
  allbysubject: any[] = [];
  constructor(
    private bysubjectService: bysubjectService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getallbysubject();
    this.CreatebysubjectForm();
  }

  getallbysubject() {
    this.bysubjectService.GetbysubjectData().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allbysubject = data;
      }
    });
  }
  CreatebysubjectForm() {
    this.bysubjectForm = this.formBuilder.group({
      fullname: [null, [Validators.required, this.removeSpaces]],
      id: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  Savebysubject(formval) {
    this.bysubjectinformation = formval.value;
    this.bysubjectinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.bysubjectinformation.id > 0) {
      this.bysubjectService
        .checkduplicate(this.bysubjectinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.bysubjectService
              .Updatebysubject(this.bysubjectinformation)
              .subscribe((_data: any) => {
                this.getallbysubject();
                this.bysubjectForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "update successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "BySubject Already Exist",
            });
          }
        });
    } else {
      this.bysubjectService
        .checkduplicate(this.bysubjectinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.bysubjectService
              .Createbysubject(this.bysubjectinformation)
              .subscribe((_data: any) => {
                this.getallbysubject();
                this.bysubjectForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "BySubject Already Exist",
            });
          }
        });
    }
  }
  updatebysubject(allbysubject: bysubjectModel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete By Subject";
    this.bysubjectForm.setValue({
      fullname: allbysubject.fullname,
      id: allbysubject.id,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  deletebysubject(id: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "bysubjectkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.bysubjectService
          .Deletebysubject(id)
          .subscribe((data: bysubjectModel[]) => {
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getallbysubject();
            this.bysubjectForm.reset();
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.bysubjectForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add By Subject";
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild,HostListener, SimpleChanges, ViewEncapsulation  } from '@angular/core';
import { MessageService } from 'primeng/api';
import { VideouploadService } from 'src/app/services/files/videoupload.service';
import { environment } from 'src/environments/environment';
import * as videojs from 'video.js/dist/video.js';
import Player from "video.js/dist/types/player";

import * as hotkeys from "videojs-hotkeys";


@Component({
  selector: 'app-videoplayer',
  // templateUrl: './videoplayer.component.html',
  template: `
   <div *ngIf="errorMessage" class="video-error-overlay">
      {{ errorMessage }}
    </div>
  <video #target class="video-js" controls playsinline preload="none"></video>
`,
  styleUrls: ['./videoplayer.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class VideoplayerComponent implements OnInit {
  errorMessage: string;
  @Input() mysrc:any;
  @Input() myposter:any;
  @Input() myvideodata:any;

  @ViewChild("video", { static: false }) public videoElement: ElementRef;
  // @ViewChild("videogif", { static: true }) private videogif: ElementRef
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],

    poster: string

  };
  player: videojs.Player;

  permissionlist: any[]=[];

  ButtonStatus:any = 'MarkIn'

  exportbtn: any;
  cropbtn: any;
  uploadbtn:any
  editbtn:any
  deletebtn:any
  addtocartbtn:any
  downloadbtn:any
  approvebtn:any;

  markinvl:any = 0
  markoutval:any = 0
  exportitemid: number
  minutes: any;
  MarkInValue: any;
  MarkOutValue:any;
  totalDuration:any;
  markinArray:any[]=[];
  totaldurarray:any[]=[];
  durationarray:any[]=[];
  myScriptElement: HTMLScriptElement;
   constructor( public videoservice: VideouploadService,private messageservice: MessageService , private elementRef: ElementRef){
      
   }
   ngOnChanges(changes: SimpleChanges): void {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
    });
    this.player.on('canplay', () => { 
    })
    
    // // Add the hotkeys plugin
    // this.player.hotkeys({
    //   volumeStep: 0.1, // Adjust as needed
    //   seekStep: 5, // Adjust as needed
    //   enableMute: true, // Enable mute hotkey
    //   enableFullscreen: true, // Enable fullscreen hotkey
    //   enableNumbers: false, // Disable number key seek shortcuts
    //   enableVolumeScroll: false // Disable volume scroll shortcut
    // });
  
    // // Custom hotkeys for play/pause
    // this.player.hotkeys({
    //   customKeys: {
    //     play: {
    //       key: function() {
    //         return 'space'; // Change the key if desired (e.g., 'p')
    //       },
    //       handler: function() {
    //         if (this.player.paused()) {
    //           this.player.play();
    //         } else {
    //           this.player.pause();
    //         }
    //       }
    //     }
    //   }
    // });
  }

  // ngOnInit() {   
  //   this.checkpermissions();
  //   this.myScriptElement = document.createElement("script");
  //     this.myScriptElement.src = 'http://192.168.1.123/kazyimages/aadhar/vjsplayer.js';
  //     // this.myScriptElement.src = environment.ApiServerPath + 'vjsplayer.js';
  //     document.body.appendChild(this.myScriptElement);
  //   console.log('this is the video path ', this.mysrc);  
  //   setTimeout(() => {
  //     $('.vjs-progress-holder.vjs-slider.vjs-slider-horizontal').append('<div id="markslider" style="display: none;"></div>')
      
  //   }, 1000);

  // }

  ngOnInit() {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
    });
    this.player.on('error', (error: any) => {
      this.handleError(error);
    });
    this.player.blur();
  }
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
  handleError(error: any) {
    this.showErrorMessage();
  }
  showErrorMessage() {
    this.errorMessage = 'Lowres proxy is not available please check';
    const overlay = document.createElement('div');
    overlay.className = 'video-error-overlay';
    overlay.innerHTML = this.errorMessage;
    // this.player.el().appendChild(overlay);
  }

//     // Listen for keydown events on the document
//     @HostListener('document:keydown', ['$event'])
//     handleKeyboardEvent(event: KeyboardEvent) {
//       // console.log('SpaceKeyPress');  
//       // Check if the pressed key is the spacebar (code 32)
//       if (event.keyCode === 32) {
//         event.preventDefault();
//         // Call the playPauseVideo method
//         this.playPauseVideo();
//       }
//     }

//     playPauseVideo() {
//       const video: HTMLVideoElement = this.videoElement.nativeElement;
//       if (video.paused) {
//         video.play();
//       } else {
//         video.pause();
//       }
//     }

//   checkpermissions() {
//     this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"))
//     for (let i = 0; i < this.permissionlist.length; i++) {
//       if (this.permissionlist[i].taskid == 7) {
//         this.uploadbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 8) {
//         this.editbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 9) {
//         this.deletebtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 45) {
//         this.exportbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 46) {
//         this.cropbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 55) {
//         this.addtocartbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 34) {
//         this.downloadbtn = 1;
//       }
//       if (this.permissionlist[i].taskid == 63) {
//         this.approvebtn = 1;
//       }
//     }
//   }

//   markin() {
//     if (this.ButtonStatus == 'Mark in') {
//       this.ButtonStatus = 'MarkOut';
//       var ttl_obj_width=0;
//       var tt=$('#markslider').find("div");
//       for (let i=0;i<tt.length;i++){
//         ttl_obj_width=(ttl_obj_width + parseFloat($($(tt)[i]).css("width")));
//       }
//       document.getElementById('markslider').style.display = 'block'
//       document.getElementById("markslider").innerHTML += "<div id='markinvl" + this.markinvl + "'class='markin' style='height:29px; width:13px;border-right: 4px solid rgba(115, 133, 159, 0.75);margin: -12px 0px 0px 0px;float:left'></div>";
//       var timebar = $('.vjs-play-progress.vjs-slider-bar').css('width');
//       var markinwidth = $('#markinvl' + this.markinvl).css('width');
//       var markoutwidth = $('#markoutval' + this.markinvl).css('width');
//       var markSetwidth = Number(0) + Number(timebar.replace('px', ''));
//       $('#markinvl' + this.markinvl).css('width', markSetwidth-ttl_obj_width);
//       this.tooltip('markinvl' + this.markinvl);
//       this.getProgressCurrentTime(markSetwidth) + ",";
//       this.markinvl = this.markinvl + 1;
//     }
//     else {
//       this.ButtonStatus = 'Mark in'
//       var ttl_obj_width=0;
//       var tt=$('#markslider').find("div"); 
//       for (let i=0;i<tt.length;i++){
//         ttl_obj_width=(ttl_obj_width + parseFloat($($(tt)[i]).css("width")));
//       }
//       document.getElementById('markslider').style.display = 'block'
//       document.getElementById("markslider").innerHTML += "<div id='markoutval" + this.markoutval + "' class='markout' style='height:29px; width:13px; border-right:4px solid rgba(115, 133, 159, 0.75);background: rgba(102, 153, 200,0.7);margin:-12px 0px 0px 0px;float:left'></div>";
//       var timebar = $('.vjs-play-progress.vjs-slider-bar').css('width');
//       var markinwidth = $('#markinvl' + this.markoutval).css('width');
//       var markSetwidth = (Number(0) + Number(timebar.replace('px', ''))) - Number(ttl_obj_width);
//       $('#markoutval' + this.markoutval).css('width', markSetwidth);
//       // $('#markoutval' + this.markoutval).css('margin-left', markinwidth);
//       this.tooltip('markoutval' + this.markoutval);
//       this.markoutval = this.markoutval + 1;
//       this.getProgressCurrentTime(markSetwidth) + ",";

//     }
//   }
  

//   getProgressCurrentTime(x) {
//     var video = $('#my-video');
//     var progress = $('.vjs-progress-holder.vjs-slider.vjs-slider-horizontal')[0];

//     //calculate drag position
//     //and update video currenttime
//     //as well as progress bar
//     var maxduration = this.videoElement.nativeElement.duration
//     var position = x - progress.offsetLeft
//     var percentage = 100 * position / progress.clientWidth;
//     if (percentage > 100) {
//       percentage = 100;
//     }
//     if (percentage < 0) {
//       percentage = 0;
//     }
//     var Ctime = maxduration * percentage / 100;
//     return Ctime;
//   }

//   tooltip(id) {
//     // var MarkInValue;
//     //  var MarkOutValue;
//     var videoCurrentTime12 = this.videoElement.nativeElement.currentTime
//     this.minutes = "0" + Math.floor(videoCurrentTime12 / 60);
//     var seconds = "0" + Math.floor(videoCurrentTime12 - this.minutes * 60);
//     var dur = this.minutes.substr(-2) + ":" + seconds.substr(-2);
//     document.getElementById(id).innerHTML += "<span style='float:right;font-size:13px;'>" + dur + "</span>";
//     $(id).attr('title', dur);
//     if (id == ('markinvl' + this.markinvl)) {
//       this.MarkInValue = videoCurrentTime12
//     }
//     if (id == ('markoutval' + this.markoutval)) {
//       this.MarkOutValue = videoCurrentTime12
//       this.totalDuration = this.MarkOutValue - this.MarkInValue
//       this.markinArray.push(this.MarkInValue)
//       this.totaldurarray.push(this.totalDuration)
//       this.durationarray = this.markinArray.concat(this.totaldurarray)
//     }
//     //  this.totalDuration=this.MarkOutValue-this.MarkInValue
//     //  this.markinArray.push(this.MarkInValue)
//     //  this.totaldurarray.push(this.totalDuration)
//     //  this.durationarray.concat(this.markinArray,this.totaldurarray)

//   }

//   cropvideo(path, orgname) {
//     var person = prompt("Please enter filename", "");
//     if (person == null || person == "") {
//     }
//     else {
//       var ext = orgname.split('.')[orgname.split('.').length - 1]
//       var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId
//       // this.videogif.nativeElement.style.display = 'block'
//       this.videoElement.nativeElement.pause()
//       // this.videogif.nativeElement.src='assets/image/2.gif'
//       orgname = person.split('.')[0] + "." + ext
//       // console.log("video crop");
//       this.videoservice.ffmpeg(path, orgname, this.durationarray, userid).subscribe((data: any[]) => {
//         if (data.length > 0) {
//           this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'Successfully Export' });
//           //this.downloadVideo(orgname,path,userid)
//           // this.videogif.nativeElement.style.display = 'none'
//           // this.close1();
//         }
//       })
//     }
// }

// close1() {
//   document.getElementById("markslider").innerHTML = ''
//   this.durationarray = []
//   this.markinArray = []
//   this.totaldurarray = []
//   this.ButtonStatus = 'Mark in'
//   this.markinvl = null
//   this.markoutval= null

// }

// openvideocomment(id) {
//     window.open("#/comment?q=" + id, "_blank")
//   }
// openvideokeywords(id) {
//     window.open("#/keywords?q=" + id, "_blank")
//   }

//   ExportVideo(exportid, filename, filepath) {
//     console.log("export: ", exportid, filename, filepath)
//     this.exportitemid = 0
//     this.videoservice.InsertExportData(this.exportitemid, exportid, filename, filepath).subscribe((data: any) => {
//       console.log(data);
//       if (data != null) {
//         this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'File Downloaded Will Start Soon...' });
//         //this.ExportEntry(this.exportitemid,filename, exportid)
//       }
//       else if (data=='Already') {
//         this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'File is Already Exists On Server...' });
//       }

//       else {
//         this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Failed Message', detail: 'Original File Does Not Exist.' });
//       }
//     })
//     //this.downloadonebyone(exportid, filename, filepath)
//   }

  
}

export class Subcategoryformmodel{
    SubCategoryId:number
    SubCategoryName:string
    CategoryId:number
    ActiveStatus:string
    CategoryName:string
    userid:string
    eventlist:string
    eventname: string
    eventid: string
}
import { Injectable } from '@angular/core';
import { agencymodel } from 'src/app/model/file/agencymodel';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { cartmodel } from 'src/app/model/file/cartmodel';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
agencymodel:agencymodel=new agencymodel()
  constructor(private httpclient:HttpClient,private http:HttpClient) { }
  baseurl=environment.ApiUrl;

  //this API is not working
  GetAllImages():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agency/getAllImages")
  }
  //this API is not working
  SearchAllImages(imageserachinfo:agencymodel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/SearchImages",imageserachinfo)
  }
   //this API is not working
  getTodayImages():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agency/getTodayImages")
  }
  SearchByFields(Searchstring):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"agency/SearchByFields",{Searchstring:Searchstring,maxlimit:maxlimit})
  }
  //this API is not working
  Testdownload(filename,attachmentpath):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"agency/testdownload",{imagename:filename,attachmentpath:attachmentpath},{responseType: "blob"})
  }

  getAllstory():Observable<agencymodel[]>{
    return this.httpclient.get<agencymodel[]>(this.baseurl+"agency/getallagencystory")
  }
   //this API is not working
  SearchByFieldimage(Searchstring):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/SearchByFieldimage",{Searchstring:Searchstring})
  }
   //this API is not working
  SearchAllStory(storyinfo:agencymodel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/SearchStory",storyinfo)
  }
  getTextFileContend(filename,filepath):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/getTextFileContend",{filename:filename,filepath:filepath})
  }
  gettakedata(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+'agency/gettakedata',{id:id})
  }

  addtocart(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocart",{cartname:cartname,itemid:itemid,itemtype:itemtype,userid:userid,carttype:carttype})
  }

  bindcart(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/bindcart",{userid:userid})
  }
  removefromcart(cartdata:cartmodel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/removefromcart",cartdata)
  }
  getResults():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agency/getautodata")
  }

  assigncart(cartdata:any,assigntoid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/assigncart",{cartdata,assigntoid:assigntoid})
  }

  getcartdata(id,uid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/getcartdata",{itemid:id,userid:uid})
  }

  myassignedcartlist(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/myassignedcartlist",{id:id})
  }

  assignedtome(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/assignedtome",{id:id})
  }

  removeitemfromcart(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/removeitemfromcart",{id:id})
  }

  getallagency():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agencyupload/getallagencies")
  }

  getagencyname(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getagencyname",{id:id})
  }

  getagencycatdata(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getagencycatdata",{agencyid:id})
  }

  uploadagencyfiles(filename,agencykey,agencyid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/api/upload",{filename:filename,agencykey:agencykey,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,agencyid:agencyid})
  }

  getallagencydata(catid,limit):Observable<any[]>{
    let maxlimit="54"
    // if(JSON.parse(localStorage.getItem("userperference")).length>0){
    //   maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
    //   maxlimit=(Number(maxlimit)*2).toString()
    // }
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getallagencydata",{catid:catid,limit:limit,maxlimit:maxlimit})
  }

  getimagedata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getitem",{itemid:itemid})
  }

  getallcount(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getallcount",{id:id})
  }

  SearchByFields1(id,Searchstring,limit):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/SearchByFields",{id:id,Searchstring:Searchstring,limit:limit,maxlimit:maxlimit})
  }

  advancesearch(id,srchtxt,limit):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/advancesearch",{id:id,srchtxt:srchtxt,limit:limit,maxlimit:maxlimit})
  }

  getncount(id,srchtxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getncount",{id:id,Searchstring:srchtxt,tabstr:tabstr})
  }

  
  getacount(id,srchtxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getacount",{id:id,srchtxt:srchtxt,tabstr:tabstr})
  }

  getfolderdata(key,path):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/getfolderdata",{fkey:key,path:path})
  }
  checkduplicateagncy(data):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/checkduplicate",data)
  }
  updateagency(data):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/updateagency",data)
  }
  Createagency(data):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/createagency",data)
  }
  updateAgency(id,status):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/updateAgency",{agencyid:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,status:status})
  }

  createagencycat(data):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/createagencycat",data)
  }

  getallagencycat():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agencyupload/getallagencycat")
  }

  checkduplicateagncycat(data):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/checkduplicateagencycat",data)
  }
  updatAgencycat(id,status):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/updatAgencycat",{catid:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,status:status})
  }

  deleteimages(lst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agencyupload/deleteimg",{id:lst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  
  deleteAgency(AgencyId){
    return this.httpclient.post<any>(this.baseurl + "agencyupload/DELETE_AGENCY",{AgencyId:AgencyId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  deleteAgencyCat(TreeViewId){
    return this.httpclient.post<any>(this.baseurl + "agencyupload/DELETE_AGENCY_CATEGORY",{TreeViewId:TreeViewId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
}

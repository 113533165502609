import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { TreedataComponent } from '../treedata/treedata/treedata.component';

@Component({
  selector: 'app-treeupload',
  templateUrl: './treeupload.component.html',
  styleUrls: ['./treeupload.component.css']
})
export class TreeuploadComponent implements OnInit {

  Image_Edit_form: FormGroup
  selectedimage: ImageUploadFormModel;
  idforupdate:string=""
  imageuploadinformation: ImageUploadFormModel;
  today:string=""
  pdftypelst:any
  itemtype:string
  filteredkeywords: any[];
  allkeywords:any[]
  glbkeywordsgst:string=""
  keywords:any[]=[];
  customMetaData:boolean;

  constructor( private formBuilder: FormBuilder,  public sanitizer: DomSanitizer,public treecom:TreedataComponent,private messageservice: MessageService,public dashser:DashboardService ) { }

  ngOnInit() {
    this.currentDate()
    this.CreateImageEditForm()
    //this.getpdftype()
    //this.getallkeyword()
    let pdftypesub = this.dashser.getpdftypedata().subscribe((data1: any[]) => {
      if (data1.length > 0) {
        this.pdftypelst = data1
      }
      pdftypesub.unsubscribe()
    })
    let keywordsub = this.dashser.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })

    this.Image_Edit_form.get('orgmetadata').valueChanges.subscribe((value) => {
      if (value) {
        // If orgmetadata is true, enable all other form fields
        this.enableFormControls();
      } else {
        // If orgmetadata is false, disable all other form fields
        this.disableFormControls();
      }
    });
  }

  disableFormControls() {
    Object.keys(this.Image_Edit_form.controls).forEach((control) => {
      if (control !== 'orgmetadata') {
        this.Image_Edit_form.get(control).disable();
      }
    });
  }

  enableFormControls() {
    Object.keys(this.Image_Edit_form.controls).forEach((control) => {
      if (control !== 'orgmetadata') {
        this.Image_Edit_form.get(control).enable();
      }
    });
  }

  @Input()
  selectype: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectype']) {
     this.itemtype=this.selectype
    //  if(this.itemtype !="pdf"){
    //    this.Image_Edit_form.controls["storytype"].disable()
    //  }
    //  else{
    //    this.Image_Edit_form.controls["storytype"].setValue("0")
    //   this.Image_Edit_form.controls["storytype"].enable()
    //  }
    }
    let pdftypesub = this.dashser.getpdftypedata().subscribe((data1: any[]) => {
      if (data1.length > 0) {
        this.pdftypelst = data1
      }
      pdftypesub.unsubscribe()
    })
    let keywordsub = this.dashser.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
}

  CreateImageEditForm() {
    this.Image_Edit_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      itemcaption: [null],
      itemkeyword: ['', Validators.required],
      itemauthor: [null],
      itemrating: [null],
      title: [null,Validators.required],
      source: [null],
      copyright: [null],
      itempermission:[1],
      orgmetadata:[true],
      acldaterange:[this.today],
      storytype:["0"],
      imagetype:["0"]
    })
  }
  

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  getpdftype(){
    this.dashser.getpdftype().subscribe((data:any[])=>{
      if(data.length>0){
        this.pdftypelst=data
      }
    })
  }

  currentDate() {
    const currentDate = new Date();
    this.today= currentDate.toISOString().substring(0,10);
  }

  //collection tree view form popup
  savedate(){
    // console.log("savedate called");
    // const expandedNodes = this.getExpandedNodes();

    if ((!this.Image_Edit_form.value.title || this.keywords.length < 0) && this.Image_Edit_form.value.orgmetadata === true) {
      this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please enter the Title and Keywords",
      });
  } else {
      this.imageuploadinformation=this.Image_Edit_form.value
      // console.log(this.Image_Edit_form.value, "image edit form on save");
      // console.log("orgmetadata is false");
      
      this.cleardata()
      if(this.imageuploadinformation.storytype==undefined ||this.imageuploadinformation.storytype=="0"){
        this.imageuploadinformation.storytype=""
      }
      if(this.imageuploadinformation.imagetype==undefined || this.imageuploadinformation.imagetype=="0"){
        this.imageuploadinformation.imagetype="image"
      }
      if(this.imageuploadinformation.imagetype==undefined ||this.imageuploadinformation.imagetype=="1"){
        this.imageuploadinformation.imagetype="InfoGraphics"
      }
      if(this.imageuploadinformation.imagetype==undefined ||this.imageuploadinformation.imagetype=="2"){
        this.imageuploadinformation.imagetype="Illustration"
      }
      if(this.imageuploadinformation.imagetype==undefined ||this.imageuploadinformation.imagetype=="3"){
        this.imageuploadinformation.imagetype="Cartoon"
      }
      if(this.imageuploadinformation.imagetype==undefined ||this.imageuploadinformation.imagetype=="4"){
        this.imageuploadinformation.imagetype="AIimage"
      }
      var tmpkeywords="";
      if(this.Image_Edit_form.value.orgmetadata === true){
        for(let i=0;i<this.imageuploadinformation.itemkeyword.length;i++){
          tmpkeywords +=this.imageuploadinformation.itemkeyword[i].keywordname +", "
        }
        this.imageuploadinformation.itemkeyword=tmpkeywords.trim().substring(0,tmpkeywords.trim().length-1)
      }

      // const selectedKeywords = this.imageuploadinformation.itemkeyword || [];
      // console.log("selectedd keywords",selectedKeywords);
      
      // this.imageuploadinformation.itemkeyword = selectedKeywords
      //   .map((keyword: any) => keyword.keywordname)
      //   .join(';');


      this.treecom.droppedontree(this.imageuploadinformation)
      // this.setExpandedNodes(expandedNodes);
      // this.gettreejson1(this.tcmnodekey);
      // this.apendFolderColl(data, path);
      // this.getfolderdata(e.node.key, e.node.data, e.node.label);

     
    }
  }

  // getExpandedNodes() {
  //   const expandedNodes = [];
  //   this.treecom.treeModel.nodes.forEach((node) => {
  //     if (node.expanded) {
  //       expandedNodes.push(node);
  //     }
  //   });
  //   return expandedNodes;
  // }
  
  // // Function to set expanded nodes after saving
  // setExpandedNodes(expandedNodes) {
  //   expandedNodes.forEach((node) => {
  //     node.expanded = true;
  //   });
  //   this.treecom.updateTreeModel(); // Update the tree model after restoring the state
  // }

  cleardata(){
    this.Image_Edit_form.reset();
    // this.Image_Edit_form.patchValue({acldaterange:this.today});
    this.Image_Edit_form.patchValue({itempermission:"1"});
    // this.Image_Edit_form.patchValue({storytype:"0"})
    // this.Image_Edit_form.patchValue({imagetype:"0"})
  }

  //getting all keywords
  getallkeyword(){
    this.dashser.getkeywords("").subscribe((data:any[])=>{
      if(data.length>0){
        this.allkeywords=data
      }
    })
  }

  // filter all keywords
  filterkeyword(e){
    if(e.query !=""){
      this.glbkeywordsgst=e.query
      this.dashser.getkeywords(this.glbkeywordsgst).subscribe((data:any[])=>{
        if(data.length>0){
          this.allkeywords=data
        }
      })
    }
    
    let query = e.query;
    this.filteredkeywords = this.filterCountry(query, this.allkeywords);
        
  }
  

  //getting all country and filter them
  filterCountry(query, countries: any[]):any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    for(let i = 0; i < countries.length; i++) {
        let country = countries[i];
        if(country.keywordname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
    return filtered;
}
}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService } from "primeng/api";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-vault-management",
  templateUrl: "./vault-management.component.html",
  styleUrls: ["./vault-management.component.css"],
})
export class VaultManagementComponent implements OnInit {
  vaultForm: FormGroup;
  ButtonStatus: string = "Save";
  Header: string = "Add Vault";
  selecteduser: number;
  valutList: any;
  UserId: any;
  total: number;
  barcodeImage: any;
  @ViewChild("imageToPrint", { static: false }) imageToPrint!: ElementRef;
  constructor(
    private fb: FormBuilder,
    private userser: UserserviceService,
    public domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getAllVaultData();
    this.vaultForm = this.fb.group({
      UserId: [""],
      Vault_number: ["", Validators.required],
      Rack_number: ["", Validators.required],
      Hdd_number: ["", Validators.required],
      Barcode_image: [""],
    });
  }
  onSubmit() {
    // Check if the form is valid
    if (this.vaultForm.valid) {
      // Check if a user is selected
      if (this.selecteduser > 0) {
        this.updateVault();
      } else {
        // Check if a barcode is generated
        if (this.barcodeImage) {
          this.vaultForm.value.Barcode_image = this.barcodeImage;
          this.createVault();
        } else {
          this.messageService.add({
            key: "message",
            severity: "error",
            summary: "warn Message",
            detail: "Please Generate Barcode",
          });
        }
      }
    } else {
      // Form is invalid
      this.messageService.add({
        key: "message",
        severity: "error",
        summary: "warn Message",
        detail: "Please fill all the fields",
      });
    }

    // Reset user selection
    this.selecteduser = 0;
  }

  updateVault() {
    this.userser
      .UpdateAltoVault(this.vaultForm.value)
      .subscribe((data: any) => {
        if (data === "updated") {
          this.handleSuccess("Update Successfully");
          this.ButtonStatus = "Save";
        } else {
          this.handleVaultExistError();
        }
      });
  }

  createVault() {
    this.userser
      .CreateAltoVault(this.vaultForm.value)
      .subscribe((data: any) => {
        if (data === "Created") {
          this.handleSuccess("Save successfully");
        } else {
          this.handleVaultExistError();
        }
      });
  }

  handleSuccess(detail: string) {
    this.messageService.add({
      key: "message",
      severity: "success",
      summary: "Success Message",
      detail,
    });
    this.getAllVaultData();
    this.clear();
  }

  handleVaultExistError() {
    this.messageService.add({
      key: "message",
      severity: "error",
      summary: "warn Message",
      detail: "Vault Already Exist",
    });
  }

  getAllVaultData() {
    this.userser.getAllVaultData().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.valutList = data;
        this.total = data.length;
        //  console.log(this.userlist);
      } else {
        this.valutList = data;
      }
    });
  }
  makeSafe(barcodeImage: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(barcodeImage);
  }

  getSafeBarcodeImage() {
    return this.barcodeImage;
  }

  updateUser(valutList: userformmodel) {
    // console.log("valutList", valutList);
    this.selecteduser = valutList.UserId;
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Vault";

    // Set the safe URL directly
    this.barcodeImage = this.makeSafe(valutList.Barcode_image);

    // Set values directly without patchValue
    this.vaultForm.setValue({
      UserId: valutList.UserId,
      Barcode_image: this.barcodeImage,
      Hdd_number: valutList.Hdd_number,
      Rack_number: valutList.Rack_number,
      Vault_number: valutList.Vault_number,
    });
  }

  deleteUser(Vault_number: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "vaultmanagementkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.userser.DeleteAltoVault(Vault_number).subscribe((data: any) => {
          if (data == "Deleted") {
            this.getAllVaultData();
            this.clear();
            this.ButtonStatus = "Save";
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Delete successfully",
            });
          }
        });
      },
      reject: () => {},
    });
  }
  clear() {
    this.vaultForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Vault";
    this.barcodeImage = "";
  }
  // generateRandomBarcode() {
  //   const randomBarcode = Math.floor(100000 + Math.random() * 900000).toString();
  //   this.vaultForm.patchValue({
  //     Barcode_image: randomBarcode
  //   });
  // }
  generateBarcode() {
    if (this.vaultForm.get("Barcode_image")) {
      const barcodeText = this.vaultForm.get("Hdd_number").value;

      this.userser.generateBarcode(barcodeText).subscribe(
        (blob: any) => {
          const reader = new FileReader();
          reader.onload = () => {
            this.barcodeImage = reader.result;
          };
          reader.readAsDataURL(blob);
        },
        (error) => {
          console.error("Error generating barcode:", error);
        }
      );
    }
  }
  printImage() {
    const printContent = this.imageToPrint.nativeElement;

    // Check if the image is available
    const windowPrint = window.open("", "_blank");

    if (windowPrint) {
      windowPrint.document.write("<html><head><title>Print Image</title>");
      windowPrint.document.write("<style>");
      windowPrint.document.write(
        "body { display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; }"
      );
      windowPrint.document.write("img { max-width: 100%; max-height: 100%; }");
      windowPrint.document.write("</style>");
      windowPrint.document.write("</head><body>");
      windowPrint.document.write('<img src="' + printContent.src + '" />');
      windowPrint.document.write("</body></html>");
      windowPrint.document.close();
      windowPrint.print();
      windowPrint.onafterprint = () => windowPrint.close();
    } else {
      // alert('Please enable pop-ups to print the image.');
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  CountryFormModel:CountryFormModel=new CountryFormModel();
  baseurl=environment.ApiUrl;
  //baseurl:string="http://localhost:5000/country"
  constructor(private httpclient:HttpClient) { }

      GetCountryData():Observable<CountryFormModel[]>{
        return this.httpclient.get<CountryFormModel[]>(this.baseurl+"country")
      }
      CreateCountry(countryinfo:CountryFormModel):Observable<CountryFormModel[]>{
        return this.httpclient.post<CountryFormModel[]>(this.baseurl + "country/insertcountry",countryinfo)
      }
      DeleteCountry(CountryId:number):Observable<CountryFormModel[]>{
        return this.httpclient.post<CountryFormModel[]>(this.baseurl + "country/deletecountry",{CountryId:CountryId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
      }
      UpdateCountry(countryinfo:CountryFormModel):Observable<CountryFormModel[]>{
        return this.httpclient.post<CountryFormModel[]>(this.baseurl + "country/updatecountry",countryinfo)
      }
      checkduplicate(countryinfo:CountryFormModel):Observable<CountryFormModel[]>{
        return this.httpclient.post<CountryFormModel[]>(this.baseurl + "country/checkduplicate",countryinfo)
      }
    }

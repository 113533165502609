import { Injectable } from '@angular/core';
import { PoolFormModel } from 'src/app/model/master/pool.formmodel';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicationFormModel } from 'src/app/model/master/publication.formmodel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoolService {

  poolformmodel:PoolFormModel=new PoolFormModel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  //baseurl: string = "http://localhost:5000/pool";

  getAllPool():Observable<PoolFormModel[]>{
    return this.httpclient.get<PoolFormModel[]>(this.baseurl+"pool");
  }
  CreatePool(poolinfo:PoolFormModel):Observable<PoolFormModel[]>{
    return this.httpclient.post<PoolFormModel[]>(this.baseurl+ "pool/insertpool",poolinfo);
  }
 
  getpublicationDropDown():Observable<PublicationFormModel[]>{
    return this.httpclient.get<PublicationFormModel[]>(this.baseurl+"pool/selectpublication");
  }
  UpdatePool(poolinfo:PoolFormModel):Observable<PoolFormModel[]>{
    return this.httpclient.post<PoolFormModel[]>(this.baseurl+"pool/updatepool",poolinfo)
  }
  DeletePool(PoolId:number):Observable<PoolFormModel[]>{
    return this.httpclient.post<PoolFormModel[]>(this.baseurl+"pool/deletepool",{PoolId:PoolId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  checkduplicate(poolinfo:PoolFormModel):Observable<PoolFormModel[]>{
    return this.httpclient.post<PoolFormModel[]>(this.baseurl+ "pool/checkduplicate",poolinfo);
  }
}

import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, QueryList } from '@angular/core';
import { Table } from 'primeng/table';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { style } from '@angular/animations';
import { logmodel } from 'src/app/model/master/logmodel';
import { GetdataService } from 'src/app/services/masters/getdata.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionModel } from 'src/app/model/master/Permission.model';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService, ConfirmationService, MenuItem } from 'primeng/api';
import {Paginator} from 'primeng/paginator'
import {ContextMenu} from 'primeng/contextmenu'
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { Childcategoryformmodel } from 'src/app/model/master/childCategory.form.model';
import { ChildCategoryService } from 'src/app/services/masters/child-category.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';



@Component({
  selector: 'app-logexport',
  templateUrl: './logexport.component.html',
  styleUrls: ['./logexport.component.css']
})
export class LogexportComponent implements OnInit {
  searchfilter: string = ""
  hrFiles: any
  hrFiles1: any
  dateValue: any
  dateValue1: any
  rtoday: string = ""
  rtoday1: string = ""
  user:any[]=[]
  login1: boolean = false
  insert1: boolean = false
  download1: boolean = false
  update1: boolean = false
  delete1: boolean = false
  approve1: boolean = false
  selecteduser:string="0"
  itemcount:string=""
  first: number = 0;
  @ViewChild("dt", { static: false }) dt: Table

  exceldata: logmodel[] = []
  tableName: any = "0"
  Action: any
  categoryList: any[]=[]
  subCategoryList: any[]=[]
  childCategoryList: any[]=[]
  category: any = "0"
  subcategory:any= "0"
  childCategory: any= "0"
  // exceldata = this.worksheet.addRow([logmodel]);
  // exceldata.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
  //   worksheet.addRow([]);




  constructor(public getdataser: GetdataService,private auth: AuthService, private myRoute: Router,
    private childCategoryService: ChildCategoryService,public categoryService: CategoryService, public subcategoryService: SubCategoryService, private messageservice: MessageService,) { 
    this.checkpermission()
  }

  ngOnInit() {
    this.Action = "Action"
    this.checkpermission()
    //this.getdata()
this.getusers()
this.selecteduser="0"
this.rtoday="0"
this.rtoday1 ="0"
this.login1=true
this.searchfilter="~login~logout"
this.getcategorydata() 
this.getSubcategorydata('')
this.getChildCategoryData('')
  }

  onrange(e){
this.rtoday=""
  }

  onrange1(e){
    this.rtoday1=""
      }
  radiochange(){
    this.dateValue=""
    this.dateValue1=""
  }

  getusers(){
    this.getdataser.getuser().subscribe((data:any[])=>{
      if(data.length>0){
        this.user=data
        
      }
    })
  }
  
  totalfilter(e){
    this.itemcount=e.filteredValue.length
  }
  formatDate(inputDate: string | Date): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1; // Add 1 because getMonth() returns zero-based month index
    let day: number | string = date.getDate();

    // Format month and day to ensure leading zeros if necessary
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    const formattedDate = year + '/' + month + '/' + day;
    return formattedDate;
}
  getdata() {
    this.Action = "Action"
    if (this.dt != undefined) {
      this.dt.first = 0
    }
    var sql = ""
    if(this.selecteduser !="0" && this.selecteduser !=""){
      sql +=" and userid='"+this.selecteduser+"'"
    }
    if (this.rtoday !="") {
       sql += " and (logdate BETWEEN  DATE_SUB(curdate(), INTERVAL "+this.rtoday+" DAY) AND DATE_ADD(curdate(), INTERVAL 1 DAY)) and "
    }
    else if(this.dateValue != ""  && this.dateValue != undefined && this.dateValue.length != 0 && this.dateValue1 != ""  && this.dateValue1 != undefined && this.dateValue1.length != 0) {
      // var sdate = this.dateValue[0].toLocaleDateString('zh-Hans-CN')
      // var sdate1 = this.dateValue1[0].toLocaleDateString('zh-Hans-CN')
       var sdate = this.formatDate(this.dateValue)
      var sdate1 = this.formatDate(this.dateValue1)
      if(this.dateValue[1]==null && this.dateValue1[1]==null){
        sql += " and (logdate BETWEEN '" + sdate + "' AND '" + sdate1 + "') and "
      }
      else{
        var edate = this.dateValue[1].toLocaleDateString('zh-Hans-CN')
        var edate1 = this.dateValue1[1].toLocaleDateString('zh-Hans-CN')
        sql += " and (logdate BETWEEN '" + sdate + "' AND DATE_ADD('" + edate + "', INTERVAL 1 DAY)) and "
      }
      
    }
    var x = this.searchfilter.split("~")

    for (let i = 1; i < x.length; i++) {
      if (x.length == 2) {
        sql += " ( logtask='" + x[i] + "')"
      }
      else {
        if (i == 1) {
          sql += " ( logtask='" + x[i] + "'"
        }
        else if (i == x.length - 1) {
          sql += " or logtask='" + x[i] + "')"
        }
        else {
          sql += " or logtask='" + x[i] + "'"
        }
      }


    }
    //console.log(sql);
    
    this.getdataser.getdata(sql).subscribe((data: any[]) => {
      if (data.length > 0) {
        //console.log("this.exceldata",this.exceldata);
        this.exceldata = data;
        //console.log("this.exceldata 2",this.exceldata);
        
        this.itemcount=this.exceldata.length.toString()
      }
      else{
        this.exceldata = [];
        this.itemcount="0"
      }
    })

  }

  clearfilters(){
  this.searchfilter = ""
  this.hrFiles= ""
  this.hrFiles1= ""
  this.dateValue= ""
  this.dateValue1= ""
  this.rtoday=""  
  this.rtoday1=""
  this.login1= false
  this.insert1 = false
  this.download1 = false
  this.update1 = false
  this.delete1 = false
  this.approve1 = false
  this.selecteduser="0"
  }
  getexcel() {
    let data = this.exceldata;
    //console.log("data", data);
    
    // for(let item of this.) {

    //     data.push({"username":item.username,"Action":item.logtask,"Time":item.logdate});
    // }
    return data;
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.getexcel());
     // console.log("worksheet",worksheet);

      // worksheet.eachCell((cell) => {
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'FFFFFF00' },
      //     bgColor: { argb: 'FF0000FF' }
      //   };
      //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      // });

      

      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
     // console.log("workbook",workbook);
      
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Report");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_' + new Date().toLocaleDateString().replace('/', "").replace('/',"") + EXCEL_EXTENSION);
    });
  }

  login(e) {
    if (e.target.checked == true) {
      document.querySelector("#insert").setAttribute("disabled", "true")
      document.querySelector("#down").setAttribute("disabled", "true")
      document.querySelector("#update").setAttribute("disabled", "true")
      document.querySelector("#delete").setAttribute("disabled", "true")
      document.querySelector("#approve").setAttribute("disabled", "true")
      this.searchfilter = '~login~logout'
      this.delete1 = false
      this.approve1 = false
      this.insert1 = false
      this.update1 = false
      this.download1 = false


    }
    else {
      document.querySelector("#insert").removeAttribute("disabled")
      document.querySelector("#down").removeAttribute("disabled")
      document.querySelector("#update").removeAttribute("disabled")
      document.querySelector("#delete").removeAttribute("disabled")
      document.querySelector("#approve").removeAttribute("disabled")
      this.searchfilter = this.searchfilter.replace('~login~logout', '')

    }
  }
  insert(i) {
    if (i.target.checked) {
      this.searchfilter += '~Insert'
      this.searchfilter = this.searchfilter.replace('~login~logout', '')
      this.login1 = false
    } else {
      this.searchfilter = this.searchfilter.replace('~Insert', '')
    }

  }
  down(e) {
    if (e.target.checked) {
      this.searchfilter += '~Download'
      this.searchfilter = this.searchfilter.replace('~login~logout', '')
      this.login1 = false
    } else {
      this.searchfilter = this.searchfilter.replace('~Download', '')
    }
  }
  update(e) {
    if (e.target.checked) {
      this.searchfilter += '~Update'
      this.searchfilter = this.searchfilter.replace('~login~logout', '')
      this.login1 = false
    } else {
      this.searchfilter = this.searchfilter.replace('~Update', '')
    }
  }
  delete(e) {
    if (e.target.checked) {
      this.searchfilter += '~Delete'
      this.searchfilter = this.searchfilter.replace('~login~logout', '')
      this.login1 = false
    } else {
      this.searchfilter = this.searchfilter.replace('~Delete', '')
    }
  }
  approve(e) {
    if (e.target.checked) {
      this.searchfilter += '~Approve'
      this.searchfilter = this.searchfilter.replace('~login~logout', '')
      this.login1 = false
    } else {
      this.searchfilter = this.searchfilter.replace('~Approve', '')
    }
  }

  checkpermission(){
    if (this.auth.isLoggednIn()) {
      var permission=0;
      let roles = "66";
      var permissionlist:PermissionModel[];
      permissionlist=JSON.parse(localStorage.getItem("userpermissions"));
      if(roles !=undefined){
        for(let i=0;i<permissionlist.length;i++){
          if(permissionlist[i].taskid.toString()==roles){
            permission=1;
            return true;
          }
      }
      if(permission==0){
        this.myRoute.navigate(["home"]);
            return false;
      }

      }
      else{
        return true;
      }
      
    } else {
      this.myRoute.navigate(["login"]);
      return false;
    }
  }

  selectTableName(){
    this.Action = "Reporter"
    let tableNameInside = $('#dataLog').val()
    switch(tableNameInside){
      case '0':{
        this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Table not selected' });
        break
      }
      case '1':{
        this.tableName = 'transaction_audio'
        break
      }
      case '2':{
        this.tableName = 'transaction_document'
        break
      }
      case '3':{
        this.tableName = 'transaction_image'
        break
      }
      case '4':{
        this.tableName = 'transaction_video'
        break
      }
      case '5':{
        this.tableName = 'transaction_hr'
        break
      }
      default:{
        this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warn Message', detail: 'Table not selected' });
        break
      }
    }
 
    
    
    
  }

  getFilterDataCategory(){
  this.getdataser.getTableDataLog(this.tableName,this.hrFiles,this.hrFiles1 ).subscribe((data:any)=>{
        // console.log(data);
         this.exceldata = data
      })
  }


  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categoryList = data;
       // console.log("categoryList",this.categoryList);
      }
    })
  }
  
selectCategory(){
  this.category = $('#category').val()
  this.getSubcategorydata(this.category)
}

  getSubcategorydata(category) {
       this.subcategoryService.getSubcategoryData(category).subscribe((data:any[]) => {
      if (data != null) {
        this.subCategoryList = data;
        //console.log("subCategoryList",this.subCategoryList);
      }
    })
  }

  selectSubCategory(){
    this.subcategory = $('#subcategory').val()
  this.getChildCategoryData(this.subcategory)
  }

  getChildCategoryData(subcategory) {
   // console.log("subcategory",subcategory);
    this.childCategoryService.getChildCategoryData(subcategory).subscribe((data: any[]) => {
      if (data != null) {
        this.childCategoryList = data
        //console.log("childCategoryList",this.childCategoryList);
      }
    })
  }

  selectChildCategory(){
    this.childCategory = $('#childCategory').val()
    //console.log("childCategory",this.childCategory);
    
  }

  // getFilterDataCategory(){
  //     let totalFilter = this.tableName+"/"+ this.category + "/" +  this.subcategory+ "/" + this.childCategory
  //     this.getdataser.getCategoryWiseTableData(totalFilter).subscribe(data=>{
  //       console.log(data);
  //       this.exceldata = data
  //     })
  // }
  clearFields(){
    // $('#dataLog').prop('selectedIndex', 0);
    $('#dataLog').val('0');
    this.tableName="0"
    this.category = "0"
    this.subcategory="0"
    this.childCategory="0"
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  HostListener,
  EventEmitter,
  Output,
  SimpleChanges,
} from "@angular/core";
import { HttpEventType, HttpResponse, HttpClient } from "@angular/common/http";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { MessageService, ConfirmationService, MenuItem } from "primeng/api";
import { Paginator } from "primeng/paginator";
import { ContextMenu } from "primeng/contextmenu";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  NgForm,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { CategoryService } from "src/app/services/masters/category.service";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { saveAs } from "file-saver";
import { DomSanitizer } from "@angular/platform-browser";
import * as JSZip from "jszip";
import { CoreEnvironment } from "@angular/compiler/src/compiler_facade_interface";
import { environment } from "src/environments/environment";
import Cropper from "cropperjs";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { UploadPopupComponent } from "../image-upload-popup/upload-popup.component";
import { EventService } from "src/app/services/masters/event.service";
import { Eventformmodel } from "src/app/model/master/event.form.model";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { cartmodel, cartoncartname } from "src/app/model/file/cartmodel";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { LeftMenuComponent } from "src/app/common/left-menu/left-menu.component";
import { async } from "@angular/core/testing";
import { formatDate, getLocaleFirstDayOfWeek } from "@angular/common";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { Autocomplete } from "src/app/services/autocomplete";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";
import { style } from "@angular/animations";
import { StoryserviceService } from "src/app/services/files/storyservice.service";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.css"],
})
export class ImageUploadComponent implements OnInit {
  @ViewChild("ytplayer", { static: false }) ytPlayer: ElementRef;
  @ViewChild("cropimagecanvas", { static: false }) cropimagecanvas: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: any;
  ctx: CanvasRenderingContext2D;
  downloaditems: MenuItem[];
  rightclickmenuopen: boolean = false;
  edititems: MenuItem[];
  contextmenuitem: MenuItem[];
  display: boolean = false;
  show: boolean = false;
  imagePath: any;
  showDownload: boolean = false;
  config: boolean;
  imageList: ImageUploadFormModel[] = [];
  fileurl;
  currentFileUpload: File;
  searchdata: ImageUploadFormModel[] = [];
  downloadList: downloadimagesmodel[] = [];
  getdowloadimagelist: downloadimagesmodel[] = [];
  //progress: { percentage: number } = { percentage: 0 };
  Searchbyfield: string = "";
  ImageUpdated: boolean = false;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Image_Edit_form: FormGroup;
  selectedimage: ImageUploadFormModel;
  imageuploadinformation: ImageUploadFormModel;
  first: number = 0;
  view: boolean = false;
  fullpathlist: ImageUploadFormModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalimagepath: string;
  ButtonStatus = "Download";
  eventlist: Eventformmodel;
  glbDelte: number = 0;
  glupdate: number = 0;
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  crppbtn: number = 0;
  approvebtn: number = 0;
  @ViewChild("image", { static: false }) public imageElement: ElementRef;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @Input("src")
  public imageSource: string;
  public imageDestination = "";
  private cropper: Cropper;
  adnvacesrchfields: string;
  cartname: string;
  cartitemid: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  cartlist: cartmodel[] = [];
  cartfinallist: cartoncartname[] = [];
  selectedNodes3: cartoncartname[];
  results: string[] = [];
  filteredBrands: any[];

  assigntoid: string = "null";
  dataforassign: cartmodel[];
  selectedCars3: cartmodel[];
  assignpopup: boolean = false;
  brightness: number = 0;
  contrast: number = 0;
  saturate: number = 0;
  watermark: string = "India Today";
  metadatadiv: number = 1;
  editdiv: number = 0;
  grin: string = "";
  cid: string = null;
  totalcount: string;
  loading: boolean = true;
  watermarkposition: string = "topright";
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexspermis: any[] = [];
  showeditor: boolean = false;
  iframepath: string = "";
  cols = [
    { field: "cartname", header: "Name" },
    //{ field: 'itemname', header: 'Item Name' },
  ];
  userlist: userformmodel[] = [];
  idforedit: string = "";
  edittable: string = "";
  nameforedit: string = "";
  orgname: string = "";
  showmax: number = 0;
  showmin: number = 1;
  addtocartbtn: number = 0;
  showaclrange: boolean = false;
  acldaterange: string = "";
  cdate: string = "";
  approvallst: any[] = [];
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  ismenuclick: boolean = false;
  tabcols: any[];
  loginuser: string = "";
  autoCompletArr: any[] = [];
  relationlist: string[] = [];
  filteredrelation: any[];
  showrelation: boolean = false;
  relationname: string;
  activeLtoDot: number = 0;
  microphone: number = 0;
  closeForm: any;
  indexPrev: any = null;
  indexCurrent: any;
  downloadPath:any=environment.download_path;
  constructor(
    public imageservice: ImageuploadService,
    private router: Router,
    private videoservice: VideouploadService,
    private route: ActivatedRoute,
    private speechTextService: SpeechTextService,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public eventservice: EventService,
    private userser: UserserviceService,
    public dashser: DashboardService,
    private storyservice: StoryserviceService
  ) {

    // console.log(environment.ApiServerPath,"API Server Path");
    // environment.ApiServerPath=sessionStorage.getItem("ApiServerPath");
    // environment.ApiBarcodePath=sessionStorage.getItem("ApiBarcodePath");
    // this.ApiServerPath=environment.ApiServerPath;
    this.cid = this.route.snapshot.paramMap.get("cid");
    if (this.cid != null) {
      //this.getcartdata(this.cid)
    }
    let cartlistsub = this.dashser
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashser
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashser
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashser
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngOnInit() {
    
    this.dashser.eventEmitter.subscribe((data) => {
      this.fullpathlist = data.message;
      // console.log(this.fullpathlist);
    });
    this.loginuser = JSON.parse(localStorage.getItem("userdetails"))[0][
      "UserId"
    ];
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    // const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    // if (savedApiServerPath) {
    //   this.ApiServerPath = savedApiServerPath;
    // }
    // console.log("Server path is", this.ApiServerPath);
    var x = new Date();
    this.checkpermissions();
    //this.search()
    if (this.cid == null) {
      // this.Searchbyfield = environment.globalsearchtext
      this.Searchbyfield = "";
      // this.adnvacesrchfields = environment.glbsearchstring
      this.adnvacesrchfields = "";
      if (this.Searchbyfield == null) {
        this.Searchbyfield = "";
      }
      if (this.Searchbyfield == "") {
        if (this.adnvacesrchfields.length < 1) {
          this.SearchByFields("");
        } else {
          this.advancesearch(this.adnvacesrchfields);
        }
      } else {
        this.SearchByFields("");
      }
    }

    this.dashser.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });

    this.selectedimage = new ImageUploadFormModel();
    this.fullpathlist = new ImageUploadFormModel();
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });

    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Add to cart",
        icon: "fa fa-shopping-cart",
        command: (event) => {
          this.showmulcartdiv();
        },
      },
      {
        label: "Delete",
        icon: "fa fa-trash",
        command: (event) => {
          this.deletemultipleimg();
        },
      },
      // {
      //   label: 'Add ACL',
      //   icon: 'fa fa-ban',
      //   command: (event) => { this.showacldaterangediv(); }
      // },
      // {
      //   label: 'Remove ACL',
      //   icon: 'fa fa-times-circle-o',
      //   command: (event) => { this.removeacl(); }
      // },
      {
        label: "Approve",
        icon: "fa fa-check",
        command: (event) => {
          this.approveimg();
        },
      },
      {
        label: "Edit",
        icon: "fa fa-edit",
        command: (event) => {
          this.multipleedit();
        },
      },
      // {
      //   label: 'Set Relationship',
      //   icon: 'fa fa-link',
      //   command: (event) => { this.showmulrelationpopup(); }
      // }
    ];

    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.speechTextService.init();
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  showacldaterangediv() {
    this.ismenuclick = true;
    this.showaclrange = true;
  }

  addacl() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"];
    if (this.acldaterange == "") {
      this.messageservice.add({
        key: "message",
        severity: "error",
        summary: "Warning Message",
        detail: "Please select a date",
      });
    } else if (this.selectedIndexs.length > 0) {
      // for (let i = 0; i < this.selectedIndexs.length; i++) {
      // console.log("this.selectedIndexs.length", this.selectedIndexs[i]);

      //   document.getElementById("n" + this.selectedIndexs[i]).style.display = "none"
      //   document.getElementById("e" + this.selectedIndexs[i]).style.display = "inline-block"
      // }
      this.imageservice
        .addacl(userid, this.selectedIndexs, this.acldaterange)
        .subscribe((data: any[]) => {
          let addAcl: any = data;
          // console.log("addAcl",addAcl);
          if (addAcl == "Success") {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "ACL successfully",
            });
            this.acldaterange = "";
            this.showaclrange = false;
            this.removeselection();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "ACL Failed",
      });
      this.acldaterange = "";
      this.showaclrange = false;
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  removeacl() {
    this.ismenuclick = true;
    if (this.selectedIndexs.length > 0) {
      // for (let i = 0; i < this.selectedIndexs.length; i++) {
      //   document.getElementById("e" + this.selectedIndexs[i]).style.display = "none"
      //   document.getElementById("n" + this.selectedIndexs[i]).style.display = "inline-block"
      // }
      var userid = JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"];
      this.imageservice
        .removeacl(userid, this.selectedIndexs)
        .subscribe((data: any[]) => {
          let removeAcl: any = data;
          if (removeAcl == "Success") {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "ACL Remove Successfully",
            });
            this.removeselection();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "ACL Remove Failed",
      });
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 10) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 11) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 12) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 51) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 44) {
        this.crppbtn = 1;
      }
      if (this.permissionlist[i].taskid == 54) {
        this.addtocartbtn = 1;
      }
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
    }
  }

  approveimg1(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.approvallst.push(id);
      this.approveimg();
      // this.confirmationService.confirm({
      //   message: 'Are you sure that you want to Approve?',
      //   header: 'Confirmation',
      //   icon: 'pi pi-exclamation-triangle',
      //   accept: () => {
      //     this.approvallst.push(id)
      //     this.approveimg()
      //   },
      //   reject: () => {

      //   }
      // });
    }
  }

  approveimg() {
    if (this.approvebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Approve?",
        header: "Confirmation",
        key: "imageuploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (this.selectedIndexs.length > 0) {
            this.approvallst = this.selectedIndexs;
          }

          if (this.approvallst.length > 0) {
            this.imageservice
              .approveimg(this.approvallst)
              .subscribe((data: any[]) => {
                // console.log(data);
                if (data != null) {
                  this.messageservice.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Approve Successfull",
                  });
                  for (let i = 0; i < this.approvallst.length; i++) {
                    document.getElementById(
                      "p" + this.approvallst[i]
                    ).style.display = "none";
                    // document.getElementById("pr" + this.approvallst[i]).style.display = "inline"
                    document.getElementById(
                      "a" + this.approvallst[i]
                    ).style.display = "inline";
                    if (i == this.approvallst.length - 1) {
                      this.approvallst = [];
                      this.rightclickmenuopen = false;
                      this.ismenuclick = false;
                      this.removeselection();
                    }
                  }
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "Approve Failed",
            });
          }
        },
        reject: () => {},
      });
    }
  }

  cmshow() {
    // console.log("cmshow clicked");
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    this.contextmenuitem[2].disabled = false;
    this.contextmenuitem[3].disabled = false;
    this.contextmenuitem[4].disabled = false;
    this.contextmenuitem[5].disabled = false;
    this.contextmenuitem[6].disabled = false;
    if (this.downloadbtn == 0) {
      this.contextmenuitem[0].disabled = true;
    }
    if (this.addtocartbtn == 0) {
      this.contextmenuitem[1].disabled = true;
    }
    if (this.deletebtn == 0) {
      this.contextmenuitem[2].disabled = true;
    }
    if (this.editbtn == 0) {
      this.contextmenuitem[3].disabled = true;
      this.contextmenuitem[4].disabled = true;
      this.contextmenuitem[6].disabled = true;
    }
    if (this.approvebtn == 0) {
      this.contextmenuitem[5].disabled = true;
    }
    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[3].disabled = true;
        this.contextmenuitem[4].disabled = true;
        this.contextmenuitem[5].disabled = true;
        this.contextmenuitem[6].disabled = true;
        break;
      }
    }
  }

  getitems(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.imageservice.getitems(itemid, "").subscribe((data: any[]) => {
        if (data != null) {
          this.fullimagepopup(data[0], e);
          this.activeLtoLink(data[0].ltoname);
        }
      });
    }
  }

  multipleedit() {
    if (this.editbtn == 1) {
      this.ismenuclick = true;
      var itemid = "";
      var nameforedit1 = "";
      var orgname = "";
      var path = "normal";
      var lst = this.selectedIndexs;

      for (let i = 0; i < this.selectedIndexs.length; i++) {
        itemid += this.selectedIndexs[i] + "~";
        orgname +=
          document
            .getElementById(lst[i].toString())
            .children[2].innerHTML.trim() + "~";
        if (i == this.selectedIndexs.length - 1) {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        }
      }
      this.ImageUpdated = true;
      this.idforedit = itemid;
      this.nameforedit = nameforedit1;
      this.edittable = path;
      this.orgname = orgname;
    }
  }
  onHide1() {
    this.display = false;
  }

  CreateImageEditForm() {
    this.Image_Edit_form = this.formBuilder.group({
      categoryId: [null],
      subcategoryid: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfiename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null, [this.removeSpaces]],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      title: [null],
      Source: [null],
      photogrphername: [null],
      copyright: [null],
      orietation: [null],
      shootdate: [null],
      itemid: [null],
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  cancel() {
    this.editdiv = 0;
    this.metadatadiv = 1;
    this.view = false;
    this.showfulldiv();
    this.imagePath = "";
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatus = "Download";
      //   document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  hideedit() {
    this.idforedit = "";
    this.orgname = "";
    this.ImageUpdated = false;
    this.showfulldiv();
  }

  Reset() {
    this.cropper.clear();
    this.editdiv = 0;
    this.metadatadiv = 1;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatus = "Download";
      // document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  showeditdiv(path, itemid) {
    this.dashser.createlog(
      itemid,
      "Edit_Download",
      JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
      "transaction_image"
    );
    this.showeditor = true;
    this.ytPlayer.nativeElement.src = environment.editorpath + path;
    //this.iframepath = environment.editorpath + path
  }
  hideeditor() {
    this.showeditor = false;
    this.iframepath = "";
  }

  CropedImageDownload(filename, filepath, itemid) {
    if (this.imageDestination != "") {
      // this.ButtonStatus='Cropped Download '
      //var downloadpath = this.imageDestination
      this.ctx = this.cropimagecanvas.nativeElement.getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.cropimagecanvas.nativeElement.width,
        this.cropimagecanvas.nativeElement.height
      );
      var image = new Image();
      image.src = this.imageDestination;
      this.cropimagecanvas.nativeElement.height = image.naturalHeight;
      this.cropimagecanvas.nativeElement.width = image.naturalWidth;
      if (this.contrast == 0) {
        this.ctx.filter = "contrast(none)";
      } else {
        this.ctx.filter = "contrast(" + this.contrast / 10 + ")";
      }
      if (this.brightness == 0) {
        this.ctx.filter = "brightness(none)";
      } else {
        this.ctx.filter = "brightness(" + this.brightness / 10 + ")";
      }
      if (this.saturate == 0) {
        this.ctx.filter = "saturate(none)";
      } else {
        this.ctx.filter = "saturate(" + this.saturate / 10 + ")";
      }
      if (this.grin == "") {
        this.ctx.filter = this.grin + "(none)";
      } else {
        this.ctx.filter = this.grin + "(1)";
      }
      //this.ctx.filter="brightness(25.5)";

      this.ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      if (this.watermark != "") {
        this.ctx.globalAlpha = 0.5;
        if (this.watermarkposition == "topright") {
          var fntsize = image.naturalWidth / 20;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.textAlign = "end";
          this.ctx.fillText(
            this.watermark,
            image.naturalWidth - 10,
            image.naturalHeight * 0.06,
            image.naturalWidth
          );
        } else {
          var fntsize = image.naturalWidth / 5;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.translate(0, image.naturalWidth * 0.12);
          this.ctx.rotate(
            Math.atan(
              image.naturalHeight /
                (image.naturalWidth + image.naturalWidth * 0.05)
            )
          );
          this.ctx.fillText(this.watermark, 0, 0, image.naturalWidth);
        }
        // setup text for filling
      }
      var downloadpath =
        this.cropimagecanvas.nativeElement.toDataURL("image/jpeg");
      this.LoadImageCrop(downloadpath, this.SaveCropImage, filename, itemid);
      this.Reset();
      this.downloadentry(itemid);
    } else {
      this.downloadFullImage(filename, filepath, itemid);
      this.downloadentry(itemid);
    }
  }
  private SaveCropImage(content: Blob, filename, itemid): void {
    saveAs(content, filename);
  }
  private LoadImageCrop(
    url: string,
    callback: Function,
    filename,
    itemid
  ): void {
    saveAs(url, filename);
  }

  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }
  cropimages() {
    this.ButtonStatus = "Cropped Download ";
    document.querySelector(".cropbtn").setAttribute("disabled", "true");
    // document.querySelector(".toolbox").removeAttribute("style")
    // document.querySelector(".toolbox").setAttribute("style","display:block")
    this.metadatadiv = 0;
    this.editdiv = 1;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 0,
      autoCropArea: 1,
      checkCrossOrigin: false,
      movable: false,
      viewMode: 2,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/jpeg");
      },
    });
  }
  async downloadFullImage(filename, filepath, itemid) {
    // console.log("download image")

    let unsub = this.storyservice
      .Testdownload(filename, filepath.trim(), itemid)
      .subscribe(async (data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();
        // var b64Data1 = data.split(";")[1]
        // const byteCharacters = atob(b64Data1);

        // const byteNumbers = new Array(byteCharacters.length);
        // for (let i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        // const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], { type: data.split(";")[0].split(":")[1] });
        // //let byteCharacters = atob(data);

        // var url = window.URL.createObjectURL(blob);
        // var a = document.createElement('a');
        // document.body.appendChild(a);
        // a.setAttribute('style', 'display: none');
        // a.href = url;
        // a.download = filename;
        // a.click();
        // window.URL.revokeObjectURL(url);
        // a.remove(); // remove the element
        // const file = new File([blob], filename);
      });

    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    this.dashser
      .createlog(
        itemid,
        "Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "transaction_image"
      )
      .subscribe((data1: any[]) => {});
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  updateimages(imageeditlist) {
    //this.getSubcategorydata(imageeditlist.categoryid)
    this.Image_Edit_form.setValue({
      itemid: imageeditlist.itemid,
      categoryid: imageeditlist.categoryid,
      subcategoryid: imageeditlist.subcategoryid,
      CountryId: imageeditlist.countryid,
      StateId: imageeditlist.stateid,
      CityId: imageeditlist.cityid,
      EventId: imageeditlist.eventid,
      OriginalPath: imageeditlist.OriginalPath,
      itemfiename: imageeditlist.orgfilename,
      itemsize: imageeditlist.itemsize,
      itemlength: imageeditlist.itemlength,
      itemformat: imageeditlist.itemformat,
      resolution: imageeditlist.resolution,
      itemcaption: imageeditlist.itemcaption,
      itemkeyword: imageeditlist.itemkeyword,
      location: imageeditlist.location,
      itemauthor: imageeditlist.itemauthor,
      eventdate: imageeditlist.eventdate,
      itemrating: imageeditlist.itemrating,
      title: imageeditlist.title,
      Source: imageeditlist.Source,
      photogrphername: imageeditlist.creator,
      copyright: imageeditlist.copyright,
      orietation: imageeditlist.genre,
      shootdate: imageeditlist.shootdate,
    });
  }

  fullimagepopup(imageList, e) {
    this.downloaditems = [
      {
        label: "Low",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.thumbpath,
            imageList.itemid
          );
        },
      },
      {
        label: "Medium",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.HiresPath,
            imageList.itemid
          );
        },
      },
      {
        label: "High",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.OriginalPath,
            imageList.itemid
          );
        },
      },
    ];

    // this.edititems = [
    //   {
    //     label: 'Low', command: () => {
    //       this.showeditdiv(imageList.thumbpath, imageList.itemid)
    //     }
    //   },
    //   {
    //     label: 'Medium', command: () => {
    //       this.showeditdiv(imageList.HiresPath, imageList.itemid)
    //     }
    //   },
    //   {
    //     label: 'High', command: () => {
    //       this.showeditdiv(imageList.OriginalPath, imageList.itemid)
    //     }
    //   },
    // ];
    this.fullpathlist = imageList;
    this.ratingitem = imageList.itemrating;
    this.imageservice
      .Testdownload(imageList.orgfilename, imageList.HiresPath)
      .subscribe((data: any) => {
        if (data != "error") {
          // var b64Data1 = data.split(";")[1]
          // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          //   + b64Data1);
          this.imagePath = this.ApiServerPath + imageList.HiresPath;
          // console.log(this.imagePath);
        } else {
          this.imagePath = "assets/image/noimagehires.png";
        }
      });
    this.view = true;
  }

  // fullimagepopup(imageList, e) {
  //   this.downloaditems = [
  //     {
  //       label: 'Low', command: () => {
  //         this.CropedImageDownload(imageList.orgfilename, imageList.thumbpath, imageList.itemid)
  //       }
  //     },
  //     {
  //       label: 'Medium', command: () => {
  //         this.CropedImageDownload(imageList.orgfilename, imageList.HiresPath, imageList.itemid)
  //       }
  //     },
  //     {
  //       label: 'High', command: () => {
  //         this.CropedImageDownload(imageList.orgfilename, imageList.OriginalPath, imageList.itemid)
  //       }
  //     },
  //   ];

  //   this.edititems = [
  //     {
  //       label: 'Low', command: () => {
  //         this.showeditdiv(imageList.thumbpath, imageList.itemid)
  //       }
  //     },
  //     {
  //       label: 'Medium', command: () => {
  //         this.showeditdiv(imageList.HiresPath, imageList.itemid)
  //       }
  //     },
  //     {
  //       label: 'High', command: () => {
  //         this.showeditdiv(imageList.OriginalPath, imageList.itemid)
  //       }
  //     },
  //   ];
  //   this.fullpathlist = imageList
  //   this.ratingitem = imageList.itemrating
  //   this.imageservice.Testdownload(imageList.HiresPath).subscribe((data: any) => {
  //     if (data != null) {
  //       var b64Data1 = data.split(";")[1]
  //       this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
  //         + b64Data1);

  //     }

  //   })
  //   this.view = true
  // }

  updatepopup(itemid, name, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "normal";
      }
      this.ImageUpdated = true;
      this.idforedit = itemid;
      this.nameforedit = name;
      this.edittable = path;
      this.orgname = name;
    }
  }

  SaveAfterUpdate(formval) {
    this.imageuploadinformation = formval.value;
    this.imageservice
      .UpdateImages(this.imageuploadinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          if (this.glupdate == 0) {
            this.SearchByFields("");
          } else {
            this.getAllImages("", "");
          }
          //this.Image_Edit_form.reset()
          this.ImageUpdated = false;
        }
      });
  }

  advanceSearch() {
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  showDialog() {
    this.display = true;
  }

  //this function is not working
  getTodayImages() {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.imageservice.getTodayImages().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.imageList = data;
        this.loading = false;
      } else {
        this.totalcount = "0";
        this.imageList = [];
      }
    });
  }

  //this function is not working
  SearchAllImages1(formvalues, fn) {
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.categoryid == "null") {
      formvalues.categoryid = null;
      formvalues.subcategoryid = null;
    }

    if (fn != "") {
      this.imageservice.SearchAllImages(formvalues).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.imageList = data;
          if (formvalues.totalcount != "") {
            this.totalcount = formvalues.totalcount;
          } else {
            this.totalcount = this.imageList.length.toString();
          }

          this.loading = false;
        } else {
          this.totalcount = "0";
          this.imageList = [];
        }
      });
      if (x != undefined) {
        x["style"].cursor = "pointer";
        x["style"]["pointer-events"] = "auto";
      }

      this.loading = false;
    } else {
      this.getAllImages("", "");
    }

    this.loading = false;
  }

  getAllImages(type, limit) {
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }

    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (type == "count") {
      this.totalcount = localStorage.getItem("imagecount");
      this.loading = false;
    } else {
      environment.glbsearchstring = "";
      environment.globalsearchtext = "";
      this.imageservice.GetAllImages("0", "image").subscribe((data: any[]) => {
        if (data.length > 0) {
          // console.log(data);
          
          this.imageList = data;
          this.totalcount = localStorage.getItem("imagecount");
          this.glbDelte = 1;
          this.glupdate = 1;
          this.loading = false;
        }
      });
      //var x=document.querySelector(".ui-paginator-bottom")
      if (x != undefined) {
        x["style"].cursor = "pointer";
        x["style"]["pointer-events"] = "auto";
      }
      this.loading = false;
    }
  }

  advancesearch(srctxt) {
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.hideadvncsrch();
    environment.glbsearchstring = srctxt;
    environment.globalsearchtext = "";
    var tbastr = localStorage.getItem("tabperstring");
    this.imageservice
      .getacount(srctxt, "image", tbastr)
      .subscribe((data: any[]) => {
        if (data.length > 0) this.totalcount = data[0].rowcount.toString();
      });

    this.imageservice
      .advancesearch(srctxt, "0", "image")
      .subscribe((data: any[]) => {
        if (data != null) {
          this.imageList = data;
          //this.totalcount = this.imageList.length.toString()
        } else {
          this.imageList = [];
        }

        this.loading = false;
      });
    if (x != undefined) {
      x["style"].cursor = "pointer";
      x["style"]["pointer-events"] = "auto";
    }
  }

  SearchByFields(type) {
    //this.first=0
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.microPhoneText != "") {
      this.Searchbyfield = this.microPhoneText;
    }
    this.Searchbyfield = this.Searchbyfield.trim();
    if (this.Searchbyfield.trim() != "") {
      environment.globalsearchtext = this.Searchbyfield;
      environment.glbsearchstring = "";
      var srchtxt = this.ExtractSearchingKeyword(this.Searchbyfield);
      var tbastr = localStorage.getItem("tabperstring");
      this.imageservice
        .getncount(srchtxt, "image", tbastr)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.totalcount = data[0].rowcount.toString();
          }
        });
      this.imageservice
        .SearchByFields(srchtxt, type, "0", "image")
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            //environment.glbsearchstring=""
            this.imageList = data;
            // this.totalcount = this.imageList.length.toString()
            this.loading = false;
          } else {
            this.totalcount = "0";
            this.imageList = [];
          }
        });
      if (x != undefined) {
        x["style"].cursor = "pointer";
        x["style"]["pointer-events"] = "auto";
      }

      this.loading = false;
    }
    // else if (this.microPhoneText != "") {
    //   console.log("else if");
    //   this.Searchbyfield = this.microPhoneText
    //   console.log("srchtext 2", this.Searchbyfield);
    //   this.blindsearchwithdata(type);
    // }
    else {
      this.getAllImages("", "");
    }
    this.stopMicroPhoneService();
    // this.Searchbyfield = ""
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.Searchbyfield = "";
    //this.microPhoneText = ""
    environment.globalsearchtext = "";
    this.SearchByFields("");
  }
  switchview(type) {
    this.tablestyle = type;
  }

  pagenochange(e) {
    this.indexPrev = null;
    this.removeselection();
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      var x = document.querySelector(".ui-paginator-bottom");
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
      this.loading = true;
      environment.globalsearchtext = environment.globalsearchtext.trim();
      if (environment.globalsearchtext.trim() != "") {
        var srchtxt = this.ExtractSearchingKeyword(
          environment.globalsearchtext
        );
        this.imageservice
          .SearchByFields(srchtxt, "", this.dataview1.totalRecords, "image")
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else if (environment.glbsearchstring != "") {
        this.imageservice
          .advancesearch(
            environment.glbsearchstring,
            this.dataview1.totalRecords,
            "image"
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        this.imageservice
          .GetAllImages(this.dataview1.totalRecords, "image")
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      }
    }

    this.removeselection();
  }
  getEventData() {
    //this.userinfomation=new userformmodel();
    this.eventservice.getAllEvent().subscribe((data: any) => {
      if (data.length > 0) {
        this.eventlist = data;
      }
    });
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  trashdata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "imageuploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.imageservice.TrashImages(itemid).subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "info",
                summary: "Success Message",
                detail: "Delete successfully",
              });
              // if(this.glbDelte==1){
              //   this.SearchByFields("")
              // }
              // else{
              //   //this.SearchByFields()
              // }
              this.SearchByFields("");
            }
          });
        },
        reject: () => {},
      });
    }
  }

  deletemultipleimg() {
    // console.log("delete");
    if (this.deletebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "imageuploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const lst = this.selectedIndexs;
          //console.log("selectedIndexs",this.selectedIndexs);
          if (lst.length > 0) {
            this.imageservice
              .deletemultipleimg(lst)
              .subscribe((data: any[]) => {
                let returnMsg: any = data;
                if (returnMsg == "Success") {
                  this.messageservice.add({
                    key: "message",
                    severity: "info",
                    summary: "Success Message",
                    detail: "Delete successfully",
                  });
                  this.rightclickmenuopen = false;
                  this.ismenuclick = false;
                  this.SearchByFields("");
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "File Not Deleted ",
            });
            this.rightclickmenuopen = false;
            this.ismenuclick = false;
          }
        },
        reject: () => {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        },
      });
    }
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.imageservice
      .GetDownloadedImageData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadList = data;
          this.showDownload = true;
        }
      });
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
    this.Searchbyfield = "";
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        lst.push(this.cartitemid);
        this.imageservice
          .addtocart(this.cartname, lst, "image", userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    // console.log("this.selectedIndexs", this.selectedIndexs);

    if (this.selectedIndexs.length > 0) {
      this.imageservice
        .addtocart(this.cartname, lst, "image", userid, "archieve")
        .subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Add to Cart successfully",
            });
            this.clearcartname();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "Add to Cart Failed",
      });
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  checkrelationid() {
    this.imageservice
      .checkrelationid(this.relationname)
      .subscribe((data: any) => {
        if (data.length > 0) {
          if (
            confirm(
              "Group exist do you want to merge these item(s) into this group?"
            )
          ) {
            this.setrealtionshipmul();
          } else {
          }
        } else {
          this.setrealtionshipmul();
        }
      });
  }

  setrealtionshipmul() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    this.imageservice
      .setrealtionshipmul(this.relationname, lst, "image", userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Relationed Successfully",
          });
          this.clearrelation();
        }
      });
  }

  clearrelation() {
    this.relationname = null;
    this.showrelation = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulrelationpopup() {
    this.showrelation = true;
    this.ismenuclick = true;
  }

  getrelationlist() {
    this.relationlist = [];
    this.imageservice.allrealtionlist().subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.relationlist.push(data[i].groupname);
        }
      }
    });
  }

  filterrealtion(event) {
    this.filteredrelation = [];
    if (event.query != "") {
      //this.glbkeywordsgst=event.query
      this.imageservice
        .getrelationsrch(event.query)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.relationlist = [];

            for (let i = 0; i < data.length; i++) {
              let brand = data[i].groupname;
              this.filteredrelation.push(brand);
              this.relationlist.push(brand);
            }
          }
        });
    } else {
      for (let i = 0; i < this.relationlist.length; i++) {
        let brand = this.relationlist[i];
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredrelation.push(brand);
        }
      }
    }
  }

  showcartdiv(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.showcart = true;
    }
  }

  showmulcartdiv() {
    if (this.addtocartbtn == 1) {
      this.showcart = true;
      this.ismenuclick = true;
    }
  }

  showcartlist() {
    this.cartfinallist = [];
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .bindcart("image", userid, "archieve")
      .subscribe((data: any[]) => {
        if (data != null) {
          this.cartlist = data;
          this.showcartbox = true;
        }
      });
  }

  downloadmultiplecart(data) {
    //this.leftmenu.downloadinggif=1
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    y["style"].display = "block";
    //x.className.replace("downloadinggif","")
    if (data != null) {
      if (data.length != undefined) {
        var id = "";
        for (let i = 0; i < data.length; i++) {
          id += data[i].cartid.toString() + "~";
          if (i == data.length - 1) {
            this.downloadcart(id);
          }
        }
      } else {
        this.downloadcart(data);
      }
    }
  }

  downloadcart(data1) {
    this.imageservice
      .downloadcart(data1.toString())
      .subscribe((data: any[]) => {
        if (data != null) {
          // this.downloadzipflder(data)
        }
      });
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.ImageUpdated = false;
    }
    if (event.keyCode == 27) {
      this.view = false;
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
    }
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event1: KeyboardEvent) {
    if (
      event1.ctrlKey == false &&
      event1.metaKey == false &&
      event1.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        try {
          this.conetxtmenudiv.hide();
        } catch {}
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }
  CloseDialogUpdate(event: any) {
    this.ImageUpdated = false;
  }
  changeiframurl() {
    alert("Error");
    this.iframepath = "http://www.dnavigation.com/Home";
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        }
      }
    }
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
        // console.log("_index",this.selectedIndexs);
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var fname = iid.children[2].innerHTML.trim();
      var filepath = iid.children[0].innerHTML.trim();
      //this.downloadFullImage(fname,filepath)
      filepath = filepath.replace("lowres/", "raw/");
      // console.log(filepath)
      if (lst.length > 1) {
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      } else {
        this.downloadFullImage(fname, filepath, imageid);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  downloadmultiplezip(arr) {
    this.imageservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  removefromcart(cartdata) {
    this.imageservice.removefromcart(cartdata).subscribe((data: any[]) => {
      this.showcartlist();
    });
  }
  hidecartbox() {
    this.showcartbox = false;
    this.selectedCars3 = null;
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  assign(cartdata) {
    if (cartdata.length > 0) {
      this.dataforassign = cartdata;
      this.assignpopup = true;
    }
    if (cartdata != null) {
      this.dataforassign = cartdata;
      this.assignpopup = true;
    }
  }

  assigncarttouser() {
    if (this.assigntoid != "null") {
      if (this.dataforassign.length != undefined) {
        for (let i = 0; i < this.dataforassign.length; i++) {
          var finaldata = this.dataforassign[i];
          this.imageservice
            .assigncart(finaldata, this.assigntoid)
            .subscribe((data: any[]) => {
              if (data != null) {
              }
            });
        }
      } else {
        var finaldata1 = this.dataforassign;
        this.imageservice
          .assigncart(finaldata1, this.assigntoid)
          .subscribe((data: any[]) => {
            if (data != null) {
            }
          });
      }

      this.hideassigndrop();
    }
  }

  hideassigndrop() {
    this.assignpopup = false;
    this.assigntoid = "null";
    this.dataforassign = null;
    this.selectedCars3 = null;
  }

  getuserdata() {
    this.userser.getuserdata().subscribe((data: userformmodel[]) => {
      if (data != null) {
        this.userlist = data;
      }
    });
  }

  // getcartdata(id) {
  //   this.imageservice.getcartdata(id, "image").subscribe((data: any[]) => {
  //     if (data.length > 0) {
  //       this.imageList = data
  //       this.totalcount = this.imageList.length.toString()
  //     } else {
  //       this.imageList = []
  //     }
  //     this.loading = false
  //   })
  //   this.showcartbox = false
  // }

  changecolor(id1) {
    var filter = "";
    var style = document
      .querySelector(".cropper-container")
      .getAttribute("style");
    var x = style.split(";");
    //var y=x[2]
    x[2] = "";
    var bvalue = this.brightness / 10;
    var cvalue = this.contrast / 10;
    var svalue = this.saturate / 10;
    if (bvalue > 0) {
      filter += "brightness(" + bvalue + ") ";
    }
    if (cvalue > 0) {
      filter += "contrast(" + cvalue + ") ";
    }
    if (svalue > 0) {
      filter += "saturate(" + svalue + ")";
    }
    if (this.grin != "") {
      filter += this.grin + "(1)";
    }
    x[2] = "filter:" + filter;
    //filter=id1+"("+bvalue+");"
    if (filter != "") {
      style = x[0] + ";" + x[1] + ";" + x[2] + ";";
    } else {
      style = x[0] + ";" + x[1] + ";";
    }

    document.querySelector(".cropper-container").removeAttribute("style");
    document
      .querySelector(".cropper-container")
      .setAttribute("style", "" + style + "");
  }
  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashser.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.Searchbyfield = this.Searchbyfield.substring(
      0,
      this.Searchbyfield.lastIndexOf(" ")
    );
    this.Searchbyfield = this.Searchbyfield.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  activeLtoLink(ltoIdVideoData) {
    // if (ltoIdVideoData != '') {
    //   this.videoservice.activeLtoLink().subscribe((data: any) => {
    //     if (data.length != 0) {
    //       var ltoIdArchive = data[0].ltoid
    //       if (ltoIdArchive == ltoIdVideoData) {
    //         this.activeLtoDot = 1;
    //       }
    //       else {
    //         this.activeLtoDot = 0;
    //       }
    //     }
    //   })
    // }
  }

  // value = function get(){
  //   return this.speechTextService.text
  // }

  //getting microphone text from its service
  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startMicroPhoneService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopMicroPhoneService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }
}

import { Component, OnInit } from "@angular/core";
import { PoolFormModel } from "src/app/model/master/pool.formmodel";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { PublicationFormModel } from "src/app/model/master/publication.formmodel";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { PoolService } from "src/app/services/masters/pool.service";

@Component({
  selector: "app-pool",
  templateUrl: "./pool.component.html",
  styleUrls: ["./pool.component.css"],
})
export class PoolComponent implements OnInit {
  poollist: PoolFormModel[] = [];
  poolinformation: PoolFormModel;
  PoolForm: FormGroup;
  publicationlist: PublicationFormModel[] = [];
  msgs: Message[] = [];
  Header: string = "Add Pool";
  ButtonStatus: string = "Save";
  selectedpool: string;
  constructor(
    private poolservice: PoolService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getPoolName();
    this.CreatePoolForm();
    this.getPublicationDropDown();
  }
  getPoolName() {
    this.poolservice.getAllPool().subscribe((data: PoolFormModel[]) => {
      if (data != null) {
        this.poollist = data;
      }
    });
  }
  CreatePoolForm() {
    this.PoolForm = this.formBuilder.group({
      PoolName: [
        null,
        [
          Validators.required,
          this.removeSpaces,
          Validators.pattern("^[a-zA-Z ]*$"),
        ],
      ],
      PoolShortName: [
        null,
        [
          Validators.required,
          this.removeSpaces,
          Validators.pattern("^[a-zA-Z ]*$"),
        ],
      ],
      PoolId: [null],
      PublicationId: [null],
      userid: [null],
    });
    this.PoolForm.patchValue({ PublicationId: 0 });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SavePool(formval) {
    this.poolinformation = formval.value;
    this.poolinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.poolinformation.PoolId > 0) {
      if (this.poolinformation.PublicationId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Publication",
        });
      } else {
        if (this.poolinformation.PoolName != this.selectedpool) {
          this.poolservice
            .checkduplicate(this.poolinformation)
            .subscribe((data: any) => {
              if (data.length == 0) {
                this.poolservice
                  .UpdatePool(this.poolinformation)
                  .subscribe((data: any) => {
                    this.getPoolName();
                    this.PoolForm.reset();
                    this.messageService.add({
                      key: "message",
                      severity: "success",
                      summary: "Success Message",
                      detail: "Update successfully",
                    });
                    this.ButtonStatus = "Save";
                    this.PoolForm.patchValue({ PublicationId: 0 });
                  });
              } else {
                this.messageService.add({
                  key: "message",
                  severity: "error",
                  summary: "Warn Message",
                  detail: "Pool Already Exist",
                });
              }
            });
        } else {
          this.poolservice
            .UpdatePool(this.poolinformation)
            .subscribe((data: any) => {
              this.getPoolName();
              this.PoolForm.reset();
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Update successfully",
              });
              this.ButtonStatus = "Save";
              this.PoolForm.patchValue({ PublicationId: 0 });
            });
        }
      }
    } else {
      if (this.poolinformation.PublicationId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Publication",
        });
      } else {
        this.poolservice
          .checkduplicate(this.poolinformation)
          .subscribe((data: any) => {
            if (data.length == 0) {
              this.poolservice
                .CreatePool(this.poolinformation)
                .subscribe((data: any) => {
                  this.getPoolName();
                  this.PoolForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Save successfully",
                  });
                  this.PoolForm.patchValue({ PublicationId: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "Pool Already Exist",
              });
            }
          });
      }
    }
  }

  getPublicationDropDown() {
    this.poolservice
      .getpublicationDropDown()
      .subscribe((data: PublicationFormModel[]) => {
        if (data != null) {
          this.publicationlist = data;
        }
      });
  }
  updatepool(poollist: PoolFormModel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Pool";
    this.PoolForm.setValue({
      PoolName: poollist.PoolName,
      PoolShortName: poollist.PoolShortName,
      PublicationId: poollist.PublicationId,
      PoolId: poollist.PoolId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeletePool(PoolId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "poolkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.poolservice.DeletePool(PoolId).subscribe((data: any[]) => {
          this.getPoolName();
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.PoolForm.reset();
          this.PoolForm.patchValue({ PublicationId: 0 });
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  clear() {
    this.PoolForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Pool";
    this.PoolForm.patchValue({ PublicationId: 0 });
  }
}

import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { environment } from "src/environments/environment";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { CategoryService } from "src/app/services/masters/category.service";
import { Router } from "@angular/router";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { ConfirmationService, MessageService, MenuItem } from "primeng/api";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DashboardService } from "src/app/services/files/dashboard.service";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { cartmodel } from "src/app/model/file/cartmodel";
import { ContextMenu, Dialog, Menu } from "primeng/primeng";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";

@Component({
  selector: "app-document-upload",
  templateUrl: "./document-upload.component.html",
  styleUrls: ["./document-upload.component.css"],
})
export class DocumentUploadComponent implements OnInit {
  @ViewChild("ytplayer", { static: false }) ytPlayer: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: DataView;
  display: boolean = false;
  show: boolean = false;
  showDownload: boolean = false;
  showDocumentSearch: boolean = false;
  documentList: DocumentUploadFormModel[] = [];
  searchdata: DocumentUploadFormModel[] = [];
  downloadDocumentList: downloadimagesmodel[] = [];
  getdowloadDocumentlist: downloadimagesmodel[] = [];
  //progress: { percentage: number } = { percentage: 0 };
  Searchbyfield: string;
  DocumentUpdated: boolean = false;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Document_Edit_form: FormGroup;
  selecteddocument: DocumentUploadFormModel;
  documentuploadinformation: DocumentUploadFormModel;
  showDocumentDownload: boolean = false;
  viewDocument: boolean = false;
  fullDocumentpathlist: DocumentUploadFormModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalDocumentpath = "assets/image/audio.jpg";
  glbDelete: number = 0;
  glbupdate: number = 0;
  fileContent: string = "";
  text: string;
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  pdfimages: any[];
  loading: boolean = true;
  totalcount: string;
  // ButtonStatus='Download'
  adnvacesrchfields: string;
  cartname: string;
  cartitemid: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  selectedIndexs: DocumentUploadFormModel[] = [];
  selectedIndexspermis: any[] = [];
  results: string[] = [];
  filteredBrands: any[];
  assigntoid: string = "null";
  dataforassign: cartmodel[];
  selectedCars3: cartmodel[];
  assignpopup: boolean = false;
  contextmenuitem: MenuItem[];
  docfileforup: string = "";
  idforedit: string = "";
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  addtocartbtn: number = 0;
  showaclrange: boolean = false;
  acldaterange: string = "";
  cdate: string = "";
  edittable: string = "";
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  ismenuclick: boolean = false;
  rightclickmenuopen: boolean = false;
  treedropvisible: boolean = false;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @ViewChild("editdialog", { static: true }) editdialog: Dialog;
  autoCompletArr: any[] = [];
  orgname: string = "";
  relationlist: string[] = [];
  filteredrelation: any[];
  showrelation: boolean = false;
  relationname: string;
  fulltextdata: string = "";
  isfulltext: boolean = false;
  approvebtn: number = 0;
  approvallst: any[] = [];
  microphone: number = 0;
  indexPrev: any = null;
  indexCurrent: any;

  constructor(
    private router: Router,
    private storyservice: StoryserviceService,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    public subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    public sanitizer: DomSanitizer,
    private speechTextService: SpeechTextService,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public documentservice: DocumentuploadService,
    private dashboarservice: DashboardService,
    public storyser: StoryserviceService
  ) {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngOnInit() {
    this.dashboarservice.eventEmitter.subscribe((data) => {
      this.fullDocumentpathlist = data.message;
      // console.log(this.fullDocumentpathlist);
    });
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    // const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    // if (savedApiServerPath) {
    //   this.ApiServerPath = savedApiServerPath;
    // }
    // console.log("Server path is", this.ApiServerPath);
    this.checkpermissions();
    this.dashboarservice.visibletreedrop(false);
    this.editdialog.onShow.subscribe({
      next: this.handleOnShow.bind(this),
    });
    this.dashboarservice.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });
    //this.CreateDocumentEditForm()
    //this.getcategorydata()
    // this.Searchbyfield = environment.globalsearchtext
    this.Searchbyfield = "";
    // this.adnvacesrchfields = environment.glbsearchstring
    this.adnvacesrchfields = "";
    if (this.Searchbyfield == null) {
      this.Searchbyfield = "";
    }
    if (this.Searchbyfield == "") {
      if (this.adnvacesrchfields.length < 1) {
        this.SearchByFields();
      } else {
        this.newadvancesearch(this.adnvacesrchfields);
      }
    } else {
      this.SearchByFields();
    }
    //this.getTodayDocument()
    //this.search()
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Add to cart",
        icon: "fa fa-shopping-cart",
        command: (event) => {
          this.showmulcartdiv();
        },
      },
      // {
      //   label: 'Delete',
      //   icon: 'fa fa-trash',
      //   command: (event) => { this.deletemultipledoc(); }
      // },
      {
        label: "Add ACL",
        icon: "fa fa-ban",
        command: (event) => {
          this.showacldaterangediv();
        },
      },
      {
        label: "Remove ACL",
        icon: "fa fa-times-circle-o",
        command: (event) => {
          this.removeacl();
        },
      },
      {
        label: "Edit",
        icon: "fa fa-edit",
        command: (event) => {
          this.multipleedit();
        },
      },
      // {
      //   label: "Set Relationship",
      //   icon: "fa fa-link",
      //   command: (event) => {
      //     this.showmulrelationpopup();
      //   },
      // },
    ];
    this.fullDocumentpathlist = new DocumentUploadFormModel();
    this.selecteddocument = new DocumentUploadFormModel();
    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.speechTextService.init();
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }
  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 13) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 14) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 15) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 55) {
        this.addtocartbtn = 1;
      }
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
    }
  }

  cmshow() {
    // console.log("cmshow clicked");
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    this.contextmenuitem[2].disabled = false;
    this.contextmenuitem[3].disabled = false;
    this.contextmenuitem[4].disabled = false;
    this.contextmenuitem[5].disabled = false;
    if (this.downloadbtn == 0) {
      this.contextmenuitem[0].disabled = true;
    }
    if (this.addtocartbtn == 0) {
      this.contextmenuitem[1].disabled = true;
    }
    if (this.deletebtn == 0) {
      this.contextmenuitem[2].disabled = true;
    }
    if (this.editbtn == 0) {
      this.contextmenuitem[3].disabled = true;
      this.contextmenuitem[4].disabled = true;
      this.contextmenuitem[5].disabled = true;
    }
    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[3].disabled = true;
        this.contextmenuitem[4].disabled = true;
        this.contextmenuitem[5].disabled = true;
        break;
      }
    }
  }

  handleOnShow() {
    this.editdialog.contentViewChild.nativeElement.addEventListener(
      "scroll",
      this.scroll.bind(this)
    );
  }

  scroll(e: Event) {
    //alert("scroll")
    this.dashboarservice.visibletreedrop(false);
  }

  showacldaterangediv() {
    this.showaclrange = true;
    this.ismenuclick = true;
  }

  multipleedit() {
    if (this.editbtn == 1) {
      this.ismenuclick = true;
      var itemid = "";
      var nameforedit1 = "";
      var orgname1 = "";
      var path = "normal";
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        itemid += this.selectedIndexs[i] + "~";
        orgname1 +=
          document
            .getElementById(this.selectedIndexs[i].toString())
            .children[2].innerHTML.trim() + "~";
        if (i == this.selectedIndexs.length - 1) {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        }
      }
      this.DocumentUpdated = true;
      this.idforedit = itemid;
      this.docfileforup = nameforedit1;
      this.edittable = path;
      this.orgname = orgname1;
    }
  }

  addacl() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"];
    if (this.acldaterange == "") {
      this.messageservice.add({
        key: "message",
        severity: "error",
        summary: "Warning Message",
        detail: "Please select a date",
      });
    } else if (this.selectedIndexs.length > 0) {
      // for (let i = 0; i < this.selectedIndexs.length; i++) {
      //   document.getElementById("n" + this.selectedIndexs[i]).style.display = "none"
      //   document.getElementById("e" + this.selectedIndexs[i]).style.display = "inline-block"
      // }
      this.documentservice
        .addacl(userid, this.selectedIndexs, this.acldaterange)
        .subscribe((data: any[]) => {
          let addAcl: any = data;
          if (addAcl == "Success") {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "ACL successfully",
            });
            this.acldaterange = "";
            this.showaclrange = false;
            this.removeselection();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "ACL Failed",
      });
      this.acldaterange = "";
      this.showaclrange = false;
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  removeacl() {
    this.ismenuclick = true;

    if (this.selectedIndexs.length > 0) {
      // for (let i = 0; i < this.selectedIndexs.length; i++) {
      //   document.getElementById("e" + this.selectedIndexs[i]).style.display = "none"
      //   document.getElementById("n" + this.selectedIndexs[i]).style.display = "inline-block"
      // }
      var userid = JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"];
      this.documentservice
        .removeacl(userid, this.selectedIndexs)
        .subscribe((data: any[]) => {
          let removeAcl: any = data;
          if (removeAcl == "Success") {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "ACL remove successfully",
            });
            this.removeselection();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "ACL Remove Failed",
      });
    }

    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  CreateDocumentEditForm() {
    this.Document_Edit_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      itemcaption: [null],
      itemkeyword: [null],
      itemrating: [null],
      itemid: [null],
    });
  }
  updatedocument(documenteditlist) {
    this.getSubcategorydata(documenteditlist.categoryid);
    this.Document_Edit_form.setValue({
      CategoryId: documenteditlist.categoryid,
      SubCategoryId: documenteditlist.subcategoryid,
      itemcaption: documenteditlist.itemcaption,
      itemkeyword: documenteditlist.itemkeyword,
      itemrating: documenteditlist.itemrating,
      itemid: documenteditlist.itemid,
    });
  }
  updatepopup(itemid, name, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "normal";
      }
      this.DocumentUpdated = true;
      this.docfileforup = name;
      this.idforedit = itemid;
      this.edittable = path;
      this.orgname = name;
      //this.updatedocument(documentList)
    }
  }

  SaveAfterUpdate(formval) {
    this.documentuploadinformation = formval.value;
    this.documentservice
      .UpdateDocument(this.documentuploadinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          this.getAllDocument();
          this.Document_Edit_form.reset();
          this.DocumentUpdated = false;
        }
      });
  }
  showDialog() {
    this.display = true;
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  hidepopup() {
    this.ytPlayer.nativeElement.src = "";
    this.viewDocument = false;
    this.showfulldiv();
  }

  hideedit() {
    this.idforedit = "";
    this.orgname = "";
    this.DocumentUpdated = false;
    this.treedropvisible = false;
    this.showfulldiv();
  }

  advanceSearch() {
    this.Searchbyfield = this.Searchbyfield;
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
    this.Searchbyfield = "";
  }
  onHide1() {
    this.display = false;
  }

  getAllDocument() {
    this.removeselection();
    this.loading = true;

    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    environment.glbsearchstring = "";
    environment.globalsearchtext = "";
    this.documentservice.GetAllDocument("0").subscribe((data: any[]) => {
      if (data != null) {
        this.documentList = data;
        this.dashboarservice.GetAllDocument().subscribe((data: any[]) => {
          if (data != null) {
            this.totalcount = localStorage.getItem("documentcount");
            localStorage.setItem("documentcount", this.totalcount.toString());
          }
        });
        this.totalcount = localStorage.getItem("documentcount");
        this.glbDelete = 1;
        this.glbupdate = 1;
      } else {
        this.documentList = [];
        this.totalcount = "0";
      }
    });
    this.loading = false;
  }

  SearchByFields() {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.microPhoneText != "") {
      this.Searchbyfield = this.microPhoneText;
    }
    this.Searchbyfield = this.Searchbyfield.trim();
    if (this.Searchbyfield == null || this.Searchbyfield.trim() == "") {
      this.getAllDocument();
    }
    // else if (this.microPhoneText != "") {
    //   console.log("else if");
    //   this.Searchbyfield = this.microPhoneText
    //   console.log("srchtext 2", this.Searchbyfield);
    //   this.blindsearchwithdata(type);
    // }
    else {
      var txt = this.Searchbyfield;
      environment.globalsearchtext = this.Searchbyfield;
      environment.glbsearchstring = "";

      txt = this.ExtractSearchingKeyword(txt);
      var tbastr = localStorage.getItem("tabperstring");
      this.documentservice
        .getncount(txt, this.isfulltext, tbastr)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.totalcount = data[0].rowcount.toString();
          }
        });
      this.documentservice
        .SearchByFields(txt, "0", this.isfulltext)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.documentList = data;
            //this.totalcount=this.documentList.length.toString()
          } else {
            this.documentList = [];
            this.totalcount = "0";
          }
        });
    }
    this.loading = false;
    this.stopMicroPhoneService();
    // this.Searchbyfield = ""
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.Searchbyfield = "";
    //this.microPhoneText = ""
    environment.globalsearchtext = "";
    this.SearchByFields();
  }

  fulltextsrch(data) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.hideadvncsrch();
    data = data.trim();

    var txt = data;
    environment.globalsearchtext = "";
    environment.glbsearchstring = "";
    this.fulltextdata = txt;
    txt = this.ExtractSearchingKeyword(txt);
    this.documentservice.getfulltxtcount(txt).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.totalcount = data[0].rowcount.toString();
      }
    });
    this.documentservice.fulltextsrch(txt, "0").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.documentList = data;
        //this.totalcount=this.documentList.length.toString()
      } else {
        this.documentList = [];
        this.totalcount = "0";
      }
    });

    this.loading = false;
  }

  newadvancesearch(srchtxt) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.hideadvncsrch();
    environment.glbsearchstring = srchtxt;
    environment.globalsearchtext = "";
    var tbastr = localStorage.getItem("tabperstring");
    this.documentservice.getacount(srchtxt, tbastr).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.totalcount = data[0].rowcount.toString();
      }
    });
    this.documentservice
      .advancesearch(srchtxt, "0")
      .subscribe((data: any[]) => {
        if (data != null) {
          this.documentList = data;
          //this.totalcount=this.documentList.length.toString()
        } else {
          this.documentList = [];
        }
        this.loading = false;
      });
  }

  pagenochange(e) {
    this.indexPrev = null;
    this.removeselection();

    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      if (
        this.fulltextdata != "" &&
        this.fulltextdata != null &&
        this.fulltextdata != undefined
      ) {
        var x = document.querySelector(".ui-paginator-bottom");
        x["style"].cursor = "not-allowed";
        x["style"]["pointer-events"] = "none";
        this.loading = true;
        //environment.globalsearchtext=environment.globalsearchtext.trim()
        var srchtxt = this.ExtractSearchingKeyword(this.fulltextdata);
        this.documentservice
          .fulltextsrch(srchtxt, this.dataview1.totalRecords)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        var x = document.querySelector(".ui-paginator-bottom");
        x["style"].cursor = "not-allowed";
        x["style"]["pointer-events"] = "none";
        this.loading = true;
        environment.globalsearchtext = environment.globalsearchtext.trim();
        if (environment.globalsearchtext.trim() != "") {
          var srchtxt = this.ExtractSearchingKeyword(
            environment.globalsearchtext
          );
          this.documentservice
            .SearchByFields(
              srchtxt,
              this.dataview1.totalRecords,
              this.isfulltext
            )
            .subscribe((data: any[]) => {
              if (data.length > 0) {
                if (
                  Number(this.dataview1.totalRecords) >= Number(this.totalcount)
                ) {
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                } else {
                  this.dataview1.totalRecords =
                    this.dataview1.totalRecords + data.length;
                  var len = data.length;
                  for (let i = 0; i < len; i++) {
                    this.dataview1._value.push(data[i]);
                  }
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                }
              } else {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            });
        } else if (environment.glbsearchstring != "") {
          this.documentservice
            .advancesearch(
              environment.glbsearchstring,
              this.dataview1.totalRecords
            )
            .subscribe((data: any[]) => {
              if (data.length > 0) {
                if (
                  Number(this.dataview1.totalRecords) >= Number(this.totalcount)
                ) {
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                } else {
                  this.dataview1.totalRecords =
                    this.dataview1.totalRecords + data.length;
                  var len = data.length;
                  for (let i = 0; i < len; i++) {
                    this.dataview1._value.push(data[i]);
                  }
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                }
              } else {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            });
        } else {
          this.documentservice
            .GetAllDocument(this.dataview1.totalRecords)
            .subscribe((data: any[]) => {
              if (data.length > 0) {
                if (
                  Number(this.dataview1.totalRecords) >= Number(this.totalcount)
                ) {
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                } else {
                  this.dataview1.totalRecords =
                    this.dataview1.totalRecords + data.length;
                  var len = data.length;
                  for (let i = 0; i < len; i++) {
                    this.dataview1._value.push(data[i]);
                  }
                  x["style"].cursor = "pointer";
                  x["style"]["pointer-events"] = "auto";
                  this.loading = false;
                }
              } else {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            });
        }
      }
    }

    this.removeselection();
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.DocumentUpdated = false;
    }
    if (event.keyCode == 27) {
      this.viewDocument = false;
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
    }
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        // let _index = event['path'][3]['id']
        try {
          this.conetxtmenudiv.hide();
        } catch {}
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }
  getdocumentdata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.dashboarservice
        .getdocumentdata(itemid.trim(), path)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.fullDocumentpopup(data[0], e);
          }
        });
    }
  }
  CloseDialogUpdate(event: any) {
    this.DocumentUpdated = false;
  }

  fullDocumentpopup(documentList, e) {
    this.fullDocumentpathlist = documentList;
    this.ratingitem = documentList.itemrating;

    setTimeout(() => {
      try {
        this.ytPlayer.nativeElement.src =
          this.ApiServerPath + documentList.OriginalPath;
      } catch (err) {}
    }, 500);
    //this.pdfpath=this.ApiServerPath+documentList.OriginalPath

    // if(this.fullDocumentpathlist.itemtype.toLowerCase()=="pdf"){
    //   this.getpdfimg(this.fullDocumentpathlist.itemid)
    // }
    if (this.fullDocumentpathlist.itemtype.toLowerCase() == "txt") {
      this.Textread(documentList.itemfilename, documentList.OriginalPath);
    }
    this.viewDocument = true;
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  SearchAllDocument(formvalues) {
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }

    this.documentservice
      .SearchAllDocument(formvalues)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.documentList = data;
        } else {
          this.documentList = [];
        }
      });
  }

  //function is not working
  getTodayDocument() {
    this.documentservice.getTodayDocument().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.documentList = data;
      } else {
        this.documentList = [];
      }
    });
  }

  downloadDocument(filename, filepath, itemid) {
    this.documentservice
      .Testdownload(filename, filepath.trim(), itemid)
      .subscribe((data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        this.downloadentry(itemid);
        var x = document.querySelector(".downloadinggif1");
        //x.classList.add("downloadinggif")
        x["style"].display = "none";
        var y = document.querySelector(".downloadback");
        y["style"].display = "none";
        this.removeselection();
      });
  }

  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  close() {
    this.viewDocument = false;
  }

  trashdata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "documentuploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.documentservice
            .deleteDocument(itemid)
            .subscribe((data: any[]) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "info",
                  summary: "Success Message",
                  detail: "Delete successfully",
                });
                this.getAllDocument();
              }
            });
        },
        reject: () => {},
      });
    }
  }

  deletemultipledoc() {
    if (this.deletebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        key: "documentuploadkey",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const lst = this.selectedIndexs;
          if (lst.length > 0) {
            this.documentservice
              .deletemultipledoc(lst)
              .subscribe((data: any[]) => {
                let returnMsg: any = data;
                if (returnMsg == "Success") {
                  this.messageservice.add({
                    key: "message",
                    severity: "info",
                    summary: "Success Message",
                    detail: "Delete successfully",
                  });
                  this.rightclickmenuopen = false;
                  this.ismenuclick = false;
                  this.getAllDocument();
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "File Not Deleted ",
            });
            this.rightclickmenuopen = false;
            this.ismenuclick = false;
          }
        },
        reject: () => {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        },
      });
    }
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.documentservice
      .GetDownloadedDocumentData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadDocumentList = data;
          this.showDocumentDownload = true;
        }
      });
  }

  Textread(filename, filepath) {
    this.documentservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data;
        }
      });
  }

  //function is not working
  getpdfimg(itemid) {
    this.documentservice.getpdfimg(itemid).subscribe((data: any) => {
      if (data != null) {
        this.pdfimages = data;
      }
    });
  }

  showzoom(itemid, fname) {
    window.open(this.ApiServerPath + fname, "_blank", "popupwindow");
    //window.open("#/pdfpreview;id="+itemid+";fname="+fname,"_blank")
  }

  showcartdiv(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.showcart = true;
    }
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        lst.push(this.cartitemid);
        this.documentservice
          .addtocart(this.cartname, lst, "document", userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    if (this.selectedIndexs.length > 0) {
      this.documentservice
        .addtocart(this.cartname, lst, "document", userid, "archieve")
        .subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Add to Cart successfully",
            });
            this.clearcartname();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "Add to Cart Failed",
      });
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
        }
      }
      this.rightclickmenuopen = false;
      this.ismenuclick = false;
    }
  }
  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  // filterBrands(event) {
  //   this.filteredBrands = [];
  //   for (let i = 0; i < this.results.length; i++) {
  //     let brand = this.results[i];
  //     if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
  //       this.filteredBrands.push(brand);
  //     }
  //   }
  // }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  async downloadmultiple() {
    //console.log("download");

    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    //x.className.replace("downloadinggif","")
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var fname = iid.children[2].innerHTML.trim();
      var filepath = iid.children[0].innerHTML.trim();
      //this.downloadDocument(fname,filepath,imageid)
      if (lst.length > 1) {
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
          this.removeselection();
        }
      } else {
        this.downloadDocument(fname, filepath, imageid);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  downloadmultiplezip(arr) {
    this.documentservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
      // if (data.size >0) {
      //   // this.downloadzipflder(data)
      // }
    });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + filename;
    //this.documentservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    //this.removeselection()
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    this.documentservice.deletezip(cartname).subscribe((data: any[]) => {});

    //})
  }

  showmulcartdiv() {
    if (this.addtocartbtn == 1) {
      this.showcart = true;
      this.ismenuclick = true;
    }
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashboarservice.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.Searchbyfield = this.Searchbyfield.substring(
      0,
      this.Searchbyfield.lastIndexOf(" ")
    );
    this.Searchbyfield = this.Searchbyfield.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  checkrelationid() {
    this.documentservice
      .checkrelationid(this.relationname)
      .subscribe((data: any) => {
        if (data.length > 0) {
          if (
            confirm(
              "Group exist do you want to merge these item(s) into this group?"
            )
          ) {
            this.setrealtionshipmul();
          } else {
          }
        } else {
          this.setrealtionshipmul();
        }
      });
  }

  setrealtionshipmul() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    this.documentservice
      .setrealtionshipmul(
        this.relationname,
        lst,
        "document",
        userid,
        "archieve"
      )
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Relationed Successfully",
          });
          this.clearrelation();
        }
      });
  }

  clearrelation() {
    this.relationname = null;
    this.showrelation = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulrelationpopup() {
    this.showrelation = true;
    this.ismenuclick = true;
  }

  getrelationlist() {
    this.relationlist = [];
    this.documentservice.allrealtionlist().subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.relationlist.push(data[i].groupname);
        }
      }
    });
  }

  filterrealtion(event) {
    this.filteredrelation = [];
    if (event.query != "") {
      //this.glbkeywordsgst=event.query
      this.documentservice
        .getrelationsrch(event.query)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.relationlist = [];

            for (let i = 0; i < data.length; i++) {
              let brand = data[i].groupname;
              this.filteredrelation.push(brand);
              this.relationlist.push(brand);
            }
          }
        });
    } else {
      for (let i = 0; i < this.relationlist.length; i++) {
        let brand = this.relationlist[i];
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredrelation.push(brand);
        }
      }
    }
  }

  approveimg1(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.approvallst.push(id);
      this.approveimg();
      // this.confirmationService.confirm({
      //   message: 'Are you sure that you want to Approve?',
      //   header: 'Confirmation',
      //   icon: 'pi pi-exclamation-triangle',
      //   accept: () => {
      //     this.approvallst.push(id)
      //     this.approveimg()
      //   },
      //   reject: () => {

      //   }
      // });
    }
  }

  approveimg() {
    this.ismenuclick = true;
    this.confirmationService.confirm({
      message: "Are you sure that you want to Approve?",
      header: "Confirmation",
      key: "documentuploadkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        if (this.selectedIndexs.length > 0) {
          this.approvallst = this.selectedIndexs;
        }

        if (this.approvallst.length > 0) {
          this.documentservice
            .approveimg(this.approvallst)
            .subscribe((data: any[]) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Approve Successfull",
                });
                for (let i = 0; i < this.approvallst.length; i++) {
                  document.getElementById(
                    "p" + this.approvallst[i]
                  ).style.display = "none";
                  document.getElementById(
                    "a" + this.approvallst[i]
                  ).style.display = "inline";
                  if (i == this.approvallst.length - 1) {
                    this.approvallst = [];
                    this.rightclickmenuopen = false;
                    this.ismenuclick = false;
                    this.removeselection();
                  }
                }
              }
            });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warn Message",
            detail: "Approve Failed",
          });
        }
      },
      reject: () => {},
    });
  }
  //getting microphone text from its service
  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startMicroPhoneService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopMicroPhoneService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }
}

// import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
// import { TreeviewItem } from "ngx-treeview";
// import { PermissionModel } from "src/app/model/master/Permission.model";
// import { MessageService, TreeNode } from "primeng/api";
// import { Router } from "@angular/router";
// import { environment } from "src/environments/environment";
// import { AgencyService } from "src/app/services/files/agency.service";
// import { DataShareService } from "src/app/services/data-share/data-share.service";
// // import {MenuItem} from 'primeng/api';
// import { MenuItem, ContextMenu } from "primeng/primeng";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { DashboardService } from "src/app/services/files/dashboard.service";
// import { ConfirmationService, SortEvent } from "primeng/api";
// import { TreeModule } from 'primeng/tree';
// import { ContextMenuModule } from 'primeng/contextmenu';
// import { CommonModule } from '@angular/common';


import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TreeviewItem } from "ngx-treeview";
import { AgencyService } from "src/app/services/files/agency.service";
import { DataShareService } from "src/app/services/data-share/data-share.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from "ngx-file-drop";
import {
  ConfirmationService,
  MenuItem,
  MessageService,
  SortEvent,
  TreeNode,
} from "primeng/api";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { environment } from "src/environments/environment";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { ThrowStmt } from "@angular/compiler";
import { Tree } from "primeng/tree";
import { file } from "jszip";
import { ContextMenu, Dialog } from "primeng/primeng";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { Lightbox } from "ngx-lightbox";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { saveAs } from "file-saver";
import { TreeDragDropService } from "primeng/api";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { data } from "jquery";
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.css"],
})
export class LeftMenuComponent implements OnInit {
  agencyform: FormGroup;
  ButtonStatus: string = "Save";
  agencylist: any[] = [];
  agencyinformation: any;
  agencycatlist: any[];
  agencyDialog: boolean = false;
  masterTag: number = 0;
  VideoArchive: number = 0;
  ImageArchive: number = 0;
  IllustrationArchive: number = 0;
  CarttonArchive: number = 0;
  GraphicsArchive: number = 0;

  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  AudioArchive: number = 0;
  StoryArchive: number = 0;
  DocumentArchive: number = 0;
  LicenceTab: number = 0;
  relationTab: number = 0;

  loginuser: string;
  Carttab: number = 0;
  allitemstab: number = 0;
  Agencytab: number = 0;
  masterkeyword: number = 0;
  masterbysubject: number = 0;
  permissionlist: PermissionModel[];
  downloadinggif: number = 0;
  tabpermissionstring: string = "";
  val: string = "";
  itCategory: TreeviewItem[];
  tconfig: any;
  files1: TreeNode[];
  files3: TreeNode[];
  collectiontab: number = 0;
  emagzinetab: number = 0;
  report: number = 0;
  serverstatus: number = 0;
  dailypages: number = 0;
  recycleBin: number = 0;
  hrCollection: number = 0;
  master_onDemand: number = 0;
  allItemsTab: number = 0;
  librarypermission = 0;
  agencytabstr: string = "";
  agencystorytabstr: string = "";
  userId: any;
  contextmenuitem: MenuItem[];

  agencyDataList: any[] = [];
  
  treeloader1: boolean = false;
  treeloader: boolean = true;
  tcmitem: MenuItem[];
  createFolder: number = 0;
  mediaManager: number = 0;
  newcolpop: boolean = false;
  newYearcolpop: boolean = false;
  newcollname: string = "";
  selectedYear: string;
  tcmnodekey: any;
  tcmnodepath: any;
  editcolpop: boolean = false;
  selectedcollpath: string = "";
  showfilter: boolean = false;
  insertFile: number = 0;
  checkinpop: boolean = false;
  insertFolder: number = 0;
  editMonthcolpop: boolean = false;
  checkinfldrpop: boolean = false;
  selectedMonth: string;
  newMonthcolpop: boolean = false;
  editYearcolpop: boolean = false;
  treeDataNull: number = 0;
  srchtext: string = "";
  glbadvancefilter: string = "";
  foldersrchtxt: string = "";
  duplicatelist: any[] = [];
  missedFiles: any[] = [];
  filesforuploadglb: any = [];
  pathforupload: any = "";
  dropedvalue: any;
  files2: any[] = [];
  uploadform: boolean = false;
  displayProcessBar: boolean = false;
  progressBarValue: number = 0;
  indexPrev: any = null;
  loading: boolean = false;
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexstype: any[] = [];
  selectedIndexsid: any[] = [];
  bditems: MenuItem[];
  totalcount: string;
  treeactivekey: string;
  folderdata: any[] = [];
  elementarr: ImageUploadFormModel[];
  totalfcount: string;
  selectedIndexspermis: any[] = [];
  ismenuclick: boolean = false;
  selectedkey: string = "";
  rightclickmenuopen: boolean = false;
  treeusers: any[] = [];
  treeperpopup: boolean = false;
  @ViewChild("dv", { static: false }) dataview1: any;
  @ViewChild("tcm", { static: false }) tcm: ContextMenu;

  constructor(
    public router: Router,
    public agencyser: AgencyService,
    private messageService: MessageService,
    private dataService: DataShareService,
    private formBuilder: FormBuilder,
    private dashboarservice: DashboardService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // this.dashboarservice.gettreeonuploadEmit.subscribe((data:any)=>{
    //   this.gettreejson("");
    // });
    this.dashboarservice.treeCall("test");
    this.gettreejson("");
    this.loginuser = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserCompleteName;
    this.userId = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;

    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      trees.tree();
    });
    this.checkpermissions();
    // this.bindtreeview();
    this.bindagency();
    this.tconfig = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };

    this.contextmenuitem = [
      {
        label: "File",
        items: [
          {
            label: "New",
            icon: "pi pi-fw pi-plus",
            items: [{ label: "Project" }, { label: "Other" }],
          },
          { label: "Open" },
          { label: "Quit" },
        ],
      },
      {
        label: "Edit",
        icon: "pi pi-fw pi-pencil",
        items: [
          { label: "Delete", icon: "pi pi-fw pi-trash" },
          { label: "Refresh", icon: "pi pi-fw pi-refresh" },
        ],
      },
    ];
  }

  savetreepermission() {
    var users = "";
    if (this.treeusers.length > 0) {
      for (let i = 0; i < this.treeusers.length; i++) {
        if (i != this.treeusers.length - 1) {
          users += "," + this.treeusers[i];
        }
        if (i == this.treeusers.length - 1) {
          users += "," + this.treeusers[i] + ",";
          this.dashboarservice
            .savetreepermission(users, this.selectedcollpath)
            .subscribe((data: any) => {
              if (data != null) {
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Permissions Assigned",
                });
                this.treeperpopup = false;
                this.selectedcollpath = "";
              }
            });
        }
      }
    } else {
      this.dashboarservice
        .savetreepermission(",", this.selectedcollpath)
        .subscribe((data: any) => {
          if (data != null) {
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Permissions Assigned",
            });
            this.treeperpopup = false;
            this.selectedcollpath = "";
          }
        });
    }
  }


  gettreejson(e) {
    this.dashboarservice.getTreejson(e);
    this.treeloader = true;
    this.treeloader1 = false;
    this.dashboarservice
      .gettreejson("0", "1", "", "")
      .subscribe((data: any) => {

        if (data != 0) {
          this.files1 = JSON.parse(data.toString());
          //console.log(this.files1);
          this.treeloader = false;
          this.treeloader1 = true;
        } else {
          this.treeloader = false;
          this.treeloader1 = true;
          this.treeDataNull = 1;
        }
        //this.treeloader=false
        //this.treeloader1=true
      });
  }

  searchfolder() {
    this.srchtext = "";
    this.glbadvancefilter = "";
    // console.log("foldersrchtxt", this.foldersrchtxt);

    if (this.foldersrchtxt != "") {
      this.dashboarservice
        .searchfolder(this.foldersrchtxt.replace(new RegExp("'", "g"), "''"))
        .subscribe((data: any) => {
          // console.log("data", data);

          if (data != "") {
            var arr = [];
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                var Treeview_Key = data[i]["TreeViewId"].toString();
                var Parent_Key = data[i]["ParentTreeId"].toString();
                var Parent_Path = data[i]["CollectionPath"].toString();
                var Parent_name = Parent_Path.split("/")
                  [Parent_Path.split("/").length - 1].replace(
                    new RegExp("'", "g"),
                    "\\'"
                  )
                  .replace(new RegExp('"', "g"), '\\"')
                  .replace(new RegExp("//", "g"), "\\\\");
                if (
                  Parent_name.toLowerCase().indexOf(this.foldersrchtxt) > -1
                ) {
                  var key = "";
                  for (
                    let j = 0;
                    j <
                    Parent_Path.replace(/\\/g, "\\\\")
                      .replace(new RegExp("'", "g"), "\\'")
                      .replace(new RegExp('"', "g"), '\\"')
                      .replace(new RegExp("//", "g"), "\\\\")
                      .split("/").length -
                      1;
                    j++
                  ) {
                    key += "/";
                  }
                  arr.push({
                    label: Parent_name,
                    data: Parent_Path.replace(/\\/g, "\\\\")
                      .replace(new RegExp("'", "g"), "\\'")
                      .replace(new RegExp('"', "g"), '\\"')
                      .replace(new RegExp("//", "g"), "\\\\"),
                    key: key + Treeview_Key,
                    expandedIcon: "pi pi-folder-open",
                    collapsedIcon: "pi pi-folder",
                  });
                }
              }
            }
            // this.showfulltree = 1
            // this.files3 = arr
            this.files1 = arr;
          }
        });
    } else {
      // this.showfulltree = 0
      this.files3 = this.files1;
      //  this.gettreejson("")
    }
  }

  showcollpop() {
    if (this.createFolder == 1) {
      this.newcolpop = true;
      this.dashboarservice.openAddCompany(this.newcolpop);
    }
  }

  newYearcolpopfunc(){
    if (this.createFolder == 1){
      this.newYearcolpop = true;
      this.newcollname = this.selectedYear;
      this.dashboarservice.openAddYear(this.newYearcolpop);
    }
  }


  deletecoll() {
    // console.log("delete coll left menu tcmnodekey",this.tcmnodekey,"tcmnodepath",this.tcmnodepath);
    this.dashboarservice.deletecollection();
    // this.confirmationService.confirm({
    //   message: "Are you sure that you want to Delete?",
    //   header: "Confirmation",
    //   key: "treeviewkey",
    //   icon: "pi pi-exclamation-triangle",
    //   accept: () => {
    //     var pkey =
    //       this.tcmnodekey.node.key.split("/")[
    //         this.tcmnodekey.node.key.split("/").length - 1
    //       ];
    //     var pname = this.tcmnodekey.node.data;
    //     this.dashboarservice.dltcoll(pkey, pname).subscribe((data: any) => {
    //       this.messageService.add({
    //         key: "message",
    //         severity: "success",
    //         summary: "Success Message",
    //         detail: "Company Deleted",
    //       });
    //       this.gettreejson1(this.tcmnodekey);
    //       for (
    //         let i = 0;
    //         i < this.tcmnodekey.node.parent.children.length;
    //         i++
    //       ) {
    //         if (this.tcmnodekey.node.parent.children[i].TreeViewId == pkey) {
    //           this.tcmnodekey.node.parent.children.splice(i, 1);
    //         }
    //       }
    //     });
    //   },
    //   reject: () => {},
    // });
  }


  editcoll(dialogType:string) {

    this.dashboarservice.editCollection(dialogType);

    if (dialogType === 'company') {

      if (!this.newcollname || this.newcollname.trim() === "") {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Company name cannot be empty.",
        });
        return;
      }
    } else if (dialogType === 'year') {
      this.newcollname = this.selectedYear;
      if (!this.newcollname) {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a year.",
        });
        return;
      }
    } else if (dialogType === 'month') {
      this.newcollname = this.selectedMonth;
      if (!this.newcollname) {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a month.",
        });
        return;
      }
    }

    if (this.newcollname != "" || this.newcollname != undefined) {
      // console.log("edit company",this.tcmnodekey);
      
      // this.newcollname.replace(/[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g, '')
      var pkey =
        this.tcmnodekey.node.key.split("/")[
          this.tcmnodekey.node.key.split("/").length - 1
        ];
      var path =
        this.tcmnodepath.node.data.substring(
          0,
          this.tcmnodepath.node.data.lastIndexOf("/")
        ) +
        "/" +
        this.newcollname;
      this.tcmnodepath.node.label = this.newcollname;
      this.dashboarservice
        .editcoll(
          pkey,
          path,
          this.newcollname
            .trim()
            .replace(/[`~!@#$%^&*_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ")
            .trim()
        )
        .subscribe((data: any) => {
          if (data == "Exist") {
            this.messageService.add({
              key: "message",
              severity: "warning",
              summary: "Warning Message",
              detail: "Company Allready Exist",
            });
          } else {
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Company Updated",
            });
          }
        });
        this.gettreejson1(this.tcmnodekey);
        this.hideeditcollpop();
        this.editYearcolpop = false;
        this.editMonthcolpop = false;
    } else {
      this.messageService.add({
        key: "message",
        severity: "warning",
        summary: "Warning Message",
        detail: "Do not use any special characters... ",
      });
    }
  }

  hideeditcollpop() {
    this.editcolpop = false;
    this.newcollname = "";
  }


  savecoll(dialogType:string) {

    this.dashboarservice.saveCollection(dialogType);

    if (dialogType === 'company') {

      if (!this.newcollname || this.newcollname.trim() === "") {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Company name cannot be empty.",
        });
        return;
      }
    } else if (dialogType === 'year') {
      this.newcollname = this.selectedYear;
      if (!this.newcollname) {
        this.messageService.add({
          key: "message",
          severity: "warning",  
          summary: "Warning Message",
          detail: "Please select a year.",
        });
        return;
      }
    } else if (dialogType === 'month') {
      this.newcollname = this.selectedMonth;
      if (!this.newcollname) {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Please select a month.",
        });
        return;
      }
    } else if (dialogType === 'NewFolder'){
      if (!this.newcollname || this.newcollname.trim() === "") {
        this.messageService.add({
          key: "message",
          severity: "warning",
          summary: "Warning Message",
          detail: "Folder name cannot be empty.",
        });
        return;
      }
    }

    if (this.newcollname != "" || this.newcollname != undefined) {
      // console.log("tcmnodekey",this.tcmnodekey);
      // console.log("tcmnodepath",this.tcmnodepath);
      // console.log("newcollname value",this.newcollname);
      

      var pkey =
        this.tcmnodekey.node.key.split("/")[
          this.tcmnodekey.node.key.split("/").length - 1
        ];
      var path =
        this.tcmnodepath.node.data +
        "/" +
        this.newcollname
        .trim()
        .replace(/[`~!@#$%^&*|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ")
        .trim();
      this.dashboarservice
        .createcoll(pkey, path.trim(),null)
        .subscribe((data: any) => {
          if (data == "Exist") {
            this.messageService.add({
              key: "message",
              severity: "warning",
              summary: "Warning Message",
              detail: "Data Allready Exist",
            });
          } else if (dialogType === 'NewFolder'){
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Folder Added Successfully",
            });
            this.gettreejson("");
            this.gettreejson1(this.tcmnodekey);
          } 
          else {
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Company Added",
            });

            this.gettreejson("");
            this.gettreejson1(this.tcmnodekey);
            this.apendFolderColl(data, path);
            this.hidecollpop();
            this.newYearcolpop = false;
            this.newMonthcolpop = false;
            this.newcolpopNewFolder = false;
            this.dashboarservice.visibletreedrop(false);
            // this.cdr.markForCheck();
            // this.files1 = [...this.files1];
          }
        });
    } else {
      this.messageService.add({
        key: "message",
        severity: "warning",
        summary: "Warning Message",
        detail: "Do not use any special characters... ",
      });
    }
  }


  hidecollpop() {
    this.newcolpop = false;
    this.newcollname = "";
  }


  apendFolderColl(key, colpath1) {
    var obj = {
      key: key,
      colpath1: colpath1
    }

    this.dashboarservice.appendFolder(obj);
    var tmpchild = [];
    if (this.tcmnodekey.node.children) {
      var tmpchild1 = this.tcmnodekey.node.children[0];
      tmpchild["Level"] = tmpchild1.Level;
      tmpchild["ParentTreeId"] = tmpchild1.ParentTreeId;
      tmpchild["TreeViewId"] = key;
      tmpchild["UserPermissions"] = tmpchild1.UserPermissions;
      tmpchild["collapsedIcon"] = tmpchild1.collapsedIcon;
      tmpchild["data"] = colpath1;
      tmpchild["expandedIcon"] = tmpchild1.expandedIcon;
      tmpchild["key"] =
        tmpchild1.key.substring(0, tmpchild1.key.lastIndexOf("/") + 1) + key;
      tmpchild["key1"] = key;
      tmpchild["label"] = this.newcollname;
      tmpchild["parent"] = tmpchild1.parent;
      //console.log(tmpchild)
      this.tcmnodekey.node.children.push(tmpchild);
    } else {
      var tmpchild1 = this.tcmnodekey.node;
      tmpchild.push({
        Level: tmpchild1.Level + 1,
        ParentTreeId: tmpchild1.TreeViewId,
        TreeViewId: key,
        UserPermissions: tmpchild1.UserPermissions,
        collapsedIcon: tmpchild1.collapsedIcon,
        data: colpath1,
        expandedIcon: tmpchild1.expandedIcon,
        key: tmpchild1.key + "/" + key,
        key1: key,
        label: this.newcollname,
        parent: this.tcmnodekey.node,
      });
      //console.log(tmpchild)
      this.tcmnodekey.node["children"] = tmpchild;
    }
  }

  droppedontree(dataforup) {
    this.dashboarservice.droppedOnTree(dataforup);
    // console.log("upload image func droppedontree");
    this.duplicatelist = [];
    this.missedFiles = [];
    var files = this.filesforuploadglb;
    var pathkey = this.pathforupload;
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.dropedvalue = pathkey;
    var tempdata = pathkey.node.data;
    pathkey = pathkey.node.key;
    pathkey = pathkey.split("/")[pathkey.split("/").length - 1];
    this.files2 = [];
    var filesforupload = [];
    const formData = new FormData();
    let filenamearray: any[] = [];
    let filepatharray: any[] = [];
    let filesizearray: any = [];
    let filesizearraySum = 0;

    for (let j = 0; j < files.length; j++) {
      let newRelativeFileNameArray: any[] = [];
      let newRelativeFileName: any = "";
      let relativePathFile = files[j].relativePath;
      let fileNameFile = files[j].file.name;

      let relative_path = relativePathFile.split(".").filter((e) => e != "");
      let relativeFileExtension = relative_path.pop().trim();
      let newTrimRelaFileName = relative_path
        .join("")
        .replace(/[^a-zA-Z0-9-/\_]/g, " ")
        .replace(/\s+/g, " ")
        .split("/");

      for (let i = 0; i < newTrimRelaFileName.length; i++) {
        newRelativeFileNameArray.push(newTrimRelaFileName[i].trim());
      }

      newRelativeFileName = newRelativeFileNameArray.join("/");
      // newTrimRelativeFileName.forEach(element => {
      //   element.trim()
      //   newRelativeFileName =+ `${element}/`
      // });
      let file_name = fileNameFile.split(".").filter((e) => e != "");
      let fileExtension = file_name.pop().trim();
      let newFileName = file_name
        .join("")
        .trim()
        .replace(/[^a-zA-Z0-9-_]/g, " ")
        .trim()
        .replace(/\s+/g, " ")
        .trim();

      relativePathFile = `${newRelativeFileName}.${relativeFileExtension}`;
      fileNameFile = `${newFileName}.${fileExtension}`;
      filesizearray.push(files[j].file.size);
      if (
        relativePathFile.startsWith(".") == false &&
        fileNameFile.startsWith(".") == false
      ) {
        var tmppath = tempdata;
        this.files2.push({ relativePath: relativePathFile });
        var relativepath = relativePathFile.trim();
        var filename = fileNameFile.trim();
        var y1 = "";
        var a1 = "";
        if (relativepath.trim().indexOf("/") == 0) {
          y1 = relativepath.replace("/", "");
        } else {
          y1 = relativepath;
        }
        if (y1.trim().indexOf("/") == -1) {
          a1 = "";
        } else {
          a1 = y1;
        }
        if (a1 == "") {
          tmppath = tmppath + "/" + a1 + "/" + filename;
        } else {
          tmppath = tmppath + "/" + a1;
        }
        if ((a1 + filename).indexOf("~") > -1) {
        } else {
          filepatharray.push(a1);
          filenamearray.push(filename);
          formData.append(
            "photo",
            new File([files[j].file], filename),
            tmppath.trim()
          );
        }
        if (j == files.length - 1) {
          // console.log("dataforup", dataforup);
          filesizearraySum = filesizearray.reduce(
            (partialSum, a) => partialSum + a,
            0
          );
          // console.log(filesizearraySum);
          this.uploadform = false;
          this.displayProcessBar = true;
          // setInterval(() => this.manageProgress(), 50 * files.length);
          this.dashboarservice
            .uploadfiles(tmppath, formData, filenamearray.length)
            .subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  console.log("Request has been made!");
                  break;
                case HttpEventType.ResponseHeader:
                  console.log("Response header has been received!");
                  break;
                case HttpEventType.UploadProgress:
                  this.progressBarValue = Math.round(
                    Math.round((event.loaded / event.total!) * 100)
                  );
                  console.log(`Uploaded! ${this.progressBarValue}%`);
                  break;
                case HttpEventType.Response:
                  console.log("Files Uploaded Successfully!");
                  // this.gettreejson("");
                  this.gettreejson1("");
                  this.getfolderdata(pathkey,tempdata,this.dropedvalue.node.expandedIcon);
                  this.displayProcessBar = false;
                  // this.insertUploadFilesData(
                  //   filepatharray,
                  //   filenamearray,
                  //   userid,
                  //   dataforup,
                  //   pathkey
                  // );
              }
            });
        }
      } else {
        this.uploadform = false;
        this.displayProcessBar = false;
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warn Message",
          detail: "File Name Not Correct..",
        });
      }
    }
  }


  bindtreecntxtmenu() {

    this.dashboarservice.bindTreeContext();
    // console.log("bind tree context menu in treedata");
    
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var RolesId = JSON.parse(localStorage.getItem("userdetails"))[0].RolesId;
    // console.log("Role is", RolesId);
    if (userid == "53" || RolesId == "1225") {
      this.tcmitem = [
        {
          label: "",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckin();
          },
        },
        {
          label: "Check In Folder",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckinfldr();
          },
        },
        {
          label: "New Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpop();
          },
        },
        {
          label: "Edit Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showeditcollpop();
          },
        },
        {
          label: "Delete Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
        {
          label: "Assign Permission",
          icon: "fa fa-gear",
          command: (event) => {
            this.showperpopup();
          },
        },
      ];
    } else {
      this.tcmitem = [
       {
         label: "Check In File",
         icon: "fa fa-upload",
         command: (event) => {
           this.showcheckin();
         },
       },
       {
         label: "Check In Folder",
         icon: "fa fa-upload",
         command: (event) => {
           this.showcheckinfldr();
         },
       },
       {
         label: "New Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.showcollpop();
         },
       },
       {
         label: "Edit Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.showeditcollpop();
         },
       },
       {
         label: "Delete Company",
         icon: "fa fa-folder",
         command: (event) => {
           this.deletecoll();
         },
       },
      //  {
      //    label: "Search In",
      //    icon: "fa fa-search",
      //    command: (event) => {
      //      this.searchincoll();
      //    },
      //  },
     ];
    }
     }



     showperpopup() {
      this.dashboarservice.showPerpopup();
      this.selectedcollpath = this.tcmnodekey.node.data;
      this.dashboarservice
        .gettreeper(this.selectedkey)
        .subscribe((data: any[]) => {
          if (data != null) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].UserId != "53") {
                this.treeusers.push(data[i].UserId);
  
                // console.log("array", this.treeusers);
              }
            }
            //this.treeusers=data
            this.treeperpopup = true;
          }
        });
    }


  showeditcollpop() {
    this.editcolpop = true;
    this.dashboarservice.openEditCom(this.editcolpop);
  }

  searchincoll() {
    //passing path of the folder on which user clicked
    this.selectedcollpath = this.tcmnodekey.node.data;
    // console.log(this.selectedcollpath);

    //alert(this.selectedcollpath)
    this.advanceSearch();
  }

  advanceSearch() {
    this.showfilter = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  // showcheckin() {
  //   if (this.insertFile == 1) {
  //     this.checkinpop = true;
  //     this.dashboarservice.openCheckInFile(this.checkinpop);
  //   }
  // }
  
  showcheckin() {
    if (this.insertFile == 1) {
      this.checkinpop = true;
      this.dashboarservice.openCheckInFile(this.checkinpop);
    } else {
      this.messageService.add({
        key: "message",
        severity: "warning",  
        summary: "Warning Message",
        detail: "No Permission to add files",
      });
    }
  }

  showcheckinfldr() {
    if (this.insertFolder == 1) {
      this.checkinfldrpop = true;
      this.dashboarservice.openCheckInFolder(this.checkinfldrpop);
    } else {
      this.messageService.add({
        key: "message",
        severity: "warning",  
        summary: "Warning Message",
        detail: "No Permission to add files",
      });
    }
  }

  editMonthcolpopfunc(){
    this.editMonthcolpop = true;
    this.newcollname = this.selectedMonth;
    this.dashboarservice.openEditMonth( this.editMonthcolpop);
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    //console.log("true");
    this.tcm.hide();
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        // if(event == null){
        //   this.removeselection()
        // }
        try {
          this.conetxtmenudiv.hide();
        } catch {}
      }
    }
  }

  
  gettreejson1(e) {

    this.dashboarservice.getTreejson1(e);
    // console.log("gettreejson1 event",e);
    try {
      if (e.node.children == undefined) {
        e.node.parent.expanded = false;
        e.node.parent.expanded = true;
      } else {
        var ln = e.node.children[0].data.split("/").length;
        var temppath = "";
        if (e.node.data.indexOf("/") > -1) {
          temppath = e.node.data + "/";
        } else {
          temppath = e.node.data;
        }
        // this.dashboarservice.gettreejson(ln - 1, ln, temppath, e.node.children[0].key).subscribe((data: any) => {
        //   if (data != "") {
        //     e.node.children = JSON.parse(data.toString())
        //   }
        // })
      }
    } catch (err) {}
  }

  drop(event) {
    // console.log("drop", event);
    const items = event.dataTransfer.items;
    //this.items1=items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const entry = item.webkitGetAsEntry();
        if (entry.isFile) {
        } else if (entry.isDirectory) {
          this.parseDirectoryEntry(entry);
        }
      }
    }
  }

  parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        (entries) => {
          resolve(entries);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  

  newMonthcolpopfunc(){
    if (this.createFolder == 1){
      this.newMonthcolpop = true;
      this.newcollname = this.selectedMonth;
      this.dashboarservice.openNewMonth(this.newMonthcolpop);
    }
  }
  
  editYearcolpopfunc(){
    this.editYearcolpop = true;
    this.newcollname = this.selectedYear;
    this.dashboarservice.openEditYear(this.editYearcolpop);
  }

  newcolpopNewFolder: boolean = false;
  showcollpopNewFolder() {
    if (this.createFolder == 1) {
      this.newcolpopNewFolder = true;
      this.dashboarservice.openAddFolder(this.newcolpopNewFolder);
    }
  }

  hidecollpopNewFolder() {
    this.newcolpopNewFolder = false;
    this.newcollname = "";
  }

  treerigclck(e,data) {

    this.dashboarservice.treeriglckEvent(e);
    
    // console.log("right click event in left menu",e);
    
    if(e.node.Level == 0){
      this.tcmitem = [
        {
          label: "Add New Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpop();
          },
        },
      ];
    } else if (environment.treeViewIds.indexOf(e.node.TreeViewId) !== -1){
      this.tcmitem = [
        {
          label: "Add New Folder",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpopNewFolder();
          },
        },
        {
          label: "Check In File",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckin();
          },
        },
        {
          label: "Check In Folder",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckinfldr();
          },
        },
      ]
    } 
    else if (e.node.Level == 2 && environment.treeViewIds.indexOf(e.node.ParentTreeId) !== -1){
      this.tcmitem = [  
        {
          label: "Add New Folder",
          icon: "fa fa-folder",
          command: (event) => {
            this.showcollpopNewFolder();
          },
        },
        {
          label: "Check In File",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckin();
          },
        },
        {
          label: "Check In Folder",
          icon: "fa fa-upload",
          command: (event) => {
            this.showcheckinfldr();
          },
        },
      ]
    }
    else if (e.node.Level == 1 && !e.node.children) {
      this.tcmitem = [
        {
          label: "Add New Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.newYearcolpopfunc();
          },
        },
        {
          label: "Delete Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        {
          label: "Edit Company",
          icon: "fa fa-folder",
          command: (event) => {
            this.showeditcollpop();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } else if(e.node.Level == 1 && e.node.children){
      this.tcmitem = [
        {
          label: "Add New Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.newYearcolpopfunc();
          },
        },
        // {
        //   label: "Edit Company",
        //   icon: "fa fa-folder",
        //   command: (event) => {
        //     this.showeditcollpop();
        //   },
        // },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    }
    else if (e.node.Level == 2 && !e.node.children){
      this.tcmitem = [
        {
          label: "Add New Month",
          icon: "fa fa-folder",
          command: (event) => {
            this.newMonthcolpopfunc();
          },
        },
        {
          label: "Edit Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.editYearcolpopfunc();
          },
        },
        {
          label: "Delete Year",
          icon: "fa fa-folder",
          command: (event) => {
            this.deletecoll();
          },
        },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } 
    else if(e.node.Level == 2 && e.node.children){
      this.tcmitem = [
        {
          label: "Add New Month",
          icon: "fa fa-folder",
          command: (event) => {
            this.newMonthcolpopfunc();
          },
        },
        // {
        //   label: "Edit Year",
        //   icon: "fa fa-folder",
        //   command: (event) => {
        //     this.editYearcolpopfunc();
        //   },
        // },
        // {
        //   label: "Search In",
        //   icon: "fa fa-search",
        //   command: (event) => {
        //     this.searchincoll();
        //   },
        // },
      ];
    } 
    else {
      const key = e.node.key1;
      const path = e.node.data;
      this.dashboarservice.getfolderdata(key, path).subscribe((data: any) => {
        if(data[1].length > 0){
          this.tcmitem = [
            {
              label: "Check In File",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckin();
              },
            },
            {
              label: "Check In Folder",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckinfldr();
              },
            },
            // {
            //   label: "Edit Month",
            //   icon: "fa fa-folder",
            //   command: (event) => {
            //     this.editMonthcolpopfunc();
            //   },
            // },
          ];
        } else {
          this.tcmitem = [
            {
              label: "Check In File",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckin();
              },
            },
            {
              label: "Check In Folder",
              icon: "fa fa-upload",
              command: (event) => {
                this.showcheckinfldr();
              },
            },
            {
              label: "Edit Month",
              icon: "fa fa-folder",
              command: (event) => {
                this.editMonthcolpopfunc();
              },
            },
            {
              label: "Delete Month",
              icon: "fa fa-folder",
              command: (event) => {
                this.deletecoll();
              },
            },
          ];
        }
      });
      
    }
    
    
    // this.tcmnodekey = e;
    // this.tcmnodepath = e;
    // this.tcmitem[3].disabled = false;
    // this.tcmitem[4].disabled = false;
    // this.selectedkey = e.node.key.split("/")[e.node.key.split("/").length - 1];
    // if (e.node.children != undefined) {
    //   if (e.node.children.length > 0) {
    //     this.tcmitem[3].disabled = true;
    //     this.tcmitem[4].disabled = true;
    //   }
    // } else {
    //   var key = e.node.key.split("/")[e.node.key.split("/").length - 1];
    //   this.selectedkey = key;
    //   this.dashboarservice.checkdir(key).subscribe((data: any) => {
    //     if (data != "") {
    //       this.tcmitem[3].disabled = true;
    //       this.tcmitem[4].disabled = true;
    //     }
    //   });
    // }
  }


  async checkpermissions() {
    this.dashboarservice.checkAllPermissions();
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 47) {
        this.masterTag = 1;
      }
      if (this.permissionlist[i].taskid == 39) {
        this.VideoArchive = 1;
      }
      if (this.permissionlist[i].taskid == 101) {
        this.IllustrationArchive = 1;
      }
      if (this.permissionlist[i].taskid == 102) {
        this.CarttonArchive = 1;
      }
      if (this.permissionlist[i].taskid == 103) {
        this.GraphicsArchive = 1;
      }
      if (this.permissionlist[i].taskid == 40) {
        this.ImageArchive = 1;
      }
      if (this.permissionlist[i].taskid == 42) {
        this.AudioArchive = 1;
      }
      if (this.permissionlist[i].taskid == 41) {
        this.StoryArchive = 1;
      }
      if (this.permissionlist[i].taskid == 43) {
        this.DocumentArchive = 1;
      }
      if (this.permissionlist[i].taskid == 58) {
        this.Carttab = 1;
      }
      if (this.permissionlist[i].taskid == 125) {
        this.LicenceTab = 1;
      }
      if (this.permissionlist[i].taskid == 126) {
        this.relationTab = 1;
      }

      // if(this.permissionlist[i].taskid==59){
      //   this.allitemstab=1;
      // }
      if (this.permissionlist[i].taskid == 62) {
        this.Agencytab = 1;
      }
      if (this.permissionlist[i].taskid == 64) {
        this.collectiontab = 1;
      }
      if (this.permissionlist[i].taskid == 65) {
        this.emagzinetab = 1;
      }
      if (this.permissionlist[i].taskid == 66) {
        this.report = 1;
      }
      if (this.permissionlist[i].taskid == 88) {
        this.serverstatus = 1;
      }
      if (this.permissionlist[i].taskid == 92) {
        this.masterkeyword = 1;
      }
      if (this.permissionlist[i].taskid == 91) {
        this.masterbysubject = 1;
      }
      if (this.permissionlist[i].taskid == 95) {
        this.dailypages = 1;
      }
      if (this.permissionlist[i].taskid == 130) {
        this.recycleBin = 1;
      }
      if (this.permissionlist[i].taskid == 134) {
        this.hrCollection = 1;
      }
      if (this.permissionlist[i].taskid == 135) {
        this.master_onDemand = 1;
      }
      if (this.permissionlist[i].taskid == 136) {
        this.allItemsTab = 1;
      }
      if (
        this.permissionlist[i].taskid == 146 ||
        this.permissionlist[i].taskid == 147
      ) {
        this.librarypermission = 1;
      }

      // if (this.permissionlist[i].taskid == 34) {
      //   this.downloadbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 51) {
      //   this.imgdownloadbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 44) {
      //   this.imgcrppbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 45) {
      //   this.videoexportbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 46) {
      //   this.videocropbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 56) {
      //   this.storydownloadbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 48) {
      //   this.audiodownloadbtn = 1;
      // }
      // if (this.permissionlist[i].taskid == 55) {
      //   this.addtocartdoc = 1;
      // }
      // if (this.permissionlist[i].taskid == 54) {
      //   this.addtocartimage = 1;
      // }
      // if (this.permissionlist[i].taskid == 49) {
      //   this.addtocartaudio = 1;
      // }
      // if (this.permissionlist[i].taskid == 50) {
      //   this.addtocartvideo = 1;
      // }
      // if (this.permissionlist[i].taskid == 57) {
      //   this.addtocartstory = 1;
      // }
      // if (this.permissionlist[i].taskid == 14) {
      //   this.editdocmeta = 1;
      // }
      // if (this.permissionlist[i].taskid == 11) {
      //   this.editimgmeta = 1;
      // }
      // if (this.permissionlist[i].taskid == 6) {
      //   this.audiodelete = 1;
      // }
      // if (this.permissionlist[i].taskid == 9) {
      //   this.videodelete = 1;
      // }
      // if (this.permissionlist[i].taskid == 12) {
      //   this.imagedelete = 1;
      // }
      // if (this.permissionlist[i].taskid == 15) {
      //   this.docdelete = 1;
      // }
      // if (this.permissionlist[i].taskid == 18) {
      //   this.storydelete = 1;
      // }
      if (this.permissionlist[i].taskid == 127) {
        this.insertFile = 1;
      }
      if (this.permissionlist[i].taskid == 128) {
        this.insertFolder = 1;
      }
      if (this.permissionlist[i].taskid == 129) {
        this.createFolder = 1;
      }
      if(this.permissionlist[i].taskid == 200){
        this.mediaManager = 1;
      }

    }
    this.tabpermissionstring =
      this.ImageArchive +
      "~" +
      this.DocumentArchive +
      "~" +
      this.StoryArchive +
      "~" +
      this.VideoArchive +
      "~" +
      this.AudioArchive +
      "~" +
      this.GraphicsArchive +
      "~" +
      this.CarttonArchive +
      "~" +
      this.IllustrationArchive;
    localStorage.setItem("tabperstring", this.tabpermissionstring);
  }

  // bindtreeview() {
  //   this.files1 = [
  //     {
  //       label: "Documents",
  //       data: "Documents Folder",
  //       expandedIcon: "pi pi-folder-open",
  //       collapsedIcon: "pi pi-folder",
  //       key: "1",
  //       children: [
  //         {
  //           label: "Work",
  //           data: "Work Folder",
  //           expandedIcon: "pi pi-folder-open",
  //           collapsedIcon: "pi pi-folder",
  //           key: "2",
  //           children: [
  //             {
  //               label: "Expenses.doc",
  //               icon: "pi pi-file",
  //               key: "3",
  //               data: "Expenses Document",
  //               children: [
  //                 {
  //                   label: "Expenses.doc",
  //                   icon: "pi pi-file",
  //                   data: "Expenses Document",
  //                   key: "4",
  //                 },
  //                 {
  //                   label: "Resume.doc",
  //                   icon: "pi pi-file",
  //                   data: "Resume Document",
  //                   key: "5",
  //                 },
  //               ],
  //             },
  //             {
  //               label: "Resume.doc",
  //               icon: "pi pi-file",
  //               data: "Resume Document",
  //               key: "6",
  //             },
  //           ],
  //         },
  //         {
  //           label: "Home",
  //           data: "Home Folder",
  //           expandedIcon: "pi pi-folder-open",
  //           collapsedIcon: "pi pi-folder",
  //           key: "7",
  //           children: [
  //             {
  //               label: "Invoices.txt",
  //               icon: "pi pi-file",
  //               data: "Invoices for this month",
  //               key: "8",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Pictures",
  //       data: "Pictures Folder",
  //       expandedIcon: "pi pi-folder-open",
  //       collapsedIcon: "pi pi-folder",
  //       key: "9",
  //       children: [
  //         {
  //           label: "barcelona.jpg",
  //           icon: "pi pi-image",
  //           data: "Barcelona Photo",
  //           key: "10",
  //         },
  //         {
  //           label: "logo.jpg",
  //           icon: "pi pi-file",
  //           data: "PrimeFaces Logo",
  //           key: "11",
  //         },
  //         {
  //           label: "primeui.png",
  //           icon: "pi pi-image",
  //           data: "PrimeUI Logo",
  //           key: "12",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Movies",
  //       data: "Movies Folder",
  //       expandedIcon: "pi pi-folder-open",
  //       collapsedIcon: "pi pi-folder",
  //       key: "13",
  //       children: [
  //         {
  //           label: "Al Pacino",
  //           data: "Pacino Movies",
  //           key: "14",
  //           children: [
  //             {
  //               label: "Scarface",
  //               icon: "pi pi-video",
  //               data: "Scarface Movie",
  //               key: "15",
  //             },
  //             {
  //               label: "Serpico",
  //               icon: "pi pi-file-video",
  //               data: "Serpico Movie",
  //               key: "16",
  //             },
  //           ],
  //         },
  //         {
  //           label: "Robert De Niro",
  //           data: "De Niro Movies",
  //           key: "17",
  //           children: [
  //             {
  //               label: "Goodfellas",
  //               icon: "pi pi-video",
  //               data: "Goodfellas Movie",
  //               key: "18",
  //             },
  //             {
  //               label: "Untouchables",
  //               icon: "pi pi-video",
  //               data: "Untouchables Movie",
  //               key: "19",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ];
  // }

  // onSelectedChange(e) {
  //   this.val = e.node.data;
  // }

  onSelectedChange(e) {
    this.val = e.node.key;
    //this.blindsearchwithdata('');
    this.getfolderdata(e.node.key, e.node.data, e.node.label);
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexstype.splice(0, this.selectedIndexstype.length);
          this.selectedIndexsid.splice(0, this.selectedIndexsid.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
        }
      }
      this.rightclickmenuopen = false;
      this.ismenuclick = false;
    }
  }

  getfolderdata(key, name, label) {
      var obj={
        key:key,
        name:name,
        label:label
      }
    this.dashboarservice.getFolderData(obj);
    //  console.log("getfolderdata", key, name, label)

    this.indexPrev = null;
    this.removeselection();

    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.srchtext = "";
    this.glbadvancefilter = "";
    var tmpkeyarr = key.split("/");
    var tmpamearr = name.split("/");
    var str = key.split("/")[key.split("/").length - 1];
    if (str == "") {
      this.loading = false;
      return;
    }
    var tmpname = "";
    var tmpkey = "";
    this.bditems = [];
    for (let j = 0; j < tmpamearr.length; j++) {
      tmpname = tmpname + "/" + tmpamearr[j];
      tmpkey = tmpkey + "/" + tmpkeyarr[j];
      if (j == tmpamearr.length - 1) {
        tmpamearr[j] = label;
      }

      this.bditems.push({
        label: tmpamearr[j],
        icon: "fa fa-folder",
        id: tmpkey.replace("/", ""),
        target: tmpname.replace("/", ""),
        command: (event) => {
          this.getfolderdata(
            event.item.id,
            event.item.target,
            event.item.label
          );
        },
      });
    }
    this.treeactivekey = key + "/";

    this.loading = true;

    this.dashboarservice.getfolderdata(str, name).subscribe((data: any) => {
      if (data.length > 0) {
        //  console.log("folder data",data);

        this.folderdata = data[0];
        data[1].sort((a, b) => b.Udate1.localeCompare(a.Udate1));
        this.elementarr = data[1];
        // this.selectedIndexs = []
        // console.log("folder data elementarr",this.elementarr);

        this.loading = false;
        this.totalcount = this.elementarr.length.toString();
        this.totalfcount = this.folderdata.length.toString();
        // console.log("elementarr getfolderdata",this.elementarr);
        
      } else {
        return;
      }
      this.loading = false;
      // this.customCmshow();
    });
  }

  opentreeview() {
    // window.open("#/filesystem", "_blank");
    this.router.navigate(['/filesystem']);
  }

  // openHRTreeView() {
  //   window.open('#/HRTreeView', "_blank")
  // }

  openreport() {
    window.open("#/report", "_blank");
  }

  openebook() {
    window.open(
      environment.ebookpath + "?id=" + localStorage.getItem("guid"),
      "_blank"
    );
  }

  bindagency() {
    this.agencyDataList = [];
    this.agencyser.getallagency().subscribe((data: any[]) => {
      if (data.length > 0 && this.agencyDataList.length == 0) {
        this.agencyDataList = data;
        this.agencytabstr = "";
        for (let i = 0; i < data.length; i++) {
          if (data[i].ActiveStatus == "A" && data[i].agencytype == "Image") {
            this.agencytabstr +=
              " <li><a routerLink='/agencyimages' href='#/agencyimages?quip=" +
              data[i].AgencyId +
              "' ><i class='fa fa-circle-o'></i>" +
              data[i].AgencyName +
              "</a></li>";
          }
          // else if (data[i].ActiveStatus == 'A' && data[i].agencytype == 'Story') {
          //   this.agencystorytabstr += " <li><a routerLink='/agencystory' href='#/agencystory?quip=" + data[i].AgencyId + "' ><i class='fa fa-circle-o'></i>" + data[i].AgencyName + "</a></li>"
          // }
          document.getElementById("agencytab").innerHTML = "";
          if (i == data.length - 1) {
            document.getElementById("agencytab").innerHTML = this.agencytabstr;
            // document.getElementById("agencystorytab").innerHTML = this.agencystorytabstr
          }
        }
      } else {
        document.getElementById("agencytab").innerHTML = "";
        let unique = data.filter((o) =>
          this.agencyDataList.some(
            ({ id, name }) => o.id === id && o.name === name
          )
        );
        this.agencytabstr +=
          " <li><a routerLink='/agencyimages' href='#/agencyimages?quip=" +
          data[0].AgencyId +
          "' ><i class='fa fa-circle-o'></i>" +
          data[0].AgencyName +
          "</a></li>";
        document.getElementById("agencytab").innerHTML = this.agencytabstr;
      }
    });
  }

  sendDataType(dataType) {
    // console.log("sending data to data service",dataType)
    this.dataService.dataType(dataType);
  }

  agencyClick() {
    // console.log("clicked on menu");
    this.contextmenuitem = [
      {
        label: "Create Folder",
        icon: "fa fa-download",
        command: (event) => {
          this.agencyDialog = true;
        },
      },
    ];
  }

  openCm(ev, conetxtmenudiv) {
    this.agencyClick();
    conetxtmenudiv.show(ev);
  }

  @HostListener("window:click", ["$event"]) Click(event: KeyboardEvent) {
    this.conetxtmenudiv.hide();
  }

  @HostListener("window:dblclick", ["$event"]) dblClick(event: KeyboardEvent) {
    // console.log("double click");
    this.agencyDialog = false;
  }








  
}



































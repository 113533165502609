
import { Component, OnInit, Input, SimpleChanges, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HRUploadFormModel } from 'src/app/model/file/hrFormUploadModel';
import { DocumentuploadService } from 'src/app/services/files/documentupload.service';
import {  MessageService, TreeNode } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { formatDate } from '@angular/common';
import { TreeviewConfig } from 'ngx-treeview';
import { TreedropdownComponent } from 'src/app/masters/treedropdown/treedropdown.component';
import { HrcornerService } from 'src/app/services/files/hrcorner.service';
@Component({
  selector: 'app-hr-corner-form',
  templateUrl: './hr-corner-form.component.html',
  styleUrls: ['./hr-corner-form.component.css']
})
export class HrCornerFormComponent implements OnInit {


  @ViewChild("keywordinput", { static: false }) keywordinput: AutoComplete
  @ViewChild("TreedropdownComponent", { static: false }) TreedropdownComponent: TreedropdownComponent
  Document_Edit_form: FormGroup
  documentuploadinformation: HRUploadFormModel;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  idforupdate: string = ""
  categoryid: number = 0
  collpath: string = ""
  pdftypelst: any
  filteredkeywords: any[];
  allkeywords: any[]
  glbkeywordsgst: string = ""
  keywords: any[] = []
  fileformt: string = ""
  items: any[] = [];
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  public AllowParentSelection = true;
  public RestructureWhenChildSameName = false;
  public ShowFilter = true;
  public Disabled = false;
  public FilterPlaceholder = 'Type here to filter elements...';
  public MaxDisplayed = 5;

  @ViewChild('filter', { static: true })
  targetElement: ElementRef;
  @Input()
  dropDownHeight = 250;
  selection: string = "";

  visible: boolean = false;
  id: any = Math.floor(Math.random() * 1000);
  firstTimeLoad = true;
  dropDownEl;
  orgnamearr:string=""
  constructor(private hrCornerService: HrcornerService,public formBuilder: FormBuilder, public documentservice: DocumentuploadService, private messageservice: MessageService, public categoryService: CategoryService, public subcategoryService: SubCategoryService, public dashboarservice: DashboardService) { }

  ngOnInit() {
    this.CreateDocumentEditForm()
    this.dashboarservice.gettreedropstatus().subscribe((data: boolean) => {
      this.visible = data
    })
  }

  @Input()
  selecttab: string;
  @Input()
  selectValue: string;
  @Input()
  visibletree: boolean;
  @Input()
  orgname:string
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.selectValue,this.idforupdate,this.orgnamearr);
    if (changes['selectValue']) {
      if (this.selectValue != "") {
        this.idforupdate = this.selectValue
        this.orgnamearr=this.orgname
        console.log(this.idforupdate,this.orgnamearr);
        
        this.getdocumentdata(this.idforupdate, this.selecttab)
      }

    }
    this.visible = this.visibletree
    let keywordsub = this.dashboarservice.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
    let bysubjectser = this.dashboarservice.getbysubjectlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.items = data
      }
      bysubjectser.unsubscribe()
    })
    let pdftypesub = this.dashboarservice.getpdftypedata().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.pdftypelst = data
      }
      pdftypesub.unsubscribe()
    })

  }



  onDataSelection(e) {
    alert(e)
  }

  CreateDocumentEditForm() {
    this.Document_Edit_form = this.formBuilder.group({
      itemid: [""],
      title: [""],
      itemcaption: [""],
      itemauthor: [""],
      noofpages: [""],
      Pages: [""],
      magsection: [""],
      bysubject: [""],
      authorinput: [""],
      source: [""],
      storytype: ["0"],
      itemkeyword: [""],
      Photographer: [""],
      eventdate: [""],
      itemrating: [""],
      itemfilename: [""],
      orgpath: [""],
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: [""]
    })
  }

  updatedocument(documenteditlist) {
    //this.getSubcategorydata(documenteditlist.categoryid)
    if (this.idforupdate.split("~")[1] == "" || this.idforupdate.split("~")[1] == undefined) {
      this.collpath = documenteditlist.collpath
    }
    else {
      documenteditlist.orgfilename = ""
      this.collpath = ""
    }
    this.keywords = []
    if (documenteditlist.itemkeyword != "") {
      for (let i = 0; i < documenteditlist.itemkeyword.split(";").length; i++) {
        this.keywords.push({ keywordname: documenteditlist.itemkeyword.split(";")[i].toString().trim() })
      }
    }
    //this.simpleSelected=[]
    if (documenteditlist.channeltype != null || documenteditlist.channeltype != "") {
      this.selection = documenteditlist.channeltype
    }

    this.Document_Edit_form.setValue({
      itemid: documenteditlist.itemid,
      title: documenteditlist.title,
      itemcaption: documenteditlist.itemcaption,
      itemauthor: documenteditlist.itemauthor,
      noofpages: documenteditlist.totalbitrate,
      Pages: documenteditlist.samplerate,
      magsection: documenteditlist.composer,
      bysubject: documenteditlist.channeltype,
      authorinput: documenteditlist.datarate,
      source: documenteditlist.Source,
      storytype: documenteditlist.framerate,
      itemkeyword: documenteditlist.itemkeyword,
      Photographer: documenteditlist.creator,
      eventdate: documenteditlist.eventdate1,
      itemrating: documenteditlist.itemrating,
      itemfilename: documenteditlist.orgfilename,
      orgpath: documenteditlist.OriginalPath,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: documenteditlist.MergeMultipleCol
    })
  }

  SaveAfterUpdate() {
    this.documentuploadinformation = this.Document_Edit_form.value  
    this.documentuploadinformation.itemid = this.idforupdate
    this.documentuploadinformation.orgfilename=this.orgnamearr
    if (this.documentuploadinformation.eventdate == "") {
      this.documentuploadinformation.eventdate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

    if (this.selection != "" && this.selection != undefined) {
      this.documentuploadinformation.bysubject = this.selection
    }

    if (this.documentuploadinformation.storytype == undefined || this.documentuploadinformation.storytype == "0") {
      this.documentuploadinformation.storytype = ""
    }
    var tmpkeywords = ""
    for (let i = 0; i < this.keywords.length; i++) {
      tmpkeywords += this.keywords[i].keywordname + ";"
    }
    this.documentuploadinformation.itemkeyword = tmpkeywords.trim().substring(0, tmpkeywords.trim().length - 1)

    this.hrCornerService.UpdateDocument(this.documentuploadinformation).subscribe((data: any) => {
      if (data != null) {
        this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Successfully Updated' });
        this.cleardata()

      }
    })
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }

  getallkeyword() {
    this.dashboarservice.getkeywords("").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
    })
  }

  filterkeyword(e) {
    if (e.query != "") {
      this.glbkeywordsgst = e.query
      this.dashboarservice.getkeywords(this.glbkeywordsgst).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.allkeywords = data
        }
      })
    }

    let query = e.query;
    this.filteredkeywords = this.filterCountry(query, this.allkeywords);

  }

  filterCountry(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.keywordname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }

  getpdftype() {
    this.dashboarservice.getpdftype().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.pdftypelst = data
      }
    })
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }

  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getdocumentdata(itemid, path) {
    if (itemid.indexOf("~") > -1) {
      itemid = itemid.split("~")[0]
    }
    this.hrCornerService.getdocumentdata(itemid.trim(), path).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.updatedocument(data[0])
      }
    })
  }

  cleardata() {
    this.Document_Edit_form.reset()
    //this.Document_Edit_form.patchValue({acldaterange:this.today})
    //this.Document_Edit_form.patchValue({itempermission:"1"})
    this.Document_Edit_form.patchValue({ storytype: "0" })
    this.glbkeywordsgst = ""
    this.keywords = []
    this.selectValue = ""
    this.selection = ""
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode === 113) {
      if (this.glbkeywordsgst != "") {
        this.dashboarservice.getkeywords(this.glbkeywordsgst).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.allkeywords = data
          }
        })
      }

    }


  }
  getallbysubject() {
    this.dashboarservice.getallbysubject().subscribe((data: any[]) => {
      if (data != null) {
        var data1 = JSON.parse(data.toString())
        this.items = data1
        //this.TreedropdownComponent.binddata(this.items)
      }
    })
  }



  private process(data): any {
    let result = [];
    result = data.map((item) => {
      return this.toTreeNode(item);
    });
    return result;
  }

  private toTreeNode(node) {
    //console.log(node, parent);
    if (node && node.children) {
      node.children.map(item => {
        return this.toTreeNode(item);
      });
    }
    return node;
  }

  click(event) {
    if (this.firstTimeLoad) {
      this.dropDownEl = document.getElementById(this.id);
      document.body.appendChild(this.dropDownEl);
      this.firstTimeLoad = false;
    }
    this.showDropDown();
  }
  setPosition() {
    const el: Element = this.targetElement.nativeElement;
    const position = el.getBoundingClientRect();
    this.dropDownEl.style.top = position.height + position.top + 'px';
    this.dropDownEl.style.left = position.left + 'px';
    this.dropDownEl.style.width = position.width + 'px';
    this.dropDownEl.style.height = this.dropDownHeight + 'px';
  }
  showDropDown() {
    this.visible = !this.visible;
    this.setPosition();
  }
  nodeSelect(event) {
    this.visible = false;
    this.selection = event.node.data;
    //alert(this.selection)
    //this.selectionChange.emit(event.node);
  }

  onfocusoutbysubject() {
    this.visible = false;
  }


}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { Router } from '@angular/router';
import { AgencyimagesComponent } from '../agencyimages/agencyimages.component';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { environment } from 'src/environments/environment';
import { PermissionModel } from 'src/app/model/master/Permission.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { AgencyService } from 'src/app/services/files/agency.service';

@Component({
  selector: 'app-search-agencyimg',
  templateUrl: './search-agencyimg.component.html',
  styleUrls: ['./search-agencyimg.component.css']
})
export class SearchAgencyimgComponent implements OnInit {
  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  Searching_image_form: FormGroup
  categoryid: number = 0
  imageSearchlist:ImageUploadFormModel[]=[]
  eventlist:Eventformmodel[]=[]
  totalcount1:string=''
  keywordtype:string="like"
  keyword:string
  descriptiontype:string="like"
  description:string
  titletype:string="like"
  title:string
  sourcetype:string="like"
  source:string
  photogrtype:string="like"
  photographer:string
  authortype:string="like"
  author:string
  resolutiontype:string="greater"
  resolution:string
  categorytype:string="like"
  category:string
  countrytype:string="like"
  country:string
  statetype:string="like"
  state:string
  citytype:string="like"
  city:string
  yeartype:string="equal"
  year:string
  startdate:string
  enddate:string
  imgorientation:string
  acltype:string
  rating:string
  filenametype:string="like"
  filename:string
  keywordsrchtype:string="or"
  descsrchtype:string="or"
  titlesrchtype:string="or"
  sourcesrchtype:string="and"
  photographersrchtype:string="and"
  authorsrchtype:string="and"
  resolutionsrchtype:string="and"
  catsrchtype:string="and"
  countrysrchtype:string="and"
  statesrchtype:string="and"
  citysrchtype:string="and"
  datesrchtype:string="and"
  ratingsrchtype:string="and"
  filenamesrchtype:string="or"
  yearsrchtype:string="and"
  datetype:string="shootdate"
  formatsrchtype:string="and"
  formattype:string="equal"
  format:string="null"
  statussrchtype:string="and"
  statustype:string="equal"
  status:string="All"
  itemformats:any[]=[]
  permissionlist: PermissionModel[]
  approvebtn:number=0
  typediv:number=0
  keywordsdiv:number=0
  descriptiondiv:number=0
  titlediv:number=0
  filenamediv:number=0
  sourcediv:number=0
  photographerdiv:number=0
  authordiv:number=0
  resolutiondiv:number=0
  categorydiv:number=0
  countrydiv:number=0
  statediv:number=0
  citydiv:number=0
  yeardiv:number=0
  filestatusdiv:number=0
  datediv:number=0
  orientationdiv:number=0
  acldiv:number=0
  ratingdiv:number=0
  constructor( private imageUplaodService:ImageuploadService, private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private messageservice: MessageService, private sanitizer: DomSanitizer,public imguploadComp:AgencyimagesComponent,private route:Router,private imageservice:ImageuploadService,public agencyser:AgencyService) { }

  ngOnInit() {
    this.checkpermissions()
    //this.CreateImageSearchForm()
    //this.getcategorydata()
    //this.filestatus()
    //this.getevent()
    // this.keyword=this.imguploadComp.Searchbyfield
    // this.title=this.imguploadComp.Searchbyfield
    // this.description=this.imguploadComp.Searchbyfield
    this.getitemtype()
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"))
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
      if (this.permissionlist[i].taskid == 67) {
        this.typediv = 1;
      }
      if (this.permissionlist[i].taskid == 68) {
        this.keywordsdiv = 1;
      }
      if (this.permissionlist[i].taskid == 69) {
        this.descriptiondiv = 1;
      }
      if (this.permissionlist[i].taskid == 70) {
        this.titlediv = 1;
      }
      if (this.permissionlist[i].taskid == 71) {
        this.filenamediv = 1;
      }
      if (this.permissionlist[i].taskid == 72) {
        this.sourcediv = 1;
      }
      if (this.permissionlist[i].taskid == 73) {
        this.photographerdiv = 1;
      }
      if (this.permissionlist[i].taskid == 74) {
        this.authordiv = 1;
      }
      if (this.permissionlist[i].taskid == 75) {
        this.resolutiondiv = 1;
      }
      if (this.permissionlist[i].taskid == 76) {
        this.categorydiv = 1;
      }
      if (this.permissionlist[i].taskid == 77) {
        this.countrydiv = 1;
      }
      if (this.permissionlist[i].taskid == 78) {
        this.statediv = 1;
      }
      if (this.permissionlist[i].taskid == 79) {
        this.citydiv = 1;
      }
      if (this.permissionlist[i].taskid == 80) {
        this.yeardiv = 1;
      }
      if (this.permissionlist[i].taskid == 81) {
        this.filestatusdiv = 1;
      }
      if (this.permissionlist[i].taskid == 82) {
        this.datediv = 1;
      }
      if (this.permissionlist[i].taskid == 83) {
        this.orientationdiv = 1;
      }
      if (this.permissionlist[i].taskid == 84) {
        this.acldiv = 1;
      }
      if (this.permissionlist[i].taskid == 85) {
        this.ratingdiv = 1;
      }
    }
  }

  getitemtype(){
    this.imageUplaodService.getitemtypes().subscribe((data:any[])=>{
      if(data !=null){
        for(let i=0;i<data.length;i++){
          var l=data[i].typeformat.split(",")
          for(let j=0;j<l.length;j++){
            this.itemformats.push({id:data[i].typeformat.split(",")[j].trim().substring(1,data[i].typeformat.split(",")[j].length),value:data[i].typeformat.split(",")[j].trim().substring(1,data[i].typeformat.split(",")[j].length)})
          }
        }
      }
    })
  }

  CreateImageSearchForm() {
    this.Searching_image_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword:[null],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      AvgBitrate:[null],
      startdate:[null],
      endDate:[null],
      orgfilename:[null],
      framerate:[null],
      Searching_Method:['n'],
      Width:[null],
      Height:[null],
      File_Status:['0'],
      orientationtype:[null],
      event:[null],
      type:[""],
      totalcount:[""]
    })

  }

  @Input()
  selectValue: string;
  @Input()
  selectedid: string;

  ngOnChanges(changes: SimpleChanges) {
         if (changes['selectValue']) {
          this.keyword=this.selectValue
          this.title=this.selectValue
          this.description=this.selectValue
          this.filename=this.selectValue
         }
  }
 //this function is not working
  SearchAllImages(formvalues,type){
    
    var fn=""
    if(this.Searching_image_form.dirty){
      fn="fn"
    }
    if(this.imgorientation !=null){
      fn="fn"
    }
    formvalues.value.orientationtype=this.imgorientation
    if(formvalues.value.totalcount==null){
      formvalues.value.totalcount=""
    }
    if(this.totalcount1==""){
      formvalues.value.totalcount=""
    }
    formvalues.value.type=type
    if(type==""){
      this.totalcount1=""
      this.imguploadComp.SearchAllImages1(formvalues.value,fn)
    }
    else{
      if (formvalues.itemkeyword != null) {
        formvalues.itemkeyword = formvalues.itemkeyword.replace(new RegExp(",", 'g'), " ")
      }
      if (formvalues.CategoryId == 'null') {
        formvalues.CategoryId = null
        formvalues.SubCategoryId = null
      }
  
      this.imageservice.SearchAllImages(formvalues.value).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount1=data[0].rowcount
          this.Searching_image_form.patchValue({totalcount:this.totalcount1})
        }
        else {
          this.totalcount1="0"
        }
      })
    }

  }
  clearform(){
    this.Searching_image_form.reset()
    this.imgorientation=null
    this.Searching_image_form.patchValue({Searching_Method:'n'})
    this.Searching_image_form.patchValue({File_Status:'0'})
    this.totalcount1=''
  }

  clearform1(){
    this.totalcount1=""
  this.keywordtype="like"
  this.keyword=""
  this.descriptiontype="like"
  this.description=""
  this.titletype="like"
  this.title=""
  this.filename=""
  this.filenametype="like"
  this.sourcetype="like"
  this.source=""
  this.photogrtype="like"
  this.photographer=""
  this.authortype="like"
  this.author=""
  this.resolutiontype="greater"
  this.resolution=""
  this.categorytype="like"
  this.category=""
  this.countrytype="like"
  this.country=""
  this.statetype="like"
  this.state=""
  this.citytype="like"
  this.city=""
  this.startdate=""
  this.enddate=""
  this.imgorientation=""
  this.acltype=""
  this.rating=""
  this.yeartype="equal"
  this.year=""
  this.keywordsrchtype="or"
  this.descsrchtype="or"
  this.titlesrchtype="or"
  this.filenamesrchtype="or"
  this.sourcesrchtype="and"
  this.photographersrchtype="and"
  this.authorsrchtype="and"
  this.resolutionsrchtype="and"
  this.catsrchtype="and"
  this.countrysrchtype="and"
  this.statesrchtype="and"
  this.citysrchtype="and"
  this.datesrchtype="and"
  this.ratingsrchtype="and"
  this.yearsrchtype="and"
  this.datetype="shootdate"
  this.formatsrchtype="and"
  this.formattype="equal"
  this.format="null"
  this.statussrchtype="and"
  this.statustype="equal"
  this.status="All"
  environment.glbsearchstring=""
environment.globalsearchtext=""
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }
  filestatus() {
    //this.userinfomation=new userformmodel();
    this.imageUplaodService.GetAllImages('0',"image").subscribe((data: any[]) => {
      if (data != null) {
        this.imageSearchlist = data;
      }

    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getevent() {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getevent().subscribe((data: Eventformmodel[]) => {
      if (data != null) {
        this.eventlist = data;
      }

    })
  }


  advancesearch(type){
    var srchtxt=""
    var keystring=""
    var descstring=""
    var titlestring=""
    var filenaestring=""
    if(this.keyword==""||this.keyword==null||this.keyword==undefined){

    }
    else{
      var key=this.ExtractSearchingKeyword(this.keyword)
      var keylist=key.split("+")
      for(let i=0;i<keylist.length;i++){
        if(i==0){
          keystring +="("
          if(this.keywordtype=="like"){
            keystring +="  itemkeyword like '%"+keylist[i]+"%' "
          }
          else if(this.keywordtype=="equal"){
            keystring +=" itemkeyword = '"+keylist[i]+"' "
          }
          else{
            keystring +=" itemkeyword NOT IN ('"+keylist[i]+"') "
          }
        }
        else{
          if(this.keywordtype=="like"){
            keystring +="and  itemkeyword like '%"+keylist[i]+"%' "
          }
          else if(this.keywordtype=="equal"){
            keystring +="and itemkeyword = '"+keylist[i]+"' "
          }
          else{
            keystring +="and itemkeyword NOT IN ('"+keylist[i]+"') "
          }
        }
        
        if(i==keylist.length-1){
          keystring +=")"
        }

      }
      
    }
    if(this.description==""||this.description==null||this.description==undefined){

    }
    else{
      var key=this.ExtractSearchingKeyword(this.description)
      var keylist=key.split("+")
      for(let i=0;i<keylist.length;i++){
        if(i==0){
          descstring +=this.descsrchtype+" ("
          if(this.descriptiontype=="like"){
            descstring +=" itemcaption like '%"+keylist[i]+"%' "
          }
          else if(this.descriptiontype=="equal"){
            descstring +=" itemcaption = '"+keylist[i]+"' "
          }
          else{
            descstring +=" itemcaption NOT IN ('"+keylist[i]+"') "
          }
        }
        else{
          if(this.descriptiontype=="like"){
            descstring +="and  itemcaption like '%"+keylist[i]+"%' "
          }
          else if(this.descriptiontype=="equal"){
            descstring +=" and itemcaption = '"+keylist[i]+"' "
          }
          else{
            descstring +=" and itemcaption NOT IN ('"+keylist[i]+"') "
          }
        }
        
        if(i==keylist.length-1){
          descstring +=")"
        }

      }
    }
    if(this.title==""||this.title==null||this.title==undefined){

    }
    else{
      var key=this.ExtractSearchingKeyword(this.title)
      var keylist=key.split("+")
      for(let i=0;i<keylist.length;i++){
        if(i==0){
          titlestring +=this.titlesrchtype+" ("
          if(this.titletype=="like"){
            titlestring +="  title like '%"+keylist[i]+"%' "
          }
          else if(this.titletype=="equal"){
            titlestring +=" title = '"+keylist[i]+"' "
          }
          else{
            titlestring +=" title NOT IN ('"+keylist[i]+"') "
          }
        }
        else{
          if(this.titletype=="like"){
            titlestring +="and  title like '%"+keylist[i]+"%' "
          }
          else if(this.titletype=="equal"){
            titlestring +=" and title = '"+keylist[i]+"' "
          }
          else{
            titlestring +=" and title NOT IN ('"+keylist[i]+"') "
          }
        }
        
        if(i==keylist.length-1){
          titlestring +=")"
        }

      }
    }

    if(this.filename==""||this.filename==null||this.filename==undefined){

    }
    else{

      var key=this.ExtractSearchingKeyword(this.filename)
      var keylist=key.split("+")
      for(let i=0;i<keylist.length;i++){
        if(i==0){
          filenaestring +=this.filenamesrchtype+" ("
          if(this.filenametype=="like"){
            filenaestring +="  orgfilename like '%"+keylist[i]+"%' "
          }
          else if(this.filenametype=="equal"){
            filenaestring +=" orgfilename = '"+keylist[i]+"' "
          }
          else{
            filenaestring +=" orgfilename NOT IN ('"+keylist[i]+"') "
          }
        }
        else{
          if(this.filenametype=="like"){
            filenaestring +="and  orgfilename like '%"+keylist[i]+"%' "
          }
          else if(this.filenametype=="equal"){
            filenaestring +=" and orgfilename = '"+keylist[i]+"' "
          }
          else{
            filenaestring +=" and orgfilename NOT IN ('"+keylist[i]+"') "
          }
        }
        
        if(i==keylist.length-1){
          filenaestring +=")"
        }

      }
    }
    srchtxt=keystring+" "+descstring+" "+titlestring+" "+filenaestring
    srchtxt=srchtxt.trim()
    if(srchtxt.indexOf("or")==0){
      srchtxt=srchtxt.replace("or","")
    }
    if(srchtxt.indexOf("and")==0){
      srchtxt=srchtxt.replace("and","")
    }
    if(srchtxt.trim() !=""){
      srchtxt ="("+srchtxt+")"
    }
    if(this.source==""||this.source==null||this.source==undefined){

    }
    else{
      if(this.sourcetype=="like"){
        srchtxt+=" "+this.sourcesrchtype+" source like '%"+this.source+"%' "
      }
      else if(this.sourcetype=="equal"){
        srchtxt+=" "+this.sourcesrchtype+" source = '"+this.source+"' "
      }
      else{
        srchtxt+=" "+this.sourcesrchtype+" source NOT IN ('"+this.source+"') "
      }
    }
    if(this.photographer==""||this.photographer==null||this.photographer==undefined){

    }
    else{
      if(this.photogrtype=="like"){
        srchtxt+=" "+this.photographersrchtype+" creator like '%"+this.photographer+"%' "
      }
      else if(this.photogrtype=="equal"){
        srchtxt+=" "+this.photographersrchtype+" creator = '"+this.photographer+"' "
      }
      else{
        srchtxt+=" "+this.photographersrchtype+" creator NOT IN ('"+this.photographer+"') "
      }
    }
    if(this.author==""||this.author==null||this.author==undefined){

    }
    else{
      if(this.authortype=="like"){
        srchtxt+=" "+this.authorsrchtype+" itemauthor like '%"+this.author+"%' "
      }
      else if(this.authortype=="equal"){
        srchtxt+=" "+this.authorsrchtype+" itemauthor = '"+this.author+"' "
      }
      else{
        srchtxt+=" "+this.authorsrchtype+" itemauthor NOT IN ('"+this.author+"') "
      }
    }
    if(this.resolution==""||this.resolution==null||this.resolution==undefined){

    }
    else{
      if(this.resolutiontype=="greater"){
        srchtxt+=" "+this.resolutionsrchtype+" resolution > '"+this.resolution+"' "
      }
      else if(this.resolutiontype=="smaller"){
        srchtxt+=" "+this.resolutionsrchtype+" resolution < '"+this.resolution+"' "
      }
      else{
        srchtxt+=" "+this.resolutionsrchtype+" resolution = '"+this.resolution+"' "
      }
    }

    if(this.category==""||this.category==null||this.category==undefined){

    }
    else{
      if(this.categorytype=="like"){
        srchtxt+=" "+this.catsrchtype+" categoryid like '%"+this.category+"%' "
      }
      else if(this.categorytype=="equal"){
        srchtxt+=" "+this.catsrchtype+" categoryid = '"+this.category+"' "
      }
      else{
        srchtxt+=" "+this.catsrchtype+" categoryid NOT IN ('"+this.category+"') "
      }
    }

    if(this.country==""||this.country==null||this.country==undefined){

    }
    else{
      if(this.countrytype=="like"){
        srchtxt+=" "+this.countrysrchtype+" countryid like '%"+this.country+"%' "
      }
      else if(this.countrytype=="equal"){
        srchtxt+=" "+this.countrysrchtype+" countryid = '"+this.country+"' "
      }
      else{
        srchtxt+=" "+this.countrysrchtype+" countryid NOT IN ('"+this.country+"') "
      }
    }

    if(this.state==""||this.state==null||this.state==undefined){

    }
    else{
      if(this.statetype=="like"){
        srchtxt+=" "+this.statesrchtype+" stateid like '%"+this.state+"%' "
      }
      else if(this.statetype=="equal"){
        srchtxt+=" "+this.statesrchtype+" stateid = '"+this.state+"' "
      }
      else{
        srchtxt+=" "+this.statesrchtype+" stateid NOT IN ('"+this.state+"') "
      }
    }
//city//
    if(this.city==""||this.city==null||this.city==undefined){

    }
    else{
      if(this.citytype=="like"){
        srchtxt+=" "+this.citysrchtype+" cityid like '%"+this.city+"%' "
      }
      else if(this.citytype=="equal"){
        srchtxt+=" "+this.citysrchtype+" cityid = '"+this.city+"' "
      }
      else{
        srchtxt+=" "+this.citysrchtype+" cityid NOT IN ('"+this.city+"') "
      }
    }
    //city//
//year//
    if(this.year==""||this.year==null||this.year==undefined){

    }
    else{
      if(this.yeartype=="equal"){
        srchtxt+=" "+this.yearsrchtype+" Year(shootdate) = '"+this.year+"' "
      }
      else if(this.yeartype=="before"){
        srchtxt+=" "+this.yearsrchtype+" shootdate < '"+this.year+"-01-01"+"' "
      }
      else if(this.yeartype=="after"){
        srchtxt+=" "+this.yearsrchtype+" shootdate > '"+this.year+"-12-31"+"' "
      }
      else if(this.yeartype=="beforequal"){
        srchtxt+=" "+this.yearsrchtype+" shootdate <= '"+this.year+"-12-31"+"' "
      }
      else{
        srchtxt+=" "+this.yearsrchtype+" shootdate >= '"+this.year+"-01-01"+"' "
      }
    }

    if(this.format==""||this.format=="null"||this.format==undefined||this.format==null){

    }
    else{ 
      if(this.formattype=="equal"){
        srchtxt+=" "+this.formatsrchtype+" itemformat = '"+this.format+"' "
      }
      else{
        srchtxt+=" "+this.formatsrchtype+" itemformat NOT IN ('"+this.format+"') "
      }
    }

    if(this.status==""||this.status==undefined||this.status==null||this.status=="All"){

    }
    else{ 
      if(this.statustype=="equal"){
        srchtxt+=" "+this.statussrchtype+" file_status = '"+this.status+"' "
      }
      else{
        srchtxt+=" "+this.statussrchtype+" file_status NOT IN ('"+this.status+"') "
      }
    }

//year//
    if(this.startdate==""||this.startdate==null||this.startdate==undefined){

    }
    else{
        srchtxt+=" "+this.datesrchtype+" Date("+this.datetype+")  >=  '"+this.startdate+"' "
      
    }
    if(this.enddate==""||this.enddate==null||this.enddate==undefined){

    }
    else{
        srchtxt+=" "+this.datesrchtype+" Date("+this.datetype+")  <=  '"+this.enddate+"' "
    }
    if(this.imgorientation==""||this.imgorientation==null||this.imgorientation==undefined||this.imgorientation=="null"){

    }
    else{
        srchtxt+=" and genre = '"+this.imgorientation+"' "
    }
    if(this.acltype==""||this.acltype==null||this.acltype==undefined||this.acltype=="null"){

    }
    else{
        srchtxt+=" and itempermission = '"+this.acltype+"' "
    }
    if(this.rating==""||this.rating==null||this.rating==undefined){

    }
    else{
        srchtxt+=" "+this.ratingsrchtype+" itemrating = '"+this.rating+"' "
    }
    if(srchtxt == "" || srchtxt == null || srchtxt == "null" || srchtxt == undefined){

    }
    else{
      var tmppath=""
      if(this.selectedid.substring(this.selectedid.length-1)=="/"){
        //console.log(id)
        tmppath=this.selectedid.substring(0,this.selectedid.length-1)
    }
      srchtxt=srchtxt+" and originalpath like 'raw/agency/"+tmppath+"/%' order by "+this.datetype
      if(type==""){
        this.totalcount1=""
        
        if(srchtxt == "" || srchtxt == null || srchtxt == "null" || srchtxt == undefined){
          srchtxt=" 1=1  and originalpath like 'raw/agency/"+tmppath+"/%'  order by "+this.datetype;
        }
        else{
          
        }
        this.imguploadComp.advancesearch(srchtxt)
      }
      else{
    var tbastr = localStorage.getItem("tabperstring")
        this.agencyser.getacount(tmppath,srchtxt,tbastr).subscribe((data:any[])=>{
          if(data !=null){
            this.totalcount1=(data[0].rowcount).toString()
          }
        })
      }
    }
    
    
  }


  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
        Paramater = _TextVal;
        Paramater = Paramater.replace(/\  /gi, " ");
        Paramater = Paramater.replace(/\  /gi, " ");
        Paramater = Paramater.replace(/\  /gi, " ");
        Paramater = Paramater.replace(/\,/gi, " ");
        Paramater = Paramater.replace(/\+/gi, " ");
        if ((Paramater.indexOf('"') >= 0)) {
            Paramater = Paramater.replace(/\"/gi, "~");
            while (1) {
                startind = (Paramater.indexOf("~", (startval - 1)) + 1);
                if ((startind <= 0)) {
                    break;
                }
                
                endindex = (Paramater.indexOf("~", startind) + 1);
                if ((endindex <= 0)) {
                    break;
                }
                
                if ((Searchingparam.trim() == "")) {
                    Searchingparam = Paramater.substring((startind - 1),startind+ ((endindex - startind) 
                                    + 1)).trim();
                    Paramater = Paramater.split(Searchingparam).join("");
                    Paramater = Paramater.trim();
                }
                else {
                    Searchingparam = (Searchingparam + "+" + Paramater.substring((startind - 1),startind+  ((endindex - startind) + 1))).trim();
                    Paramater = Paramater.split(Paramater.substring((startind - 1),startind+  ((endindex - startind) + 1))).join("");
                }
                
                // startval = endindex
            }
            
            if ((Searchingparam != "")) {
                MergeParamater = Searchingparam.replace(/\~/gi, "");
            }
            
            if ((Paramater.trim() != "")) {
                if ((MergeParamater == "")) {
                    MergeParamater = Paramater.trim().replace(/\ /gi, "+");
                }
                else {
                    MergeParamater = (MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+")));
                }
                
            }
            
            MergeParamater = MergeParamater.split("+++").join("+");
            MergeParamater = MergeParamater.split("++").join("+");
            //  split_Parma = MergeParamater.Split("+")
        }
        else {
            MergeParamater = Paramater.replace(/\ /gi, "+");
            MergeParamater = MergeParamater.split("+++").join("+");
            MergeParamater = MergeParamater.split("++").join("+");
            // split_Parma = Paramater.Split(" ")
        }
        
    }
    catch (ex /*:Exception*/) {
        MergeParamater = _TextVal;
    }
    
    return MergeParamater;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {
 
   subcategoryformmodel:Subcategoryformmodel=new Subcategoryformmodel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  //baseurl: string = "http://localhost:5000/subcategory";

  getAllSubCategory():Observable<Subcategoryformmodel[]>{
    return this.httpclient.get<Subcategoryformmodel[]>(this.baseurl+"subcategory");
  }
  CreateSubCategory(subcategoryinfo:Subcategoryformmodel):Observable<Subcategoryformmodel[]>{
    return this.httpclient.post<Subcategoryformmodel[]>(this.baseurl+ "subcategory/insertSubcategory",subcategoryinfo);
  }
  getCategoryDropdown():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"subcategory/selectcatedropdown");
  }
  UpdateSubCategory(subcategoryinfo: Subcategoryformmodel):Observable<Subcategoryformmodel[]>{
    return this.httpclient.post<Subcategoryformmodel[]>(this.baseurl+"subcategory/updateSubcategory",subcategoryinfo)
  }
  DeleteSubCategory(SubCategoryId:number):Observable<Subcategoryformmodel[]>{
    return this.httpclient.post<Subcategoryformmodel[]>(this.baseurl+"subcategory/deleteSubcategory",{SubCategoryId:SubCategoryId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  getSubcategoryData(categoryid:number):Observable<Subcategoryformmodel[]>{
    return this.httpclient.post<Subcategoryformmodel[]>(this.baseurl+"subcategory/selectsubcategory",{CategoryId:categoryid})
  }

  checkduplicate(subcategoryinfo:Subcategoryformmodel):Observable<Subcategoryformmodel[]>{
    return this.httpclient.post<Subcategoryformmodel[]>(this.baseurl+ "subcategory/checkduplicate",subcategoryinfo);
  }
  getevent():Observable<Eventformmodel[]>{
    return this.httpclient.get<Eventformmodel[]>(this.baseurl+"event")
  }
}

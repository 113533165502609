import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { PermissionModel } from '../model/master/Permission.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private myRoute: Router) { }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.auth.isLoggednIn()) {
      var permission=0;
      let roles = next.data["pid"] as Array<string>;

      //console.log("roles",roles)

      var permissionlist:PermissionModel[];
      permissionlist=JSON.parse(localStorage.getItem("userpermissions"));
      if(roles !=undefined){
        for(let i=0;i<permissionlist.length;i++){
           if(permissionlist[i].taskid.toString()==roles[0]){
            permission=1;
            return true;
          }
      }
      if(permission==0){
        this.myRoute.navigate(["home"]);
            return false;
      }

      }
      else{
        return true;
      }
      
    } else {
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from 'src/app/services/files/agency.service';

@Component({
  selector: 'app-agencycategory',
  templateUrl: './agencycategory.component.html',
  styleUrls: ['./agencycategory.component.css']
})
export class AgencycategoryComponent implements OnInit {
  agencylist: any[] = [];
  agencyinformation: any;
  agencyform: FormGroup
  Header: string = "Add/Edit Agency"
  ButtonStatus: string = 'Save'
  tmpagencyid: string;
  agencycatlist: any[]
  constructor(private agencyser: AgencyService, private formBuilder: FormBuilder, private messageService: MessageService, private Confirmservice: ConfirmationService) { }

  ngOnInit() {
    this.getagency()
    this.CreateCountryForm()
    this.getallagencycat()
  }

  getagency() {
    this.agencyser.getallagency().subscribe((data: any[]) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].ActiveStatus == "A") {
            this.agencylist.push(data[i])
          }
        }
      }
    })
  }

  CreateCountryForm() {
    this.agencyform = this.formBuilder.group({
      catname: [null, [Validators.required]],
      agencyid: [0, [Validators.required]],
      userid: [null],
      agencyname: [null],
      catid: [null]
    })
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  savecategory(formval) {
    this.agencyinformation = formval.value
    this.agencyinformation.agencyname = formval.directives[0].valueAccessor._elementRef.nativeElement.selectedOptions[0].innerText
    if (this.agencyinformation.agencyid == "0") {
      this.messageService.add({ key: 'message', severity: 'error', summary: 'Error Message', detail: 'Please select agency..' });
    }
    else {
      this.agencyinformation.userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId
      this.agencyser.checkduplicateagncycat(this.agencyinformation).subscribe((data: any) => {
        if (data.length < 1) {
          this.agencyser.createagencycat(this.agencyinformation).subscribe((_data: any) => {
            this.getallagencycat()
            this.Clear()
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });

          });
        } else {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
        }
      })
    }
    //}
  }
  updateagency(countrylist: any) {
    this.ButtonStatus = "Update"
    this.agencyform.setValue({
      agencyname: countrylist.AgencyName,
      agencyid: countrylist.AgencyId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  changestatus(agencyid, status) {
    this.Confirmservice.confirm({
      message: 'Are you sure that you want update?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.agencyser.updatAgencycat(agencyid, status).subscribe((data: any[]) => {
          this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Update successfully' });
          this.getallagencycat()
          this.Clear()
          this.ButtonStatus = "Save"
        })

      },
      reject: () => {
      }
    })
  }

  getallagencycat() {
    this.agencyser.getallagencycat().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.agencycatlist = data
      }
    })
  }

  Clear() {
    this.agencyform.reset()
    this.ButtonStatus = "Save"
    this.agencyform.patchValue({ agencyid: "0" })
  }

  deleteAgencyCat(TreeViewId) {
    // console.log("TreeViewId",TreeViewId);
    this.Confirmservice.confirm({
      message: 'Are you sure that you want update?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.agencyser.deleteAgencyCat(TreeViewId).subscribe((data: any) => {
          if (data.length > 0) {
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Delete successfully' });
          this. getallagencycat()
          } else {
            this.messageService.add({ key: 'message', severity: 'info', summary: 'Failed Message', detail: 'Delete Failed' });
          }
        })
      }
    })
  }

}

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { UserComponent } from "./masters/user/user.component";
import { CategoryComponent } from "./masters/category/category.component";
import { CityComponent } from "./masters/city/city.component";
import { CountryComponent } from "./masters/country/country.component";
import { StateComponent } from "./masters/state/state.component";
import { SubCategoryComponent } from "./masters/Sub-Category/sub-category.component";
import { EventComponent } from "./masters/event/event.component";
import { PublicationComponent } from "./masters/publication/publication.component";
import { PoolComponent } from "./masters/pool/pool.component";
import { UserRightsComponent } from "./masters/user/user-rights/user-rights.component";
import { ImageUploadComponent } from "./file/image-upload/image-upload.component";
import { VideoUploadComponent } from "./file/video-upload/video-upload.component";
import { VideoUploadPopupComponent } from "./file/video-upload-popup/video-upload-popup.component";
import { AudioUploadComponent } from "./file/audio-upload/audio-upload.component";
import { DocumentUploadComponent } from "./file/document-upload/document-upload.component";
import { LoginComponent } from "./login/login.component";
import { SharedcomponentComponent } from "./common/sharedcomponent/sharedcomponent.component";
import { DashboardComponent } from "./common/dashboard/dashboard.component";
import { AuthGuard } from "./Guard/auth.guard";
import { ExportConfigComponent } from "./masters/export-config/export-config.component";
import { StoryArchiveComponent } from "./file/story-archive/story-archive.component";
import { SettingsComponent } from "./settings/settings/settings.component";
import { ProfileComponent } from "./profile/profile/profile.component";
import { AllitemsComponent } from "./common/allitems/allitems.component";
import { PdfpreviewComponent } from "./common/pdfpreview/pdfpreview.component";
import { AgencyimagesComponent } from "./file/agencyimages/agencyimages.component";
import { AgencystoryComponent } from "./file/agencystory/agencystory.component";
import { MysharecartComponent } from "./file/Cart/mysharecart/mysharecart.component";
import { SharedtomecartComponent } from "./file/Cart/sharedtomecart/sharedtomecart.component";
import { CartComponent } from "./common/cart/cart.component";
import { CartlinkComponent } from "./common/cartlink/cartlink.component";
import { PurgingComponent } from "./settings/purging/purging.component";
import { TreeviewcomComponent } from "./common/treeviewcom/treeviewcom.component";
import { UserperferenceComponent } from "./masters/userperference/userperference.component";
import { LogexportComponent } from "./common/logexport/logexport.component";
import { ServerstatusComponent } from "./masters/serverstatus/serverstatus.component";
import { MastergroupComponent } from "./masters/mastergroup/mastergroup.component";
import { MasteragencyComponent } from "./masters/agency/masteragency/masteragency.component";
import { AgencycategoryComponent } from "./masters/agency/agencycategory/agencycategory.component";
import { VideocommentComponent } from "./file/videocomment/videocomment.component";
import { keywordmodule } from "./model/master/keyword.model";
import { KeywordsComponent } from "./masters/keywords/keywords.component";
import { BysubjectComponent } from "./masters/bysubject/bysubject.component";
import { VideokeywordsComponent } from "./file/videokeywords/videokeywords.component";
import { DailypagesComponent } from "./file/dailypages/dailypages.component";
import { MastereditionComponent } from "./masters/masteredition/masteredition.component";
import { EditionrightComponent } from "./masters/masteredition/editionright/editionright.component";
import { MasterRelationComponent } from "./masters/master-relation/master-relation.component";
import { GraphicsUploadComponent } from "./file/graphics-upload/graphics-upload.component";
import { RecycleBinComponent } from "./common/recycle-bin/recycle-bin.component";
import { LicenceComponent } from "./profile/licence/licence.component";
import { SocialSearchComponent } from "./file/social-search/social-search.component";
import { ExternalDeviceDetailComponent } from "./file/external-device-detail/external-device-detail.component";
import { SubsubcategoryComponent } from "./masters/sub-sub-category/subsubcategory.component";
import { HrCornerComponent } from "./file/hr-corner/hr-corner.component";
import { VaultManagementComponent } from "./masters/vault-management/vault-management.component";
import { LogingClipComponent } from "./masters/loging-clip/loging-clip.component";
import { ItemPreviewComponent } from "./item-preview/item-preview.component";
import { CompanyMasterComponent } from "./masters/CompanyMaster/company-master/company-master.component";
import { TreeviewdatacomComponent } from "./common/treeviewdatacom/treeviewdatacom.component";
import { TreedataComponent } from "./common/treedata/treedata/treedata.component";
import { UserLogsComponent } from "./common/ApprovalLogs/user-logs/user-logs.component";
import { ManagerLogsComponent } from "./common/ApprovalLogs/manager-logs/manager-logs.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "preview-items/:id", component: ItemPreviewComponent },
  { path: "pdfpreview", component: PdfpreviewComponent },
  { path: "weblink/:id", component: CartlinkComponent },
  { path: "report", component: LogexportComponent },
  { path: "comment", component: VideocommentComponent },
  { path: "keywords", component: VideokeywordsComponent },
  // {path:'filesystem',component:TreeviewcomComponent},
  {
    path: "",
    component: SharedcomponentComponent,
    children: [
      { path: "home", component: DashboardComponent, canActivate: [AuthGuard] },
      { path: "NewUser", component: UserComponent, canActivate: [AuthGuard] },
      {
        path: "NewCategory",
        component: CategoryComponent,
        canActivate: [AuthGuard],
      },
      { path: "NewCity", component: CityComponent, canActivate: [AuthGuard] },
      { path: "CompanyMaster", component: CompanyMasterComponent, canActivate: [AuthGuard] },
      {path: "Treedata", component: TreedataComponent, canActivate: [AuthGuard]},
      {
        path: "NewCountry",
        component: CountryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "NewStates",
        component: StateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "NewSubCategory",
        component: SubCategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "NewSubSUbCategory",
        component: SubsubcategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "CreateEvent",
        component: EventComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "NewPublication",
        component: PublicationComponent,
        canActivate: [AuthGuard],
      },
      { path: "NewPool", component: PoolComponent, canActivate: [AuthGuard] },
      {
        path: "UserRight",
        component: UserRightsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "imageUpload",
        component: ImageUploadComponent,
        canActivate: [AuthGuard],
        data: { pid: ["40"] },
      },
      {
        path: "videoupload",
        component: VideoUploadComponent,
        canActivate: [AuthGuard],
        data: { pid: ["39"] },
      },
      {
        path: "audioupload",
        component: AudioUploadComponent,
        canActivate: [AuthGuard],
        data: { pid: ["42"] },
      },
      {
        path: "documentupload",
        component: DocumentUploadComponent,
        canActivate: [AuthGuard],
        data: { pid: ["43"] },
      },
      {
        path: "Device",
        component: ExportConfigComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "stories",
        component: StoryArchiveComponent,
        canActivate: [AuthGuard],
        data: { pid: ["41"] },
      },
      {
        path: "changePass",
        component: SettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "allitems",
        component: AllitemsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "agencyimages",
        component: AgencyimagesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "agencystory",
        component: AgencystoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "mycart",
        component: MysharecartComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "assignedtome",
        component: SharedtomecartComponent,
        canActivate: [AuthGuard],
      },
      { path: "cart/:id", component: CartComponent, canActivate: [AuthGuard] },
      {
        path: "purging",
        component: PurgingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "filesystem",
        component: TreeviewcomComponent,
        canActivate: [AuthGuard],
        data: { pid: ["64"] },
      },
      {
        path: "preference",
        component: UserperferenceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "vaultmanager",
        component: VaultManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "serverstatus",
        component: ServerstatusComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "group",
        component: MastergroupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "masteragency",
        component: MasteragencyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "agencycat",
        component: AgencycategoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "masterkeywords",
        component: KeywordsComponent,
        canActivate: [AuthGuard],
        data: { pid: ["92"] },
      },
      {
        path: "bysubject",
        component: BysubjectComponent,
        canActivate: [AuthGuard],
        data: { pid: ["91"] },
      },
      {
        path: "dailypages",
        component: DailypagesComponent,
        canActivate: [AuthGuard],
        data: { pid: ["95"] },
      },
      {
        path: "edition",
        component: MastereditionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "editionrights",
        component: EditionrightComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "relation",
        component: MasterRelationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "graphicsUpload",
        component: GraphicsUploadComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "restore",
        component: RecycleBinComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "logingclip",
        component: LogingClipComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "vaultManagement",
        component: VaultManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "userapprovalLogs",
        component: UserLogsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "ManagerApprovalLogs",
        component: ManagerLogsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "licence",
        component: LicenceComponent,
        canActivate: [AuthGuard],
        data: { pid: ["125"] },
      },
      {
        path: "social",
        loadChildren: () =>
          import("../app/file/social-search/socialsearch.module").then(
            (m) => m.SocialsearchModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "ltoDisk",
        component: ExternalDeviceDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "HRDocs",
        component: HrCornerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "HRTreeView",
        loadChildren: () =>
          import("./hr-tree/hrtreeview/hrtreeview.module").then(
            (m) => m.HrtreeviewModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "HRTreeView",
        loadChildren: () =>
          import("../app/hr-tree/hrtreeview/hrtreeview.module").then(
            (m) => m.HrtreeviewModule
          ),
      },
      {
        path: "story-agency-wire",
        loadChildren: () =>
          import("./story-agency-wire/story-agency-wire.module").then(
            (m) => m.StoryAgencyWireModule
          ),
      },
      {
        path: "rundown_view",
        loadChildren: () =>
          import("./rundown-view/rundown-view.module").then(
            (m) => m.RundownViewModule
          ),
      },
      {
        path: "altostatus",
        loadChildren: () =>
          import("./masters/alto-status/alto-status.module").then(
            (m) => m.AltoStatusModule
          ),
      },
      // {path:'altostatus',component:AltoStatusComponent,canActivate:[AuthGuard]},
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  HostListener,
} from "@angular/core";
import { HttpEventType, HttpResponse, HttpClient } from "@angular/common/http";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { MessageService, ConfirmationService, MenuItem } from "primeng/api";
import { Paginator } from "primeng/paginator";
import { ContextMenu } from "primeng/contextmenu";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  NgForm,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { CategoryService } from "src/app/services/masters/category.service";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { saveAs } from "file-saver";
import { DomSanitizer } from "@angular/platform-browser";
import * as JSZip from "jszip";
import { CoreEnvironment } from "@angular/compiler/src/compiler_facade_interface";
import { environment } from "src/environments/environment";
import Cropper from "cropperjs";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { UploadPopupComponent } from "../image-upload-popup/upload-popup.component";
import { EventService } from "src/app/services/masters/event.service";
import { Eventformmodel } from "src/app/model/master/event.form.model";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { cartmodel, cartoncartname } from "src/app/model/file/cartmodel";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { LeftMenuComponent } from "src/app/common/left-menu/left-menu.component";
import { async } from "@angular/core/testing";
import { formatDate } from "@angular/common";
import { DashboardService } from "src/app/services/files/dashboard.service";
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from "ngx-file-drop";
import { AgencyService } from "src/app/services/files/agency.service";

@Component({
  selector: "app-agencyimages",
  templateUrl: "./agencyimages.component.html",
  styleUrls: ["./agencyimages.component.css"],
})
export class AgencyimagesComponent implements OnInit {
  @ViewChild("cropimagecanvas", { static: false }) cropimagecanvas: ElementRef;
  @ViewChild("glbsearchbox", { static: false }) glbsearchbox: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: DataView;
  ctx: CanvasRenderingContext2D;
  downloaditems: MenuItem[];
  rightclickmenuopen: boolean = false;
  edititems: MenuItem[];
  contextmenuitem: MenuItem[];
  foldercntxtitem: MenuItem[];
  display: boolean = false;
  show: boolean = false;
  imagePath: any;
  showDownload: boolean = false;
  config: boolean;
  imageList: ImageUploadFormModel[] = [];
  fileurl;
  currentFileUpload: File;
  searchdata: ImageUploadFormModel[] = [];
  downloadList: downloadimagesmodel[] = [];
  getdowloadimagelist: downloadimagesmodel[] = [];
  //progress: { percentage: number } = { percentage: 0 };
  Searchbyfield: string = "";
  ImageUpdated: boolean = false;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Image_Edit_form: FormGroup;
  selectedimage: ImageUploadFormModel;
  imageuploadinformation: ImageUploadFormModel;
  first: number = 0;
  view: boolean = false;
  fullpathlist: ImageUploadFormModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalimagepath: string;
  ButtonStatus = "Download";
  eventlist: Eventformmodel;
  glbDelte: number = 0;
  glupdate: number = 0;
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  crppbtn: number = 0;
  approvebtn: number = 0;
  @ViewChild("image", { static: false }) public imageElement: ElementRef;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @Input("src")
  public imageSource: string;
  public imageDestination = "";
  private cropper: Cropper;
  adnvacesrchfields: string;
  cartname: string;
  cartitemid: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  cartlist: cartmodel[] = [];
  cartfinallist: cartoncartname[] = [];
  selectedNodes3: cartoncartname[];
  results: string[] = [];
  filteredBrands: any[];
  assigntoid: string = "null";
  dataforassign: cartmodel[];
  selectedCars3: cartmodel[];
  assignpopup: boolean = false;
  brightness: number = 0;
  contrast: number = 0;
  saturate: number = 0;
  watermark: string = "India Today";
  metadatadiv: number = 1;
  editdiv: number = 0;
  grin: string = "";
  cid: string = null;
  totalcount: string;
  loading: boolean = true;
  watermarkposition: string = "topright";
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexspermis: any[] = [];
  showeditor: boolean = false;
  iframepath: string = "";
  cols = [
    { field: "cartname", header: "Name" },
    //{ field: 'itemname', header: 'Item Name' },
  ];
  userlist: userformmodel[] = [];
  idforedit: string = "";
  edittable: string = "";
  nameforedit: string = "";
  showmax: number = 0;
  showmin: number = 1;
  addtocartbtn: number = 0;
  showaclrange: boolean = false;
  acldaterange: string = "";
  cdate: string = "";
  approvallst: any[] = [];
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  ismenuclick: boolean = false;
  searchboxwidth: string = "";
  searchboxleft: string = "";
  public files: NgxFileDropEntry[] = [];
  value: number = 0;
  fixfilepath: string = "";
  public progresstat: number = 0;
  folderdata: any[] = [];
  rowlimit: number = 20;
  maxrowlimit: number = 40;
  selectedcollpath: String = "";
  glbsrchtxt: string = "";
  glbadnvsrchtxt: string = "";
  selectedid: string = "";
  bditems: MenuItem[];
  totalfcount: string;
  treeactivekey: string = "";
  home: MenuItem;
  selectedagency: string = "";
  constructor(
    public imageservice: ImageuploadService,
    private router: Router,
    private route: ActivatedRoute,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public eventservice: EventService,
    private userser: UserserviceService,
    public dashser: DashboardService,
    public agencyser: AgencyService
  ) {
    this.route.queryParams.subscribe((queryParams) => {
      this.cid = queryParams.quip;
      if (this.cid != null || this.cid != "") {
        this.getagencydata(this.cid);
        this.getagencyname(this.cid);
      }
    });
  }
  ngOnInit() {
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.checkpermissions();
    this.search();
    this.getuserdata();
    this.selectedimage = new ImageUploadFormModel();
    this.fullpathlist = new ImageUploadFormModel();
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });

    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Delete",
        icon: "fa fa-trash",
        command: (event) => {
          this.deletemultiple();
        },
      },
      // {
      //   label: 'Add to cart',
      //   icon: 'fa fa-shopping-cart',
      //   command: (event) => { this.showmulcartdiv(); }
      // },
    ];
    this.home = {
      icon: "pi pi-home",
      command: (event) => {
        this.getagencydata(this.cid);
      },
    };
    this.bditems = [];
    this.dashser.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  showacldaterangediv() {
    this.ismenuclick = true;
    this.showaclrange = true;
  }

  hideupload() {
    this.display = false;
    this.progresstat = 0;
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 10) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 11) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 12) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 51) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 44) {
        this.crppbtn = 1;
      }
      if (this.permissionlist[i].taskid == 54) {
        this.addtocartbtn = 1;
      }
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
    }
  }

  setcaption(id, index) {
    var x = document.getElementsByClassName(index + id);
    x[0]["style"]["display"] = "block";
    x[1]["style"]["display"] = "-webkit-box";
  }

  unsetcaption(id, index) {
    var x = document.getElementsByClassName(index + id);
    x[0]["style"]["display"] = "none";
    x[1]["style"]["display"] = "none";
  }

  cmshow() {
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;

    if (this.downloadbtn == 0) {
      this.contextmenuitem[0].disabled = true;
    }
    if (JSON.parse(localStorage.getItem("userdetails"))[0].UserId != 53) {
      this.contextmenuitem[1].disabled = true;
    }

    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[6].disabled = true;
        break;
      }
    }
  }

  getitems(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.agencyser.getimagedata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullimagepopup(data[0]);
        }
      });
    }
  }

  setpath(id, name) {
    this.selectedcollpath = name;
    this.selectedid = id;
  }

  deletemultiple() {
    var lst = this.selectedIndexs;
    this.agencyser.deleteimages(lst).subscribe((data: any) => {
      if (data != null) {
        this.messageservice.add({
          key: "message",
          severity: "success",
          summary: "Success Message",
          detail: "Delete successfully",
        });
        this.removeselection();
        if (this.glbsrchtxt != "") {
          this.SearchByFields("");
        } else if (this.glbadnvsrchtxt != "") {
          this.advancesearch(this.glbadnvsrchtxt);
        } else {
          this.getfolderdata(
            this.treeactivekey.substring(0, this.treeactivekey.length - 1),
            this.selectedcollpath
          );
        }
      }
    });
  }

  onHide1() {
    this.display = false;
  }

  cancel() {
    this.editdiv = 0;
    this.metadatadiv = 1;
    this.view = false;
    this.showfulldiv();
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatus = "Download";
      //   document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  hideedit() {
    this.idforedit = "";
    this.ImageUpdated = false;
    this.showfulldiv();
  }

  Reset() {
    this.cropper.clear();
    this.editdiv = 0;
    this.metadatadiv = 1;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatus = "Download";
      // document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  showeditdiv(path, itemid) {
    this.dashser.createlog(
      itemid,
      "Edit_Download",
      JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
      "transaction_image"
    );
    this.showeditor = true;
    this.iframepath = environment.editorpath + path;
  }
  hideeditor() {
    this.showeditor = false;
    this.iframepath = "";
  }

  CropedImageDownload(filename, filepath, itemid) {
    if (this.imageDestination != "") {
      // this.ButtonStatus='Cropped Download '
      //var downloadpath = this.imageDestination
      this.ctx = this.cropimagecanvas.nativeElement.getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.cropimagecanvas.nativeElement.width,
        this.cropimagecanvas.nativeElement.height
      );
      var image = new Image();
      image.src = this.imageDestination;
      this.cropimagecanvas.nativeElement.height = image.naturalHeight;
      this.cropimagecanvas.nativeElement.width = image.naturalWidth;
      if (this.contrast == 0) {
        this.ctx.filter = "contrast(none)";
      } else {
        this.ctx.filter = "contrast(" + this.contrast / 10 + ")";
      }
      if (this.brightness == 0) {
        this.ctx.filter = "brightness(none)";
      } else {
        this.ctx.filter = "brightness(" + this.brightness / 10 + ")";
      }
      if (this.saturate == 0) {
        this.ctx.filter = "saturate(none)";
      } else {
        this.ctx.filter = "saturate(" + this.saturate / 10 + ")";
      }
      if (this.grin == "") {
        this.ctx.filter = this.grin + "(none)";
      } else {
        this.ctx.filter = this.grin + "(1)";
      }
      //this.ctx.filter="brightness(25.5)";

      this.ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      if (this.watermark != "") {
        this.ctx.globalAlpha = 0.5;
        if (this.watermarkposition == "topright") {
          var fntsize = image.naturalWidth / 20;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.textAlign = "end";
          this.ctx.fillText(
            this.watermark,
            image.naturalWidth - 10,
            image.naturalHeight * 0.06,
            image.naturalWidth
          );
        } else {
          var fntsize = image.naturalWidth / 5;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.translate(0, image.naturalWidth * 0.12);
          this.ctx.rotate(
            Math.atan(
              image.naturalHeight /
                (image.naturalWidth + image.naturalWidth * 0.05)
            )
          );
          this.ctx.fillText(this.watermark, 0, 0, image.naturalWidth);
        }
        // setup text for filling
      }
      var downloadpath =
        this.cropimagecanvas.nativeElement.toDataURL("image/jpeg");
      this.LoadImageCrop(downloadpath, this.SaveCropImage, filename, itemid);
      this.Reset();
      this.downloadentry(itemid);
    } else {
      this.downloadFullImage(filename, filepath, itemid);
      this.downloadentry(itemid);
    }
  }
  private SaveCropImage(content: Blob, filename, itemid): void {
    saveAs(content, filename);
  }
  private LoadImageCrop(
    url: string,
    callback: Function,
    filename,
    itemid
  ): void {
    saveAs(url, filename);
  }

  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  async downloadFullImage(filename, filepath, itemid) {
    let unsub = this.imageservice
      .Testdownload(filename, filepath)
      .subscribe(async (data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: data.split(";")[0].split(":")[1],
          });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          //const file = new File([blob], filename);
        }
        unsub.unsubscribe();
      });
    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    this.dashser
      .createlog(
        itemid,
        "Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "agency_image"
      )
      .subscribe((data1: any[]) => {});
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  fullimagepopup(imageList) {
    this.downloaditems = [
      {
        label: "Low",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.thumbpath,
            imageList.itemid
          );
        },
      },
      {
        label: "Medium",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.HiresPath,
            imageList.itemid
          );
        },
      },
      {
        label: "High",
        command: () => {
          this.CropedImageDownload(
            imageList.orgfilename,
            imageList.OriginalPath,
            imageList.itemid
          );
        },
      },
    ];

    this.edititems = [
      {
        label: "Low",
        command: () => {
          this.showeditdiv(imageList.thumbpath, imageList.itemid);
        },
      },
      {
        label: "Medium",
        command: () => {
          this.showeditdiv(imageList.HiresPath, imageList.itemid);
        },
      },
      {
        label: "High",
        command: () => {
          this.showeditdiv(imageList.OriginalPath, imageList.itemid);
        },
      },
    ];
    this.fullpathlist = imageList;
    this.ratingitem = imageList.itemrating;
    this.imageservice
      .Testdownload(imageList.orgfilename, imageList.HiresPath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
            "data:image/jpg;base64," + b64Data1
          );
        }
        this.view = true;
      });
  }

  advanceSearch() {
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  showDialog() {
    this.display = true;
  }

  getTodayImages() {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.imageservice.getTodayImages().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.imageList = data;
        this.loading = false;
      } else {
        this.totalcount = "0";
        this.imageList = [];
      }
    });
  }

  //this function is not working
  SearchAllImages1(formvalues, fn) {
    this.removeselection();
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }

    if (fn != "") {
      this.imageservice.SearchAllImages(formvalues).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.imageList = data;
          if (formvalues.totalcount != "") {
            this.totalcount = formvalues.totalcount;
          } else {
            this.totalcount = this.imageList.length.toString();
          }

          this.loading = false;
        } else {
          this.totalcount = "0";
          this.imageList = [];
          this.loading = false;
        }
      });
    } else {
      this.getAllImages();
    }

    this.loading = false;
  }

  getAllImages() {
    this.removeselection();
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.agencyser
      .getallcount(this.selectedagency + "/" + this.selectedcollpath)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount = data[0].rowcount;
        } else {
          this.totalcount = "0";
        }
      });

    this.glbadnvsrchtxt = "";
    this.glbsrchtxt = "";
    this.agencyser
      .getallagencydata(this.selectedagency + "/" + this.selectedcollpath, "0")
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.imageList = data;
          //this.totalcount = localStorage.getItem("imagecount")
          this.glbDelte = 1;
          this.glupdate = 1;
          this.loading = false;
        } else {
          this.imageList = [];
          this.loading = false;
        }
      });
  }

  advancesearch(srctxt) {
    this.removeselection();
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (srctxt != "") {
      this.hideadvncsrch();
      this.glbadnvsrchtxt = srctxt;
      this.glbsrchtxt = "";
      var tbastr = localStorage.getItem("tabperstring");
      this.agencyser
        .getacount(
          this.selectedagency + "/" + this.selectedcollpath,
          srctxt,
          tbastr
        )
        .subscribe((data: any[]) => {
          if (data.length > 0) this.totalcount = data[0].rowcount.toString();
        });

      this.agencyser
        .advancesearch(
          this.selectedagency + "/" + this.selectedcollpath,
          srctxt,
          "0"
        )
        .subscribe((data: any[]) => {
          if (data != null) {
            this.imageList = data;
            //this.totalcount = this.imageList.length.toString()
          } else {
            this.imageList = [];
          }
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  SearchByFields(type) {
    //this.first=0
    this.removeselection();
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.Searchbyfield = this.Searchbyfield.trim();
    if (this.Searchbyfield != "") {
      this.glbsrchtxt = this.Searchbyfield;
      this.glbadnvsrchtxt = "";
      var srchtxt = this.ExtractSearchingKeyword(this.Searchbyfield);
      var tbastr = localStorage.getItem("tabperstring");
      this.agencyser
        .getncount(
          this.selectedagency + "/" + this.selectedcollpath,
          srchtxt,
          tbastr
        )
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.totalcount = data[0].rowcount.toString();
          }
        });
      this.agencyser
        .SearchByFields1(
          this.selectedagency + "/" + this.selectedcollpath,
          srchtxt,
          "0"
        )
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            //environment.glbsearchstring=""
            this.imageList = data;
            // this.totalcount = this.imageList.length.toString()
            this.loading = false;
          } else {
            this.totalcount = "0";
            this.imageList = [];
          }
        });
      this.loading = false;
    } else {
      //this.getAllImages()
      this.loading = false;
    }
  }

  pagenochange(e) {
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      var x = document.querySelector(".ui-paginator-bottom");
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
      this.loading = true;
      this.glbsrchtxt = this.glbsrchtxt.trim();
      if (this.glbsrchtxt != "") {
        var srchtxt = this.ExtractSearchingKeyword(this.glbsrchtxt);
        this.agencyser
          .SearchByFields1(
            this.selectedagency + "/" + this.selectedcollpath,
            srchtxt,
            this.dataview1.totalRecords
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else if (this.glbadnvsrchtxt != "") {
        this.agencyser
          .advancesearch(
            this.selectedagency + "/" + this.selectedcollpath,
            this.glbadnvsrchtxt,
            this.dataview1.totalRecords
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        this.agencyser
          .getallagencydata(
            this.selectedagency + "/" + this.selectedcollpath,
            this.dataview1.totalRecords
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      }
    }

    this.removeselection();
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.imageservice
      .GetDownloadedImageData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadList = data;
          this.showDownload = true;
        }
      });
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        lst.push(this.cartitemid);
        this.imageservice
          .addtocart(this.cartname, lst, "image", userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    this.imageservice
      .addtocart(this.cartname, lst, "image", userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Add to Cart successfully",
          });
          this.clearcartname();
        }
      });
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }
  showcartdiv(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.showcart = true;
    }
  }

  showmulcartdiv() {
    this.showcart = true;
    this.ismenuclick = true;
  }

  showcartlist() {
    this.cartfinallist = [];
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .bindcart("image", userid, "archieve")
      .subscribe((data: any[]) => {
        if (data != null) {
          this.cartlist = data;
          this.showcartbox = true;
        }
      });
  }

  downloadmultiplecart(data) {
    //this.leftmenu.downloadinggif=1
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    y["style"].display = "block";
    //x.className.replace("downloadinggif","")
    if (data != null) {
      if (data.length != undefined) {
        var id = "";
        for (let i = 0; i < data.length; i++) {
          id += data[i].cartid.toString() + "~";
          if (i == data.length - 1) {
            this.downloadcart(id);
          }
        }
      } else {
        this.downloadcart(data);
      }
    }
  }

  downloadcart(data1) {
    this.imageservice
      .downloadcart(data1.toString())
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadzipflder(data);
        }
      });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;
    //this.imageservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});

    //})
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event1: KeyboardEvent) {
    if (
      event1.ctrlKey == false &&
      event1.metaKey == false &&
      event1.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        this.removeselection();
      }
    }
  }

  removeselection() {
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      document.getElementById(
        this.selectedIndexs[i].toString()
      ).style.background = "transparent";
      if (i == this.selectedIndexs.length - 1) {
        this.selectedIndexs.splice(0, this.selectedIndexs.length);
        this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    // console.log(_index);

    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "orange";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    // console.log(_index);

    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var fname = iid.children[2].innerHTML.trim();
      var filepath = iid.children[0].innerHTML.trim();
      //this.downloadFullImage(fname,filepath)
      filepath = filepath.replace("lowres/", "raw/");
      // console.log(filepath)
      if (lst.length > 1) {
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      } else {
        this.downloadFullImage(fname, filepath, imageid);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  downloadmultiplezip(arr) {
    this.imageservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  removefromcart(cartdata) {
    this.imageservice.removefromcart(cartdata).subscribe((data: any[]) => {
      this.showcartlist();
    });
  }
  hidecartbox() {
    this.showcartbox = false;
    this.selectedCars3 = null;
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  assign(cartdata) {
    if (cartdata.length > 0) {
      this.dataforassign = cartdata;
      this.assignpopup = true;
    }
    if (cartdata != null) {
      this.dataforassign = cartdata;
      this.assignpopup = true;
    }
  }

  assigncarttouser() {
    if (this.assigntoid != "null") {
      if (this.dataforassign.length != undefined) {
        for (let i = 0; i < this.dataforassign.length; i++) {
          var finaldata = this.dataforassign[i];
          this.imageservice
            .assigncart(finaldata, this.assigntoid)
            .subscribe((data: any[]) => {
              if (data != null) {
              }
            });
        }
      } else {
        var finaldata1 = this.dataforassign;
        this.imageservice
          .assigncart(finaldata1, this.assigntoid)
          .subscribe((data: any[]) => {
            if (data != null) {
            }
          });
      }

      this.hideassigndrop();
    }
  }

  hideassigndrop() {
    this.assignpopup = false;
    this.assigntoid = "null";
    this.dataforassign = null;
    this.selectedCars3 = null;
  }

  getuserdata() {
    this.userser.getuserdata().subscribe((data: userformmodel[]) => {
      if (data != null) {
        this.userlist = data;
      }
    });
  }

  // getcartdata(id) {
  //   this.imageservice.getcartdata(id, "image").subscribe((data: any[]) => {
  //     if (data.length > 0) {
  //       this.imageList = data
  //       this.totalcount = this.imageList.length.toString()
  //     } else {
  //       this.imageList = []
  //     }
  //     this.loading = false
  //   })
  //   this.showcartbox = false
  // }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  //uploading

  public dropped(files: NgxFileDropEntry[]) {
    var _self = this;
    this.files = files;
    var filenames = [];
    const formData = new FormData();
    var i: number = 0;

    (function loop() {
      if (i < files.length) {
        if (files[i].fileEntry.isFile) {
          const fileEntry = files[i].fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            // console.log(files[i].relativePath, file);
            filenames.push(files[i].relativePath);
            formData.append(
              "photo",
              file,
              _self.selectedagency +
                "/" +
                _self.selectedcollpath +
                "/" +
                files[i].relativePath
            );
            i++;
            loop();
          });
        } else {
          const fileEntry = files[i].fileEntry as FileSystemDirectoryEntry;
          i++;
          loop();
        }
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open(
          "post",
          environment.ApiUrl +
            "agencyupload/api/upload2?filelength=" +
            files.length,
          true
        );
        var prct1 = "";
        xhr.upload.onprogress = function (e) {
          if (e.lengthComputable) {
            var percentage = (e.loaded / e.total) * 100;
            prct1 = percentage + "%";
            _self.progresstat = percentage;
            console.log(percentage + "%");
          }
        };
        xhr.onerror = function (e) {
          console.log("Error");
          console.log(e);
        };
        xhr.onload = function () {
          _self.fileuploadentry(filenames);
          _self.display = false;
          _self.progresstat = 0;
          console.log(this.statusText);
        };
        xhr.send(formData);
      }
    })();
    // Is it a file?
  }

  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }

  fileuploadentry(files) {
    this.agencyser
      .uploadagencyfiles(files, this.selectedid, this.cid)
      .subscribe((data: any[]) => {
        if (data != null) {
        }
      });
  }

  getagencydata(id) {
    console.log(id);
    this.bditems = [];
    this.selectedid = "";
    this.selectedcollpath = "";
    this.agencyser.getagencycatdata(id).subscribe((data: any[]) => {
      console.log(data);

      if (data.length > 0) {
        this.folderdata = data;
        this.imageList = [];
        this.totalcount = "0";
        this.totalfcount = this.folderdata.length.toString();
      } else {
        this.folderdata = [];
        this.imageList = [];
        this.totalcount = "0";
        this.totalfcount = this.folderdata.length.toString();
      }
      this.loading = false;
    });
  }
  getagencyname(id) {
    this.agencyser.getagencyname(id).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.selectedagency = data[0].AgencyName;
      }
    });
  }

  getfolderdata(key, name) {
    console.log(key, name);
    key = key.toString();
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.glbadnvsrchtxt = "";
    this.glbsrchtxt = "";
    var tmpkeyarr = key.split("/");
    var tmpamearr = name.split("/");
    var str = key.split("/")[key.split("/").length - 1];
    if (str == "") {
      this.loading = false;
      return;
    }
    var tmpname = "";
    var tmpkey = "";
    this.bditems = [];
    for (let j = 0; j < tmpamearr.length; j++) {
      tmpname = tmpname + "/" + tmpamearr[j];
      tmpkey = tmpkey + "/" + tmpkeyarr[j];
      this.bditems.push({
        label: tmpamearr[j],
        icon: "fa fa-folder",
        id: tmpkey.replace("/", ""),
        target: tmpname.replace("/", ""),
        command: (event) => {
          this.getfolderdata(event.item.id, event.item.target);
        },
      });
    }
    this.selectedid = key.split("/")[key.split("/").length - 1];
    this.selectedcollpath = name;
    this.treeactivekey = key + "/";
    // this.selectedcollpath=name
    // this.selectedid=id
    this.loading = true;

    this.agencyser.getfolderdata(str, name).subscribe((data: any) => {
      console.log(data);

      if (data.length > 0) {
        this.folderdata = data[0];
        //data[1].sort((a,b) => b.Udate1.localeCompare(a.Udate1));
        this.imageList = data[1];
        this.loading = false;
        this.totalcount = this.imageList.length.toString();
        this.totalfcount = this.folderdata.length.toString();
      }
      this.loading = false;
    });
  }
}

import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ConfirmationService, MessageService, MenuItem } from "primeng/api";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { DomSanitizer } from "@angular/platform-browser";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { environment } from "src/environments/environment";
import { DashboardService } from "src/app/services/files/dashboard.service";

@Component({
  selector: "app-recycle-bin",
  templateUrl: "./recycle-bin.component.html",
  styleUrls: ["./recycle-bin.component.css"],
})
export class RecycleBinComponent implements OnInit {
  ApiServerPath = environment.ApiServerPath;
  elementarr: any[] = [];
  loading: boolean;
  dataview1: any;
  constructor(
    private dashboarservice: DashboardService,
    private documentservice: DocumentuploadService,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService,
    public audiouploadservice: AudiouplaodService,
    public imageservice: ImageuploadService,
    public sanitizer: DomSanitizer,
    public videoservice: VideouploadService,
    private storyservice: StoryserviceService
  ) {}

  restore(itemid, type, event) {
    // console.log("itemid,type,event",itemid,type,event);

    if (type.toLowerCase() == "audio") {
      this.audiouploadservice.restoreAudio(itemid).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Restore successfully",
          });
        }
      });
    } else if (type.toLowerCase() == "image") {
      this.imageservice.restoreImage(itemid, type).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Restore successfully",
          });
        }
      });
    } else if (type.toLowerCase() == "graphics") {
      this.imageservice.restoreImage(itemid, type).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Restore successfully",
          });
        }
      });
    } else if (type.toLowerCase() == "video") {
      this.videoservice.restoreVideo(itemid).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Restore successfully",
          });
        }
      });
    } else {
      this.documentservice.restoreDocument(itemid).subscribe((data: any) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Restore successfully",
          });
        }
      });
    }
  }

  filterData(itemid) {
    let data = this.elementarr.filter(
      (elementarr) => elementarr.itemid !== itemid
    );
    this.elementarr = data;
  }

  restorepopup(itemid, type, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Restore?",
        header: "Confirmation",
        key: "recyclebinkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.restore(itemid, type, e);
          this.filterData(itemid);
        },
        reject: () => {},
      });
    }
  }

  getAllDeletedData(type) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    } else {
      environment.globalsearchtext = "";
      environment.glbsearchstring = "";
      var tbastr = localStorage.getItem("tabperstring");
      this.dashboarservice
        .getAllDeletedData(tbastr, "0~0~0~0~0")
        .subscribe((data: any) => {
          if (data != null) {
            var data1 = JSON.parse(
              data.toString().split("~")[0].replace(/\ʁ/g, "~")
            );
            data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
            this.elementarr = data1;
          }
        });
      this.loading = false;
    }
    this.loading = false;
  }

  delete(itemid, type, event) {
    // console.log("itemid,type,event",itemid,type,event);

    if (type.toLowerCase() == "audio") {
      this.audiouploadservice
        .deleteAudioRstr(itemid)
        .subscribe((data: any[]) => {
          if (data != null) {
            // console.log(data);
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
          }
        });
    } else if (
      type.toLowerCase() == "image" ||
      type.toLowerCase() == "graphics"
    ) {
      this.imageservice.deleteImageRstr(itemid).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
        }
      });
    } else if (type.toLowerCase() == "video") {
      this.videoservice.deleteVideoRstr(itemid).subscribe((data: any[]) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
        }
      });
    } else {
      this.documentservice.deleteDocumentRstr(itemid).subscribe((data: any) => {
        if (data != null) {
          // console.log(data);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
        }
      });
    }
  }

  deletepopup(itemid, type, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "recyclebinkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.delete(itemid, type, e);
          this.filterData(itemid);
        },
        reject: () => {},
      });
    }
  }

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.getAllDeletedData("");
  }
}

import { Injectable } from '@angular/core';
import { CityFormModel } from 'src/app/model/master/city.form.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  cityformmodel:CityFormModel=new CityFormModel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  //baseurl: string = "http://localhost:5000/city";

  getAllCity():Observable<CityFormModel[]>{
    return this.httpclient.get<CityFormModel[]>(this.baseurl+"city");
  }
  CreateCity(cityinfo:CityFormModel):Observable<CityFormModel[]>{
    return this.httpclient.post<CityFormModel[]>(this.baseurl+ "city/insertcity",cityinfo);
  }
  getStateDropDown(countryid:number):Observable<stateFormModel[]>{
   return this.httpclient.post<stateFormModel[]>(this.baseurl+"city/selectstate",{CountryId:countryid});
    }
  getCountryDropDown():Observable<CountryFormModel[]>{
    return this.httpclient.get<CountryFormModel[]>(this.baseurl+"city/selectcountry");
  }
  UpdateCity(cityinfo:CityFormModel):Observable<CityFormModel[]>{
    return this.httpclient.post<CityFormModel[]>(this.baseurl+"city/updatecity",cityinfo)
  }
  DeleteCity(CityId:number):Observable<CityFormModel[]>{
    return this.httpclient.post<CityFormModel[]>(this.baseurl+"city/deletecity",{CityId:CityId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  getCityData(stateid:number):Observable<CityFormModel[]>{
    return this.httpclient.post<CityFormModel[]>(this.baseurl+"city/selectcity",{StateId:stateid});
     }
 checkduplicate(cityinfo:CityFormModel):Observable<CityFormModel[]>{
  return this.httpclient.post<CityFormModel[]>(this.baseurl+ "city/checkduplicate",cityinfo);
}    
}
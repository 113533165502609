import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http'
import { CategoryService } from 'src/app/services/masters/category.service';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { FormGroup, FormBuilder, NgForm, Validators, AbstractControl, FormControl } from '@angular/forms';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { CountryService } from 'src/app/services/masters/country.service';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { CityService } from 'src/app/services/masters/city.service';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { CityFormModel } from 'src/app/model/master/city.form.model';
import { EventService } from 'src/app/services/masters/event.service';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { MessageService } from 'primeng/api';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { FileUpload } from 'primeng/primeng';
import { environment } from '../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
@Component({
  selector: 'app-graphics-upload-popup',
  templateUrl: './graphics-upload-popup.component.html',
  styleUrls: ['./graphics-upload-popup.component.css']
})
export class GraphicsUploadPopupComponent implements OnInit {

  @ViewChild('fileInput', { static: true }) fileInput: FileUpload;
  @ViewChild('imagetemp', { static: true }) ngtemp: ElementRef;


  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  ImageUploadForm: FormGroup
  categoryid: number = 0
  eventlist: Eventformmodel[] = [];
  countrylist: CountryFormModel[] = []
  Statelist: stateFormModel[] = []
  //stateid:number=0
  citylist: CityFormModel[] = []
  imageuploadInformation: ImageUploadFormModel;
  imageuploadlist: ImageUploadFormModel[] = [];
  x: string
  check: boolean = true;
  ImageUrl = environment.ApiUrl
  i: number;
  value:number=0
  uploadis:boolean=true
  limitexceed:number=0
  itemtypes:string=""
  constructor(private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private countryservice: CountryService, private cityservice: CityService, private eventservice: EventService, private messageservice: MessageService, private imageuploadservice: ImageuploadService, private sanitizer: DomSanitizer, private router: Router ,private imagecomponent:ImageUploadComponent) { }


  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    //this.getcategorydata()
    this.CreateImageUploadForm()
    //this.getCountryData()
    //this.getEventData()
    this.getitemtype()
  }

  getitemtype(){
    this.imageuploadservice.getitemtypes().subscribe((data:any[])=>{
      if(data !=null){
        this.itemtypes=data[0].typeformat
      }
    })
  }

  getStyle(s){
    if(this.selectedFiles){
      return "lightblue"
    }else
    {
      return "white"
    }

  }
  clear(){
    // this.fileInput._files=[]
    this.ImageUploadForm.reset()
    
    this.value=0
    this.limitexceed=0
    this.ImageUploadForm.patchValue({ itempermission: "1"})
    this.imagecomponent.onHide1()
  }
 remove(name){
    // this.fileInput._files=[]

  for(let i=0;i<this.fileInput.files.length;i++){
    if(this.fileInput.files[i].name==name){
      // this.fileInput._files.splice(i,1)
    }
  }
    this.ImageUploadForm.reset()
  }
 
  getmetadata(nme,type) {
    for(let i=0; i<this.fileInput.files.length; i++)
    {
        document.getElementById(this.fileInput.files[i].name).style.background='white'
    }

    document.getElementById(nme).style.background="lavender"
    let indx
    for(let i=0;i<this.fileInput.files.length;i++){
      var tmp=this.fileInput.files[i].name
      if(tmp==nme){
        indx=i

      }
    }
    var format =this.fileInput.files[indx].type.substring(6,this.fileInput.files[indx].type.length)
    var filesize=""
    if ((this.fileInput.files[indx].size) / 1000 > 1024) {
       filesize = (((this.fileInput.files[indx].size) / 1000) / 1024).toFixed(2) + " MB"
    }
    else {
       filesize = ((this.fileInput.files[indx].size) / 1000).toFixed(3) + " KB"
    }
    if((this.fileInput.files[indx].size)){
    this.ImageUploadForm.patchValue({itemformat:format})
    }

      this.ImageUploadForm.patchValue({itemformat:format})
    this.ImageUploadForm.patchValue({ itemsize: filesize })
   

    var p = environment.folderpath + nme
    this.imageuploadservice.getmetadata(p).subscribe((data: any) => {
      if (data != null) {
        var dataparse=JSON.parse(data)
         var dt = dataparse[0]
        this.imageuploadlist = dt
        this.x = JSON.stringify(this.imageuploadlist)
        this.x = this.x.replace("Caption-Abstract", "Caption")
        this.imageuploadlist = JSON.parse(this.x)
        this.ImageUploadForm.patchValue({ resolution: this.imageuploadlist["XResolution"] })
        this.ImageUploadForm.patchValue({ itemcaption: this.imageuploadlist["Caption"] })
        this.ImageUploadForm.patchValue({ itemkeyword: this.imageuploadlist["Keywords"] })
        this.ImageUploadForm.patchValue({ itemfiename: nme })
        this.ImageUploadForm.patchValue({ title: this.imageuploadlist["Title"] })
        this.ImageUploadForm.patchValue({ source: this.imageuploadlist["Source"] })
        if(this.imageuploadlist["Credit"] !=undefined){
          this.ImageUploadForm.patchValue({ photogrphername: this.imageuploadlist["Credit"] })
        }
        else if(this.imageuploadlist["Creator"] !=undefined){
          this.ImageUploadForm.patchValue({ photogrphername: this.imageuploadlist["Creator"] })
        }
        else if(this.imageuploadlist["ItemAuthor"] !=undefined){
          this.ImageUploadForm.patchValue({ photogrphername: this.imageuploadlist["ItemAuthor"] })
        }

        if(this.imageuploadlist["CopyrightNotice"] !=undefined){
          this.ImageUploadForm.patchValue({ copyright: this.imageuploadlist["CopyrightNotice"] })
        }
        else if(this.imageuploadlist["Copyright"] !=undefined){
          this.ImageUploadForm.patchValue({ copyright: this.imageuploadlist["Copyright"] })
        }
        this.ImageUploadForm.patchValue({ CategoryId: this.imageuploadlist["Category"] })
        this.ImageUploadForm.patchValue({ CountryId: this.imageuploadlist["Country"] })
        this.ImageUploadForm.patchValue({ StateId: this.imageuploadlist["State"] })
        this.ImageUploadForm.patchValue({ CityId: this.imageuploadlist["City"] })
        this.ImageUploadForm.patchValue({ shootdate: this.imageuploadlist["CreateDate"] })
        this.ImageUploadForm.patchValue({ orietation: this.imageuploadlist["Orientation"] })
      }

    })
  }


  getfilename(e) {
    var i: number
    var nme = e.files[0].name
    
    if(e.files.length>0 && e.files.length<6){
      this.uploadis=false
      this.selectedFiles.push(e.files)
      if ((e.files[0].size) / 1000 > 1024) {
        var filesize = (((e.files[0].size) / 1000) / 1024).toFixed(2) + " MB"
      }
      else {
        var filesize = ((e.files[0].size) / 1000).toFixed(3) + " KB"
      }
      var format = e.files[0].type.substring(6, this.fileInput.files[0].type.length)
      this.ImageUploadForm.patchValue({ itemfiename: nme })
      this.ImageUploadForm.patchValue({ itemsize: filesize })
      this.fileInput.upload();
    }
    else{
      // this.fileInput._files=[]
      //this.ImageUploadForm.reset()
      this.uploadis=true
      this.limitexceed=1
    }

    
  }



  CreateImageUploadForm() {
    this.ImageUploadForm = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfiename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null, [this.removeSpaces]],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      title:[null],
      source:[null],
      photogrphername:[null],
      copyright:[null],
      orietation:[null],
      shootdate:[null],
      itempermission:[null],
      userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })

  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  getcategorydata() {
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }
  getSubcategorydata(c) {
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getCountryData() {
    this.countryservice.GetCountryData().subscribe((data: CountryFormModel[]) => {
      if (data.length > 0) {
        this.countrylist = data;
      }
    })
  }
  getStateDropDown(c) {
    this.cityservice.getStateDropDown(c).subscribe((data: stateFormModel[]) => {
      if (data.length > 0) {
        this.Statelist = data;
      }
      else {
        this.Statelist = []
        this.ImageUploadForm.patchValue({ StateId: "null" })
        this.citylist = []
        this.ImageUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    // this.countryid=selctedcountryid;
    this.getStateDropDown(selctedcountryid)
  }

  getCityDropdown(s) {
    this.cityservice.getCityData(s).subscribe((data: CityFormModel[]) => {
      if (data.length > 0) {
        this.citylist = data;
      }
      else {
        this.citylist = []
        this.ImageUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getCity(e) {
    let selectedstateid = e.target.selectedOptions[0].value;
    this.getCityDropdown(selectedstateid)
  }

  getEventData() {
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }

    })

  }

  upload(formval) {
    this.imageuploadInformation = formval.value
    if(this.fileInput.files.length==0){
      this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please Browse Files' });
    }
    else{
      var stduplicate=0
      var stinsert=0
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var name = this.fileInput.files[i].name;
      name = this.fileInput.files[i].name.replace(new RegExp("'", 'g'), "''")
      var p = environment.folderpath + name

      this.imageuploadservice.getmetadata(p).subscribe((data: any) => {
        if (data != null) {
         var dataparse=JSON.parse(data)
          var dt = dataparse[0]
          this.imageuploadlist = dt
         this.x = JSON.stringify(this.imageuploadlist)
          this.x = this.x.replace("Caption-Abstract", "Caption")
          this.imageuploadlist = JSON.parse(this.x)
          let imgsize = this.imageuploadlist["FileSize"].split(" ")[0]+this.imageuploadlist["FileSize"].split(" ")[1].toUpperCase()
          this.imageuploadInformation.itemfiename = this.fileInput.files[i].name;
          this.imageuploadInformation.itemsize = imgsize
          this.imageuploadInformation.itemformat=this.imageuploadlist["FileTypeExtension"]
          this.imageuploadInformation.itemlength = this.imageuploadlist["ImageSize"]
          this.imageuploadInformation.resolution = this.imageuploadlist["XResolution"]
          if(this.imageuploadInformation.itemcaption==this.imageuploadlist["Caption"] || this.imageuploadInformation.itemcaption==null ||this.imageuploadInformation.itemcaption==undefined){
            this.imageuploadInformation.itemcaption= this.imageuploadlist["Caption"] 
          }
          if(this.imageuploadInformation.itemkeyword==this.imageuploadlist["Keywords"] ||this.imageuploadInformation.itemkeyword==null ||this.imageuploadInformation.itemkeyword==undefined){
            this.imageuploadInformation.itemkeyword= this.imageuploadlist["Keywords"] 
          }
          if(this.imageuploadInformation.title==this.imageuploadlist["Title"] ||this.imageuploadInformation.title==null||this.imageuploadInformation.title==undefined){
            this.imageuploadInformation.title= this.imageuploadlist["Title"] 
          }
          if(this.imageuploadInformation.source==this.imageuploadlist["Source"] ||this.imageuploadInformation.source==null||this.imageuploadInformation.source==undefined){
            this.imageuploadInformation.source= this.imageuploadlist["Source"] 
          }
          if(this.imageuploadInformation.photogrphername==this.imageuploadlist["Credit"] ||this.imageuploadInformation.photogrphername==null ||this.imageuploadInformation.photogrphername==undefined){
            if(this.imageuploadlist["Credit"] !=undefined){
              this.imageuploadInformation.photogrphername= this.imageuploadlist["Credit"] 
            }
            else if(this.imageuploadlist["Creator"] !=undefined){
              this.imageuploadInformation.photogrphername= this.imageuploadlist["Creator"] 
            }
            else if(this.imageuploadlist["ItemAuthor"] !=undefined){
              this.imageuploadInformation.photogrphername= this.imageuploadlist["ItemAuthor"] 
            }
            
          }
          if(this.imageuploadInformation.copyright==this.imageuploadlist["CopyrightNotice"] || this.imageuploadInformation.copyright==null||this.imageuploadInformation.copyright==undefined){
            if(this.imageuploadlist["CopyrightNotice"] !=undefined){
              this.imageuploadInformation.copyright= this.imageuploadlist["CopyrightNotice"] 
            }
            else if(this.imageuploadlist["Copyright"] !=undefined){
              this.imageuploadInformation.copyright= this.imageuploadlist["Copyright"] 
            }
            
          }
          if(this.imageuploadInformation.CategoryId==this.imageuploadlist["Category"] ||this.imageuploadInformation.CategoryId==null||this.imageuploadInformation.CategoryId==undefined){
            this.imageuploadInformation.CategoryId= this.imageuploadlist["Category"] 
          }
          if(this.imageuploadInformation.CountryId==this.imageuploadlist["Country"] ||this.imageuploadInformation.CountryId==null ||this.imageuploadInformation.CountryId==undefined){
            this.imageuploadInformation.CountryId= this.imageuploadlist["Country"] 
          }
          if(this.imageuploadInformation.StateId==this.imageuploadlist["State"] ||this.imageuploadInformation.StateId==null ||this.imageuploadInformation.StateId==undefined){
            this.imageuploadInformation.StateId= this.imageuploadlist["State"] 
          }
          if(this.imageuploadInformation.CityId==this.imageuploadlist["City"] || this.imageuploadInformation.CityId==null ||this.imageuploadInformation.CityId==undefined){
            this.imageuploadInformation.CityId= this.imageuploadlist["City"] 
          }
          this.imageuploadInformation.shootdate =this.imageuploadlist["CreateDate"] 
          this.imageuploadInformation.orietation= this.imageuploadlist["Orientation"] 
          if(this.imageuploadInformation.itemcaption==undefined  || this.imageuploadInformation.itemcaption=='' ||  this.imageuploadInformation.itemcaption==null){
            this.imageuploadInformation.itemcaption=''
          }else{
            
          }
          
          if (this.check == true) {
            this.imageuploadInformation.itemcaption = this.imageuploadInformation.itemcaption.replace(new RegExp('"', 'g'), "''''")
          }
            if(this.imageuploadInformation.itempermission==null){
              this.imageuploadInformation.itempermission="1"
            }
            this.imageuploadservice.AddNewImage(this.imageuploadInformation).subscribe((data: any) => {
              let interval = setInterval(() => {
                this.value =this.value+  Math.floor(Math.random() * 10) + 1;
                if(this.value >= 100) {
                    this.value = 100;
                    clearInterval(interval);
                }
            });
              if (data!=null) {
                if(data=="Duplicate"){
                  stduplicate=stduplicate+1
                }
                if(data=="inserted"){
                  stinsert=stinsert+1
                }
                if (i == this.fileInput.files.length - 1) {
                  this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: stinsert.toString()+" Inserted And "+ stduplicate.toString()+" Duplicate"  })
                 setTimeout(()=>this.imagecomponent.SearchByFields(""),1000)
                 this.clear()
                 
                }
              }
            });
        }
      })
    }
    
  }
  }
}



import { Component, OnInit } from "@angular/core";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Message, MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-sub-category",
  templateUrl: "./sub-category.component.html",
  styleUrls: ["./sub-category.component.css"],
})
export class SubCategoryComponent implements OnInit {
  subcategorylist: Subcategoryformmodel[] = [];
  subcategoryinformation: Subcategoryformmodel;
  SubCategoryForm: FormGroup;
  categorylist: categoryformmodel[] = [];
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  Header: string = "Add Sub Category";
  isSubmitted = false;
  constructor(
    private getsubcategoryservice: SubCategoryService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getsubcatgorydata();
    this.CreateSubCategoryForm();
    this.getcategoryDropdown();
  }
  getsubcatgorydata() {
    this.getsubcategoryservice
      .getAllSubCategory()
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  CreateSubCategoryForm() {
    this.SubCategoryForm = this.formBuilder.group({
      SubCategoryName: [null, [Validators.required, this.removeSpaces]],
      CategoryId: [null, [Validators.required]],
      SubCategoryId: [null],
      userid: [null],
    });
    this.SubCategoryForm.patchValue({ CategoryId: 0 });
  }

  savesubcategoryinfo(formval) {
    this.subcategoryinformation = formval.value;
    // console.log("this.subcategoryinformation",this.subcategoryinformation);

    this.subcategoryinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.subcategoryinformation.SubCategoryId > 0) {
      if (this.subcategoryinformation.CategoryId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select CategoryId",
        });
      } else {
        this.getsubcategoryservice
          .checkduplicate(this.subcategoryinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.getsubcategoryservice
                .UpdateSubCategory(this.subcategoryinformation)
                .subscribe((data: any) => {
                  this.getsubcatgorydata();
                  this.SubCategoryForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "update successfully",
                  });
                  this.ButtonStatus = "Save";
                  this.SubCategoryForm.patchValue({ CategoryId: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "SubCategory Already Exist",
              });
            }
          });
      }
    } else {
      if (this.subcategoryinformation.CategoryId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select CategoryId",
        });
      } else {
        this.getsubcategoryservice
          .checkduplicate(this.subcategoryinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.getsubcategoryservice
                .CreateSubCategory(this.subcategoryinformation)
                .subscribe((data: any) => {
                  this.getsubcatgorydata();
                  this.SubCategoryForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Save successfully",
                  });
                  this.SubCategoryForm.patchValue({ CategoryId: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "SubCategory Already Exist",
              });
            }
          });
      }
    }
  }

  getcategoryDropdown() {
    this.getsubcategoryservice
      .getCategoryDropdown()
      .subscribe((data: categoryformmodel[]) => {
        if (data != null) {
          this.categorylist = data;
        }
      });
  }
  updateSubcategory(subcategorylist: Subcategoryformmodel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete SubCategory";
    this.SubCategoryForm.setValue({
      SubCategoryName: subcategorylist.SubCategoryName,
      SubCategoryId: subcategorylist.SubCategoryId,
      CategoryId: subcategorylist.CategoryId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeleteSubCategory(SubCategoryId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "sub_subcategory",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.getsubcategoryservice
          .DeleteSubCategory(SubCategoryId)
          .subscribe((data: any[]) => {
            this.getsubcatgorydata();
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.SubCategoryForm.reset();
            this.SubCategoryForm.patchValue({ CategoryId: 0 });
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  clear() {
    this.SubCategoryForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Sub Category";
    this.SubCategoryForm.patchValue({ CategoryId: 0 });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
  NgForm,
} from "@angular/forms";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { userformmodel } from "src/app/model/master/user.form.model";
import { Message, ConfirmationService, MessageService } from "primeng/api";
import { UserRightsComponent } from "./user-rights/user-rights.component";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit {
  display: boolean = false;
  userid: number;
  uname: string;
  userlist: userformmodel[] = [];
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  Header: string = "Add User";
  UserForm: FormGroup;
  userinfomation: userformmodel;
  selecteduser: string;
  glvlgnid: string = "";
  grouplist: any[] = [];
  centerId: any[] = [];
  selectedCity: any;
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  constructor(
    private userser: UserserviceService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}
  @ViewChild(UserRightsComponent, { static: true })
  userRightsComponent: UserRightsComponent;
  ngOnInit() {
    this.getAllUsers();
    this.createuserform();
    this.getgroups();
    this.getCenter();
  }

  showDialog(uid: any, UserCompleteName: string) {
    this.userRightsComponent.userid = uid;
    this.userRightsComponent.UserCompleteName = UserCompleteName;
    this.userRightsComponent.DDdisabled = false;
    this.userRightsComponent.getdragdropdata(uid);
    this.display = true;
  }

  getAllUsers() {
    //this.userinfomation=new userformmodel();
    this.userser.getAllUsers().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.userlist = data;
        // console.log(this.userlist);

        for (let i = 0; i < data.length; i++) {
          if (data[i].LoginId.toLowerCase() == "admin") {
            this.userlist.splice(i, 1);
          }
        }
        //this.userlist = data;
      }
    });
  }

  createuserform() {
    this.UserForm = this.formBuilder.group({
      UserFirstName: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ]*$"),
          this.removeSpaces,
        ],
      ],
      UserLastName: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ]*$"),
          this.removeSpaces,
        ],
      ],
      UserEmailId: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$"),
        this.removeSpaces,
      ]),
      Mobile: new FormControl(null, [Validators.required]),
      Password: new FormControl(null),
      LoginId: [null, [Validators.required, this.removeSpaces]],
      UserId: [null],
      updateby: [null],
      groupid: [""],
      centerId: new FormControl("", [Validators.required]),
    });
  }

  getgroups() {
    this.userser.getgrouplist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.grouplist = data;
      }
    });
  }

  getCenter() {
    this.userser.getCenter().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.centerId = data;
      }
    });
  }

  saveuserinfo(formval) {
    this.userinfomation = formval.value;
    this.userinfomation.updateby = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.userinfomation.UserId > 0) {
      if (this.userinfomation.LoginId != this.selecteduser) {
        this.userser
          .checkduplicate(this.userinfomation)
          .subscribe((data: any) => {
            if (data.length > 0) {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "warn Message",
                detail: "User Already Exist",
              });
            } else {
              this.userser
                .UpdateUser(this.userinfomation)
                .subscribe((data: any) => {
                  this.getAllUsers();
                  this.clear();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Update Successfully",
                  });
                  this.ButtonStatus = "Save";
                });
            }
          });
      } else {
        this.userser.UpdateUser(this.userinfomation).subscribe((data: any) => {
          this.getAllUsers();
          this.clear();
          this.messageService.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Update Successfully",
          });
          this.ButtonStatus = "Save";
        });
      }
    } else {
      this.userser
        .checkduplicate(this.userinfomation)
        .subscribe((data: any) => {
          if (data.length > 0) {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "warn Message",
              detail: "User Already Exist",
            });
          } else {
            this.userser
              .CreateUser(this.userinfomation)
              .subscribe((data: any) => {
                this.getAllUsers();
                this.clear();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          }
        });
    }
  }

  deleteUser(UserId: number, name) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "newusercompkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.userser.DelteUser(UserId, name).subscribe((data: any[]) => {
          this.getAllUsers();
          this.clear();
          this.ButtonStatus = "Save";
          this.messageService.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Delete successfully",
          });
        });
      },
      reject: () => {},
    });
  }

  clear() {
    this.UserForm.reset();
    this.UserForm.patchValue({ groupid: "" });
    this.UserForm.patchValue({ centerId: "" });
    this.ButtonStatus = "Save";
    this.Header = "Add User";
  }

  updateUser(userlist: userformmodel) {
    // console.log("userlist", userlist);
    this.selecteduser = userlist.LoginId;
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete User";
    this.UserForm.patchValue({
      UserFirstName: userlist.UserFirstName,
      UserLastName: userlist.UserLastName,
      UserEmailId: userlist.UserEmailId,
      Mobile: userlist.Mobile,
      // Password: userlist.Password,
      LoginId: userlist.LoginId,
      UserId: userlist.UserId,
      groupid: userlist.RolesId,
      centerId: userlist.CenterId,
      updateby: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket: WebSocket | null = null;

  constructor() {
    this.connect();
  }

  socketUrl = environment.webSocketUrl;
  /**
   * Establishes a WebSocket connection.
   */
  private connect(): void {
    this.socket = new WebSocket(this.socketUrl);

    this.socket.addEventListener('open', () => {
      console.log('WebSocket connection established');
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Message from server:', event.data);
    });

    this.socket.addEventListener('close', () => {
      console.log('WebSocket connection closed');
    });

    this.socket.addEventListener('error', (err) => {
      console.error('WebSocket error:', err);
    });
  }

  /**
   * Sends a message to the WebSocket server.
   * @param msg - The message to send
   */
  sendMessage(msg: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(msg);
    } else {
      console.error('WebSocket is not connected or not ready');
    }
  }

  /**
   * Allows subscribing to messages received from the WebSocket server.
   * @param callback - A callback to handle incoming messages
   */
  onMessage(callback: (data: string) => void): void {
    if (this.socket) {
      this.socket.addEventListener('message', (event) => {
        callback(event.data);
      });
    } else {
      console.error('WebSocket is not initialized');
    }
  }
}

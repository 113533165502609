import { Component, OnInit } from "@angular/core";
import { CountryService } from "src/app/services/masters/country.service";
import { CountryFormModel } from "src/app/model/master/country.formmodel";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-country",
  templateUrl: "./country.component.html",
  styleUrls: ["./country.component.css"],
})
export class CountryComponent implements OnInit {
  countrylist: CountryFormModel[] = [];
  countryinformation: CountryFormModel;
  CountryForm: FormGroup;
  msgs: Message[] = [];
  Header: string = "Add Country";
  ButtonStatus: string = "Save";
  CountryId: string;
  constructor(
    private countryservice: CountryService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getCountry();
    this.CreateCountryForm();
  }

  getCountry() {
    this.countryservice.GetCountryData().subscribe((data: any[]) => {
      if (data != null) {
        this.countrylist = data;
      }
    });
  }
  CreateCountryForm() {
    this.CountryForm = this.formBuilder.group({
      CountryName: [
        null,
        [
          Validators.required,
          this.removeSpaces,
          Validators.pattern("^[a-zA-Z ]*$"),
        ],
      ],
      CountryId: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveCountry(formval) {
    this.countryinformation = formval.value;
    this.countryinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.countryinformation.CountryId > 0) {
      this.countryservice
        .checkduplicate(this.countryinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.countryservice
              .UpdateCountry(this.countryinformation)
              .subscribe((_data: any) => {
                this.getCountry();
                this.CountryForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "update successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Country Already Exist",
            });
          }
        });
    } else {
      this.countryservice
        .checkduplicate(this.countryinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.countryservice
              .CreateCountry(this.countryinformation)
              .subscribe((_data: any) => {
                this.getCountry();
                this.CountryForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Country Already Exist",
            });
          }
        });
    }
  }
  updatecountry(countrylist: CountryFormModel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Country";
    this.CountryForm.setValue({
      CountryName: countrylist.CountryName,
      CountryId: countrylist.CountryId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  deletecountry(CountryId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "countrykey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.countryservice
          .DeleteCountry(CountryId)
          .subscribe((data: CountryFormModel[]) => {
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getCountry();
            this.CountryForm.reset();
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.CountryForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Country";
  }
}

import { Component, OnInit } from '@angular/core';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { MessageService } from 'primeng/api';
import { AudioUploadComponent } from '../../audio-upload/audio-upload.component';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { Eventformmodel } from 'src/app/model/master/event.form.model';

@Component({
  selector: 'app-audio-search',
  templateUrl: './audio-search.component.html',
  styleUrls: ['./audio-search.component.css']
})
export class AudioSearchComponent implements OnInit {
 AudioSearchingForm:FormGroup
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0
  audiosearchlist:audiouploadModel[]=[]
  eventlist:Eventformmodel[]=[]


  constructor(private imageUplaodService:ImageuploadService, private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private messageservice: MessageService, private sanitizer: DomSanitizer,private route:Router,private audiocomponent:AudioUploadComponent) { }

  ngOnInit() {

    this.CreateAudioSearchForm()
     this.getcategorydata()
     this.getevent()
  }
  CreateAudioSearchForm() {
    this.AudioSearchingForm = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword:[null],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      AvgBitrate:[null],
      startdate:[null],
      endDate:[null],
      orgfilename:[null],
      framerate:[null],
      Searching_Method:['n'],
      File_Status:['0'],
      event:[null]
    })

  }
  SearchAllAudio(formvalues){
    this.audiocomponent.SearchAllAudio(formvalues.value)
    this.AudioSearchingForm.reset()
    this.AudioSearchingForm.patchValue({Searching_Method:'n'})
    this.AudioSearchingForm.patchValue({File_Status:'0'})

  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }

  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getevent() {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getevent().subscribe((data: Eventformmodel[]) => {
      if (data != null) {
        this.eventlist = data;
      }

    })
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { downloadimagesmodel } from 'src/app/model/file/downloadimagemodel';
import { StoryFormModel } from 'src/app/model/file/storyformModel';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { CountryService } from 'src/app/services/masters/country.service';
import { CityService } from 'src/app/services/masters/city.service';
import { EventService } from 'src/app/services/masters/event.service';
import { MessageService } from 'primeng/api';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { CityFormModel } from 'src/app/model/master/city.form.model';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { DocumentUploadFormModel } from 'src/app/model/file/documentUploadModel';
import { StoryserviceService } from 'src/app/services/files/storyservice.service';
import { FileUpload } from 'primeng/primeng';
import { LanguageFormModel } from 'src/app/model/file/languageFormmodel';
import { StoryArchiveComponent } from '../story-archive/story-archive.component';

@Component({
  selector: 'app-story-archive-upload',
  templateUrl: './story-archive-upload.component.html',
  styleUrls: ['./story-archive-upload.component.css']
})
export class StoryArchiveUploadComponent implements OnInit {
  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  StoryUploadForm: FormGroup
  categoryid: number = 0
  eventlist: Eventformmodel[] = [];
  countrylist: CountryFormModel[] = []
  languagelist:LanguageFormModel[]=[]
  languageinformation:LanguageFormModel
  Statelist: stateFormModel[] = []
  citylist: CityFormModel[] = []
  storyinformation: StoryFormModel;
  display: boolean = false
  x: string
  check: boolean = true;
  StoryUrl = environment.ApiUrl
  i: number
  value: number = 0
  languageid:number
  constructor(private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private countryservice: CountryService, private cityservice: CityService, private eventservice: EventService, private messageservice: MessageService,private storyservice:StoryserviceService,private storyarchivecomponent:StoryArchiveComponent ) { }

  @ViewChild('attachment',{static:true}) attachment:FileUpload

  ngOnInit() {
    this.CreateStoryForm()
    //this.getcategorydata()
    //this.getCountryData()
    //this.getEventData()
    this.getlanguage()
  }


  // clear() {
  //   this.attachment._files = []
  //   this.StoryUploadForm.reset()
  //  this.display=false
  // }
 

  CreateStoryForm() {
    this.StoryUploadForm = this.formBuilder.group({
      CategoryId: [null, [Validators.required]],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null, [Validators.required, this.removeSpaces]],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      HeadLine:[null],
      story:[null],
      LanguageId:[null]
    })

  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  getcategorydata() {
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getCountryData() {
    this.countryservice.GetCountryData().subscribe((data: CountryFormModel[]) => {
      if (data.length > 0) {
        this.countrylist = data;
      }
    })
  }
  getStateDropDown(c) {
    this.cityservice.getStateDropDown(c).subscribe((data: stateFormModel[]) => {
      if (data.length > 0) {
        this.Statelist = data;
      }
      else {
        this.Statelist = []
        this.StoryUploadForm.patchValue({ StateId: "null" })
        this.citylist = []
        this.StoryUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    // this.countryid=selctedcountryid;
    this.getStateDropDown(selctedcountryid)
  }

  getCityDropdown(s) {
    this.cityservice.getCityData(s).subscribe((data: CityFormModel[]) => {
      if (data.length > 0) {
        this.citylist = data;
      }
      else {
        this.citylist = []
        this.StoryUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getCity(e) {
    let selectedstateid = e.target.selectedOptions[0].value;
    // this.countryid=selectedstateid;
    this.getCityDropdown(selectedstateid)
  }

  getEventData() {
    //this.userinfomation=new userformmodel();
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }

    })

  }

  remove(name) {
    for(let i=0;i<this.attachment.files.length;i++){
      if(this.attachment.files[i].name==name){
        // this.attachment._files.splice(i,1)
      }
    }
  }

StoryUpload(formval){
  this.storyinformation = formval.value
  // if (this.storyinformation.CategoryId == null || this.storyinformation.itemkeyword == null || this.languageid==null || this.storyinformation.HeadLine==null || this.storyinformation.story==null) {
  //     this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please Fill Mandatatory Fields ' });
  // }else{
  this.storyservice.AddStory(this.storyinformation).subscribe((data:any[])=>{
    if(data!=null){
      if(this.attachment.files.length>0){
        for(let i=0;i<this.attachment.files.length;i++){
          this.storyinformation.attchmentname = this.attachment.files[i].name;
          this.storyservice.AddAttachment(this.storyinformation).subscribe((data:any[])=>{
            this.attachment.upload()
            if(data!=null){
              //  this.attachment._files=[]
               this.StoryUploadForm.reset()
             // alert('Merry Christmas')
            }
  
          })
  
        }
      }
      else{
        this.StoryUploadForm.reset()
      }
     
    }
    this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' })
    setTimeout(() => this.storyarchivecomponent.getAllStory(), 1000)
  })

//}

}

//function not working
getlanguage(){

 this.storyservice.getlanguage().subscribe((data:any[])=>{
   if(data!=null){
     this.languagelist=data
   }
 })

}
fontChange(e){
 this.languageid=e.target.selectedIndex
  this.storyservice.getLanguagesFont(this.languageid).subscribe((data:any)=>{
    if(data!=null){
      document.getElementById("story").style.fontFamily=data[0].LanguageFont
      document.getElementById("headline").style.fontFamily=data[0].LanguageFont
    }
  })
}


// clear(){
//   this.s
// }
}


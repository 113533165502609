import { Component, OnInit, ViewChild } from "@angular/core";
import { keywordService } from "src/app/services/masters/keyword.service";
import { keywordmodule } from "src/app/model/master/keyword.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { DashboardService } from "src/app/services/files/dashboard.service";

@Component({
  selector: "app-keywords",
  templateUrl: "./keywords.component.html",
  styleUrls: ["./keywords.component.css"],
})
export class KeywordsComponent implements OnInit {
  @ViewChild("dt", { static: false }) table1: any;
  keywordlist: keywordmodule[] = [];
  keywordinformation: keywordmodule;
  keywordForm: FormGroup;
  msgs: Message[] = [];
  Header: string = "Add Keyword";
  ButtonStatus: string = "Save";
  id: string;
  allkeywords: any[] = [];
  keyword: string = "";
  first: number = 0;
  constructor(
    private keywordService: keywordService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService,
    public dashboadservice: DashboardService
  ) {}

  ngOnInit() {
    this.getallkeyword();
    this.CreatekeywordForm();
  }

  getallkeyword() {
    this.keywordService.Getkeyword().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data;
      }
    });
  }
  CreatekeywordForm() {
    this.keywordForm = this.formBuilder.group({
      keywordname: [null, [Validators.required, this.removeSpaces]],
      id: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveCountry(formval) {
    this.keywordinformation = formval.value;
    this.keywordinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.keywordinformation.id > 0) {
      this.keywordService
        .checkduplicate(this.keywordinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.keywordService
              .Updatekeyword(this.keywordinformation)
              .subscribe((_data: any) => {
                this.getallkeyword();
                this.keywordForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "update successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Keyword Already Exist",
            });
          }
        });
    } else {
      this.keywordService
        .checkduplicate(this.keywordinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.keywordService
              .Createkeyword(this.keywordinformation)
              .subscribe((_data: any) => {
                this.getallkeyword();
                this.keywordForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Keyword Already Exist",
            });
          }
        });
    }
  }
  updatekeyword(allkeywords: keywordmodule) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete keyword";
    this.keywordForm.setValue({
      keywordname: allkeywords.keywordname,
      id: allkeywords.id,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  deletekeyword(id: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "keywordskey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.keywordService
          .Deletekeyword(id)
          .subscribe((data: keywordmodule[]) => {
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getallkeyword();
            this.keywordForm.reset();
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.keywordForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add keyword";
  }

  searchkeywords() {
    var keyword = this.keyword;
    if (this.table1 != undefined) {
      this.table1.first = 0;
    }
    if (keyword == "") {
      this.getallkeyword();
    } else {
      this.keywordService.searchkeyword(keyword).subscribe((data: any[]) => {
        this.allkeywords = data;
      });
    }
  }
}

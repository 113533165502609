import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { fromEvent } from "rxjs";
import { pairwise, switchMap, takeUntil } from "rxjs/operators";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-videokeywords",
  templateUrl: "./videokeywords.component.html",
  styleUrls: ["./videokeywords.component.css"],
})
export class VideokeywordsComponent implements OnInit {
  textareas: string = "";
  datalist: any[] = [];
  sendstatus: boolean = false;
  loginuser: string = "";
  prvsreplydivid: string = "";
  prvscmntdivid: string = "";
  replyvalue: string = "";
  videoid: string = "";
  videourl: string = "";
  videoname: string = "";
  colordiv: number = 0;
  serverfilepath: string = "";
  playbtn: number = 1;
  pausebtn: number = 0;
  savebtn: number = 1;
  updatebtn: number = 0;
  contentid: string = "";
  addkeywordper: number = 0;
  ApiServerPath: any;
  @ViewChild("c1", { static: false }) public canvasEl1: ElementRef;
  @ViewChild("vid1", { static: true }) public videoel1: ElementRef;

  @Input() public width = 400;
  @Input() public height = 400;
  constructor(
    public getdataser: VideouploadService,
    private sanitiser: DomSanitizer,
    private route: ActivatedRoute,
    private messageservice: MessageService
  ) {}
  public ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.checkpermissions();
    this.serverfilepath = this.ApiServerPath;
    this.videoid = this.route.snapshot.queryParams.q;
    this.loginuser = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.getvideodata();
    this.getcomments();
    //this.timer()
    this.seekbar();
    var vid = document.getElementById("vid") as HTMLVideoElement;
    $("#custom-seekbar").on("click", function (e) {
      var offset = $(this).offset();
      var left = e.pageX - offset.left;
      var totalWidth = $("#custom-seekbar").width();
      var percentage = left / totalWidth;
      var vidTime = vid.duration * percentage;
      vid.currentTime = vidTime;
    });
  }

  async checkpermissions() {
    var permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < permissionlist.length; i++) {
      if (permissionlist[i].taskid == 94) {
        this.addkeywordper = 1;
      }
    }
  }

  public ngAfterViewInit() {
    var video = document.getElementById("vid") as HTMLVideoElement;

    video.addEventListener("loadedmetadata", function () {
      var tmpmargin =
        (Number(document.getElementById("videodiv").parentElement.clientWidth) -
          video.clientWidth) /
        2;
      if (tmpmargin < 0) {
        tmpmargin = 0;
      }
      document.getElementById("videodiv").style.marginLeft = tmpmargin + "px";
      document.getElementById("videodiv").style.paddingTop =
        "calc(35vh - " + video.clientHeight / 2 + "px)";
    });
    video.addEventListener("play", function () {});
    //this.drawcanvas(canvas1);
  }

  pausevideo() {
    this.playbtn = 1;
    this.pausebtn = 0;
    var video = document.getElementById("vid") as HTMLVideoElement;

    if (video.paused) {
      // video.play()
    } else {
      video.pause();
    }
  }
  playvideo() {
    this.playbtn = 0;
    this.pausebtn = 1;
    this.savebtn = 1;
    this.updatebtn = 0;
    this.contentid = "";
    var video = document.getElementById("vid") as HTMLVideoElement;
    video.play();

    this.sendstatus = false;
    this.textareas = "";
  }

  private seekbar() {
    var _self = this;
    var vid = document.getElementById("vid") as HTMLVideoElement;
    vid.ontimeupdate = function () {
      var percentage = (vid.currentTime / vid.duration) * 100;
      document.getElementById("progressbar").style.width =
        percentage.toString() + "%";
      _self.timer(vid.currentTime, vid.duration);
    };
  }

  updateprogressbar(e) {
    var vid = document.getElementById("vid") as HTMLVideoElement;
    var progress = $(".progress");
    var maxduration = vid.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    vid.currentTime = (maxduration * percentage) / 100;
  }

  savedata() {
    var frame = this.videoel1.nativeElement.currentTime;
    var itemid = this.videoid;
    var keywords = this.textareas;
    if (keywords != "") {
      this.getdataser
        .saveframekeyword(itemid, keywords, frame)
        .subscribe((data: any[]) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          this.getcomments();
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please add some keywords",
      });
    }
  }
  getcomments() {
    this.savebtn = 1;
    this.updatebtn = 0;
    this.contentid = "";
    this.textareas = "";
    var itemid = this.videoid;
    this.getdataser.getvideokeyword(itemid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.datalist = [];
        for (let i = 0; i < data.length; i++) {
          var timeFormat = data[i].frame_duration;
          var m =
            Math.floor(timeFormat / 60) < 10
              ? "0" + Math.floor(timeFormat / 60)
              : Math.floor(timeFormat / 60);
          var s =
            Math.floor(timeFormat - Number(m) * 60) < 10
              ? "0" + Math.floor(timeFormat - Number(m) * 60)
              : Math.floor(timeFormat - Number(m) * 60);
          var tmpframe = m + ":" + s;
          this.datalist.push({
            username: data[i].username,
            frame: tmpframe,
            datetime: data[i].datetime,
            comment: data[i].frame_keyword,
            userid: data[i].userid,
            id: data[i].id,
            videoid: data[i].video_id,
            frame1: data[i].frame_duration,
          });
        }
      }
    });
  }

  commentdata(itemlist, divid) {
    if (this.prvscmntdivid != "") {
      document
        .querySelector("#" + this.prvscmntdivid)
        .classList.remove("replycmnt");
    }
    document.querySelector("#" + divid).classList.add("replycmnt");
    this.prvscmntdivid = divid;
    this.contentid = itemlist.id;
    this.pausevideo();
    this.videoel1.nativeElement.currentTime = itemlist.frame1;
    this.textareas = itemlist.comment;
    this.savebtn = 0;
    this.updatebtn = 1;
    if (this.loginuser != itemlist.userid) {
      this.sendstatus = true;
    } else {
      this.sendstatus = false;
    }
  }
  replycomment(itemlist, divid) {
    var replies = this.replyvalue + "µ" + itemlist.replies;
    var username =
      JSON.parse(localStorage.getItem("userdetails"))[0].UserCompleteName +
      "µ" +
      itemlist.replyby;
    this.getdataser
      .postreply(itemlist.id, replies, username, itemlist.videoid)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.replyvalue = "";
          document.getElementById(this.prvsreplydivid).style.display = "none";
          this.datalist = [];
          for (let i = 0; i < data.length; i++) {
            var timeFormat = data[i].frame;
            var m =
              Math.floor(timeFormat / 60) < 10
                ? "0" + Math.floor(timeFormat / 60)
                : Math.floor(timeFormat / 60);
            var s =
              Math.floor(timeFormat - Number(m) * 60) < 10
                ? "0" + Math.floor(timeFormat - Number(m) * 60)
                : Math.floor(timeFormat - Number(m) * 60);
            var tmpframe = m + ":" + s;
            if (data[i].replies == null) {
              data[i].replies = "";
            }
            if (data[i].replyby == null) {
              data[i].replyby = "";
            }
            var tmpreplyarr = data[i].replies.split("µ");
            var tmpreplybyarr = data[i].replyby.split("µ");
            var replyarr1 = [];
            var replybyarr1 = [];
            for (let j = 0; j < tmpreplyarr.length - 1; j++) {
              replyarr1.push({
                reply: tmpreplyarr[j],
                replyby: tmpreplybyarr[j],
              });
              //replybyarr1.push(tmpreplybyarr[j])
            }
            this.datalist.push({
              username: data[i].username,
              frame: tmpframe,
              datetime: data[i].datetime,
              comment: data[i].comment,
              snappath: data[i].snappath,
              userid: data[i].userid,
              replyarr: replyarr1,
              id: data[i].id,
              replies: data[i].replies,
              replyby: data[i].replyby,
              videoid: data[i].videoguid,
              frame1: data[i].frame,
            });
          }
        }
      });
  }
  showreplybox(divid) {
    if (this.prvsreplydivid != "") {
      document.getElementById(this.prvsreplydivid).style.display = "none";
    }
    document.getElementById(divid).style.display = "block";
    this.prvsreplydivid = divid;
  }

  timer(videocurrent, totalduration) {
    var minutes = "0" + Math.floor(videocurrent / 60);
    var seconds = "0" + Math.floor(videocurrent - Number(minutes) * 60);
    var dur = minutes.substr(-2) + ":" + seconds.substr(-2);

    var minutes_d = "0" + Math.floor(totalduration / 60);
    var seconds_d = "0" + Math.floor(totalduration - Number(minutes_d) * 60);
    var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
    var timer = dur + "/" + dur_d;
    $("#timer").html(timer);
  }
  getvideodata() {
    this.getdataser.getvideoinfo(this.videoid).subscribe((data: any[]) => {
      if (data.length > 0) {
        //this.videourl=window.URL.createObjectURL(environment.ApiServerPath+data[0].OriginalPath)
        this.videourl = this.ApiServerPath + data[0].HiresPath;
        this.videoname = data[0].orgfilename;
      }
    });
  }

  updatedata() {
    var keywords = this.textareas;
    if (keywords != "") {
      this.getdataser
        .updateframekeyword(this.contentid, keywords, this.videoid)
        .subscribe((data: any[]) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          this.getcomments();
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please add some keywords",
      });
    }
  }
}

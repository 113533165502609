import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TraneventsService {

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;

  blindsearch(tabstr):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/blindsearch",{tabstr:tabstr})
  }

  blindsearchwithdata(srchtext,type,tabstr):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/blindsearchwithdata",{srchtext:srchtext,type:type,tabstr:tabstr})
  }

  newadvancesearch(srchtxt,tabstr):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/newadvancesearch",{srchtxt:srchtxt,tabstr:tabstr})
  }


  getncount(srchtext,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/getncount",{srchtext:srchtext,tabstr:tabstr})
  }

  getdocumentdata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/geteventitem",{itemid:itemid})
  }
  getimagedata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/geteventitem",{itemid:itemid})
  }
  getstorydata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/geteventitem",{itemid:itemid})
  }
  getaudiodata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/geteventitem",{itemid:itemid})
  }
  getvideodata(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"masterevents/geteventitem",{itemid:itemid})
  }
}

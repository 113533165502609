import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { SessionTimeoutService} from './services/masters/session-timeout.service';

// import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
// import {Keepalive} from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'E-ASSETS365';
  constructor(private userIdle: UserIdleService,private authservice:AuthService, private sessiontimeourser: SessionTimeoutService) {
    environment.ApiServerPath=sessionStorage.getItem("ApiServerPath");
    environment.ApiBarcodePath=sessionStorage.getItem("ApiBarcodePath");
    environment.lowresThumbPath=sessionStorage.getItem("lowresThumbPath");
    environment.download_path=sessionStorage.getItem("download_path");
    environment.original_path=sessionStorage.getItem("original_path");
  }
 

  ngOnInit(): void {
    this.sessiontimeourser.startTimer();
  }

  ngOnDestroy(): void {
    this.sessiontimeourser.clearTimer();
  }

  // ngOnInit() {
    //Start watching for user inactivity.
    // this.userIdle.startWatching();
    
    // // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe();
    
    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() => 
    // //console.log('Time is up!')
    // this.Logout()
    // );
  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // beforeUnloadHandler(event) {
  //   this.Logout()
  // }
 
  onUserActivity(): void {
    this.sessiontimeourser.resetTimer();
  }

  Logout(){
    this.authservice.logout();
  }
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }
  changeOfRoutes(){
    // console.log("start")
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe();
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => 
    //console.log('Time is up!')
    this.Logout()
    );
  }

  @HostListener('contextmenu', ['$event']) _handleContextMenu(event: MouseEvent): void {
    event.preventDefault(); 
  }
}

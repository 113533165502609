// import { Injectable, NgZone } from '@angular/core';
// import { Router } from '@angular/router';

// @Injectable({
//   providedIn: 'root'
// })
// export class SessionTimeoutService {

//   private inactivityDuration = 3 * 60 * 1000; // 1 hour in milliseconds
//   private timeoutHandle!: ReturnType<typeof setTimeout>;

//   constructor(private router: Router, private ngZone: NgZone) {}

//   // Start the inactivity timer
//   startTimer(): void {
//     this.clearTimer();
//     this.timeoutHandle = setTimeout(() => {
//       this.logoutUser();
//     }, this.inactivityDuration);
//   }

//   // Reset the timer on user activity
//   resetTimer(): void {
//     this.startTimer();
//   }

//   // Clear the existing timer
//   clearTimer(): void {
//     if (this.timeoutHandle) {
//       clearTimeout(this.timeoutHandle);
//     }
//   }

//   // Logout the user after timeout
//   private logoutUser(): void {
//     this.ngZone.run(() => {
//       alert('You have been logged out due to inactivity.');
//       // Clear session data
//       localStorage.clear();
//       sessionStorage.clear();
//       // Redirect to the login page
//       this.router.navigate(['/login']);
//     });
//   }

// }


import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  private inactivityDuration = 60 * 60 * 1000; // 3 minutes in milliseconds
  private timeoutHandle!: ReturnType<typeof setTimeout>;

  constructor(private router: Router, private ngZone: NgZone) {
    this.setupEventListeners();
  }

  // Set up user activity event listeners
  private setupEventListeners(): void {
    ['click', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(event => {
      window.addEventListener(event, () => this.resetTimer());
    });
  }

  // Start the inactivity timer
  startTimer(): void {
    if (localStorage.getItem('userdetails')) { // Check if user is logged in
      this.clearTimer();
      this.timeoutHandle = setTimeout(() => {
        this.logoutUser();
      }, this.inactivityDuration);
    }
  }
  

  // Reset the timer on user activity
  resetTimer(): void {
    this.startTimer();
  }

  // Clear the existing timer
  clearTimer(): void {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  // Logout the user after timeout
  private logoutUser(): void {
    this.ngZone.run(() => {
      alert('You have been logged out due to inactivity.');
      // Clear session data
      localStorage.clear();
      sessionStorage.clear();
      // Redirect to the login page
      this.router.navigate(['/login']);
    });
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { environment } from "src/environments/environment";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import Cropper from "cropperjs";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { Router } from "@angular/router";
import { TreeviewConfig, TreeviewItem } from "ngx-treeview";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";
import { DataShareService } from "../../services/data-share/data-share.service";
import { HrcornerService } from "../../services/files/hrcorner.service";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  storyElement: any[] = [0];
  storyElement1: any[] = [0];
  storyElement2: any[] = [0];
  storyElement3: any[] = [0];
  storyElement4: any[] = [0];
  storyElement5: any[] = [0];
  storyElement6: any[] = [0];
  storyElement7: any[] = [0];
  storyElement8: any[] = [0];
  storyElement9: any[] = [0];
  storyElement10: any[] = [0];
  companyNames:string[] =[];
  companyIds:string[] = [];
  totalcount: number = 0;
  srchtext: string = "";
  permissionlist: PermissionModel[];
  globalsrchf: number = 0;
  autoCompletArr: any[] = [];
  microphone: number = 0;

  dataType: string = "";

  videoTab: number = 0;
  imageTab: number = 0;
  AIimageTab: number = 0;
  storyTab: number = 0;
  audioTab: number = 0;
  documentTab: number = 0;
  graphicsTab: number = 0;
  illustrationTab: number = 0;
  cartoonTab: number = 0;
  hrCornerTab: number = 0;
  allItemsTab: number = 0;
  emagzinetab: number = 0;
  companyID: any;
  ApiServerPath = environment.ApiServerPath;
  httppath:any=environment.CommpanyBgImagePath;
  constructor(
    private dashboarservice: DashboardService,
    private router: Router,
    public audiouploadservice: AudiouplaodService,
    public speechTextService: SpeechTextService,
    public imageservice: ImageuploadService,
    public videoservice: VideouploadService,
    private dataService: DataShareService,
    private hrcornerService: HrcornerService,
    private storyservice: StoryserviceService
  ) {}

  ngOnInit() {
    this.checkpermissions();
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      environment.ApiServerPath = savedApiServerPath;
      // this.ApiServerPath = savedApiServerPath;
    }
    // console.log("Apiserverpath", this.ApiServerPath);
    // this.clearfilters()
    // this.getAllIllustration();
    // this.getAllCartoons();
    
    localStorage.setItem("totalcount", "0");
    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.speechTextService.init();
    this.getAllCompanies();
    // if (this.companyID) {
    //   this.gettreejson1({ node: { data: this.companyID } });
    // }
  }

  // gettreejson1(e: any) {
  //   console.log("gettreejson1 event", e);
  //   try {
  //     if (!e.node.children) {
  //       e.node.parent.expanded = false;
  //       e.node.parent.expanded = true;
  //     } else {
  //       const ln = e.node.children[0].data.split("/").length;
  //       let temppath = "";
  //       if (e.node.data.includes("/")) {
  //         temppath = e.node.data + "/";
  //       } else {
  //         temppath = e.node.data;
  //       }
  //       // Uncomment and implement the service call
  //       // this.dashboarservice.gettreejson(ln - 1, ln, temppath, e.node.children[0].key).subscribe((data: any) => {
  //       //   if (data !== "") {
  //       //     e.node.children = JSON.parse(data.toString());
  //       //   }
  //       // });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  getBackgroundImage(bgImage: string): string {
    const imageUrl = bgImage ? (bgImage.replace(/\\/g, '/')) : '../../../assets/image/Cartoons.jpg';
    // console.log('Final Background Image URL:', imageUrl);
    return `url(${imageUrl})`;
  }

 
  
  
  checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      // console.log("permissions", this.permissionlist);
      if (this.permissionlist[i].taskid == 39) {
        this.getAllVideos();
        this.videoTab = 1;
      }
      if (this.permissionlist[i].taskid == 40) {
        this.getAllImages();
        this.imageTab = 1;
      }
      if (this.permissionlist[i].taskid == 145) {
        this.getAllAiImages();
        this.AIimageTab = 1;
      }
      if (this.permissionlist[i].taskid == 41) {
        this.getAllStory();
        this.storyTab = 1;
      }
      if (this.permissionlist[i].taskid == 42) {
        this.getAllAudios();
        this.audioTab = 1;
      }
      if (this.permissionlist[i].taskid == 43) {
        this.getAllDocment();
        this.documentTab = 1;
      }
      if (this.permissionlist[i].taskid == 65) {
        this.getAllMagazineData();
        this.emagzinetab = 1;
      }
      if (this.permissionlist[i].taskid == 103) {
        this.getAllgraphics();
        this.graphicsTab = 1;
      }
      if (this.permissionlist[i].taskid == 101) {
        this.getAllIllustration();
        this.illustrationTab = 1;
      }
      if (this.permissionlist[i].taskid == 102) {
        this.getAllCartoons();
        this.cartoonTab = 1;
      }

      if (this.permissionlist[i].taskid == 60) {
        this.globalsrchf = 1;
      }

      if (this.permissionlist[i].taskid == 134) {
        this.getAllHRData();
        this.hrCornerTab = 1;
      }
      if (this.permissionlist[i].taskid == 136) {
        this.allItemsTab = 1;
      }
    }
  }

  // getAllStory() {
  //   this.dashboarservice.getAllstory().subscribe((data: any[]) => {
  //     if (data != null) {
  //       this.storyElement = data[0].rowcount
  //       var tcount = localStorage.getItem("totalcount")
  //       tcount = (Number(tcount) + Number(this.storyElement)).toString()
  //       localStorage.setItem("totalcount", tcount)
  //       localStorage.setItem("storycount", this.storyElement.toString())
  //     }
  //   })
  // }

  getAllStory() {
    this.dashboarservice.getAllstory().subscribe((data: any[]) => {
      if (data != null) {
        this.storyElement = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("storycount", this.storyElement.toString());
      }
    });
  }

  getAllDocment() {
    this.dashboarservice.GetAllDocument().subscribe((data: any[]) => {
      if (data != null) {
        // console.log("documents",data);

        this.storyElement1 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement1)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("documentcount", this.storyElement1.toString());
      }
    });
  }
  getAllVideos() {
    this.dashboarservice.GetAllVideo().subscribe((data: any[]) => {
      if (data != null) {
        // console.log("videos",data);
        this.storyElement2 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement2)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("videocount", this.storyElement2.toString());
      }
    });
  }
  getAllImages() {
    this.dashboarservice.GetAllImages("image").subscribe((data: any[]) => {
      if (data != null) {
        // console.log("images",data);
        this.storyElement3 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement3)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("imagecount", this.storyElement3.toString());
      }
    });
  }

  // companies:any;
  // getCompanyNames() {
  //   this.dashboarservice.getAllcompanyNames().subscribe((data: any[]) => {
  //     if (data != null) {
  //       console.log("aaaaaaaaa");
        
  //       var arrid="";
  //       this.companies = data.map(company => (
  //         {
  //         name: company.company_name,
  //         id: company.company_id,
  //         counter:0,
  //       }
  //     ));

  //     this.companies.forEach((element,index) => {
  //       if(this.companies.length!=index+1)
  //       arrid+=element.id+"~~~~~";
  //     else
  //       arrid+=element.id;
         
  //     });

  //       // this.getAllImagesNew(arrid);
  //       this.getAllCompanies();
  //     }
  //   });
  // }

  // getAllImagesNew(company_id: any) {
  //   this.dashboarservice.GetAllImagesNew("image", company_id).subscribe((data: any[]) => {
  //     if (data != null && data.length > 0) {
  //       console.log(data,"ALL DATA");
  //       for(var i=0; i<data.length; i++){
  //         for(var j=0;j<this.companies.length;j++){
  //           if(this.companies[j].id==data[i][0].company_id){
  //             this.companies[j].counter=data[i][0].rowcount;
  //           }
  //         }
  //       }
  //     }
  //   });
  // }


  filteredCompaies:any;
  filteredCompany:string = '';
  tempComponies:any;
  // updatedImageCount: any;
  getFilterdcompanies(){
    const searchTerm = this.filteredCompany.trim().toLowerCase();
    // if (searchTerm === '') {
    //   this.allCompanies = [...this.allCompanies];
    // } else {
      if(this.tempComponies.length>0){
       this.allCompanies=this.tempComponies.filter((data)=>{
        return data.CompanyNames.toLowerCase().includes(searchTerm);
      });
    }
    this.calculateTotalImages();
    // }
  }

totalImageCount: number = 0;
calculateTotalImages() {
  // console.log("calculated image count",this.totalImageCount,this.tempComponies);
  this.totalImageCount = this.allCompanies.reduce(
    (sum, company) => sum + (company.image_count || 0),
    0
  );
}
  userId = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;

  // backgroundImages: any[] =[];
  // getAllBackgroundImages(){
  //   this.dashboarservice.GetAllBackgroundImages(this.userId).subscribe((data:any[]) => {
  //     if(data != null || data != undefined){
  //       this.backgroundImages = data;
  //       console.log("background images",this.backgroundImages);
  //     }
  //   })
  // }

  // getBackgroundImage(treeViewId: number): string {
  //   const backgroundImage = this.backgroundImages.find(image => image.TreeViewId === treeViewId);
  //   console.log("bag images",backgroundImage);
  //   return backgroundImage ? `url(${backgroundImage.logo_path})` : '../../../assets/image/Images.jpg';
  // }

  allCompanies:any[]=[];
  getAllCompanies(){
    this.dashboarservice.GetAllCompanies(this.userId).subscribe((data:any) => {
      // console.log("get all companies",data);
      this.allCompanies = data;
      this.tempComponies = data;
      this.calculateTotalImages();
      // console.log("all the companies",data);
    });
  }



  
  opentreeview() {
    window.open("#/filesystem", "_blank");
    //this.router.navigate(["/filesystem"])
  }

  opentreeviewonCompany(company: any) {
    this.companyID = encodeURIComponent(company.CompanyNames);
    // window.open(`#/filesystem?company=${this.companyID}`, "_blank");
    this.router.navigate(['/Treedata'], { queryParams: { company: this.companyID } });
  }
  

  getAllAiImages() {
    this.dashboarservice.GetAllImages("AIimage").subscribe((data: any[]) => {
      if (data != null) {
        // console.log("images",data);
        this.storyElement9 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement9)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("AIimagecount", this.storyElement9.toString());
      }
    });
  }

  getAllAudios() {
    this.dashboarservice.GetAllAudio().subscribe((data: any[]) => {
      if (data != null) {
        // console.log("audio",data);
        this.storyElement4 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement4)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("audiocount", this.storyElement4.toString());
      }
    });
  }

  getAllgraphics() {
    this.dashboarservice.GetAllImages("graphics").subscribe((data: any[]) => {
      if (data != null) {
        this.storyElement5 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement5)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("graphicscount", this.storyElement5.toString());
      }
    });
  }

  getAllCartoons() {
    this.dashboarservice.GetAllImages("cartoon").subscribe((data: any[]) => {
      if (data != null) {
        // console.log("graphics",data);
        this.storyElement6 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement6)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("cartooncount", this.storyElement6.toString());
      }
    });
  }

  getAllIllustration() {
    this.dashboarservice
      .GetAllImages("illustration")
      .subscribe((data: any[]) => {
        if (data != null) {
          // console.log("graphics",data);
          this.storyElement7 = data[0].rowcount;
          var tcount = localStorage.getItem("totalcount");
          tcount = (Number(tcount) + Number(this.storyElement7)).toString();
          localStorage.setItem("totalcount", tcount);
          localStorage.setItem(
            "illustrationcount",
            this.storyElement7.toString()
          );
        }
      });
  }

  getAllHRData() {
    this.hrcornerService.GetAllDocumentCount().subscribe((data: any[]) => {
      if (data != null) {
        // console.log("HR", data);
        this.storyElement8 = data[0].count;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement8)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("HRDocs", this.storyElement8.toString());
      }
    });
  }
  getAllMagazineData() {
    this.dashboarservice.GetAllMagazineCount().subscribe((data: any[]) => {
      if (data != null) {
        // console.log("HR", data);
        this.storyElement10 = data[0].rowcount;
        var tcount = localStorage.getItem("totalcount");
        tcount = (Number(tcount) + Number(this.storyElement10)).toString();
        localStorage.setItem("totalcount", tcount);
        localStorage.setItem("MagazineDocs", this.storyElement10.toString());
      }
    });
  }
  // blindsearch(){
  //   console.log("this.srchtext",this.srchtext);

  //   if(this.srchtext==null ||  this.srchtext==undefined || this.srchtext== ''){
  //     console.log("if");

  //     this.router.navigate(['/allitems']);
  //     this.stopService()
  //   }
  //   else{
  //     console.log("else");

  //       this.router.navigate(['/allitems', { text: this.srchtext }]);
  //     this.stopService()

  //   }

  // }

  blindsearch() {
    if (this.srchtext == "" && this.microPhoneText == "") {
      this.router.navigate(["/allitems"]);
      this.stopService();
    } else {
      if (this.srchtext != "") {
        this.router.navigate(["/allitems", { text: this.srchtext }]);
        this.stopService();
      }
      if (this.microPhoneText != "") {
        this.srchtext = this.microPhoneText;
        this.router.navigate(["/allitems", { text: this.srchtext }]);
        this.stopService();
      }
    }
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.srchtext = "";
    environment.globalsearchtext = "";
    environment.globalSearchHR = "";
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashboarservice.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.srchtext = this.microPhoneText;
    this.srchtext = this.srchtext.substring(0, this.srchtext.lastIndexOf(" "));
    this.srchtext = this.srchtext.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }

  sendDataType(dataType) {
    // console.log("sending data to data service",dataType)
    this.dataService.dataType(dataType);
  }
  openebook() {
    window.open(
      environment.ebookpath + "?id=" + localStorage.getItem("guid"),
      "_blank"
    );
  }
}

export class VideoUploadModel{
    item_id:number
    CategoryId:string
    SubCategoryId: string
    CountryId:string
    StateId:string
    CityId:string
    EventId:string
    OriginalPath:string
    itemfilename:string
    itemsize:any
    itemlength:string
    itemformat:string
    itemcaption:string
    itemkeyword:string
    location:string
    itemauthor:string
    eventdate:string
    itemrating:string
    framewidth:string
    Caption:string
    ImageSize:string
    Keywords:string
    Duration:string
    CompressorID:string
    AvgBitrate:string
    file_type:string
    startdate:Date
    endDate:Date
    file_status:string
    Searchbyfield:string
    downloadcount:string
    ltoname:string
    commentscount:string
    keywordscount:string
     framelength: string
     VideoFrameRate: string
     categoryname:string
     subcategoryname:string
     eventname:string
     countryname:string
     statename:string
     cityname:string
     itemid:number
     HiresPath:string
     orgfilename:string
     channels:number
     eventdate1:string
     title:string
     collpath:string
     ltopath: string
     ufid: string
}
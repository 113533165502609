import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserserviceService } from 'src/app/services/masters/userservice.service';

@Component({
  selector: 'app-userperference',
  templateUrl: './userperference.component.html',
  styleUrls: ['./userperference.component.css']
})
export class UserperferenceComponent implements OnInit {
  tablestyle:string='grid'
  rowperpage:string='18'
  tablecol:string
  constructor(public userser:UserserviceService, private messageservice: MessageService) { }

  ngOnInit() {
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      this.rowperpage=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      this.tablestyle=JSON.parse(localStorage.getItem("userperference"))[0].tablestyle
      this.tablecol=JSON.parse(localStorage.getItem("userperference"))[0].tablecol
    }
  }

  saveperference(){
    this.userser.saveperference(this.rowperpage,this.tablestyle,this.tablecol).subscribe((data:any[])=>{
      if(data.length>0){
        this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { DocumentuploadService } from 'src/app/services/files/documentupload.service';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { LoginService } from 'src/app/services/login/login.service';
import { PublicationService } from 'src/app/services/masters/publication.service';

@Component({
  selector: 'app-sharedcomponent',
  templateUrl: './sharedcomponent.component.html',
  styleUrls: ['./sharedcomponent.component.css']
})
export class SharedcomponentComponent implements OnInit {
  clientDetails: any;
  user: any;
  licenceDate: any
  machineId: any;
  client: any;
  
  constructor(public dashboarservice: DashboardService,private loginservice: LoginService,private authservice: AuthService,public publicservice:PublicationService,private documentser:DocumentuploadService,public imageservice:ImageuploadService) { }

  ngOnInit() {
    this.clientDetail()
    this.getallkeyword()
    this.getallbysubject()
    this.getallusers()
    this.getpublications()
    this.getpdftype()
    this.search()
    this.getrelationlist()
  }

  clientDetail() {
    this.loginservice.clientDetail().subscribe((data: any) => {
      this.clientDetails = data
      // console.log("this.clientDetail",this.clientDetails);
      this.clientDetails = this.clientDetails.split('~');
      this.user = this.clientDetails[0]
      this.licenceDate = this.clientDetails[1]
      this.machineId = this.clientDetails[2]
      this.client = this.clientDetails[3]
      // console.log("client name",this.client);
      var nowDate = new Date();
      var expiredate = new Date(this.licenceDate);
      // console.log(expiredate,'--',nowDate);
      // console.log(expiredate >= nowDate);
      if(expiredate >= nowDate){

      }else{
        this.authservice.logout()
      }
    })
  }

  search() {
    var results = []
    var userid=JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    this.dashboarservice.getcartlist(userid).subscribe((data: any[]) => {
      if (data.length>0) {
        for (let i = 0; i < data.length; i++) {
          results.push(data[i].cartname)
          if(i==data.length-1){
            this.dashboarservice.cartlistdata(results)
          }
        }
      }
  
  
    });
  }

  getrelationlist() {
    var relationlist = []
    this.imageservice.allrealtionlist().subscribe((data: any[]) => {
      if (data.length>0) {
        for (let i = 0; i < data.length; i++) {
          relationlist.push(data[i].groupname)
          if(i==data.length-1){
            this.dashboarservice.relationlistdata(relationlist)
          }
        }
      }


    });
  }

  getallkeyword() {
    this.dashboarservice.getkeywords("").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.dashboarservice.keywordropdata(data)
      }
    })
  }

  getallbysubject() {
    this.dashboarservice.getallbysubject().subscribe((data: any[]) => {
      if (data.length>0) {
        var data1 = JSON.parse(data.toString())
        this.dashboarservice.bysubjectdata(data1)
        //this.TreedropdownComponent.binddata(this.items)
      }
    })
  }

  getallusers(){
    this.dashboarservice.getallusers().subscribe((data:any[])=>{
      if(data.length>0){
        this.dashboarservice.userdata(data)
      }
      else{
        this.dashboarservice.userdata([])
      }
    })
  }

  getpublications(){
    this.publicservice.getAllPublication().subscribe((data:any[])=>{
      if(data.length>0){
        this.dashboarservice.publicationdata(data)
      }
    })
  }
  getpdftype(){
    this.documentser.getpdftypes().subscribe((data:any[])=>{
      if(data.length>0){
        this.dashboarservice.pdftypedata(data)
      }
    })
  }

}

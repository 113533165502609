import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {categoryformmodel } from 'src/app/model/master/category.form.model';
import { environment } from 'src/environments/environment';
@Injectable()

export class CategoryService {

  categoryformmodel:categoryformmodel=new categoryformmodel();

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  
  getAllCategory():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"category");
  }
  CreateCategory(catinfo:categoryformmodel):Observable<categoryformmodel[]>{
    return this.httpclient.post<categoryformmodel[]>(this.baseurl + "category/create",catinfo)
  }
  DeleteCategory(CategoryId:number):Observable<categoryformmodel[]>{
    return this.httpclient.post<categoryformmodel[]>(this.baseurl + "category/delete",{CategoryId:CategoryId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  UpdateCategory(catinfo:categoryformmodel):Observable<categoryformmodel[]>{
    return this.httpclient.post<categoryformmodel[]>(this.baseurl + "category/update",catinfo)
  }
  checkduplicate(catinfo:categoryformmodel):Observable<categoryformmodel[]>{
    return this.httpclient.post<categoryformmodel[]>(this.baseurl + "category/checkduplicate",catinfo)
  }

  getagenystorycat():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"agency/getagenystorycat")
  }
  getAllSubCategory():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"category/subcategory");
  }
  getAlleventname():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"category/eventlist");
  }
 
}
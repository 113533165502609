import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-mastergroup",
  templateUrl: "./mastergroup.component.html",
  styleUrls: ["./mastergroup.component.css"],
})
export class MastergroupComponent implements OnInit {
  groupname: string = "";
  ButtonStatus: string = "Save";
  Header: string = "Add/Edit Group";
  groupinfo: any[] = [];
  grouplist: any[] = [];
  selectedgroup: string;
  avlblper: any[] = [];
  assgnper: any[] = [];
  groupid: string = "";
  tmpgroupname: string = "";
  constructor(
    public groupser: UserserviceService,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getgrouplist();
    this.getpermission();
  }

  getgrouplist() {
    this.groupser.getgrouplist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.grouplist = data;
      }
    });
  }

  updategroup(grouplist) {
    this.groupid = grouplist.groupid;
    this.groupname = grouplist.group_name;
    this.tmpgroupname = grouplist.group_name;
    this.ButtonStatus = "Update";
    this.groupser
      .getgrouppermissions(grouplist.groupid)
      .subscribe((data: any[]) => {
        if (data[0].length > -1 && data[1].length > -1) {
          this.avlblper = data[0];
          this.assgnper = data[1];
        }
      });
  }

  getpermission() {
    this.groupser.getpermission().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.avlblper = data;
        this.assgnper = [];
      }
    });
  }

  save() {
    if (this.groupname == "") {
      this.messageService.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please provide group name",
      });
      return;
    }
    if (this.tmpgroupname != this.groupname) {
      this.groupser
        .checkduplicategroup(this.groupname)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.messageService.add({
              key: "message",
              severity: "warn",
              summary: "Warning Message",
              detail: "Duplicate",
            });
          } else {
            if (this.groupid != "") {
              this.groupser
                .updategroup(this.groupid, this.groupname, "A", this.assgnper)
                .subscribe((data1: any[]) => {
                  if (data1 != null) {
                    this.messageService.add({
                      key: "message",
                      severity: "success",
                      summary: "Success Message",
                      detail: "Update Successfully",
                    });
                    this.clear();
                  }
                });
            } else {
              this.groupser
                .creategroup(this.groupname, this.assgnper)
                .subscribe((data1: any[]) => {
                  if (data1 != null) {
                    this.messageService.add({
                      key: "message",
                      severity: "success",
                      summary: "Success Message",
                      detail: "Successfully Created",
                    });
                    this.clear();
                  }
                });
            }
          }
        });
    } else {
      if (this.groupid != "") {
        this.groupser
          .updategroup(this.groupid, this.groupname, "A", this.assgnper)
          .subscribe((data1: any[]) => {
            if (data1 != null) {
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Update Successfully",
              });
              this.clear();
            }
          });
      } else {
        this.groupser
          .creategroup(this.groupname, this.assgnper)
          .subscribe((data1: any[]) => {
            if (data1 != null) {
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Successfully Created",
              });
              this.clear();
            }
          });
      }
    }
  }
  clear() {
    this.tmpgroupname = "";
    this.groupname = "";
    this.ButtonStatus = "Save";
    this.groupid = "";
    this.getpermission();
    this.getgrouplist();
  }


  disabledGroupIds = environment.disabledGroupIds;

  isGroupDisabled(groupId: number): boolean {
    return this.disabledGroupIds.indexOf(groupId) !== -1;
  }


  deletegroup(groupid) {
    if (this.isGroupDisabled(groupid)) {
      console.warn(`Group ${groupid} is protected and cannot be deleted.`);
      this.messageService.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "This group can't be deleted.",
      });
      return;
    }
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "mastergroupkey",
      icon: "pi pi-exclamation-triangle",
      
      accept: () => {
        this.groupser.deletegroup(groupid).subscribe((response) => {
          this.messageService.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Deleted",
          });
          this.clear();
        });
      },
      reject: () => {},
    });
  }
}

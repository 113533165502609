import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login/login.service';
import { UserserviceService } from 'src/app/services/masters/userservice.service';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.css']
})
export class LicenceComponent implements OnInit {
  clientDetails: any;
  user: any;
  licenceDate: any;
  machineId: any;
  client: any;
  licenceFile: any;
  licenceName:any;
  constructor(private loginservice:LoginService,private authservice:AuthService,private route:Router,
    private messageservice: MessageService,private userser:UserserviceService) { }

    ngOnInit() {
      this.clientDetail();
    }
    clientDetail(){
    let clientDetail = this.loginservice.clientDetail().subscribe((data: any)=>{
      this.clientDetails = data
      // console.log("this.clientDetail",this.clientDetails);
      this.clientDetails = this.clientDetails.split('~');
      this.user = this.clientDetails[0]
      this.licenceDate = this.clientDetails[1]
      this.machineId = this.clientDetails[2]
      this.client = this.clientDetails[3]
    })
    // clientDetail.unsubscribe()
  }

  onFileChange(event:any) {
    if (event.target.files.length > 0) {
      this.licenceFile = event.target.files[0];
      this.licenceName = event.target.files[0].name;
    }
  }


  activateLicence(){
    // console.log("licence file", this.licenceFile);
    this.loginservice.uploadlicenseFile(this.licenceFile, this.licenceName).subscribe((data: any)=>{
      if(data != 0){
        // console.log("upload file")
        // this.messageservice.add({ key: 'message', severity:'success', summary: 'Upload Message', detail:'Licence file uploaded'});
        this.loginservice.licenseActivate().subscribe((data: any)=>{
          // console.log(data);
          if(data !=0){
          // console.log("submitted")
          this.messageservice.add({ key: 'message', severity:'success', summary: 'Success Message', detail:'Licence renewed, Enjoy the product'});
          }
        })
      }
    })
    
  
  }

}

import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { environment } from "src/environments/environment";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { CategoryService } from "src/app/services/masters/category.service";
import { Router } from "@angular/router";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { ConfirmationService, MessageService, MenuItem } from "primeng/api";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DashboardService } from "src/app/services/files/dashboard.service";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { cartmodel } from "src/app/model/file/cartmodel";
import { ContextMenu, Dialog, Menu } from "primeng/primeng";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { DailypagesService } from "src/app/services/files/dailypages.service";

@Component({
  selector: "app-dailypages",
  templateUrl: "./dailypages.component.html",
  styleUrls: ["./dailypages.component.css"],
})
export class DailypagesComponent implements OnInit {
  @ViewChild("dv", { static: false }) dataview1: DataView;
  @ViewChild("ytplayer", null) ytPlayer: ElementRef;
  display: boolean = false;
  show: boolean = false;
  showDownload: boolean = false;
  showDocumentSearch: boolean = false;
  documentList: DocumentUploadFormModel[] = [];
  searchdata: DocumentUploadFormModel[] = [];
  downloadDocumentList: downloadimagesmodel[] = [];
  getdowloadDocumentlist: downloadimagesmodel[] = [];
  Searchbyfield: string = "";
  DocumentUpdated: boolean = false;
  selecteddocument: DocumentUploadFormModel;
  documentuploadinformation: DocumentUploadFormModel;
  showDocumentDownload: boolean = false;
  viewDocument: boolean = false;
  fullDocumentpathlist: DocumentUploadFormModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalDocumentpath = "assets/image/audio.jpg";
  glbDelete: number = 0;
  glbupdate: number = 0;
  fileContent: string = "";
  text: string;
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  pdfimages: any[];
  loading: boolean = true;
  totalcount: string;
  // ButtonStatus='Download'
  adnvacesrchfields: string;
  selectedIndexs: DocumentUploadFormModel[] = [];
  selectedIndexspermis: any[] = [];
  contextmenuitem: MenuItem[];
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  ismenuclick: boolean = false;
  rightclickmenuopen: boolean = false;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @ViewChild("editdialog", { static: true }) editdialog: Dialog;
  autoCompletArr: any[] = [];
  constructor(
    public imageservice: ImageuploadService,
    private router: Router,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    public subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    public sanitizer: DomSanitizer,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public documentservice: DailypagesService,
    private dashboarservice: DashboardService,
    public storyser: StoryserviceService
  ) {}

  ngOnInit() {
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    this.getAllDocument();
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
    ];
    this.fullDocumentpathlist = new DocumentUploadFormModel();
    this.selecteddocument = new DocumentUploadFormModel();
    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
      }
    });
  }

  cmshow() {
    this.rightclickmenuopen = true;
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  hidepopup() {
    this.viewDocument = false;
    this.showfulldiv();
  }
  advanceSearch() {
    this.Searchbyfield = this.Searchbyfield;
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  setstyle() {
    const x = document.getElementById("spnauto") as any;
    var lft =
      x.offsetParent.offsetParent.offsetParent.offsetLeft +
      x.offsetParent.offsetLeft +
      x.offsetLeft;
    const y = document.getElementsByClassName("srchform") as any;
    y[0].style.left = lft + "px";
    y[0].style.width = x.offsetWidth + "px";
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
  }

  getAllDocument() {
    this.removeselection();
    this.loading = true;

    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    environment.glbsearchstring = "";
    environment.globalsearchtext = "";
    this.documentservice.getdailypagescount().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.totalcount = data[0].rowcount.toString();
      }
    });
    this.documentservice.GetAllDocument("0").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.documentList = data;
        //this.totalcount=localStorage.getItem("documentcount")
        this.glbDelete = 1;
        this.glbupdate = 1;
      } else {
        this.documentList = [];
        this.totalcount = "0";
      }
    });
    this.loading = false;
  }

  SearchByFields() {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.Searchbyfield = this.Searchbyfield.trim();
    if (this.Searchbyfield == null || this.Searchbyfield.trim() == "") {
      this.getAllDocument();
    } else {
      var txt = this.Searchbyfield;
      environment.globalsearchtext = this.Searchbyfield;
      environment.glbsearchstring = "";

      txt = this.ExtractSearchingKeyword(txt);
      var tbastr = localStorage.getItem("tabperstring");
      this.documentservice.getncount(txt, tbastr).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount = data[0].rowcount.toString();
        }
      });
      this.documentservice.SearchByFields(txt, "0").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.documentList = data;
          //this.totalcount=this.documentList.length.toString()
        } else {
          this.documentList = [];
          this.totalcount = "0";
        }
      });
    }
    this.loading = false;
  }

  newadvancesearch(srchtxt) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.hideadvncsrch();
    environment.glbsearchstring = srchtxt;
    environment.globalsearchtext = "";
    var tbastr = localStorage.getItem("tabperstring");
    this.documentservice.getacount(srchtxt, tbastr).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.totalcount = data[0].rowcount.toString();
      }
    });
    this.documentservice
      .advancesearch(srchtxt, "0")
      .subscribe((data: any[]) => {
        if (data != null) {
          this.documentList = data;
          //this.totalcount=this.documentList.length.toString()
        } else {
          this.documentList = [];
        }
        this.loading = false;
      });
  }

  pagenochange(e) {
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      var x = document.querySelector(".ui-paginator-bottom");
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
      this.loading = true;
      environment.globalsearchtext = environment.globalsearchtext.trim();
      if (environment.globalsearchtext.trim() != "") {
        var srchtxt = this.ExtractSearchingKeyword(
          environment.globalsearchtext
        );
        this.documentservice
          .SearchByFields(srchtxt, this.dataview1.totalRecords)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else if (environment.glbsearchstring != "") {
        this.documentservice
          .advancesearch(
            environment.glbsearchstring,
            this.dataview1.totalRecords
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        this.documentservice
          .GetAllDocument(this.dataview1.totalRecords)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      }
    }

    this.removeselection();
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        this.removeselection();
      }
    }
  }
  getdocumentdata(itemid, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "";
      }
      this.documentservice
        .getdocumentdata(itemid.toString().trim(), path)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.fullDocumentpopup(data[0]);
          }
        });
    }
  }

  fullDocumentpopup(documentList) {
    this.fullDocumentpathlist = documentList;
    this.ratingitem = documentList.itemrating;

    try {
      this.ytPlayer.nativeElement.src =
        this.ApiServerPath + documentList.OriginalPath;
    } catch (err) {}
    this.viewDocument = true;
  }

  downloadDocument(filename, filepath, itemid) {
    let unsub = this.storyser
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          saveAs(data, filename);
        }
        unsub.unsubscribe();
        this.downloadentry(itemid);
        var x = document.querySelector(".downloadinggif1");
        x["style"].display = "none";
        var y = document.querySelector(".downloadback");
        y["style"].display = "none";
        this.removeselection();
      });
  }
  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  close() {
    this.viewDocument = false;
  }

  showNoOfDownload(itemid) {
    this.documentservice
      .GetDownloadedDocumentData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadDocumentList = data;
          this.showDocumentDownload = true;
        }
      });
  }

  showzoom(itemid, fname) {
    window.open(this.ApiServerPath + fname, "_blank", "popupwindow");
  }

  removeselection() {
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      document.getElementById(
        this.selectedIndexs[i].toString()
      ).style.background = "transparent";
      if (i == this.selectedIndexs.length - 1) {
        this.selectedIndexs.splice(0, this.selectedIndexs.length);
        this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "orange";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    y["style"].display = "block";
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var fname = iid.children[2].innerHTML.trim();
      var filepath = iid.children[0].innerHTML.trim();
      //this.downloadDocument(fname,filepath,imageid)
      if (lst.length > 1) {
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      } else {
        this.downloadDocument(fname, filepath, imageid);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  downloadmultiplezip(arr) {
    this.documentservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    a.remove();
    this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashboarservice.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.Searchbyfield = this.Searchbyfield.substring(
      0,
      this.Searchbyfield.lastIndexOf(" ")
    );
    this.Searchbyfield = this.Searchbyfield.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }
}

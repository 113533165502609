import { Injectable } from '@angular/core';
import { VideoUploadModel } from 'src/app/model/file/VideoUploadModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { exportConfigurationModel } from 'src/app/model/file/exportconfigurationmodel';

@Injectable({
  providedIn: 'root'
})
export class VideouploadService {

  VideoUploadModel:VideoUploadModel=new VideoUploadModel()

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;


  AddNewVideo(videouploadinfo:VideoUploadModel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "videoupload/insertvideodata",videouploadinfo)
  }
  getvideometadata(nme):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/videometadata",{itemfilename:nme,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  GetAllgroupimage(groupid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getallgroup",{groupid:groupid,indx:0,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getitemtype():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getitemtype")
  }
  getdownloaditemtype():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getdownloaditemtype")
  }
  AddSrtFiles(srtdata:VideoUploadModel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/insertSRT",srtdata)
  }

  SearchAllVideo(videouploadinfo:VideoUploadModel):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/SearchVideo",videouploadinfo)
  }
  // SearchByFields(Searchstring):Observable<any[]>{
  //   return this.httpclient.post<any[]>(this.baseurl+"videoupload/SearchByFields",{Searchstring:Searchstring})
  // }

  //this function and API is not working
  GetAllVideo():Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getallvideo")
  }

  UpdateVideo(videoinfo):Observable<VideoUploadModel[]>{
    return this.httpclient.post<VideoUploadModel[]>(this.baseurl+"videoupload/updateVideos",JSON.parse(JSON.stringify(videoinfo).replace(/null/g, '""')))
  }

  deleteVideo(itemid:number):Observable<VideoUploadModel[]>{
    return this.httpclient.post<VideoUploadModel[]>(this.baseurl + "videoupload/deleteVideo",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deletemultiplevideo(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/deletemultiplevideo",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  //whole function with API is not working
  GetExportButton():Observable<exportConfigurationModel[]>{
    return this.httpclient.get<exportConfigurationModel[]>(this.baseurl+"videoupload/ExportData")
  }

  InsertExportData(Exportid,exportietmid,exportitemname, filepath,DownloadFormat):Observable<any[]>{
    //console.log("export service: ",Exportid,exportietmid,exportitemname, filepath )
    return this.httpclient.post<any[]>(this.baseurl +"videoupload/inserExportData",{exportid:Exportid,itemid:exportietmid,itemname:exportitemname,filepath:filepath,DownloadFormat,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  GetDownloadedVideoData(itemid:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getdownloadVideodata",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  ExportEntry(itemid,exportid,filename,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/exportEntry",{itemid:itemid,exportid:exportid,filename:filename,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getTodayVideo():Observable<any[]>{
    let maxlimit="54";
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getTodayVideo")
  }
  
  ffmpeg(itemfilepath,orgname,totalduration,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/ffmpeg",{itemfilepath:itemfilepath,itemname:orgname,totalduration:totalduration,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  Testdownload(exportitemid, filename, attachmentpath):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"videoupload/testdownload",{exportitemid:exportitemid,filename:filename,attachmentpath:attachmentpath,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId},{responseType: "blob"})
  }

  deleteTempFile(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/deleteVideomerge",{userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  // API is not working
  SearchAllImages(videosearchinfo):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/SearchVideo",videosearchinfo)
  }

  advancesearch(srchtxt,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/advancesearch",{srchtxt:srchtxt,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  
  SearchByFields(Searchstring,type,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/SearchByFields",{Searchstring:Searchstring,type:type,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getncount(srchtxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getncount",{Searchstring:srchtxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  
  getacount(srchtxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getacount",{srchtxt:srchtxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getallvideos(limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getallvideos",{limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getitemtypes():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getitemtype")
  }
  getbindcategory():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getcategory")
  }
  getbindevents():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getbindevents")
  }
  getbindsubcategory():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"videoupload/getsubcategorybind")
  }

  postdata(snapbase64,comment,filepath,filename,frame,itemid,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/addframecomment",{snapbase64:snapbase64,comment:comment,filepath:filepath,filename:filename,frame:frame,itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getcomment(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getframecomment",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  postreply(itemid,reply,username,videoid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/addreply",{itemid:itemid,reply:reply,username:username,videoid:videoid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getvideoinfo(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"dashboard/getvideo",{itemid:itemid,path:"",userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getvideokeyword(videoid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/getframekeyword",{videoid:videoid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  saveframekeyword(videoid,keyword,frame):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/saveframekeyword",{videoid:videoid,keyword:keyword,frame:frame,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  updateframekeyword(id,keyword,videoid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/updateframekeyword",{id:id,keyword:keyword,videoid:videoid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  approvevideo(approvallst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/approvevideo",{approvallst:approvallst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deleteVideoRstr(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/deleterestoredvideos",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId});
  }

  restoreVideo(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"videoupload/restore",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  // getitems(itemid,path):Observable<any[]>{
  //   return this.httpclient.post<any[]>(this.baseurl+"videoupload/getitem",{itemid:itemid,path:path,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  // }

  activeLtoLink(){
    return this.httpclient.get<any>(this.baseurl + "videoupload/activeLtoLink")
  }

  disApproveImg(itemId){
    return this.httpclient.post<any>(this.baseurl + "videoupload/disApproveImg", {itemId:itemId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getFilePathStatus(videolist):Observable<any>{
    return this.httpclient.post<any>(this.baseurl + 'videoupload/GET_FILE_PATH_STATUS',{videolist:videolist,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
}


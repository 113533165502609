import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { AgencyService } from 'src/app/services/files/agency.service';

@Component({
  selector: 'app-masteragency',
  templateUrl: './masteragency.component.html',
  styleUrls: ['./masteragency.component.css']
})
export class MasteragencyComponent implements OnInit {

  agencylist: any[] = [];
  agencyinformation: any;
  agencyform: FormGroup
  msgs: Message[] = [];
  Header: string = "Add/Edit Agency"
  ButtonStatus: string = 'Save'
  tmpagencyid: string;
  constructor(private agencyser: AgencyService, private formBuilder: FormBuilder, private messageService: MessageService, private Confirmservice: ConfirmationService) { }

  ngOnInit() {
    this.getagency()
    this.CreateCountryForm()
  }

  getagency() {
    this.agencyser.getallagency().subscribe((data: any[]) => {
      if (data != null) {
        this.agencylist = data
      }
    })
  }
  CreateCountryForm() {
    this.agencyform = this.formBuilder.group({
      agencyname: [null, [Validators.required, this.removeSpaces, Validators.pattern('^[a-zA-Z ]*$')]],
      agencyid: [null],
      userid: [null],
      agencytype: [null]
    })
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  SaveCountry(formval) {
    this.agencyinformation = formval.value
    if (this.agencyinformation.agencytype == "0") {
      this.agencyinformation.agencytype = "Image"
    }
    else {
      this.agencyinformation.agencytype = "Story"
    }
    this.agencyinformation.userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    if (this.agencyinformation.agencyid > 0) {
      this.agencyser.checkduplicateagncy(this.agencyinformation).subscribe((data: any) => {
        if (data.length < 1) {
          this.agencyser.updateagency(this.agencyinformation).subscribe((_data: any) => {
            this.getagency()
            this.Clear()
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'update successfully' });
            this.ButtonStatus = "Save"
          });
        } else {

          this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
        }
      })
    }

    else {
      this.agencyser.checkduplicateagncy(this.agencyinformation).subscribe((data: any) => {
        if (data.length < 1) {
          this.agencyser.Createagency(this.agencyinformation).subscribe((_data: any) => {
            this.getagency()
            this.Clear()
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });

          });
        } else {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
        }
      })
    }
  }

  updateagency(countrylist: any) {
    this.ButtonStatus = "Update"
    this.agencyform.setValue({
      agencyname: countrylist.AgencyName,
      agencyid: countrylist.AgencyId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  changestatus(agencyid, status) {
    this.Confirmservice.confirm({
      message: 'Are you sure that you want update?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.agencyser.updateAgency(agencyid, status).subscribe((data: any[]) => {
          this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Update successfully' });
          this.getagency()
          this.Clear()
          this.ButtonStatus = "Save"
        })
      },
      reject: () => {
      }
    })
  }
  Clear() {
    this.agencyform.reset()
    this.ButtonStatus = "Save"
    this.agencyform.patchValue({ agencytype: "0" })
  }

  deleteAgency(AgencyId) {
    this.Confirmservice.confirm({
      message: 'Are you sure that you want update?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.agencyser.deleteAgency(AgencyId).subscribe((data: any) => {
          if (data.length > 0) {
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Delete successfully' });
            this.getagency()
          } else {
            this.messageService.add({ key: 'message', severity: 'info', summary: 'Failed Message', detail: 'Delete Failed' });

          }
        })
      }
    })
  }
  
}

import { Injectable } from '@angular/core';
import { StoryFormModel } from 'src/app/model/file/storyformModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { LanguageFormModel } from 'src/app/model/file/languageFormmodel';

@Injectable({
  providedIn: 'root'
})
export class StoryserviceService {


  storymodel:StoryFormModel=new StoryFormModel()
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;


  getAllstory():Observable<StoryFormModel[]>{
    let maxlimit="54"
    return this.httpclient.get<StoryFormModel[]>(this.baseurl+"Story/getAllStory")
  }
  AddStory(storyinfo:StoryFormModel):Observable<StoryFormModel[]>{
    return this.httpclient.post<StoryFormModel[]>(this.baseurl + "Story/insertStory",storyinfo)
  }
  AddAttachment(storyinfo:StoryFormModel):Observable<StoryFormModel[]>{
    return this.httpclient.post<StoryFormModel[]>(this.baseurl + "Story/insertAttachment",storyinfo)
  }
  getTextFileContend(filename,filepath):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"Story/getTextFileContend",{filename:filename,filepath:filepath,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getlanguage():Observable<LanguageFormModel[]>{
    return this.httpclient.get<LanguageFormModel[]>(this.baseurl+"Story/getLanguages")
  }
  
  updateStory(storyinfo:StoryFormModel):Observable<StoryFormModel[]>{
    return this.httpclient.post<StoryFormModel[]>(this.baseurl+"Story/updateStory",storyinfo)
  }
  
  deletestory(itemid:number):Observable<StoryFormModel[]>{
    return this.httpclient.post<StoryFormModel[]>(this.baseurl + "Story/deleteStory",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  

  getAttachment(parentid:number):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"Story/getallattachment",{parentid:parentid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  Testdownload(fname,attachmentpath,itemid):Observable<Blob>{
    // return this.httpclient.post<any>(this.baseurl+"Story/testdownload",{attachmentpath:attachmentpath})
    return this.httpclient.post(this.baseurl+"Story/testdownload", {fname:fname,attachmentpath:attachmentpath,itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId}, {responseType: "blob"});
   }
  getLanguagesFont(languageid):Observable<LanguageFormModel[]>{
    return this.httpclient.post<LanguageFormModel[]>(this.baseurl+"Story/getLanguagesFont",{languageid:languageid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  SearchByFields(Searchstring):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"Story/SearchByFields",{Searchstring:Searchstring,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  SearchAllStory(storyinfo:StoryFormModel):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"Story/SearchStory",storyinfo)
  }

  advancesearch(srchtxt):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"Story/advancesearch",{srchtxt:srchtxt,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getcount(srchtxt):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"Story/getcount",{srchtxt:srchtxt,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getstory(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"Dashboard/getstory",{itemid:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  // getTodayDocument():Observable<any[]>{
  //   return this.httpclient.get<any[]>(this.baseurl+"DocumentUpload/getTodayDocument")
  // }
}

export class CityFormModel{
 
    CityId:number
    CityName:string
    StateId:number
    ActiveStatus:string
    CountryID:number
    StateName:string
    CountryName:string
    userid:string

}
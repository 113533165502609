import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LtodiskService {

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;

  ltoDiskList(){
    return this.httpclient.get<any>(this.baseurl + "lto/ltoList");
  }
}

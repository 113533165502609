import { Injectable } from '@angular/core';
import { PublicationFormModel } from 'src/app/model/master/publication.formmodel';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  
  publicationformmodel:PublicationFormModel=new PublicationFormModel();
  constructor(private httpclient:HttpClient) { }


  baseurl=environment.ApiUrl
  // baseurl: string = "http://localhost:5000/publication";

  getAllPublication():Observable<PublicationFormModel[]>{
    return this.httpclient.get<PublicationFormModel[]>(this.baseurl+"publication/");
  }
  Cretaepublication(publicinfo:PublicationFormModel):Observable<PublicationFormModel[]>{
    return this.httpclient.post<PublicationFormModel[]>(this.baseurl+ "publication/insertpublication",publicinfo);
  }
  Updatepublication(publicinfo:PublicationFormModel):Observable<PublicationFormModel[]>{
    return this.httpclient.post<PublicationFormModel[]>(this.baseurl+"publication/updatepublication",publicinfo)
  }
  Deletepublication(PublicationId:number):Observable<PublicationFormModel[]>{
    return this.httpclient.post<PublicationFormModel[]>(this.baseurl+"publication/deletepublication",{PublicationId:PublicationId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  checkduplicate(publicinfo:PublicationFormModel):Observable<PublicationFormModel[]>{
    return this.httpclient.post<PublicationFormModel[]>(this.baseurl+ "publication/checkduplicate",publicinfo);
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //  folderpath:"C:/mams/E-ASSETS365SERVICE(2)/E-ASSETS365SERVICE/ApiNode/upload/",
  folderpath: "E:/bangladesh/Mams/E-ASSETS365SERVICE/ApiNode/upload/",

  //
  ApiUrl: "http://localhost:5050/",
  webSocketUrl: "ws://localhost:8080",
  ApiServerPath: "http://192.168.1.185/mams/",
  ApiBarcodePath: "",
  lowresThumbPath: "",
  original_path: "",
  download_path: "",
  // ApiServerPath:"http://192.168.1.185/E-ASSETS365/mams/",
  globalsearchtext: "",
  advancesrchel: [],
  imagecount: "",
  glbsearchstring: "",
  globalSearchHR: "",
  downloadpathLocal: "http://192.168.1.185/ngdata/itempath/",
  editorpath: "http://192.168.1.185/imageeditor?path=../mams/",
  cartweblinkadd: "http://localhost:4200/#/weblink/",
  sessiontimeout: 1800,
  ebookpath: "http://192.168.1.185/E-book/",
  mailid: "abhishek@dnavigation.com",
  serverip: "192.168.1.185",
  //deepArchive: "\\192.168.1.185\deepArchivePath",
  // folderpath:"D:/NGDATA/mohit/nodeservicemams/mam_service/ApiNode/upload/"
  hostPreference: "localhost",
  CommpanyBgImagePath: "http://192.168.1.185/mams/",
  OtpTimerMin: 5,
  disabledGroupIds: [1226, 1227, 1228],
  // TokenKey: "DNPL@ADANI",
  TokenKey: '[19,3,23,2,24,15,22,8,3,1,10,25,22,8,18,9,0,0,12,7,16,3,6,17,10,19,24,11,18,9,8,20,3,17,11,21,16,20,23,16,9,4,17,21,20,11,2,2,9,20,4,12,7,2,9,12,3,15,23,22,11,21,1,2,7,24,23,7,7,2,1,18,12,6,2,18,8,13,7,9,10,22,20,23,12,1,20,17,2,13,10,2,12,12,2,11,18,21,15,19,10,14,17,9,17,2,6,0,24,5,24,16,24,3,0,24,18,25,14,18,22,11,10,23,21,10,3,3,3,13,19,21,4,1,16,1,1,18,19,1,7,3,8,23,16]',
  treeViewIds: [197, 198, 199],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

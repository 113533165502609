import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DashboardService } from './services/files/dashboard.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import {environment} from '../environments/environment';
import {HmacSHA256} from 'crypto-js';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    errorstatus:boolean=false
    constructor(public dashser:DashboardService,private myRoute: Router,private authservice:AuthService,private userIdle: UserIdleService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      if (request.url.includes('treeviewupload/api/upload2?tmppath=raw/')) {
        return next.handle(request); 
      }
      
      if (request.url.includes('/api/upload')) {
        return next.handle(request); 
      }

        const tokenValue = localStorage.getItem('uniqueNumber');
        
        if (tokenValue) {
            request = request.clone({
              setHeaders: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'Authorization': `${tokenValue}`
              }
            });
        }


        this.userIdle.stopWatching();
       
        if(!localStorage.getItem("userdetails")){
            
            this.myRoute.navigate(["login"]);
              
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                }
                this.userIdle.resetTimer()
                this.userIdle.startWatching()
                this.userIdle.onTimerStart().subscribe();
                this.userIdle.onTimeout().subscribe(() => 
                this.Logout()
                );
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                //this.errorDialogService.openDialog(data);
                //if(this.errorstatus==false){
                    //this.errorstatus=true
                    var x = document.querySelector(".ui-paginator-bottom")
                    x["style"].cursor = "pointer"
                    x["style"]["pointer-events"] = "auto"
                    this.dashser.visibletreedrop(false)
                //}
                this.userIdle.resetTimer()
                this.userIdle.startWatching()
                this.userIdle.onTimerStart().subscribe();
                this.userIdle.onTimeout().subscribe(() => 
                this.Logout()
                );
                return throwError(error);
            }));
    }


    decrypt(str, key) {
        let result = '';
      
        for (let i = 0; i < str.length; i++) {
          let charCode = str.charCodeAt(i);
          if (charCode >= 65 && charCode <= 90) { // uppercase letters (A-Z)
            result += String.fromCharCode((charCode - 65 - key[i % key.length] + 26) % 26 + 65);
          } else if (charCode >= 97 && charCode <= 122) { // lowercase letters (a-z)
            result += String.fromCharCode((charCode - 97 - key[i % key.length] + 26) % 26 + 97);
          } else {
            result += str[i]; // non-alphabetic characters remain unchanged
          }
        }
        return result;
      }

      
    Logout(){
        this.authservice.logout();
      }
}
import { Injectable } from '@angular/core';
import { editionformmodel } from 'src/app/model/master/edition.model';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditionService {

  
  editionformmodel:editionformmodel=new editionformmodel();
  constructor(private httpclient:HttpClient) { }


  baseurl=environment.ApiUrl
  // baseurl: string = "http://localhost:5000/Edition";

  getAllEdition():Observable<editionformmodel[]>{
    return this.httpclient.get<editionformmodel[]>(this.baseurl+"edition/editionList");
  }
  CreateEdition(publicinfo:editionformmodel):Observable<editionformmodel[]>{
    return this.httpclient.post<editionformmodel[]>(this.baseurl+ "edition/insertedition",publicinfo);
  }
  UpdateEdition(publicinfo:editionformmodel):Observable<editionformmodel[]>{
    return this.httpclient.post<editionformmodel[]>(this.baseurl+"edition/updateedition",publicinfo)
  }
  DeleteEdition(edition_id:number):Observable<editionformmodel[]>{
    return this.httpclient.post<editionformmodel[]>(this.baseurl+"edition/deleteedition",{edition_id:edition_id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  checkduplicate(publicinfo:editionformmodel):Observable<editionformmodel[]>{
    return this.httpclient.post<editionformmodel[]>(this.baseurl+ "edition/checkduplicate",publicinfo);
  }
  getuserpermissions(userid:number):Observable<any[]>{
    let assignepermission = this.httpclient.post<any[]>(this.baseurl+"edition/assignedpermission",{UserId:userid});
    let availablepermission =this.httpclient.post<any[]>(this.baseurl+"edition/availablepermission",{UserId:userid});
      return forkJoin(assignepermission,availablepermission)
    }
    getuserdata():Observable<editionformmodel[]>{
      return this.httpclient.get<editionformmodel[]>(this.baseurl+"user/userselect");
    }
    saveuserpermission(userid:number,taskid):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl +"edition/saveuserpermission",{UserId:userid,TaskId:taskid,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId});
    }
    deleteuserpermission(UserId:number):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"edition/deleteuserpermission",{UserId:UserId,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
    }
  
    saveperference(row,tabstyle,tablecol):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"user/saveperference",{row:row,tabstyle:tabstyle,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,name:JSON.parse(localStorage.getItem("userdetails"))[0].UserCompleteName,tablecol:tablecol})
    }
  
    getgrouplist():Observable<any[]>{
      return this.httpclient.get<any[]>(this.baseurl+"group/groupList")
    }
  
    getgroupavlper(groupid):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/availablepermission",{groupid:groupid})
    }
  
    getgroupassper(groupid):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/assignedpermission",{groupid:groupid})
    }
  
    getgrouppermissions(groupid){
      let avlbper= this.getgroupavlper(groupid)
      let assgnper=this.getgroupassper(groupid)
      return forkJoin([avlbper, assgnper]);
    }
  
    updategroup(groupid,groupname,activestatus,permlist):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/update",{groupid:groupid,groupname:groupname,activestatus:activestatus,permissionlist:permlist,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
    }
  
    creategroup(groupname,permlist):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/insert",{name:groupname,permissionlist:permlist,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
    }
  
    getpermission():Observable<any[]>{
      return this.httpclient.get<any[]>(this.baseurl+"group/getpermission")
    }
  
    checkduplicategroup(groupname):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/checkduplicate",{name:groupname})
    }
    deletegroup(groupid):Observable<any[]>{
      return this.httpclient.post<any[]>(this.baseurl+"group/deletegroup",{groupid:groupid,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
    }
}
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { GraphicsUploadComponent } from '../graphics-upload/graphics-upload.component';

@Component({
  selector: 'app-graphics-edit-form',
  templateUrl: './graphics-edit-form.component.html',
  styleUrls: ['./graphics-edit-form.component.css'],
  providers:[GraphicsUploadComponent],
})
export class GraphicsEditFormComponent implements OnInit {
  Image_Edit_form: FormGroup
  selectedimage: ImageUploadFormModel;
  idforupdate:string=""
  imageuploadinformation: ImageUploadFormModel;
  collpath:string=""
  filteredkeywords: any[];
allkeywords:any[]
glbkeywordsgst:string=""
keywords:any[]=[]
orgnamearr:string=""
  constructor(public imageservice: ImageuploadService,public dashboadservice:DashboardService,  private formBuilder: FormBuilder,  public sanitizer: DomSanitizer,public imagecom:GraphicsUploadComponent,private messageservice: MessageService) { }

  ngOnInit() {
    this.CreateImageEditForm()
    //this.getallkeyword()
    this.selectedimage = new ImageUploadFormModel();
    
  }

  @Input()
  selecttab:string;
  @Input()
  selectValue: string;
  @Input()
  orgname:string
  

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectValue']) {
      if(this.selectValue !=""){
        this.idforupdate=this.selectValue
        this.orgnamearr=this.orgname
        this.updatepopup(this.idforupdate,this.selecttab)
      }
    
    }
    let keywordsub = this.dashboadservice.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
}
  CreateImageEditForm() {
    this.Image_Edit_form = this.formBuilder.group({
      CategoryId: [""],
      SubCategoryId: [""],
      CountryId: [""],
      StateId: [""],
      CityId: [""],
      EventId: [""],
      OriginalPath: [""],
      itemfiename: [""],
      itemsize: [""],
      itemlength: [""],
      itemformat: [""],
      resolution: [""],
      itemcaption: [""],
      itemkeyword: [""],
      location: [""],
      itemauthor: [""],
      eventdate: [""],
      itemrating: [""],
      title: [""],
      source: [""],
      photogrphername: [""],
      copyright: [""],
      orietation: [""],
      shootdate: [""],
      itemid: [""],
      itempermission:[null],
      userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }

  updateimages(imageeditlist) {
    if(this.idforupdate.split("~")[1] =="" || this.idforupdate.split("~")[1] ==undefined){
      this.collpath=imageeditlist.collpath
    }
    else{
      imageeditlist.orgfilename = ""
      this.collpath=""
    }
    this.keywords=[]
if(imageeditlist.itemkeyword !=""){
  for(let i=0;i<imageeditlist.itemkeyword.split(";").length;i++){
    this.keywords.push({keywordname:imageeditlist.itemkeyword.split(";")[i].toString().trim()})
  }
}
    //this.getSubcategorydata(imageeditlist.categoryid)
    this.Image_Edit_form.setValue({
      itemid: imageeditlist.itemid,
      CategoryId: imageeditlist.categoryid,
      SubCategoryId: imageeditlist.subcategoryid,
      CountryId: imageeditlist.countryid,
      StateId: imageeditlist.stateid,
      CityId: imageeditlist.cityid,
      EventId: imageeditlist.eventname,
      OriginalPath: imageeditlist.OriginalPath,
      itemfiename: imageeditlist.orgfilename,
      itemsize: imageeditlist.itemsize,
      itemlength: imageeditlist.itemlength,
      itemformat: imageeditlist.itemformat,
      resolution: imageeditlist.resolution,
      itemcaption: imageeditlist.itemcaption,
      itemkeyword: imageeditlist.itemkeyword,
      location: imageeditlist.location,
      itemauthor: imageeditlist.itemauthor,
      eventdate: imageeditlist.eventdate,
      itemrating: imageeditlist.itemrating,
      title: imageeditlist.title,
      source: imageeditlist.Source,
      photogrphername: imageeditlist.creator,
      copyright: imageeditlist.copyright,
      orietation: imageeditlist.genre,
      shootdate: imageeditlist.shootdate,
      itempermission:imageeditlist.itempermission,
      userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId
    })
  }

  updatepopup(itemid,path) {
    if(itemid.indexOf("~")>-1){
      itemid=itemid.split("~")[0]
    }
    this.imageservice.getitems(itemid.trim(),path).subscribe((data: any[]) => {
      if (data.length>0) {
        //this.selectedimage = data[0]
        this.updateimages(data[0])
        
      }
    })
  }

  SaveAfterUpdate(formval) {
    this.imageuploadinformation = formval.value
    this.imageuploadinformation.itemid=this.idforupdate
    this.imageuploadinformation.orgfilename=this.orgnamearr
    if(this.imageuploadinformation.eventdate==""){
      this.imageuploadinformation.eventdate=formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
    if(this.imageuploadinformation.itempermission==null){
      this.imageuploadinformation.itempermission="1"
    }
    var tmpkeywords=""
    for(let i=0;i<this.keywords.length;i++){
      tmpkeywords +=this.keywords[i].keywordname +";"
    }
    this.imageuploadinformation.itemkeyword=tmpkeywords.trim().substring(0,tmpkeywords.trim().length-1)
    this.imageservice.UpdateImages(this.imageuploadinformation).subscribe((data: any) => {
      if (data != null) {
        this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Successfully Updated' });
        this.glbkeywordsgst=""
        this.keywords=[]
        this.Image_Edit_form.reset()
      }
    })
  }

  getallkeyword(){
    this.dashboadservice.getkeywords("").subscribe((data:any[])=>{
      if(data.length>0){
        this.allkeywords=data
      }
    })
  }

  filterkeyword(e){
    if(e.query !=""){
      this.glbkeywordsgst=e.query
      this.dashboadservice.getkeywords(this.glbkeywordsgst).subscribe((data:any[])=>{
        if(data.length>0){
          this.allkeywords=data
        }
      })
    }
    
    let query = e.query;
    this.filteredkeywords = this.filterCountry(query, this.allkeywords);
        
  }

  filterCountry(query, countries: any[]):any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    for(let i = 0; i < countries.length; i++) {
        let country = countries[i];
        if(country.keywordname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(country);
        }
    }
    return filtered;
}
}

import { Component, OnInit } from '@angular/core';
import { PasswordService } from 'src/app/services/settings_service/password.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  oldpassword:string
  newpassword:string
  Cnewpassword:string
  userid:number

  constructor(private passwordservice:PasswordService,private messageService: MessageService,) { }

  ngOnInit() {

this.userid=JSON.parse(localStorage.getItem('userdetails'))[0].UserId
  }

  Submit(){

  if(this.newpassword==this.Cnewpassword){
    this.passwordservice.ChangePassword(this.oldpassword,this.userid,this.newpassword).subscribe((data:any)=>{
      if(data!=null){
        if(data=='Old Password Does Not Exist'){
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn  Message', detail: 'Old Password Does Not Exist' }); 
        }
        else if(data=='Password Updated'){
          this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Update Successfully' });
          this.Cnewpassword=''
          this.oldpassword=''
          this.newpassword=''
        
        }
        
      }
     
    })
  }
  else{
    this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn  Message', detail: 'Password not Matched' });
  }

  }

}

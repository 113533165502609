import { Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "primeng/table";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-loging-clip",
  templateUrl: "./loging-clip.component.html",
  styleUrls: ["./loging-clip.component.css"],
  providers: [DatePipe],
})
export class LogingClipComponent implements OnInit {
  @ViewChild("dt", { static: false }) dt: Table;
  constructor(
    private fb: FormBuilder,
    private dashboarservice: DashboardService,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService,
    private datePipe: DatePipe
  ) {}
  showdialog: boolean = false;
  editdialog: boolean = false;
  userlogdata: any;
  permissionlist: any[] = [];
  taskpermission: number = 0;
  getlogUserdatalist: any;
  addissueVolume: boolean = false;
  Start_Date: any;
  defaultdate: any;
  defaultdates: any;
  historydiv: boolean = false;
  logingclipform: FormGroup;
  Selectedstatus: any;
  rangeDates!: Date[];
  selectedstartdate: any;
  selectedenddate: any;
  user_id: any;
  clip_iddata: any;
  addpermission: boolean = false;
  ngOnInit() {
    this.logingclipform = this.fb.group({
      clipname: ["", [Validators.required]],
      source: ["", Validators.required],
      location: ["", Validators.required],
      description: [""],
    });
    this.user_id = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 147) {
        this.taskpermission = 1;
        // console.log("permission 1")
      }
      if (this.permissionlist[i].taskid == 146) {
        this.addpermission = true;
        // console.log("permission 2")
      }
    }
    // this.defaultdateissue();
    this.getlogUserdata();
  }

  get f() {
    return this.logingclipform.controls;
  }
  defaultdateissue() {
    // setTimeout(() => {
    let today = new Date();
    let currentHour = today.getHours();
    let day = today.getDate() - 1;
    let month = today.getMonth();
    let year = today.getFullYear();
    this.defaultdate = `${day}/${month}/${year}`;
    this.defaultdates = `${day}/${month}/${year}`;
    this.Start_Date = new Date(today.toDateString());
    this.fromdate = this.datePipe.transform(
      this.Start_Date,
      "yyyy-MM-dd HH:mm:ss"
    );
    // let todate=this.defaultdates.transform(this.Start_Date, 'yyyy-MM-dd HH:mm:ss')
    // console
  }
  fromdate: any;
  updateDate() {
    this.fromdate = this.datePipe.transform(
      this.Start_Date,
      "yyyy-MM-dd HH:mm:ss"
    );
    // let today = new Date(this.Start_Date);

    // today.setDate(today.getDate() + 1);
    // let day = today.getDate() + 1;
    // let month = today.getMonth();
    // let year = today.getFullYear();
    // this.defaultdate = `${day}/${month}/${year}`;
    // this.defaultdates = `${day}/${month}/${year}`;
    // this.End_Date = new Date(today.toDateString())
    // console.log(this.Start_Date, this.End_Date, "Image Scanned");
    setTimeout(() => {
      // this.tableData();
      this.getlogUserdata();
    }, 100);
  }
  // logingclipform:FormGroup=new FormGroup({
  //   id:new FormControl('',),
  //   userName:new FormControl(''),
  //   clipname:new FormControl('',[Validators.required]),
  //   source:new FormControl('', [Validators.required]),
  //   location:new FormControl('', [Validators.required]),
  //   description:new FormControl('')
  // })
  logingclipreject: FormGroup = new FormGroup({
    reason: new FormControl(""),
  });
  updatelogdata() {
    if (this.clip_iddata) {
      var description = this.logingclipform.value.description;
      this.dashboarservice
        .updateuserlog(description, this.clip_iddata)
        .subscribe((data) => {
          if (data) {
            this.editdialog = false;
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Updated Successfully",
            });
            this.getlogUserdata();
            this.logingclipform.reset();
          }
        });
      console.log("update data");
    }
  }

  uploadlogingdata() {
    var data = this.logingclipform.value;
    if (this.logingclipform.invalid) {
      // Mark all controls as touched to trigger validation messages
      this.logingclipform.markAllAsTouched();
      return;
    } else {
      this.dashboarservice.Loguserdata(data).subscribe((data) => {
        if (data) {
          console.log(data);
          this.showdialog = false;
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "New log Created Successfully",
          });
          this.getlogUserdata();
          this.clip_iddata = "";
        }
      });
    }
  }

  cols: any[] = [
    { header: "Sl No", field: "iddata" },
    { header: "Name of Clips", field: "cilp_name" },
    { header: "Source", field: "source" },
    { header: "Location", field: "location" },
    { header: "User Name", field: "username" },
    { header: "Description", field: "description" },
    { header: "Date", field: "transaction_time" },
    { header: "Status", field: "status" },
  ];

  getrangedata() {
    // if (this.rangeDates[0] != null && this.rangeDates[1] != null) {
    var fromDate = this.datePipe.transform(
      this.rangeDates[0],
      "yyyy-MM-dd HH:mm:ss"
    );
    var toDate = this.datePipe.transform(
      this.rangeDates[1],
      "yyyy-MM-dd HH:mm:ss"
    );
    this.selectedstartdate = fromDate;
    this.selectedenddate = toDate;
    console.log(fromDate);
    if (fromDate != null && toDate != null) {
      this.getlogUserdata();
    }
  }

  getlogUserdata() {
    if (
      this.Selectedstatus == "undefined" ||
      this.Selectedstatus == "" ||
      this.Selectedstatus == null ||
      this.Selectedstatus == "All"
    ) {
      this.dashboarservice
        .getlogUserdata(
          this.taskpermission,
          this.selectedstartdate,
          this.selectedenddate
        )
        .subscribe((data: any) => {
          console.log(data);
          this.getlogUserdatalist = data;
        });
    } else {
      var status = this.Selectedstatus;
      this.dashboarservice
        .getlogUserdataonstatus(
          this.taskpermission,
          status,
          this.selectedstartdate,
          this.selectedenddate
        )
        .subscribe((data: any) => {
          console.log(data);
          this.getlogUserdatalist = data;
        });
    }
  }

  onSearch(value: string) {
    if (value.length >= 3) {
      this.dt.filterGlobal(value, "contains");
    } else {
      this.dt.filterGlobal("", "contains"); // Reset filter when input length is less than 3
    }
  }

  show() {
    this.logingclipform.reset();
    this.showdialog = true;
  }

  displayRejectDialog: boolean = false;

  clip_id: any;
  onPendingClick(item: any, field: string) {
    this.clip_id = item.id;
    this.confirmationService.confirm({
      message: "Are you sure want to Approve?",
      header: "Confirmation",
      icon: "pi pi-question-circle",
      acceptLabel: "Approve",
      rejectLabel: "Reject",
      accept: () => {
        item[field] = "A";
        var status = "A";
        this.dashboarservice
          .updatestatus(status, this.clip_id)
          .subscribe((data) => {
            if (data) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Approve Successfull",
              });
            }
          });
      },
      reject: (e) => {
        // console.log(this);
        if ((event.target as HTMLElement).innerText === "Reject") {
          this.openAdditionalDialog();
        }
      },
    });
  }

  onSelectChange(e: any) {
    // var status=e.target.value;
    this.Selectedstatus = e.target.value;
    this.getlogUserdata();
    console.log(status, "status is");
  }

  searItem(event: any) {
    this.dt.filterGlobal(event, "contains");
    this.getlogUserdatalist = this.dt.filteredValue.length;
  }

  openAdditionalDialog() {
    this.logingclipreject.reset();
    this.displayRejectDialog = true;
  }
  rejectclip() {
    var clip_id = this.clip_id;
    var status = "R";
    var caption = this.logingclipreject.value.reason;
    this.dashboarservice
      .updatestatusreject(status, clip_id, caption)
      .subscribe((data) => {
        if (data) {
          this.displayRejectDialog = false;
          this.messageservice.add({
            key: "message",
            severity: "error",
            summary: "Warning",
            detail: "Clip Rejected",
          });
          this.getlogUserdata();
        }
      });
  }

  approveddata: any;
  openHistory(e) {
    var clip_id = e.id;
    console.log(e);
    this.historydiv = true;
    this.dashboarservice
      .approverejecthistory(clip_id)
      .subscribe((data: any) => {
        var data = data[0];
        var approved_time = data.approved_time;
        var formattedDate = this.datePipe.transform(
          approved_time,
          "yyyy-MM-dd HH:mm:ss"
        );
        this.approveddata = `Approved By ${data.updated_by_user_name} at ${formattedDate}`;
        console.log(data);
      });
  }
  openHistoryreject(e) {
    var clipname = e.id;
    console.log(e);
    this.historydiv = true;
    this.dashboarservice
      .approverejecthistory(clipname)
      .subscribe((data: any) => {
        var data = data[0];
        var approved_time = data.reject_time;
        var formattedDate = this.datePipe.transform(
          approved_time,
          "yyyy-MM-dd HH:mm:ss"
        );
        this.approveddata = `Rejected By ${data.rejected_by_user_name} at ${formattedDate} Reason is ${data.comment}`;
        console.log(data);
      });
  }
  editlog(event: any) {
    console.log("event is");
    this.editdialog = true;
    this.clip_iddata = event.id;
    this.logingclipform.patchValue({
      clipname: event.cilp_name,
      source: event.source,
      location: event.location,
      description: event.description,
    });
    console.log("show dialog is", this.showdialog);
  }
}

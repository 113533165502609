import { Injectable } from '@angular/core';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
import { HttpClient, HttpResponse, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { cartmodel } from 'src/app/model/file/cartmodel';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageuploadService {

  imageuploadformModel:ImageUploadFormModel=new ImageUploadFormModel()

  constructor(private httpclient:HttpClient,private http:HttpClient) { }
  baseurl=environment.ApiUrl;

  AddNewImage(imageuploadinfo:ImageUploadFormModel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "imageupload/insertImageData",imageuploadinfo)
  }
   getmetadata(nme):Observable<any[]>{
     return this.httpclient.post<any[]>(this.baseurl+"imageupload/metadata1",{itemfiename:nme,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
   }

   FinalUpload(files):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "imageupload/FinalUpload",{file:files,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  GetAllImages(limit,type):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getAllImages",{limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,type:type,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  SearchAllImages(imageserachinfo:ImageUploadFormModel):Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/SearchImages",imageserachinfo)
  }

  advancesearch(srchtxt,limit,type):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/advancesearch",{srchtxt:srchtxt,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,type:type,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  
  getTodayImages():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/getTodayImages")
  }
  SearchByFields(Searchstring,type,limit,imagetype):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/SearchByFields",{Searchstring:Searchstring,type:type,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,imagetype:imagetype,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  UpdateImages(imagesinfo:ImageUploadFormModel):Observable<ImageUploadFormModel[]>{
    return this.httpclient.post<ImageUploadFormModel[]>(this.baseurl+"imageupload/updateimages",imagesinfo)
  }
  // downloadFile(path,nme):Observable<any[]>{
  //   return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadImages",{filepath:path,itemfiename:nme})
  // }
  CropFile(path,nme):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/Cropimages",{filepath:path,itemfiename:nme,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  Testdownload(filename,attachmentpath){
    return this.httpclient.post<any>(this.baseurl+"imageupload/testdownload",{imagename:filename,attachmentpath:attachmentpath,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  approveimg(lst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/approveimg",{lst:lst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  testzipdownload(filename):Observable<any[]>{
    return this.httpclient.post<any>(this.baseurl+"imageupload/testzipdownload",{filename:filename,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
//this API is not working
  deletezip(filename):Observable<any[]>{
    return this.httpclient.post<any>(this.baseurl+"imageupload/deletezip",{cartname:filename,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  TrashImages(itemid:number):Observable<ImageUploadFormModel[]>{
    return this.httpclient.post<ImageUploadFormModel[]>(this.baseurl + "imageupload/deleteImages",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deletemultipleimg(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/deletemultipleimg",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  GetDownloadedImageData(itemid:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getdownloadimagedata",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  downloadentry(itemid,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadentry",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocart(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocart",{cartname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocartmul(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocartmul",{cartname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addacl(userid,idlst,date):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/addacl",{idlst:idlst,date:date,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  removeacl(userid,idlst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/removeacl",{idlst:idlst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  bindcart(itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/bindcart",{itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  downloadcart(cartid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadcart",{cartid:cartid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  //this function is not working
downloadmultipleimg(imageid):Observable<any[]>{
  return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadmultipleimg",{imageid:imageid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
}

downloadmultiplefn(arr):Observable<Blob>{
  return this.httpclient.post(this.baseurl+"imageupload/downloadmultiplefn",{arr:arr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId},{responseType: "blob"})
}

  removefromcart(cartdata:cartmodel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/removefromcart",cartdata)
  }
  getResults(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/getautodata",{userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

 

  assigncart(cartdata:any,assigntoid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/assigncart",{cartdata,assigntoid:assigntoid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  //this function not working
  getcartdata(id,itemtype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getcartdata",{itemid:id,itemtype:itemtype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  getitems(itemid,path):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getitem",{itemid:itemid,path:path,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getncount(srchtxt,type,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getncount",{Searchstring:srchtxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,type:type,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  
  getacount(srchtxt,type,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getacount",{srchtxt:srchtxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,type:type,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  getitemtypes():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/getitemtype")
  }


  //cart//
  //this function is not working 
  downloadmultiplefile(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadmultiplefile",{id:id})
  }
  //this function is not working 
  downloadmultiplefnfile(arr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/downloadmultiplefnfile",{arr:arr})
  }

  Createrelation(relationinfo):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "relation/insertrelation",relationinfo)
  }
  Deleterelation(id:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "relation/deleterelation",{id:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  Updaterelation(relationinfo):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "relation/updaterelation",relationinfo)
  }
  checkduplicaterelation(relationinfo):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + "relation/checkduplicate",relationinfo)
  }

  getrelationsrch(groupname):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/searchrelation",{groupname:groupname,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  allrealtionlist():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"relation/allrealtionlist")
  }

  setrealtionshipmul(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/setrelationship",{relationname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  setrealtionshipall(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/setrelationshipmul",{relationname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  checkrelationid(cartname):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/getrelationid",{groupname:cartname,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  bindprocessfiles():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/bindprocessfiles")
  }
  reorderprocesslist(list):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/reorderprocesslist",{list:list,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  onRowReorderDownloadList(list):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/onRowReorderDownloadList",{list:list,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  onRowReorderApprove(list):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/onRowReorderApprove",{list:list,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})

  }
  bindprocesslowresfiles():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/bindprocesslowresfiles")
  }
  reorderlowreslist(list):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/reorderlowreslist",{list:list,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  deleteduplicatelist(id,name):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/deleteduplicatelist",{id:id,name:name,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  Downloaddeletelist(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/Downloaddeletelist",{id:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  } 
  bindprocesslogfiles():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/bindprocesslogfiles")
  }
  getNotificationApproval():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/getNotificationApproval")
  }
  getNotificationApprovalforManager():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/getNotificationApprovalforManager")
  }
  getApprovalLogs():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/getApprovalLogs")
  }
  getNotificationApprovalforUser():Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/getNotificationApprovalforUser",{userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  updateImageApprovalStatus(imageId,status,rejectionRemark):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/updateImageApprovalStatus",{imageId:imageId, status:status,rejectionRemark:rejectionRemark,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  bindapporvefiles():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/bindapporvefiles")
  }
  deleteapprovelist(id,name):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/deleteapprovelist",{id:id,name:name,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  approveDelete(id):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/approveDeletelist",{id:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  bindexportfiles():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"imageupload/bindexportfiles")
  }
  //cart//

  deleteImageRstr(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/deleterestoredimages",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  restoreImage(itemid,type){
    return this.httpclient.post<any[]>(this.baseurl+"imageupload/restore",{itemid:itemid,type:type,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from './files/dashboard.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   key = "assets365";
  encryptData(data) {
    let encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encryptedString.toString();
  }


decryptData(encrypted){
  var decrypted = CryptoJS.AES.decrypt(encrypted, this.key, {
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  return decrypted.toString(CryptoJS.enc.Utf8)
}


constructor(private myRoute: Router, public dashser: DashboardService) { }
sendToken(userdetails: any, userpermission: any, userperference: any, guid: any) {
 // let userdetails = this.encryptData(userdetail);

  localStorage.setItem("userdetails", JSON.stringify(userdetails))
  localStorage.setItem("userpermissions", JSON.stringify(JSON.parse(userpermission.split("~")[0])))
  localStorage.setItem("editionpermissions", JSON.stringify(JSON.parse(userpermission.split("~")[1])))
  localStorage.setItem("userperference", JSON.stringify(userperference))
  localStorage.setItem("guid", guid)
}

getToken() {
  //let userdetails = localStorage.getItem("userdetails")
  
  //let decryptedData = this.decryptData(userdetails.substr(1, userdetails.length-2));
  //userdetails = JSON.parse(decryptedData)
  // console.log("userdetails",userdetails);

  return localStorage.getItem("userdetails");
}
isLoggednIn() {
  return this.getToken() !== null;
}
logout() {
  // let userdetails = localStorage.getItem("userdetails")
  // let decryptedData = this.decryptData(userdetails.substr(1, userdetails.length-2));
  // var userid = JSON.parse(decryptedData)[0].UserId
  // console.log(userid);
  
  var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId
  this.dashser.createlog(userid, "Logout", userid, "master_user").subscribe((data: any[]) => {});
  localStorage.removeItem("userdetails");
  localStorage.removeItem("userpermissions");
  localStorage.removeItem("userperference");
  localStorage.removeItem("tabperstring");
  localStorage.removeItem("cartooncount");
  localStorage.removeItem("illustrationcount");
  localStorage.removeItem("graphicscount");
  localStorage.removeItem("videocount");
  localStorage.removeItem("documentcount");
  localStorage.removeItem("imagecount");
  localStorage.removeItem("totalcount");
  localStorage.removeItem("editionpermissions");
  localStorage.removeItem("token");
  localStorage.removeItem("authToken");
  localStorage.removeItem("guid");
  localStorage.removeItem("storycount");
  localStorage.removeItem("audiocount");
  localStorage.removeItem("HRDocs");
  localStorage.removeItem("viewPreferenceHR");
  localStorage.removeItem("uniqueNumber");

  this.myRoute.navigate(["login"]);
}
}


import { Injectable} from '@angular/core';

declare var webkitSpeechRecognition: any;
@Injectable({
  providedIn: 'root'
})

export class SpeechTextService {

  recognition =  new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  text = '';
  tempWords;
  speech = new SpeechSynthesisUtterance();
  
  constructor() { }

  init() {

    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
     // console.log(transcript);
    });
  }

  start() {
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
   //console.log("Speech recognition started")
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
     //  console.log("End speech recognition")
      } else {
        this.wordConcat()
        this.recognition.start();
      }
    });
  }

  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat()
    this.recognition.stop();
  //  console.log("End speech recognition")
    this.text = ""
  }

  wordConcat() {
    this.text = this.text + ' ' + this.tempWords;
    this.tempWords = '';
  // console.log("text serivce",this.text);
    
  }


  // textSpeech(message) {
  //   var speech = new SpeechSynthesisUtterance();
  
  //   // Set the text and voice attributes.
  //   speech.text = message;
  //   speech.volume = 1;
  //   speech.rate = 1;
  //   speech.pitch = 1;
  //    speech.lang = 'en-US';
  //   window.speechSynthesis.speak(speech);
  // }

  textSpeech(message) {
    
    // Set the text and voice attributes.
    this.speech.text = message;
    this.speech.volume = 1;
    this.speech.rate = 1;
    this.speech.pitch = 1;
     this.speech.lang = 'en-US';
    window.speechSynthesis.speak(this.speech);
  }


  stopSpeak(event){
    this.speech.onpause=() => { }
  }

getVoices = () => {
    return new Promise((resolve) => {
      let voices = speechSynthesis.getVoices()
      if (voices.length) {
        resolve(voices)
        return
      }
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices()
        resolve(voices)
      }
    })
  }
}

import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { ImageUploadFormModel } from 'src/app/model/file/imgaeuploadformModel';
// import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { GraphicsUploadComponent } from '../graphics-upload/graphics-upload.component';
import { Router } from '@angular/router';
// import { AgencyimagesComponent } from '../../agencyimages/agencyimages.component';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { environment } from 'src/environments/environment';
import { PermissionModel } from 'src/app/model/master/Permission.model';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { PublicationService } from 'src/app/services/masters/publication.service';
import { DocumentuploadService } from 'src/app/services/files/documentupload.service';

@Component({
  selector: 'app-search-graphics',
  templateUrl: './search-graphics.component.html',
  styleUrls: ['./search-graphics.component.css']
})
export class SearchGraphicsComponent implements OnInit {

  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  Searching_image_form: FormGroup
  categoryid: number = 0
  imageSearchlist: ImageUploadFormModel[] = []
  eventlist: Eventformmodel[] = []
  totalcount1: string = ''
  keywordtype: string = "like"
  keyword: any[] = []
  descriptiontype: string = "like"
  description: string
  magsrchtype: string = "or"
  magsectiontype: string = "like"
  magsection: string
  titletype: string = "like"
  title: string
  sourcetype: string = "like"
  source: string
  photogrtype: string = "like"
  photographer: string
  authortype: string = "like"
  author: string
  resolutiontype: string = "greater"
  resolution: string
  categorytype: string = "like"
  category: string
  countrytype: string = "like"
  country: string
  statetype: string = "like"
  state: string
  citytype: string = "like"
  city: string
  yeartype: string = "equal"
  year: string
  startdate: string
  enddate: string
  imgorientation: string
  acltype: string
  rating: string
  filenametype: string = "like"
  filename: string
  keywordsrchtype: string = "or"
  descsrchtype: string = "or"
  titlesrchtype: string = "or"
  sourcesrchtype: string = "and"
  photographersrchtype: string = "and"
  authorsrchtype: string = "and"
  resolutionsrchtype: string = "and"
  catsrchtype: string = "and"
  countrysrchtype: string = "and"
  statesrchtype: string = "and"
  citysrchtype: string = "and"
  datesrchtype: string = "and"
  ratingsrchtype: string = "and"
  filenamesrchtype: string = "or"
  yearsrchtype: string = "and"
  datetype: string = "shootdate"
  formatsrchtype: string = "and"
  formattype: string = "equal"
  format: string = "null"
  creatorsrchtype: string = "and"
  creatortype: string = "equal"
  creator: string = "null"
  statussrchtype: string = "and"
  statustype: string = "equal"
  status: string = "All"
  itemformats: any[] = []
  permissionlist: PermissionModel[]
  approvebtn: number = 0
  typediv: number = 0
  keywordsdiv: number = 0
  descriptiondiv: number = 0
  titlediv: number = 0
  filenamediv: number = 0
  sourcediv: number = 0
  photographerdiv: number = 0
  authordiv: number = 0
  resolutiondiv: number = 0
  categorydiv: number = 0
  countrydiv: number = 0
  statediv: number = 0
  citydiv: number = 0
  yeardiv: number = 0
  filestatusdiv: number = 0
  datediv: number = 0
  orientationdiv: number = 0
  acldiv: number = 0
  ratingdiv: number = 0
  creatordiv: number = 0
  magsectiondiv: number = 0
  storytypediv: number = 0
  publicationdiv: number = 0
  filteredkeywords: any[];
  allkeywords: any[]
  glbkeywordsgst: string = ""
  keywords: any[] = []
  userlist: any[] = []
  pdftysrchtype: string = "and"
  pdftytype: string = "equal"
  pdftype: string = "null"
  pdfformat: any[] = []

  pubsrchtype: string = "and"
  pubsrtype: string = "equal"
  pubid: string = "null"
  publist: any[] = []
  relationlist: any[] = []
  grpsrchtype: string = "and"
  grptype: string = "equal"
  grpid: any = "null"
  filteredrel: any[];
  relationdiv: number = 0
  reftype: string = "like"
  reference: string
  refsrchtype: string = "and"
  transref: number = 0

  constructor(private imageUplaodService: ImageuploadService, private categoryService: CategoryService, public publicservice: PublicationService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private messageservice: MessageService, private sanitizer: DomSanitizer,
    // public imguploadComp:ImageUploadComponent,
    public graphicsuploadComp: GraphicsUploadComponent,
    private route: Router, private imageservice: ImageuploadService, public dashboadservice: DashboardService, private documentser: DocumentuploadService) { }

  ngOnInit() {
    this.checkpermissions()
    //this.CreateImageSearchForm()
    //this.getcategorydata()
    //this.filestatus()
    //this.getevent()
    // this.keyword=this.imguploadComp.Searchbyfield
    // this.title=this.imguploadComp.Searchbyfield
    // this.description=this.imguploadComp.Searchbyfield
    this.getitemtype()
    this.allrealtionlist()
    //this.getallkeyword()
    //this.getallusers()
    //this.getpublications()
    //this.getpdftype()
    let getusersub = this.dashboadservice.getuserlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.userlist = data
      }
      getusersub.unsubscribe()
    })
    let pdftypesub = this.dashboadservice.getpdftypedata().subscribe((data1: any[]) => {
      if (data1.length > 0) {
        this.pdfformat = data1
      }
      pdftypesub.unsubscribe()
    })

    let getpublicationsub = this.dashboadservice.getpublicationdata().subscribe((data2: any[]) => {
      if (data2.length > 0) {
        this.publist = data2
      }
      getpublicationsub.unsubscribe()
    })
    let keywordsub = this.dashboadservice.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
  }

  getpublications() {
    this.publicservice.getAllPublication().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.publist = data
      }
    })
  }
  getpdftype() {
    this.documentser.getpdftypes().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.pdfformat = data
      }
    })
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"))
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
      if (this.permissionlist[i].taskid == 67) {
        this.typediv = 1;
      }
      if (this.permissionlist[i].taskid == 68) {
        this.keywordsdiv = 1;
      }
      if (this.permissionlist[i].taskid == 69) {
        this.descriptiondiv = 1;
      }
      if (this.permissionlist[i].taskid == 70) {
        this.titlediv = 1;
      }
      if (this.permissionlist[i].taskid == 71) {
        this.filenamediv = 1;
      }
      if (this.permissionlist[i].taskid == 72) {
        this.sourcediv = 1;
      }
      if (this.permissionlist[i].taskid == 73) {
        this.photographerdiv = 1;
      }
      if (this.permissionlist[i].taskid == 74) {
        this.authordiv = 1;
      }
      if (this.permissionlist[i].taskid == 75) {
        this.resolutiondiv = 1;
      }
      if (this.permissionlist[i].taskid == 76) {
        this.categorydiv = 1;
      }
      if (this.permissionlist[i].taskid == 77) {
        this.countrydiv = 1;
      }
      if (this.permissionlist[i].taskid == 78) {
        this.statediv = 1;
      }
      if (this.permissionlist[i].taskid == 79) {
        this.citydiv = 1;
      }
      if (this.permissionlist[i].taskid == 80) {
        this.yeardiv = 1;
      }
      if (this.permissionlist[i].taskid == 81) {
        this.filestatusdiv = 1;
      }
      if (this.permissionlist[i].taskid == 82) {
        this.datediv = 1;
      }
      if (this.permissionlist[i].taskid == 83) {
        this.orientationdiv = 1;
      }
      if (this.permissionlist[i].taskid == 84) {
        this.acldiv = 1;
      }
      if (this.permissionlist[i].taskid == 85) {
        this.ratingdiv = 1;
      }
      if (this.permissionlist[i].taskid == 90) {
        this.creatordiv = 1;
      }
      if (this.permissionlist[i].taskid == 86) {
        this.magsectiondiv = 1;
      }

      if (this.permissionlist[i].taskid == 87) {
        this.publicationdiv = 1;
      }
      if (this.permissionlist[i].taskid == 96) {
        this.relationdiv = 1;
      }
      if (this.permissionlist[i].taskid == 97) {
        this.transref = 1;
      }
      if (this.permissionlist[i].taskid == 99) {
        this.storytypediv = 1;
      }
    }
  }

  getitemtype() {
    this.imageUplaodService.getitemtypes().subscribe((data: any[]) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          var l = data[i].typeformat.split(",")
          for (let j = 0; j < l.length; j++) {
            this.itemformats.push({ id: data[i].typeformat.split(",")[j].trim().substring(1, data[i].typeformat.split(",")[j].length), value: data[i].typeformat.split(",")[j].trim().substring(1, data[i].typeformat.split(",")[j].length) })
          }
        }
      }
    })
  }

  CreateImageSearchForm() {
    this.Searching_image_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      AvgBitrate: [null],
      startdate: [null],
      endDate: [null],
      orgfilename: [null],
      framerate: [null],
      Searching_Method: ['n'],
      Width: [null],
      Height: [null],
      File_Status: ['0'],
      orientationtype: [null],
      event: [null],
      type: [""],
      totalcount: [""]
    })

  }

  @Input()
  selectValue: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectValue']) {
      //this.keyword=this.selectValue
      this.title = this.selectValue
      this.description = this.selectValue
      this.filename = this.selectValue
    }
    let getusersub = this.dashboadservice.getuserlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.userlist = data
      }
      getusersub.unsubscribe()
    })
    let pdftypesub = this.dashboadservice.getpdftypedata().subscribe((data1: any[]) => {
      if (data1.length > 0) {
        this.pdfformat = data1
      }
      pdftypesub.unsubscribe()
    })

    let getpublicationsub = this.dashboadservice.getpublicationdata().subscribe((data2: any[]) => {
      if (data2.length > 0) {
        this.publist = data2
      }
      getpublicationsub.unsubscribe()
    })
    let keywordsub = this.dashboadservice.getkeywordlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
      keywordsub.unsubscribe()
    })
  }


  //this function is not working
  SearchAllImages(formvalues, type) {

    var fn = ""
    if (this.Searching_image_form.dirty) {
      fn = "fn"
    }
    if (this.imgorientation != null) {
      fn = "fn"
    }
    formvalues.value.orientationtype = this.imgorientation
    if (formvalues.value.totalcount == null) {
      formvalues.value.totalcount = ""
    }
    if (this.totalcount1 == "") {
      formvalues.value.totalcount = ""
    }
    formvalues.value.type = type
    if (type == "") {
      this.totalcount1 = ""
      // this.imguploadComp.SearchAllImages1(formvalues.value,fn)
      this.graphicsuploadComp.SearchAllImages1(formvalues.value, fn)
    }
    else {
      if (formvalues.itemkeyword != null) {
        formvalues.itemkeyword = formvalues.itemkeyword.replace(new RegExp(",", 'g'), " ")
      }
      if (formvalues.CategoryId == 'null') {
        formvalues.CategoryId = null
        formvalues.SubCategoryId = null
      }

      this.imageservice.SearchAllImages(formvalues.value).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount1 = data[0].rowcount
          this.Searching_image_form.patchValue({ totalcount: this.totalcount1 })
        }
        else {
          this.totalcount1 = "0"
        }
      })
    }

  }
  clearform() {
    this.Searching_image_form.reset()
    this.imgorientation = null
    this.Searching_image_form.patchValue({ Searching_Method: 'n' })
    this.Searching_image_form.patchValue({ File_Status: '0' })
    this.totalcount1 = ''
  }

  clearform1() {
    this.totalcount1 = ""
    this.keywordtype = "like"
    this.keyword = []
    this.descriptiontype = "like"
    this.description = ""
    this.titletype = "like"
    this.title = ""
    this.filename = ""
    this.filenametype = "like"
    this.sourcetype = "like"
    this.source = ""
    this.photogrtype = "like"
    this.photographer = ""
    this.authortype = "like"
    this.author = ""
    this.resolutiontype = "greater"
    this.resolution = ""
    this.categorytype = "like"
    this.category = ""
    this.countrytype = "like"
    this.country = ""
    this.statetype = "like"
    this.state = ""
    this.citytype = "like"
    this.city = ""
    this.startdate = ""
    this.enddate = ""
    this.imgorientation = ""
    this.acltype = ""
    this.rating = ""
    this.yeartype = "equal"
    this.year = ""
    this.keywordsrchtype = "or"
    this.descsrchtype = "or"
    this.titlesrchtype = "or"
    this.filenamesrchtype = "or"
    this.sourcesrchtype = "and"
    this.photographersrchtype = "and"
    this.authorsrchtype = "and"
    this.resolutionsrchtype = "and"
    this.catsrchtype = "and"
    this.countrysrchtype = "and"
    this.statesrchtype = "and"
    this.citysrchtype = "and"
    this.datesrchtype = "and"
    this.ratingsrchtype = "and"
    this.yearsrchtype = "and"
    this.datetype = "shootdate"
    this.formatsrchtype = "and"
    this.formattype = "equal"
    this.format = "null"
    this.creatorsrchtype = "and"
    this.creatortype = "equal"
    this.creator = "null"
    this.statussrchtype = "and"
    this.statustype = "equal"
    this.status = "All"
    this.pdftysrchtype = "and"
    this.pdftytype = "equal"
    this.pdftype = "null"
    this.pubsrchtype = "and"
    this.pubsrtype = "equal"
    this.pubid = "null"
    this.grpsrchtype = "and"
    this.grptype = "equal"
    this.grpid = "null"
    this.reftype = "like"
    this.reference = ""
    this.refsrchtype = "and"
    this.magsrchtype = "or"
    this.magsectiontype = "like"
    this.magsection = ""
    environment.glbsearchstring = ""
    environment.globalsearchtext = ""
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.categorylist = data;
      }

    })

  }

  allrealtionlist() {
    this.imageUplaodService.allrealtionlist().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.relationlist = data
      }
      else {
        this.relationlist = []
      }
    })
  }
  filestatus() {
    //this.userinfomation=new userformmodel();
    this.imageUplaodService.GetAllImages('0', "graphics").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.imageSearchlist = data;
      }

    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data.length > 0) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getevent() {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getevent().subscribe((data: Eventformmodel[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }

    })
  }


  advancesearch(type) {
    var srchtxt = ""
    var keystring = ""
    var descstring = ""
    var magstring = ""
    var titlestring = ""
    var filenaestring = ""
    if (this.keyword == null || this.keyword == undefined || this.keyword.length == 0) {

    }
    else {
      for (let i = 0; i < this.keyword.length; i++) {
        if (i == 0) {
          keystring += " ("
          if (this.keywordtype == "like") {
            keystring += " itemkeyword like '%" + this.keyword[i].keywordname + "%' "
          }
          else if (this.keywordtype == "equal") {
            keystring += " itemkeyword = '" + this.keyword[i].keywordname + "' "
          }
          else {
            keystring += " itemkeyword NOT IN ('" + this.keyword[i].keywordname + "') "
          }
        }
        else {
          if (this.keywordtype == "like") {
            keystring += "and  itemkeyword like '%" + this.keyword[i].keywordname + "%' "
          }
          else if (this.keywordtype == "equal") {
            keystring += " and itemkeyword = '" + this.keyword[i].keywordname + "' "
          }
          else {
            keystring += " and itemkeyword NOT IN ('" + this.keyword[i].keywordname + "') "
          }
        }

        if (i == this.keyword.length - 1) {
          keystring += ")"
        }

      }

    }


    if (this.magsection == "" || this.magsection == null || this.magsection == undefined) {

    }
    else {
      var key = this.ExtractSearchingKeyword(this.magsection)
      var keylist = key.split("+")
      for (let i = 0; i < keylist.length; i++) {
        if (i == 0) {
          magstring += this.magsrchtype + " ("
          if (this.magsectiontype == "like") {
            magstring += " composer like '%" + keylist[i] + "%' "
          }
          else if (this.magsectiontype == "equal") {
            magstring += " composer = '" + keylist[i] + "' "
          }
          else {
            magstring += " composer NOT IN ('" + keylist[i] + "') "
          }
        }
        else {
          if (this.magsectiontype == "like") {
            magstring += "and  composer like '%" + keylist[i] + "%' "
          }
          else if (this.magsectiontype == "equal") {
            magstring += " and composer = '" + keylist[i] + "' "
          }
          else {
            magstring += " and composer NOT IN ('" + keylist[i] + "') "
          }
        }

        if (i == keylist.length - 1) {
          magstring += ")"
        }

      }
    }




    if (this.description == "" || this.description == null || this.description == undefined) {

    }
    else {
      var key = this.ExtractSearchingKeyword(this.description)
      var keylist = key.split("+")
      for (let i = 0; i < keylist.length; i++) {
        if (i == 0) {
          descstring += this.descsrchtype + " ("
          if (this.descriptiontype == "like") {
            descstring += " itemcaption like '%" + keylist[i] + "%' "
          }
          else if (this.descriptiontype == "equal") {
            descstring += " itemcaption = '" + keylist[i] + "' "
          }
          else {
            descstring += " itemcaption NOT IN ('" + keylist[i] + "') "
          }
        }
        else {
          if (this.descriptiontype == "like") {
            descstring += "and  itemcaption like '%" + keylist[i] + "%' "
          }
          else if (this.descriptiontype == "equal") {
            descstring += " and itemcaption = '" + keylist[i] + "' "
          }
          else {
            descstring += " and itemcaption NOT IN ('" + keylist[i] + "') "
          }
        }

        if (i == keylist.length - 1) {
          descstring += ")"
        }

      }
    }
    if (this.title == "" || this.title == null || this.title == undefined) {

    }
    else {
      var key = this.ExtractSearchingKeyword(this.title)
      var keylist = key.split("+")
      for (let i = 0; i < keylist.length; i++) {
        if (i == 0) {
          titlestring += this.titlesrchtype + " ("
          if (this.titletype == "like") {
            titlestring += "  title like '%" + keylist[i] + "%' "
          }
          else if (this.titletype == "equal") {
            titlestring += " title = '" + keylist[i] + "' "
          }
          else {
            titlestring += " title NOT IN ('" + keylist[i] + "') "
          }
        }
        else {
          if (this.titletype == "like") {
            titlestring += "and  title like '%" + keylist[i] + "%' "
          }
          else if (this.titletype == "equal") {
            titlestring += " and title = '" + keylist[i] + "' "
          }
          else {
            titlestring += " and title NOT IN ('" + keylist[i] + "') "
          }
        }

        if (i == keylist.length - 1) {
          titlestring += ")"
        }

      }
    }

    if (this.filename == "" || this.filename == null || this.filename == undefined) {

    }
    else {

      var key = this.ExtractSearchingKeyword(this.filename)
      var keylist = key.split("+")
      for (let i = 0; i < keylist.length; i++) {
        if (i == 0) {
          filenaestring += this.filenamesrchtype + " ("
          if (this.filenametype == "like") {
            filenaestring += "  orgfilename like '%" + keylist[i] + "%' "
          }
          else if (this.filenametype == "equal") {
            filenaestring += " orgfilename = '" + keylist[i] + "' "
          }
          else {
            filenaestring += " orgfilename NOT IN ('" + keylist[i] + "') "
          }
        }
        else {
          if (this.filenametype == "like") {
            filenaestring += "and  orgfilename like '%" + keylist[i] + "%' "
          }
          else if (this.filenametype == "equal") {
            filenaestring += " and orgfilename = '" + keylist[i] + "' "
          }
          else {
            filenaestring += " and orgfilename NOT IN ('" + keylist[i] + "') "
          }
        }

        if (i == keylist.length - 1) {
          filenaestring += ")"
        }

      }
    }
    srchtxt = keystring + " " + descstring + " " + titlestring + " " + filenaestring + " " + magstring
    srchtxt = srchtxt.trim()
    if (srchtxt.indexOf("or") == 0) {
      srchtxt = srchtxt.replace("or", "")
    }
    if (srchtxt.indexOf("and") == 0) {
      srchtxt = srchtxt.replace("and", "")
    }
    if (srchtxt.trim() != "") {
      srchtxt = "(" + srchtxt + ")"
    }
    if (this.source == "" || this.source == null || this.source == undefined) {

    }
    else {
      if (this.sourcetype == "like") {
        srchtxt += " " + this.sourcesrchtype + " source like '%" + this.source + "%' "
      }
      else if (this.sourcetype == "equal") {
        srchtxt += " " + this.sourcesrchtype + " source = '" + this.source + "' "
      }
      else {
        srchtxt += " " + this.sourcesrchtype + " source NOT IN ('" + this.source + "') "
      }
    }
    if (this.photographer == "" || this.photographer == null || this.photographer == undefined) {

    }
    else {
      if (this.photogrtype == "like") {
        srchtxt += " " + this.photographersrchtype + " creator like '%" + this.photographer + "%' "
      }
      else if (this.photogrtype == "equal") {
        srchtxt += " " + this.photographersrchtype + " creator = '" + this.photographer + "' "
      }
      else {
        srchtxt += " " + this.photographersrchtype + " creator NOT IN ('" + this.photographer + "') "
      }
    }
    if (this.author == "" || this.author == null || this.author == undefined) {

    }
    else {
      if (this.authortype == "like") {
        srchtxt += " " + this.authorsrchtype + " itemauthor like '%" + this.author + "%' "
      }
      else if (this.authortype == "equal") {
        srchtxt += " " + this.authorsrchtype + " itemauthor = '" + this.author + "' "
      }
      else {
        srchtxt += " " + this.authorsrchtype + " itemauthor NOT IN ('" + this.author + "') "
      }
    }
    if (this.reference == "" || this.reference == null || this.reference == undefined) {

    }
    else {
      if (this.reftype == "like") {
        srchtxt += " " + this.refsrchtype + " centerid like '%" + this.reference + "%' "
      }
      else if (this.reftype == "equal") {
        srchtxt += " " + this.refsrchtype + " centerid = '" + this.reference + "' "
      }
      else {
        srchtxt += " " + this.refsrchtype + " centerid NOT IN ('" + this.reference + "') "
      }
    }

    if (this.creator == "" || this.creator == "null" || this.creator == undefined || this.creator == null) {

    }
    else {
      if (this.creatortype == "equal") {
        srchtxt += " " + this.creatorsrchtype + " updatedby = '" + this.creator + "' "
      }
      else {
        srchtxt += " " + this.creatorsrchtype + " updatedby NOT IN ('" + this.creator + "') "
      }
    }
    if (this.resolution == "" || this.resolution == null || this.resolution == undefined) {

    }
    else {
      if (this.resolutiontype == "greater") {
        srchtxt += " " + this.resolutionsrchtype + " resolution > '" + this.resolution + "' "
      }
      else if (this.resolutiontype == "smaller") {
        srchtxt += " " + this.resolutionsrchtype + " resolution < '" + this.resolution + "' "
      }
      else {
        srchtxt += " " + this.resolutionsrchtype + " resolution = '" + this.resolution + "' "
      }
    }

    if (this.category == "" || this.category == null || this.category == undefined) {

    }
    else {
      if (this.categorytype == "like") {
        srchtxt += " " + this.catsrchtype + " categoryid like '%" + this.category + "%' "
      }
      else if (this.categorytype == "equal") {
        srchtxt += " " + this.catsrchtype + " categoryid = '" + this.category + "' "
      }
      else {
        srchtxt += " " + this.catsrchtype + " categoryid NOT IN ('" + this.category + "') "
      }
    }

    if (this.country == "" || this.country == null || this.country == undefined) {

    }
    else {
      if (this.countrytype == "like") {
        srchtxt += " " + this.countrysrchtype + " countryid like '%" + this.country + "%' "
      }
      else if (this.countrytype == "equal") {
        srchtxt += " " + this.countrysrchtype + " countryid = '" + this.country + "' "
      }
      else {
        srchtxt += " " + this.countrysrchtype + " countryid NOT IN ('" + this.country + "') "
      }
    }

    if (this.state == "" || this.state == null || this.state == undefined) {

    }
    else {
      if (this.statetype == "like") {
        srchtxt += " " + this.statesrchtype + " stateid like '%" + this.state + "%' "
      }
      else if (this.statetype == "equal") {
        srchtxt += " " + this.statesrchtype + " stateid = '" + this.state + "' "
      }
      else {
        srchtxt += " " + this.statesrchtype + " stateid NOT IN ('" + this.state + "') "
      }
    }
    //city//
    if (this.city == "" || this.city == null || this.city == undefined) {

    }
    else {
      if (this.citytype == "like") {
        srchtxt += " " + this.citysrchtype + " cityid like '%" + this.city + "%' "
      }
      else if (this.citytype == "equal") {
        srchtxt += " " + this.citysrchtype + " cityid = '" + this.city + "' "
      }
      else {
        srchtxt += " " + this.citysrchtype + " cityid NOT IN ('" + this.city + "') "
      }
    }
    //city//
    //year//
    if (this.year == "" || this.year == null || this.year == undefined) {

    }
    else {
      if (this.yeartype == "equal") {
        srchtxt += " " + this.yearsrchtype + " Year(shootdate) = '" + this.year + "' "
      }
      else if (this.yeartype == "before") {
        srchtxt += " " + this.yearsrchtype + " shootdate < '" + this.year + "-01-01" + "' "
      }
      else if (this.yeartype == "after") {
        srchtxt += " " + this.yearsrchtype + " shootdate > '" + this.year + "-12-31" + "' "
      }
      else if (this.yeartype == "beforequal") {
        srchtxt += " " + this.yearsrchtype + " shootdate <= '" + this.year + "-12-31" + "' "
      }
      else {
        srchtxt += " " + this.yearsrchtype + " shootdate >= '" + this.year + "-01-01" + "' "
      }
    }

    if (this.format == "" || this.format == "null" || this.format == undefined || this.format == null) {

    }
    else {
      if (this.formattype == "equal") {
        srchtxt += " " + this.formatsrchtype + " itemformat = '" + this.format + "' "
      }
      else {
        srchtxt += " " + this.formatsrchtype + " itemformat NOT IN ('" + this.format + "') "
      }
    }

    if (this.pdftype == "" || this.pdftype == "null" || this.pdftype == undefined || this.pdftype == null) {

    }
    else {
      if (this.pdftytype == "equal") {
        srchtxt += " " + this.pdftysrchtype + " framerate = '" + this.pdftype + "' "
      }
      else {
        srchtxt += " " + this.pdftysrchtype + " framerate NOT IN ('" + this.pdftype + "') "
      }
    }

    if (this.pubid == "" || this.pubid == "null" || this.pubid == undefined || this.pubid == null) {

    }
    else {
      if (this.pubsrtype == "equal") {
        srchtxt += " " + this.pubsrchtype + " channels = '" + this.pubid + "' "
      }
      else {
        srchtxt += " " + this.pubsrchtype + " channels NOT IN ('" + this.pubid + "') "
      }
    }
    if (this.grpid.id == "" || this.grpid.id == "null" || this.grpid.id == undefined || this.grpid.id == null) {

    }
    else {
      if (this.grptype == "equal") {
        srchtxt += " " + this.grpsrchtype + " groupid = '" + this.grpid.id + "' "
      }
      else {
        srchtxt += " " + this.grpsrchtype + " groupid NOT IN ('" + this.grpid.id + "') "
      }
    }

    if (this.status == "" || this.status == undefined || this.status == null || this.status == "All") {

    }
    else {
      if (this.statustype == "equal") {
        srchtxt += " " + this.statussrchtype + " file_status = '" + this.status + "' "
      }
      else {
        srchtxt += " " + this.statussrchtype + " file_status NOT IN ('" + this.status + "') "
      }
    }

    //year//
    if (this.startdate == "" || this.startdate == null || this.startdate == undefined) {

    }
    else {
      srchtxt += " " + this.datesrchtype + " Date(" + this.datetype + ")  >=  '" + this.startdate + "' "

    }
    if (this.enddate == "" || this.enddate == null || this.enddate == undefined) {

    }
    else {
      srchtxt += " " + this.datesrchtype + " Date(" + this.datetype + ")  <=  '" + this.enddate + "' "
    }
    if (this.imgorientation == "" || this.imgorientation == null || this.imgorientation == undefined || this.imgorientation == "null") {

    }
    else {
      srchtxt += " and genre = '" + this.imgorientation + "' "
    }
    if (this.acltype == "" || this.acltype == null || this.acltype == undefined || this.acltype == "null") {

    }
    else {
      srchtxt += " and itempermission = '" + this.acltype + "' "
    }
    if (this.rating == "" || this.rating == null || this.rating == undefined) {

    }
    else {
      srchtxt += " " + this.ratingsrchtype + " itemrating = '" + this.rating + "' "
    }
    if (type == "") {
      this.totalcount1 = ""
      if (srchtxt == "" || srchtxt == null || srchtxt == "null" || srchtxt == undefined) {
        srchtxt = " 1=1  order by " + this.datetype;
      }
      else {
        srchtxt = srchtxt + " order by " + this.datetype
      }
      // this.imguploadComp.advancesearch(srchtxt)
      this.graphicsuploadComp.advancesearch(srchtxt)
    }
    else {
      var tbastr = localStorage.getItem("tabperstring")
      this.imageservice.getacount(srchtxt, "graphics", tbastr).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount1 = (data[0].rowcount).toString()
        }
      })
    }

  }


  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if ((Paramater.indexOf('"') >= 0)) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = (Paramater.indexOf("~", (startval - 1)) + 1);
          if ((startind <= 0)) {
            break;
          }

          endindex = (Paramater.indexOf("~", startind) + 1);
          if ((endindex <= 0)) {
            break;
          }

          if ((Searchingparam.trim() == "")) {
            Searchingparam = Paramater.substring((startind - 1), startind + ((endindex - startind)
              + 1)).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          }
          else {
            Searchingparam = (Searchingparam + "+" + Paramater.substring((startind - 1), startind + ((endindex - startind) + 1))).trim();
            Paramater = Paramater.split(Paramater.substring((startind - 1), startind + ((endindex - startind) + 1))).join("");
          }

          // startval = endindex
        }

        if ((Searchingparam != "")) {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if ((Paramater.trim() != "")) {
          if ((MergeParamater == "")) {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          }
          else {
            MergeParamater = (MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+")));
          }

        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      }
      else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }

    }
    catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  getallkeyword() {
    this.dashboadservice.getkeywords("").subscribe((data: any[]) => {
      if (data.length > 0) {
        this.allkeywords = data
      }
    })
  }

  filterkeyword(e) {
    if (e.query != "") {
      this.glbkeywordsgst = e.query
      this.dashboadservice.getkeywords(this.glbkeywordsgst).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.allkeywords = data
        }
      })
    }

    let query = e.query;
    this.filteredkeywords = this.filterCountry(query, this.allkeywords);

  }

  filterCountry(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.keywordname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }

  getallusers() {
    this.dashboadservice.getallusers().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.userlist = data
      }
      else {
        this.userlist = []
      }
    })
  }

  filterrel(e) {
    if (e.query != "") {
      //this.glbkeywordsgst=e.query
      this.imageUplaodService.getrelationsrch(e.query).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data
        }
      })
    }

    let query = e.query;
    this.filteredrel = this.relfilter(query, this.relationlist);

  }

  relfilter(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.groupname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }
}

export class userformmodel {
    UserId: number=0
    UserFirstName: string=""
    UserMiddleName: string=""
    UserLastName: string=""
    EmpIdORSapId: number=0
    UserType: string=""
    Address: string=""
    Country: string=""
    State: string=""
    City: string=""
    Phone: number=0
    Mobile: number=0
    UserDOB: Date
    UserDOJ: Date
    UserEmailId: string=""
    PoolId: number=0
    CenterId: number=0
    PublicationId: number=0
    RolesId: number=0
    LoginId: string=""
    Password: string=""
    ActiveStatus: boolean
    UserImagePath: string=""
    UserCompleteName: string=""
    updateby:string
    Barcode_image:any
    Hdd_number:any
    Rack_number:any
    Vault_number:any
    id:number
    // serial_number:number

}

import { Component, OnInit } from '@angular/core';
import { LtodiskService } from 'src/app/services/settings_service/ltodisk.service';
@Component({
  selector: 'app-external-device-detail',
  templateUrl: './external-device-detail.component.html',
  styleUrls: ['./external-device-detail.component.css']
})
export class ExternalDeviceDetailComponent implements OnInit {
  ltoList: any[]=[];
  constructor(private ltodiskService:LtodiskService) { }

  ngOnInit() {
    this.ltoDiskList()
  }
  
  ltoDiskList(){
    this.ltodiskService.ltoDiskList().subscribe((data:any)=>{
      //console.log(data);
      this.ltoList = data
      
    })

  }

}

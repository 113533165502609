import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Childcategoryformmodel } from 'src/app/model/master/childCategory.form.model';


import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChildCategoryService {
  Childcategoryformmodel:Childcategoryformmodel=new Childcategoryformmodel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;


  getAllChildCategory():Observable<Childcategoryformmodel[]>{
    return this.httpclient.get<Childcategoryformmodel[]>(this.baseurl+"ChildCategory");
  }
  CreateChildCategory(ChildCategoryinfo:Childcategoryformmodel):Observable<Childcategoryformmodel[]>{
    return this.httpclient.post<Childcategoryformmodel[]>(this.baseurl+ "ChildCategory/insertChildcategory",ChildCategoryinfo);
  }
  getCategoryDropdown():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"ChildCategory/selectcatedropdown");
  }
  getSubCategoryDropdown():Observable<categoryformmodel[]>{
    return this.httpclient.get<categoryformmodel[]>(this.baseurl+"ChildCategory/selectcatedropdownSub");
  }
  UpdateChildCategory(ChildCategoryinfo: Childcategoryformmodel):Observable<Childcategoryformmodel[]>{
    return this.httpclient.post<Childcategoryformmodel[]>(this.baseurl+"ChildCategory/updateChildCategory",ChildCategoryinfo)
  }
  DeleteChildCategory(ChildCategoryId:number):Observable<Childcategoryformmodel[]>{
    return this.httpclient.post<Childcategoryformmodel[]>(this.baseurl+"ChildCategory/deleteChildCategory",{ChildCategoryId:ChildCategoryId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  getChildCategoryData(categoryid:number):Observable<Childcategoryformmodel[]>{
    return this.httpclient.post<Childcategoryformmodel[]>(this.baseurl+"ChildCategory/selectChildCategory",{CategoryId:categoryid})
  }

  checkduplicate(ChildCategoryinfo:Childcategoryformmodel):Observable<Childcategoryformmodel[]>{
    return this.httpclient.post<Childcategoryformmodel[]>(this.baseurl+ "ChildCategory/checkduplicate",ChildCategoryinfo);
  }
  getevent():Observable<Eventformmodel[]>{
    return this.httpclient.get<Eventformmodel[]>(this.baseurl+"event")
  }



}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { CategoryService } from "src/app/services/masters/category.service";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import {
  FormGroup,
  FormBuilder,
  NgForm,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { CountryService } from "src/app/services/masters/country.service";
import { CountryFormModel } from "src/app/model/master/country.formmodel";
import { CityService } from "src/app/services/masters/city.service";
import { stateFormModel } from "src/app/model/master/state.formmodel";
import { CityFormModel } from "src/app/model/master/city.form.model";
import { EventService } from "src/app/services/masters/event.service";
import { Eventformmodel } from "src/app/model/master/event.form.model";
import { MessageService } from "primeng/api";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { FileUpload } from "primeng/primeng";
import { environment } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { VideoUploadComponent } from "../video-upload/video-upload.component";
import { ParsedVariable } from "@angular/compiler";

@Component({
  selector: "app-video-upload-popup",
  templateUrl: "./video-upload-popup.component.html",
  styleUrls: ["./video-upload-popup.component.css"],
})
export class VideoUploadPopupComponent implements OnInit {
  selectedFiles: any[] = [];
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  VideoUploadForm: FormGroup;
  categoryid: number = 0;
  eventlist: Eventformmodel[] = [];
  countrylist: CountryFormModel[] = [];
  Statelist: stateFormModel[] = [];
  //stateid:number=0
  citylist: CityFormModel[] = [];
  imageuploadInformation: ImageUploadFormModel;
  videouploadinformation: VideoUploadModel;
  videouploadlist: VideoUploadModel[] = [];
  imageuploadlist: ImageUploadFormModel[] = [];
  x: string;
  check: boolean = true;
  i: number;
  videopath: string = "";
  videoUrl = environment.ApiUrl;
  display1: boolean = false;
  Vname: string = "";
  videofullname: string = "";
  ApiServerPath = environment.ApiServerPath;
  value: number = 0;
  path: string = "";
  itemtypes: string = "";

  constructor(
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private subcategoryService: SubCategoryService,
    private countryservice: CountryService,
    private cityservice: CityService,
    private eventservice: EventService,
    private messageservice: MessageService,
    private videouploadservice: VideouploadService,
    private sanitizer: DomSanitizer,
    private imageuploadservice: ImageuploadService,
    private videocomponent: VideoUploadComponent,
    private messageService: MessageService
  ) {}

  @ViewChild("video", { static: true }) videoElement: ElementRef;
  @ViewChild("fileInput", { static: true }) fileInput: FileUpload;
  @ViewChild("srtinput", { static: true }) srtinput: FileUpload;
  @ViewChild("uploadbutton", { static: true }) uploadbutton: ElementRef;
  ngOnInit() {
    this.getcategorydata();
    this.CreateVideoUploadForm();
    this.getCountryData();
    this.getEventData();
    this.getitemtype();
  }

  getitemtype() {
    this.videouploadservice.getitemtype().subscribe((data: any[]) => {
      if (data != null) {
        this.itemtypes = data[0].typeformat;
      }
    });
  }

  clear() {
    // this.fileInput._files=[]
    this.VideoUploadForm.reset();
    this.value = 0;
    this.videocomponent.onHide1();
  }

  hide() {
    this.videoElement.nativeElement.src = "";
    this.videopath = "";
  }
  remove(name) {
    for (let i = 0; i < this.fileInput.files.length; i++) {
      if (this.fileInput.files[i].name == name) {
        // this.fileInput._files.splice(i,1)
      }
    }
    this.VideoUploadForm.reset();
    // this.videocomponent.onHide1()
  }

  Videoplayer() {
    this.display1 = true;
    this.videoElement.nativeElement.type = "video/mp4";
    this.videoElement.nativeElement.src = "/assets/video/" + this.videofullname;
  }

  getfilename(e) {
    this.uploadbutton;
    var nme = e.files[0].name;
    this.selectedFiles.push(e.files);
    if (e.files[0].size / 1000 > 1048576) {
      var filesize = (e.files[0].size / 1000 / 1048576).toFixed(2) + "gb";
    } else if (e.files[0].size / 1000 > 1024) {
      var filesize = (e.files[0].size / 1000 / 1024).toFixed(2) + "mb";
    } else {
      var filesize = (e.files[0].size / 1000).toFixed(2) + "kb";
    }

    var indx = nme.lastIndexOf(".");
    var format = nme.substring(indx + 1, nme.length);
    // var format=e.files[0].type.substring(6,this.fileInput.files[0].type.length)
    this.VideoUploadForm.patchValue({ itemfilename: nme });
    this.VideoUploadForm.patchValue({ itemsize: filesize });
    this.VideoUploadForm.patchValue({ itemformat: format });
    //  this.ImageUploadForm.patchValue({itemfiename:this.imageuploadlist["data"].Keywords})
    this.fileInput.upload();
    //this.getmetadata(nme)
    // if(this.fileInput.progress<50){
    //   this.uploadbutton.nativeElement.disabled=true
    //   }
  }

  // uploadsrt(){
  //   this.srtinput.upload();
  // }

  getmetadata(nme) {
    for (let i = 0; i < this.fileInput.files.length; i++) {
      document.getElementById(this.fileInput.files[i].name).style.background =
        "white";
    }

    document.getElementById(nme).style.background = "lavender";

    let indx;
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var tmp = this.fileInput.files[i].name;
      if (tmp == nme) {
        indx = i;
      }
    }
    if (this.fileInput.files[indx].size / 1000 > 1048576) {
      var imgsize =
        (this.fileInput.files[indx].size / 1000 / 1048576).toFixed(2) + "gb";
    } else if (this.fileInput.files[indx].size / 1000 > 1024) {
      var imgsize =
        (this.fileInput.files[indx].size / 1000 / 1024).toFixed(2) + "mb";
    } else {
      var imgsize = (this.fileInput.files[indx].size / 1000).toFixed(2) + "kb";
    }

    this.VideoUploadForm.patchValue({ itemsize: imgsize });
    var p = environment.folderpath + nme;
    this.videouploadservice.getvideometadata(p).subscribe((data: any) => {
      if (data != null) {
        var dataparse = JSON.parse(data);

        var videoname = dataparse[0].FileName;
        var x = videoname.lastIndexOf("/");
        videoname = videoname.substring(x + 1, data.length);
        // alert(videoname)
        // this.videopath="assets/video/"+videoname
        this.Vname = nme;
        this.videofullname = videoname;
        //this.videoElement.nativeElement.src=this.ApiServerPath
        var dt = dataparse[0];
        this.videouploadlist = dt;
        this.x = JSON.stringify(this.videouploadlist);
        var index = nme.lastIndexOf(".");
        var format = nme.substring(index + 1, nme.length);

        if (this.videouploadlist["Duration"] == undefined) {
          // this.videouploadlist["Duration"]="00:00:00"
          this.VideoUploadForm.patchValue({
            itemlength: this.videouploadlist["00:00:00"],
          });
        } else {
          this.VideoUploadForm.patchValue({
            itemlength: this.videouploadlist["Duration"],
          });
        }
        // this.VideoUploadForm.patchValue({itemcaption:this.videouploadlist["Caption"]})
        //this.VideoUploadForm.patchValue({itemkeyword:this.videouploadlist["Keywords"]})
        // var format=this.fileInput.files[0].type.substring(6,this.fileInput.files[0].type.length)

        // this.VideoUploadForm.patchValue({ itemsize: imgsize })
        this.VideoUploadForm.patchValue({ itemformat: format });
        this.VideoUploadForm.patchValue({ itemfilename: nme });
      }
    });
  }

  CreateVideoUploadForm() {
    this.VideoUploadForm = this.formBuilder.group({
      CategoryId: [null, [Validators.required]],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null, [Validators.required, this.removeSpaces]],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
    });
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getCountryData() {
    this.countryservice
      .GetCountryData()
      .subscribe((data: CountryFormModel[]) => {
        if (data.length > 0) {
          this.countrylist = data;
        }
      });
  }
  getStateDropDown(c) {
    this.cityservice.getStateDropDown(c).subscribe((data: stateFormModel[]) => {
      if (data.length > 0) {
        this.Statelist = data;
      } else {
        this.Statelist = [];
        this.VideoUploadForm.patchValue({ StateId: "null" });
        this.citylist = [];
        this.VideoUploadForm.patchValue({ CityId: "null" });
      }
    });
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    // this.countryid=selctedcountryid;
    this.getStateDropDown(selctedcountryid);
  }

  getCityDropdown(s) {
    this.cityservice.getCityData(s).subscribe((data: CityFormModel[]) => {
      if (data.length > 0) {
        this.citylist = data;
      } else {
        this.citylist = [];
        this.VideoUploadForm.patchValue({ CityId: "null" });
      }
    });
  }
  getCity(e) {
    let selectedstateid = e.target.selectedOptions[0].value;
    // this.countryid=selectedstateid;
    this.getCityDropdown(selectedstateid);
  }

  getEventData() {
    //this.userinfomation=new userformmodel();
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }
    });
  }

  videoupload(formval) {
    this.videouploadinformation = formval.value;
    if (this.fileInput.files.length == 0) {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please Browse Files",
      });
    }
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var name = this.fileInput.files[i].name;
      var name = this.fileInput.files[i].name.replace(
        new RegExp("'", "g"),
        "''"
      );
      var p = environment.folderpath + name;

      this.videouploadservice.getvideometadata(p).subscribe((data: any) => {
        if (data != null) {
          var dataparse = JSON.parse(data);
          var dt = dataparse[0];
          this.videouploadlist = dt;
          this.x = JSON.stringify(this.videouploadlist);
          // this.videouploadlist = JSON.parse(this.x)
          if (this.fileInput.files[i].size / 1000 > 1024) {
            var filesize =
              (this.fileInput.files[i].size / 1000 / 1024).toFixed(2) + "mb";
          } else {
            var filesize =
              (this.fileInput.files[i].size / 1000).toFixed(3) + "kb";
          }
          // var format = this.fileInput.files[i].type.substring(6, this.fileInput.files[0].type.length)

          var index = name.lastIndexOf(".");
          var format = name.substring(index + 1, name.length);

          this.videouploadinformation.itemfilename =
            this.fileInput.files[i].name;
          this.videouploadinformation.itemformat = format;
          this.videouploadinformation.itemsize = filesize;
          this.videouploadinformation.itemlength =
            this.videouploadlist["Duration"];
          this.videouploadinformation.AvgBitrate =
            this.videouploadlist["AvgBitrate"];
          this.videouploadinformation.framewidth =
            this.videouploadlist["ImageWidth"];
          this.videouploadinformation.framelength =
            this.videouploadlist["ImageHeight"];
          this.videouploadinformation.VideoFrameRate =
            this.videouploadlist["VideoFrameRate"];

          if (
            this.videouploadinformation.CategoryId == null ||
            this.videouploadinformation.itemkeyword == null
          ) {
            if (i == this.fileInput.files.length - 1) {
              this.messageservice.add({
                key: "message",
                severity: "warn",
                summary: "Warning Message",
                detail: "Please select Category or Keywords",
              });
            }
          } else {
            this.videouploadservice
              .AddNewVideo(this.videouploadinformation)
              .subscribe((data: any) => {
                if (data != null) {
                  var item_id = data.item_id;
                  this.videouploadinformation.item_id = item_id;
                  let interval = setInterval(() => {
                    this.value =
                      this.value + Math.floor(Math.random() * 10) + 1;
                    if (this.value >= 100) {
                      this.value = 0;
                      // this.messageService.add({severity: 'info', summary: 'Success', detail: 'Process Completed'});
                      clearInterval(interval);
                      // this.fileInput._files = []
                      this.VideoUploadForm.reset();
                      // this.value=0
                    }
                  });
                  if (this.srtinput.files.length > 0) {
                    this.videouploadservice
                      .AddSrtFiles(this.videouploadinformation)
                      .subscribe((data: any[]) => {
                        this.srtinput.upload();

                        if (i == this.srtinput.files.length - 1) {
                        }
                        this.messageservice.add({
                          key: "message",
                          severity: "success",
                          summary: "Success Message",
                          detail: "Save successfully srt",
                        });
                        setTimeout(
                          () => this.videocomponent.getTodayVideo(),
                          3500
                        );
                      });
                  }
                  // this.srtinput._files = []
                }

                if (i == this.fileInput.files.length - 1) {
                  this.messageservice.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Save successfully",
                  });
                  setTimeout(() => this.videocomponent.getTodayVideo(), 3500);
                  // this.value=0
                }
              });
          }
        }
      });
    }
  }

  //   play(){
  //     this.videoElement.nativeElement.play()
  //       var progressbarwidth=$("#defaultBar").width()
  //       var duration=this.videoElement.nativeElement.duration
  //       var speed=progressbarwidth/duration
  //       var currentTime=this.videoElement.nativeElement.currentTime

  //       var size=(currentTime*progressbarwidth/duration);
  //       document.getElementById("progressBar").style.width=size+'px';

  // }
}

import { formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TreeviewConfig } from 'ngx-treeview';
import { MessageService } from 'primeng/api';
import { AutoComplete } from 'primeng/primeng';
import { TreedropdownComponent } from 'src/app/masters/treedropdown/treedropdown.component';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { AudiouplaodService } from 'src/app/services/files/audiouplaod.service';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';

@Component({
  selector: 'app-audio-edit-form',
  templateUrl: './audio-edit-form.component.html',
  styleUrls: ['./audio-edit-form.component.css']
})
export class AudioEditFormComponent implements OnInit {
  @ViewChild("keywordinput", { static: false }) keywordinput: AutoComplete
  @ViewChild("TreedropdownComponent", { static: false }) TreedropdownComponent: TreedropdownComponent
  Audio_Edit_form: FormGroup
  audioUploadModel: audiouploadModel;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  idforupdate: string = ""
  categoryid: number = 0
  collpath: string = ""
  // pdftypelst: any
  filteredkeywords: any[];
  allkeywords: any[]
  glbkeywordsgst: string = ""
  keywords: any[] = []
  fileformt: string = ""
  items: any[] = [];
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  public AllowParentSelection = true;
  public RestructureWhenChildSameName = false;
  public ShowFilter = true;
  public Disabled = false;
  public FilterPlaceholder = 'Type here to filter elements...';
  public MaxDisplayed = 5;

  @ViewChild('filter', { static: true })
  targetElement: ElementRef;
  @Input()
  dropDownHeight = 250;
  selection: string = "";

  visible: boolean = false;
  id: any = Math.floor(Math.random() * 1000);
  firstTimeLoad = true;
  dropDownEl;
  orgnamearr:string=""
  categoryname: any[] = []
  constructor(public formBuilder: FormBuilder, public audioService: AudiouplaodService, private messageservice: MessageService, public categoryService: CategoryService, public subcategoryService: SubCategoryService, public dashboarservice: DashboardService) { }

  ngOnInit() {
    this.CreateAudioEditForm()
    this.getcategorydata()
    this.getSubcategorydata({})
    this.dashboarservice.gettreedropstatus().subscribe((data: boolean) => {
      this.visible = data
    })

   

  }

  @Input()
  selecttab: string;
  @Input()
  selectValue: string;
  @Input()
  visibletree: boolean;
  @Input()
  orgname:string
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectValue']) {
      if (this.selectValue != "") {
        this.idforupdate = this.selectValue
        this.orgnamearr=this.orgname
        this.getaudiodata(this.idforupdate, this.selecttab)
      }

    }
    this.visible = this.visibletree
  }



  onDataSelection(e) {
    alert(e)
  }

  CreateAudioEditForm() {
    this.Audio_Edit_form = this.formBuilder.group({
      itemid: [""],
      itemcaption: [""],
      itemkeyword: [""],
      category:[""],
      subcategory:[""],
      itemrating: [""],
      itemfilename: [""],
      orgpath: [""],
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: [""]
    })
  }
  updateaudio(audioeditlist) {
  
    this.categoryname = []
    if (audioeditlist.categoryname != "") {
      for (let i = 0; i < audioeditlist.length; i++) {
        this.categoryname.push({ categoryname: audioeditlist.categoryname.split(";")[i].toString().trim() })
      }
    }
    this.getSubcategorydata(audioeditlist.categoryid)
    this.Audio_Edit_form.patchValue({
      itemid: audioeditlist.itemid,
      itemcaption: audioeditlist.itemcaption,
      itemkeyword: audioeditlist.itemkeyword,
      itemrating: audioeditlist.itemrating,
      itemfilename: audioeditlist.orgfilename,
      category:audioeditlist.categoryid,
      subcategory:audioeditlist.subcategoryid,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: audioeditlist.MergeMultipleCol
    })    
  }

  SaveAfterUpdate() {
    this.audioUploadModel = this.Audio_Edit_form.value  
    this.audioUploadModel.itemid = this.idforupdate
    if (this.audioUploadModel.itemid.includes("~")) {
      this.audioUploadModel.itemid = this.audioUploadModel.itemid.replace("~", "");
    }
    this.audioUploadModel.orgfilename=this.orgnamearr
    if (this.audioUploadModel.eventdate == "") {
      this.audioUploadModel.eventdate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }
    // console.log("this.audioUploadModel",this.audioUploadModel);
    
    this.audioService.UpdateAudio(this.audioUploadModel).subscribe((data: any) => {
      if (data != null) {
        this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Successfully Updated' });
        this.cleardata()

      }
    })
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  // getaudiodata(itemid, path) {
  //   if(itemid.length > 0){
  //     if (itemid.indexOf("~") > -1) {
  //       itemid = itemid.split("~")[0]
  //     }
  //     this.dashboarservice.getaudiodata(itemid.trim(), path).subscribe((data: any[]) => {
  //       if (data.length > 0) {
  //         this.updateaudio(data[0])
  //       }
  //     })
  //   }
   
  // }
  getaudiodata(itemid, path) {
    if (itemid && itemid.length > 0) {
      // Remove any trailing '~' character
      itemid = itemid.replace(/~+$/, '');
  
      this.dashboarservice.getaudiodata(itemid, path).subscribe(
        (data: any[]) => {
          if (data.length > 0) {
            this.updateaudio(data[0]);
          } else {
            console.error('Received empty data from the service.');
          }
        },
        (error) => {
          console.error('Error while fetching audio data:', error);
        }
      );
    } else {
      console.error('Invalid itemid provided.');
    }
  }
  cleardata() {
    this.Audio_Edit_form.reset()
    //this.Document_Edit_form.patchValue({acldaterange:this.today})
    //this.Document_Edit_form.patchValue({itempermission:"1"})
    this.Audio_Edit_form.patchValue({ storytype: "0" })
    this.glbkeywordsgst = ""
    this.keywords = []
    this.selectValue = ""
    this.selection = ""
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode === 113) {
      if (this.glbkeywordsgst != "") {
        this.dashboarservice.getkeywords(this.glbkeywordsgst).subscribe((data: any[]) => {
          if (data.length > 0) {
            this.allkeywords = data
          }
        })
      }

    }
  }

  getallbysubject() {
    this.dashboarservice.getallbysubject().subscribe((data: any[]) => {
      if (data != null) {
        var data1 = JSON.parse(data.toString())
        this.items = data1
        //this.TreedropdownComponent.binddata(this.items)
      }
    })
  }




  setPosition() {
    const el: Element = this.targetElement.nativeElement;
    const position = el.getBoundingClientRect();
    this.dropDownEl.style.top = position.height + position.top + 'px';
    this.dropDownEl.style.left = position.left + 'px';
    this.dropDownEl.style.width = position.width + 'px';
    this.dropDownEl.style.height = this.dropDownHeight + 'px';
  }
  showDropDown() {
    this.visible = !this.visible;
    this.setPosition();
  }
  nodeSelect(event) {
    this.visible = false;
    this.selection = event.node.data;
    //alert(this.selection)
    //this.selectionChange.emit(event.node);
  }

  onfocusoutbysubject() {
    this.visible = false;
  }


}
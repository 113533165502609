import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/services/masters/state.service";
import { stateFormModel } from "src/app/model/master/state.formmodel";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { CountryFormModel } from "src/app/model/master/country.formmodel";

@Component({
  selector: "app-state",
  templateUrl: "./state.component.html",
  styleUrls: ["./state.component.css"],
})
export class StateComponent implements OnInit {
  statelist: stateFormModel[] = [];
  stateinformation: stateFormModel;
  StateForm: FormGroup;
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  Header: string = "Add State";
  countrylist: CountryFormModel[] = [];
  constructor(
    private stateservice: StateService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getstate();
    this.CreateStateForm();
    this.getCountryDropDown();
  }

  getstate() {
    this.stateservice.getAllstate().subscribe((data: stateFormModel[]) => {
      if (data != null) {
        this.statelist = data;
      }
    });
  }
  CreateStateForm() {
    this.StateForm = this.formBuilder.group({
      StateName: [
        null,
        [
          Validators.required,
          this.removeSpaces,
          Validators.pattern("^[a-zA-Z ]*$"),
        ],
      ],
      CountryId: [null],
      StateId: [null],
      userid: [null],
    });
    this.StateForm.patchValue({ CountryId: 0 });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  saveStateInfo(formval) {
    this.stateinformation = formval.value;
    this.stateinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.stateinformation.StateId > 0) {
      if (this.stateinformation.CountryId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Country",
        });
      } else {
        this.stateservice
          .checkduplicate(this.stateinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.stateservice
                .Updatestate(this.stateinformation)
                .subscribe((data: any) => {
                  this.getstate();
                  this.StateForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "update successfully",
                  });
                  this.ButtonStatus = "Save";
                  this.StateForm.patchValue({ CountryId: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "State Already Exist",
              });
            }
          });
      }
    } else {
      if (this.stateinformation.CountryId == 0) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Country",
        });
      } else {
        this.stateservice
          .checkduplicate(this.stateinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.stateservice
                .Createstate(this.stateinformation)
                .subscribe((data: any) => {
                  this.getstate();
                  this.StateForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Save successfully",
                  });
                  this.StateForm.patchValue({ CountryId: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "State Already Exist",
              });
            }
          });
      }
    }
  }

  getCountryDropDown() {
    this.stateservice
      .getcountryDropDown()
      .subscribe((data: CountryFormModel[]) => {
        if (data != null) {
          this.countrylist = data;
        }
      });
  }
  updatestate(statelist: stateFormModel) {
    this.Header = "Edit State";
    this.ButtonStatus = "Update";
    this.StateForm.setValue({
      StateName: statelist.StateName,
      StateId: statelist.StateId,
      CountryId: statelist.CountryId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeleteState(StateId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "statecategory",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.stateservice.Deletestate(StateId).subscribe((data: any[]) => {
          this.getstate();
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.StateForm.reset();
          this.StateForm.patchValue({ CountryId: 0 });
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  clear() {
    this.StateForm.reset();
    this.StateForm.patchValue({ CountryId: 0 });
    this.ButtonStatus = "Save";
    this.Header = "Add State";
  }
}

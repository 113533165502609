export class StoryFormModel{
    
    CategoryId:string
    SubCategoryId: string
    CountryId:string
    StateId:string
    CityId:string
    EventId:string
    OriginalPath:string
    itemfilename:string
    itemsize:any
    itemlength:string
    itemformat:string
    itemcaption:string
    itemkeyword:string
    location:string
    itemauthor:string
    eventdate:Date
    itemrating:string
    framewidth:string
    Caption:string
    ImageSize:string
    Keywords:string
    Duration:string
    CompressorID:string
    AvgBitrate:string
    downloadcount:number
    itemid:number
    HiresPath:string
    orgfilename:string
    categoryname:string
    subcategoryname:string
    eventname:string
    countryname:string
    statename:string
    cityname:string
    itemtype:string
    resolution:string
    HeadLine:string
    story:string
    attchmentname:string
    Languageid:string
    id:number
    parentid:number
    Udate:string
    languageFont:string
    Source:string
    StoryId:string
    Heading:string 
    Story:string 
    AgencyId:string 
    LanguageId:string
    FileName:string
    Category:string
    TransactionDate:string
    FileActualDate:string
    TakeIcon:string
    Slug:string
    TempSlug:string
    TotalWords:string
    SubCategory:string
    takelist:string
    sourcename:string
    ogpath:string
   
    
}
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { DocumentUploadFormModel } from 'src/app/model/file/documentUploadModel';
import { ImageuploadService } from 'src/app/services/files/imageupload.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { MessageService } from 'primeng/api';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { DocumentuploadService } from 'src/app/services/files/documentupload.service';
import { environment } from 'src/environments/environment';
import { PublicationService } from 'src/app/services/masters/publication.service';
import { PermissionModel } from 'src/app/model/master/Permission.model';
import { DashboardService } from 'src/app/services/files/dashboard.service';
import { DailypagesComponent } from '../dailypages/dailypages.component';
import { DailypagesService } from 'src/app/services/files/dailypages.service';

@Component({
  selector: 'app-dailypagesearch',
  templateUrl: './dailypagesearch.component.html',
  styleUrls: ['./dailypagesearch.component.css']
})
export class DailypagesearchComponent implements OnInit {

  DocumentSearchingForm: FormGroup
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0
  documentsearchlist: DocumentUploadFormModel[] = []
  eventlist: Eventformmodel[] = []
  totalcount1: string = ''
  pagenotype: string = "like"
  pageno: string
  startdate: string
  enddate: string
  filenametype: string = "like"
  filename: string
  pagenosrchtype: string = "and"
  filenamesrchtype: string = "or"
  datetype: string = "updateddate"
  formatsrchtype: string = "and"
  formattype: string = "equal"
  format: string = "null"
  permissionlist: PermissionModel[]
  filteredkeywords: any[];
  allkeywords: any[]
  glbkeywordsgst: string = ""
  datesrchtype: string = "and"
  editionsrchtype: string = "and"
  editiontype: string = "equal"
  edition: string = "null"
  editionlist:any[]=[]
  constructor(private documentcomponent: DailypagesComponent, private documentser: DailypagesService, public publicservice: PublicationService, public dashboadservice: DashboardService) { }

  ngOnInit() {
this.getalledition()
  }

 

  @Input()
  selectValue: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectValue']) {
      this.filename = this.selectValue
    }
    
  }

  clearform1() {
    this.totalcount1 = ""
    this.filename = ""
    this.filenametype = "like"
    this.pagenotype = "like"
    this.pageno = ""
    this.startdate = ""
    this.enddate = ""
    this.filenamesrchtype = "or"
    this.pagenosrchtype = "and"
    this.datetype = "updateddate"
    this.formatsrchtype = "and"
    this.formattype = "equal"
    this.format = "null"
    this.editionsrchtype = "and"
    this.editiontype = "equal"
    this.edition = "null"
    this.datesrchtype = "and"
    environment.glbsearchstring = ""
    environment.globalsearchtext = ""
  }

 getalledition(){
   this.documentser.getalleditionforsearch().subscribe((data:any[])=>{
     if(data.length>0){
       this.editionlist=data
     }
   })
 }

  

  advancesearch(type) {
    var srchtxt = ""
    var keystring = ""
    var descstring = ""
    var titlestring = ""
    var filenaestring = ""
    if (this.filename == "" || this.filename == null || this.filename == undefined) {

    }
    else {

      var key = this.ExtractSearchingKeyword(this.filename)
      var keylist = key.split("+")
      for (let i = 0; i < keylist.length; i++) {
        if (i == 0) {
          filenaestring += this.filenamesrchtype + " ("
          if (this.filenametype == "like") {
            filenaestring += "  orgfilename like '%" + keylist[i] + "%' "
          }
          else if (this.filenametype == "equal") {
            filenaestring += " orgfilename = '" + keylist[i] + "' "
          }
          else {
            filenaestring += " orgfilename NOT IN ('" + keylist[i] + "') "
          }
        }
        else {
          if (this.filenametype == "like") {
            filenaestring += "and  orgfilename like '%" + keylist[i] + "%' "
          }
          else if (this.filenametype == "equal") {
            filenaestring += " and orgfilename = '" + keylist[i] + "' "
          }
          else {
            filenaestring += " and orgfilename NOT IN ('" + keylist[i] + "') "
          }
        }

        if (i == keylist.length - 1) {
          filenaestring += ")"
        }

      }
    }
    srchtxt = keystring + " " + descstring + " " + titlestring + " " + filenaestring
    srchtxt = srchtxt.trim()
    if (srchtxt.indexOf("or") == 0) {
      srchtxt = srchtxt.replace("or", "")
    }
    if (srchtxt.indexOf("and") == 0) {
      srchtxt = srchtxt.replace("and", "")
    }
    if (srchtxt.trim() != "") {
      srchtxt = "(" + srchtxt + ")"
    }
    if (this.edition == "" || this.edition == "null" || this.edition == undefined || this.edition == null) {

    }
    else {
      if (this.editiontype == "equal") {
        srchtxt += " " + this.editionsrchtype + " poolname = '" + this.edition + "' "
      }
      else {
        srchtxt += " " + this.editionsrchtype + " poolname NOT IN ('" + this.edition + "') "
      }
    }
    if (this.pageno == "" || this.pageno == null || this.pageno == undefined) {

    }
    else {
      if (this.pagenotype == "like") {
        srchtxt += " " + this.pagenosrchtype + " resolution like '%" + this.pageno + "%' "
      }
      else if (this.pagenotype == "equal") {
        srchtxt += " " + this.pagenosrchtype + " resolution = '" + this.pageno + "' "
      }
      else {
        srchtxt += " " + this.pagenosrchtype + " resolution NOT IN ('" + this.pageno + "') "
      }
    }
    

    if (this.format == "" || this.format == "null" || this.format == undefined || this.format == null) {

    }
    else {
      if (this.formattype == "equal") {
        srchtxt += " " + this.formatsrchtype + " itemtype = '" + this.format + "' "
      }
      else {
        srchtxt += " " + this.formatsrchtype + " itemtype NOT IN ('" + this.format + "') "
      }
    }

    

    //year//
    if (this.startdate == "" || this.startdate == null || this.startdate == undefined) {

    }
    else {
      srchtxt += " " + this.datesrchtype + " Date(" + this.datetype + ")  >=  '" + this.startdate + "' "

    }
    if (this.enddate == "" || this.enddate == null || this.enddate == undefined) {

    }
    else {
      srchtxt += " " + this.datesrchtype + " Date(" + this.datetype + ")  <=  '" + this.enddate + "' "
    }
    
    if (type == "") {
      this.totalcount1 = ""
      if (srchtxt == "" || srchtxt == null || srchtxt == "null" || srchtxt == undefined) {
        srchtxt = " 1=1  order by editionname,eventdate desc,cast(resolution as unsigned)"
      }
      else {
        srchtxt = srchtxt + " order by editionname,eventdate desc,cast(resolution as unsigned)"
      }
      this.documentcomponent.newadvancesearch(srchtxt)
    }
    else {
    var tbastr=localStorage.getItem("tabperstring")
      this.documentser.getacount(srchtxt,tbastr).subscribe((data: any[]) => {
        if (data != null) {
          this.totalcount1 = (data[0].rowcount).toString()
        }
      })
    }

  }


  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if ((Paramater.indexOf('"') >= 0)) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = (Paramater.indexOf("~", (startval - 1)) + 1);
          if ((startind <= 0)) {
            break;
          }

          endindex = (Paramater.indexOf("~", startind) + 1);
          if ((endindex <= 0)) {
            break;
          }

          if ((Searchingparam.trim() == "")) {
            Searchingparam = Paramater.substring((startind - 1), startind + ((endindex - startind)
              + 1)).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          }
          else {
            Searchingparam = (Searchingparam + "+" + Paramater.substring((startind - 1), startind + ((endindex - startind) + 1))).trim();
            Paramater = Paramater.split(Paramater.substring((startind - 1), startind + ((endindex - startind) + 1))).join("");
          }

          // startval = endindex
        }

        if ((Searchingparam != "")) {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if ((Paramater.trim() != "")) {
          if ((MergeParamater == "")) {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          }
          else {
            MergeParamater = (MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+")));
          }

        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      }
      else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }

    }
    catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }



}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { Observable } from 'rxjs';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  
  subcategoryformmodel:stateFormModel=new stateFormModel();
  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  //baseurl: string = "http://localhost:5000/state";

  getAllstate():Observable<stateFormModel[]>{
    return this.httpclient.get<stateFormModel[]>(this.baseurl+"state");
  }
  Createstate(stateinfo:stateFormModel):Observable<stateFormModel[]>{
    return this.httpclient.post<stateFormModel[]>(this.baseurl+ "state/insertstate",stateinfo);
  }
 
  getcountryDropDown():Observable<CountryFormModel[]>{
    return this.httpclient.get<CountryFormModel[]>(this.baseurl+"state/selectcountry");
  }
  Updatestate(stateinfo:stateFormModel):Observable<stateFormModel[]>{
    return this.httpclient.post<stateFormModel[]>(this.baseurl+"state/updatestate",stateinfo)
  }
  Deletestate(StateId:number):Observable<stateFormModel[]>{
    return this.httpclient.post<stateFormModel[]>(this.baseurl+"state/deletestate",{StateId:StateId,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  checkduplicate(stateinfo:stateFormModel):Observable<stateFormModel[]>{
    return this.httpclient.post<stateFormModel[]>(this.baseurl+ "state/checkduplicate",stateinfo);
  }
}


import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpProgressEvent } from "@angular/common/http";
import {
  DomSanitizer,
  ɵINTERNAL_BROWSER_PLATFORM_PROVIDERS,
} from "@angular/platform-browser";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { MessageService, ConfirmationService, MenuItem } from "primeng/api";
import { CategoryService } from "src/app/services/masters/category.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { Export_Item_Model } from "src/app/model/file/export_item.Model";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { ContextMenu } from "primeng/primeng";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { Lightbox } from "ngx-lightbox";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";
import { Autocomplete } from "src/app/services/autocomplete";
import { saveAs } from "file-saver";
import { VideoplayerComponent } from "./videoplayer/videoplayer.component";
import * as videojs from "video.js/dist/video.js";
import "videojs-hotkeys";

@Component({
  selector: "app-video-upload",
  templateUrl: "./video-upload.component.html",
  styleUrls: ["./video-upload.component.css"],
})
export class VideoUploadComponent implements OnInit {
  display: boolean = false;
  showVideoSearch: boolean = false;
  videolist: VideoUploadModel[] = [];
  fileurl;
  currentFileUpload: File;
  ExportList: exportConfigurationModel[] = [];
  downloadVideoList: downloadimagesmodel[] = [];
  Searchbyfield: string;
  videoupdate: boolean = false;
  categorylist: categoryformmodel[] = [];
  filePathStatus: any[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Video_Edit_form: FormGroup;
  selectedvideo: VideoUploadModel;
  videouploadinformation: VideoUploadModel;
  first: number = 0;
  view: boolean = false;
  showVideoDownload: boolean = false;
  fullvideolist: VideoUploadModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  originalimagepath: string;
  viewVideo: boolean = false;
  exportitemid: number;
  exportitemname: string;
  videopreviewpath: string;
  video: string;
  glbDelete: number = 0;
  glbupdate: number = 0;
  Buttons: string = "Play";
  mediaPlayer: any;
  value: number = 0;
  ButtonStatus: string = "MarkIn";
  playStatus: string = "Pause";
  volumeStatus: string = "unmute";
  content: any;
  markinvl = 1;
  markoutval = 1;
  MarkInValue;
  MarkOutValue;
  totalDuration;
  markinArray = [];
  totaldurarray = [];
  minutes: any;
  minutes_d: any;
  durationarray = [];
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  exportbtn: number = 0;
  cropbtn: number = 0;
  dpxbtn: number = 1;
  @ViewChild("video", { static: true }) private videoElement: ElementRef;
  @ViewChild("videogif", { static: true }) private videogif: ElementRef;
  adnvacesrchfields: any[];
  rightclickmenuopen: boolean = false;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  ismenuclick: boolean = false;
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexspermis: any[] = [];
  loading: boolean = true;
  @ViewChild("dv", { static: false }) dataview1: any;
  totalcount: string;
  show: boolean = false;
  idforedit: string = "";
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  docfileforup: string = "";
  edittable: string = "";
  contextmenuitem: MenuItem[];
  downloadbtn: number = 0;
  addtocartbtn: number = 0;
  cartname: string;
  cartitemid: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  results: string[] = [];
  filteredBrands: any[];
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  _album: any[] = [];
  grouplist: any[] = [];
  filepath: string = "";
  approvebtn: number = 0;
  slideIndex = 0;
  imglastindx: number = 0;
  imglastlimit: number = 5;
  imgminrange: number = 0;
  recgrpoupid: string = "";
  approvallst: any[] = [];
  videoDuration: any;
  activeLtoDot: number = 0;
  microphone: number = 0;
  autoCompletArr: any[] = [];
  indexPrev: any = null;
  indexCurrent: any;
  items: any;
  relationlist: string[] = [];
  canPlayVideo: boolean = false;
  poster: any;
  videodata: any;
  itemid: any;
  flagForMarkIn: any;
  videositemtypes: MenuItem[];
  restoreSelectFormat: any;
  @ViewChild("vid", { static: false }) vid: VideoplayerComponent;

  constructor(
    private _lightbox: Lightbox,
    public imageservice: ImageuploadService,
    private storyservice: StoryserviceService,
    private speechTextService: SpeechTextService,
    private router: Router,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    public subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    public videoservice: VideouploadService,
    private confirmationService: ConfirmationService,
    public dashser: DashboardService,
    public storyser: StoryserviceService
  ) {
    let cartlistsub = this.dashser
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashser
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }
  open(index: number): void {
    this._lightbox.open(this._album, index);
  }

  close(): void {
    this._lightbox.close();
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashser
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashser
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngOnInit() {
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    // console.log("Server path is", this.ApiServerPath);
    this.dashser.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });
    this.checkpermissions();
    this.getdownloaditemtype();
    // this.Searchbyfield = environment.globalsearchtext
    // this.adnvacesrchfields = environment.advancesrchel
    this.Searchbyfield = "";
    this.adnvacesrchfields = [];
    if (this.Searchbyfield == null) {
      this.Searchbyfield = "";
    }
    if (this.Searchbyfield == "") {
      if (this.adnvacesrchfields.length < 1) {
        this.SearchByFields("");
      } else {
        this.advancesearch(this.adnvacesrchfields);
      }
    } else {
      this.SearchByFields("");
    }
    //this.getTodayVideo()
    //this.getcategorydata()
    //this.CreateVideoEditForm()
    this.fullvideolist = new VideoUploadModel();
    this.selectedvideo = new VideoUploadModel();
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Add to cart",
        icon: "fa fa-shopping-cart",
        command: (event) => {
          this.showmulcartdiv();
        },
      },
      {
        label: "Delete",
        icon: "fa fa-trash",
        command: (event) => {
          this.deletemultipledoc();
        },
      },
      {
        label: "Edit",
        icon: "fa fa-edit",
        command: (event) => {
          this.multipleedit();
        },
      },

      {
        label: "Add Frame Comments",
        icon: "fa fa-edit",
        command: (event) => {
          this.addcomment();
        },
      },
      {
        label: "Add Frame Keywords",
        icon: "fa fa-edit",
        command: (event) => {
          this.addkeywords();
        },
      },
      {
        label: "Approve",
        icon: "fa fa-check",
        command: (event) => {
          this.approveimg();
        },
      },
    ];
    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });

    this.galleryOptions = [
      {
        image: false,
        width: "100%",
        height: "100px",
        thumbnailsColumns: 4,
        previewDownload: true,
      },
      { breakpoint: 700, width: "100%" },
    ];
    this.speechTextService.init();
  }

  imgchange(e) {
    var clcindx = e.index;
    if (this.imglastindx == clcindx) {
      return;
    }
    var ptop = clcindx;
    var n = ptop;
    var x = 5;
    n = n + x;
    n = n - (n % x);
    if (n - clcindx <= 3) {
      n = n + 5;
    }
    if (n - clcindx < 0) {
      n = 0;
    }
    this.imgminrange = n;
    if (this.imglastlimit < this.imgminrange) {
      //this.getlastgroup(this.imgminrange)
    } else if (this.imglastlimit > this.imgminrange) {
      //this.getfirtgroup(this.imgminrange)
    }
    this.imglastlimit = this.imgminrange;
  }

  getgroupimage(groupid) {
    this.recgrpoupid = groupid;
    this.videoservice.GetAllgroupimage(groupid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.grouplist = data;
        this.galleryImages = [];
        this._album = [];
        for (let i = 0; i < this.grouplist.length; i++) {
          // console.log(this.grouplist[i].hirespath, this.grouplist[i].originalpath)
          this.filepath = this.grouplist[i].thumbpath;
          var filename = this.grouplist[i].orgfilename;
          const album = {
            src: this.ApiServerPath + this.grouplist[i].hirespath,
            caption: filename,
            thumb: this.ApiServerPath + this.filepath,
          };

          this._album.push(album);
          this.galleryImages.push({
            small: this.ApiServerPath + this.filepath,
            medium: this.ApiServerPath + this.grouplist[i].hirespath,
            big: this.ApiServerPath + this.grouplist[i].hirespath,
            description: filename,
          });
          //this.galleryImages.push({source:[this.ApiServerPath+this.filepath], alt:'', title:filename});
        }
      }
    });
  }
  imageclick(index) {
    var selectfile = index.path[0].src;
    var path1 = decodeURIComponent(selectfile.replace(this.ApiServerPath, ""));
    var index1 = this.grouplist.findIndex((p) => p.thumbpath == path1);
    this.open(index1);
    //alert(index1)
    //document.getElementById('imgModal').style.display = "block";
  }
  closeModal() {
    document.getElementById("imgModal").style.display = "none";
  }
  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(slideIndex);
  showSlides(n) {
    let i;
    const slides = document.getElementsByClassName(
      "img-slides"
    ) as HTMLCollectionOf<HTMLElement>;
    const dots = document.getElementsByClassName(
      "images"
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[this.slideIndex - 1].style.display = "block";
    if (dots && dots.length > 0) {
      dots[this.slideIndex - 1].className += " active";
    }
  }
  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip({
        placement: "auto",
        container: "body",
      });
    });
  }
  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 7) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 8) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 9) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 45) {
        this.exportbtn = 1;
      }
      if (this.permissionlist[i].taskid == 46) {
        this.cropbtn = 1;
      }
      if (this.permissionlist[i].taskid == 55) {
        this.addtocartbtn = 1;
      }
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 63) {
        this.approvebtn = 1;
      }
    }
  }

  cmshow() {
    console.log("cmshow clicked");
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    this.contextmenuitem[2].disabled = false;
    this.contextmenuitem[3].disabled = false;
    this.contextmenuitem[4].disabled = false;
    this.contextmenuitem[5].disabled = false;
    this.contextmenuitem[6].disabled = false;
    if (this.downloadbtn == 0) {
      this.contextmenuitem[0].disabled = true;
    }
    if (this.addtocartbtn == 0) {
      this.contextmenuitem[1].disabled = true;
    }
    if (this.deletebtn == 0) {
      this.contextmenuitem[2].disabled = true;
    }
    if (this.editbtn == 0) {
      this.contextmenuitem[3].disabled = true;
      this.contextmenuitem[6].disabled = true;
    }
    if (this.selectedIndexs.length > 1) {
      this.contextmenuitem[4].disabled = true;
      this.contextmenuitem[5].disabled = true;
    }

    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[5].disabled = true;
        break;
      }
    }
  }

  approveimg1(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.approvallst = [];
      this.approvallst.push(id);
      this.approveimg();
      // this.confirmationService.confirm({
      //   message: 'Are you sure that you want to Approve?',
      //   header: 'Confirmation',
      //   icon: 'pi pi-exclamation-triangle',
      //   accept: () => {
      //     this.approvallst.push(id)
      //     this.approveimg()
      //   },
      //   reject: () => {

      //   }
      // });
    }
  }

  approveimg() {
    if (this.approvebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Approve?",
        header: "Confirmation",
        key: "videouploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (this.selectedIndexs.length > 0) {
            this.approvallst = this.selectedIndexs;
          }

          if (this.approvallst.length > 0) {
            this.videoservice
              .approvevideo(this.approvallst)
              .subscribe((data) => {
                if (data.length > 0) {
                  this.messageservice.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "Approve Successfull",
                  });
                  for (let i = 0; i < this.approvallst.length; i++) {
                    document.getElementById(
                      "p" + this.approvallst[i]
                    ).style.display = "none";
                    document.getElementById(
                      "pr" + this.approvallst[i]
                    ).style.display = "inline-block";
                    document.getElementById(
                      "a" + this.approvallst[i]
                    ).style.display = "inline-block";
                    if (i == this.approvallst.length - 1) {
                      this.approvallst = [];
                      this.rightclickmenuopen = false;
                      this.ismenuclick = false;
                      this.removeselection();
                    }
                  }
                } else {
                  // this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Approve success!' });
                  this.messageservice.add({
                    key: "message",
                    severity: "warn",
                    summary: "Warn Message",
                    detail: "Approve Failed",
                  });
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "Approve Failed",
            });
          }
        },
        reject: () => {},
      });
      // this.rightclickmenuopen = false
      // this.ismenuclick = false
    }
  }

  disApproveImg(itemId) {
    this.videoservice.disApproveImg(itemId).subscribe((data: any) => {
      if (data != 0) {
      }
    });
  }
  getdownloaditemtype() {
    this.videoservice.getdownloaditemtype().subscribe((data: any[]) => {
      if (data != null) {
        const formats = data;
        // Create an array of objects based on the 'this.items' structure
        this.videositemtypes = formats.map((format) => ({
          label: format.profilename,
          icon: format.icon, // Set your desired icon here
          command: () => {
            // Handle the command for each format if needed
            // console.log(`Selected format: ${format}`);
            this.restoreSelectFormat = format.profilename;
          },
        }));
        // console.log(this.videositemtypes);
      }
    });
  }

  addcomment() {
    this.openvideocomment(this.selectedIndexs[0]);
  }

  addkeywords() {
    this.openvideokeywords(this.selectedIndexs[0]);
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var fname = iid.children[2].innerHTML.trim();
      var filepath = iid.children[0].innerHTML.trim();
      //this.downloadFullImage(fname,filepath)

      filepath = filepath.replace("lowres/", "raw/");
      this.ExportVideo(imageid, fname, filepath);
      //console.log(imageid,fname)
      // console.log(filepath)
      // // // if (lst.length > 1) {
      // // //   rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid })
      // // //   if (i == lst.length - 1) {
      // // //     await this.sleep(1000)
      // // //     this.downloadmultiplezip(rtnarr)
      // // //   }
      // // // }
      // // // else {
      // // //   this.downloadfullvideo(fname, filepath, imageid)
      // // // }
      // var x = document.querySelector(".downloadinggif1")
      // x["style"].display = "none";
      // var y = document.querySelector(".downloadback")
      // y["style"].display = "none";
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  downloadmultiplezip(arr) {
    this.imageservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  downloadcart(data1) {
    this.imageservice
      .downloadcart(data1.toString())
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadzipflder(data);
        }
      });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    a.remove();
    this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});
  }

  exportvideoforlocal(path, id, filename) {
    this.dashser
      .exportvideoforlocal(path, id, filename)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Successfully Export",
          });
        }
      });
  }

  downloadfullvideo(filename, filepath, itemid) {
    let unsub = this.videoservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          saveAs(data, filename);
        }
        unsub.unsubscribe();
        var x = document.querySelector(".downloadinggif1");
        //x.classList.add("downloadinggif")
        x["style"].display = "none";
        var y = document.querySelector(".downloadback");
        y["style"].display = "none";
        this.removeselection();
        this.dashser
          .createlog(
            itemid,
            "Download",
            JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
            "transaction_video"
          )
          .subscribe((data1: any[]) => {});
      });
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  showmulcartdiv() {
    if (this.addtocartbtn == 1) {
      this.showcart = true;
      this.ismenuclick = true;
    }
  }
  showcartdiv(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.showcart = true;
    }
  }
  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        lst.push(this.cartitemid);
        this.imageservice
          .addtocart(this.cartname, lst, "video", userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }
  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  // filterBrands(event) {
  //   this.filteredBrands = [];
  //   for (let i = 0; i < this.results.length; i++) {
  //     let brand = this.results[i];
  //     if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
  //       this.filteredBrands.push(brand);
  //     }
  //   }
  // }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    if (this.selectedIndexs.length > 0) {
      this.imageservice
        .addtocart(this.cartname, lst, "video", userid, "archieve")
        .subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Add to Cart successfully",
            });
            this.clearcartname();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warn Message",
        detail: "Add to Cart Failed",
      });
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  close1() {
    document.getElementById("markslider").innerHTML = "";
    this.durationarray = [];
    this.markinArray = [];
    this.totaldurarray = [];
    this.ButtonStatus = "MarkIn";
    this.markinvl = null;
    this.markoutval = null;
  }

  hide() {
    // this.videoElement.nativeElement.src = ''
    // document.getElementById("markslider").innerHTML = ''
    this.ButtonStatus = "MarkIn";
    this.poster = null;
    this.viewVideo = false;
    this.showfulldiv();
    this.videopreviewpath = null;
    $("#markslider").remove();
    this.flagForMarkIn = false;
    this.restoreSelectFormat == undefined;
    $(".timeBar").css("width", "1px");
    $(".timeBar").css("display", "none");
    this.playStatus = "Pause";
  }

  multipleedit() {
    if (this.editbtn == 1) {
      this.ismenuclick = true;
      var itemid = "";
      var nameforedit1 = "";
      var path = "normal";
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        itemid += this.selectedIndexs[i] + "~";
        if (i == this.selectedIndexs.length - 1) {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        }
      }
      this.videoupdate = true;
      this.idforedit = itemid;
      this.docfileforup = nameforedit1;
      this.edittable = path;
    }
  }
  onHide1() {
    this.display = false;
  }

  showDialog() {
    this.display = true;
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.code == "Space" && this.viewVideo == true) {
      event.preventDefault();
      if (this.vid.player.paused()) {
        this.vid.player.play();
      } else {
        this.vid.player.pause();
      }
    }
    if (event.code == "KeyM" && this.viewVideo == true) {
      if (this.vid.player.muted()) {
        this.vid.player.muted(false);
      } else {
        this.vid.player.muted(true);
      }
    }
    if (event.code == "ArrowRight" && this.viewVideo == true) {
      this.vid.player.currentTime(this.vid.player.currentTime() + 5);
    }
    if (event.code == "ArrowLeft" && this.viewVideo == true) {
      this.vid.player.currentTime(this.vid.player.currentTime() - 5);
    }
    if (event.code == "KeyF" && this.viewVideo == true) {
      if (this.vid.player.isFullscreen()) {
        this.vid.player.exitFullWindow();
      } else {
        this.vid.player.enterFullWindow();
      }
    }

    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.videoupdate = false;
      try {
        this.conetxtmenudiv.hide();
      } catch (error) {}
    }
    if (event.keyCode == 27) {
      this.viewVideo = false;
      try {
        this.conetxtmenudiv.hide();
      } catch {}
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
      this.conetxtmenudiv.hide();
    }
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  hidepopup() {
    this.videoupdate = false;
    this.showfulldiv();
  }

  hideedit() {
    this.idforedit = "";
    this.videoupdate = false;
    this.showfulldiv();
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        // let _index = event['path'][1]['id']
        try {
          this.conetxtmenudiv.hide();
        } catch (error) {}
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
        }
      }
      this.rightclickmenuopen = false;
      this.ismenuclick = false;
    }
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  advanceSearch() {
    this.show = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.show = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
    this.Searchbyfield = "";
  }

  getallvideos(type, limit) {
    // console.log(type,limit)
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    // console.log(x,'ssssssssss')
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }

    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (type == "count") {
      this.totalcount = localStorage.getItem("videocount");
      this.loading = false;
    } else {
      environment.glbsearchstring = "";
      environment.globalsearchtext = "";
      this.videoservice.getallvideos("0").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.videolist = data;
          this.totalcount = localStorage.getItem("videocount");
          this.loading = false;
        }
      });
      //var x=document.querySelector(".ui-paginator-bottom")
      if (x != undefined) {
        x["style"].cursor = "pointer";
        x["style"]["pointer-events"] = "auto";
      }
      this.loading = false;
    }
  }
  advancesearch(srctxt) {
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.hideadvncsrch();
    environment.glbsearchstring = srctxt;
    environment.globalsearchtext = "";
    var tbastr = localStorage.getItem("tabperstring");
    this.videoservice.getacount(srctxt, tbastr).subscribe((data: any[]) => {
      if (data.length > 0) this.totalcount = data[0].rowcount.toString();
    });

    this.videoservice.advancesearch(srctxt, "0").subscribe((data: any[]) => {
      if (data != null) {
        this.videolist = data;
        //this.totalcount = this.videolist.length.toString()
      } else {
        this.videolist = [];
      }

      this.loading = false;
    });
    if (x != undefined) {
      x["style"].cursor = "pointer";
      x["style"]["pointer-events"] = "auto";
    }
  }

  SearchByFields(type) {
    //this.first=0
    this.removeselection();
    var x = document.querySelector(".ui-paginator-bottom");
    if (x != undefined) {
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
    }
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.microPhoneText != "") {
      this.Searchbyfield = this.microPhoneText;
      //  console.log("data",this.Searchbyfield);
    }
    this.Searchbyfield = this.Searchbyfield.trim();
    if (this.Searchbyfield.trim() != "") {
      environment.globalsearchtext = this.Searchbyfield;
      environment.glbsearchstring = "";
      var srchtxt = this.ExtractSearchingKeyword(this.Searchbyfield);
      var tbastr = localStorage.getItem("tabperstring");
      this.videoservice.getncount(srchtxt, tbastr).subscribe((data: any[]) => {
        if (data.length > 0) {
          this.totalcount = data[0].rowcount.toString();
        }
      });
      this.videoservice
        .SearchByFields(srchtxt, type, "0")
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            //environment.glbsearchstring=""
            this.videolist = data;
            // this.totalcount = this.videolist.length.toString()
            this.loading = false;
          } else {
            this.totalcount = "0";
            this.videolist = [];
          }
        });
      if (x != undefined) {
        x["style"].cursor = "pointer";
        x["style"]["pointer-events"] = "auto";
      }

      this.loading = false;
    } else {
      this.getallvideos("", "");
    }
    this.stopMicroPhoneService();
    // this.Searchbyfield = ""
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.Searchbyfield = "";
    environment.globalsearchtext = "";
    this.SearchByFields("");
  }
  switchview(type) {
    this.tablestyle = type;
  }

  pagenochange(e) {
    this.indexPrev = null;
    this.removeselection();
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
      console.log("in");
    } else {
      var x = document.querySelector(".ui-paginator-bottom");
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
      this.loading = true;
      if (environment.globalsearchtext != "") {
        var srchtxt = this.ExtractSearchingKeyword(
          environment.globalsearchtext
        );
        this.videoservice
          .SearchByFields(srchtxt, "", this.dataview1.totalRecords)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else if (environment.glbsearchstring != "") {
        this.videoservice
          .advancesearch(
            environment.glbsearchstring,
            this.dataview1.totalRecords
          )
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        this.videoservice
          .getallvideos(this.dataview1.totalRecords)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data.length;
                var len = data.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data[i]);
                }
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      }
    }

    this.removeselection();
  }

  SearchAllVideo(formvalues) {
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }

    this.videoservice.SearchAllVideo(formvalues).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.videolist = data;
      } else {
        this.videolist = [];
      }
    });
  }

  // SearchByFields() {
  //   if (this.Searchbyfield != null) {
  //     this.videoservice.SearchByFields(this.Searchbyfield).subscribe((data: any[]) => {
  //       if (data.length > 0) {
  //         this.videolist = data
  //       } else {
  //         this.videolist = []
  //       }
  //     })
  //   }
  //   else {
  //     this.getallvideo()
  //   }

  // }

  //this function is not working
  getallvideo() {
    this.videoservice.GetAllVideo().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.videolist = data;
        this.glbDelete = 1;
        this.glbupdate = 1;
      }
    });
  }

  //   fullvideopopup(videolist, e) {
  //     if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
  //       this.dashser.getvideodata(videolist.itemid, '').subscribe((data: any[]) => {
  //         //console.log("data",data);
  //         var totalduration = data[0].itemlength
  //         var totalduration = totalduration.split(':');
  //         var hour = totalduration[0]
  //         var minute = totalduration[1]
  //         var second = totalduration[2]
  //         second = Math.trunc(second);
  //         this.videoDuration = hour + ":" + minute + ":" + second
  //         this.getgroupimage(data[0].groupid)
  //         this.videoElement.nativeElement.src = this.ApiServerPath + data[0].HiresPath
  //         this.ratingitem = data[0].itemrating
  //         //  this.videopreviewpath=videolist.HiresPath
  //         this.fullvideolist = data[0]
  //         //console.log("this.fullvideolist",this.fullvideolist);
  //         if(this.fullvideolist.ltoname != null){
  //           this.activeLtoLink(this.fullvideolist.ltoname)

  //         }
  //         this.viewVideo = true
  //         this.Buttons = 'Play'
  //       })

  //     }
  // this.getFilePathStatus(videolist)
  //   }

  fullvideopopup(videolist, e) {
    this.restoreSelectFormat = undefined;
    if (videolist.orgfilename.includes(".dpx")) {
      this.dpxbtn = 0;
    }
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashser
        .getvideodata(videolist.itemid, "")
        .subscribe((data: any[]) => {
          this.videopreviewpath = this.ApiServerPath + data[0].HiresPath;

          // console.log(data[0], 'videodata');
          this.videodata = data[0];
          this.itemid = data[0].itemid;
          this.poster = this.ApiServerPath + data[0].thumbpath;

          //console.log("data",data);
          var totalduration = data[0].itemlength;
          var totalduration = totalduration.split(":");
          var hour = totalduration[0];
          var minute = totalduration[1];
          var second = totalduration[2];
          second = Math.trunc(second);
          this.videoDuration = hour + ":" + minute + ":" + second;
          this.getgroupimage(data[0].groupid);
          // this.videoElement.nativeElement.src = this.ApiServerPath + data[0].HiresPath
          this.ratingitem = data[0].itemrating;
          this.fullvideolist = data[0];
          //console.log("this.fullvideolist",this.fullvideolist);
          this.Buttons = "Play";
          this.viewVideo = true;
        });
    }
    this.getFilePathStatus(videolist);
  }

  getFilePathStatus(videolist) {
    this.videoservice.getFilePathStatus(videolist).subscribe((data: any) => {
      // console.log(data);
      this.filePathStatus = data.split("/");
      console.log(this.filePathStatus);
    });
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }
  updatevideo(videoeditlist) {
    this.getSubcategorydata(videoeditlist.categoryid);
    this.Video_Edit_form.setValue({
      CategoryId: videoeditlist.categoryid,
      SubCategoryId: videoeditlist.subcategoryid,
      itemcaption: videoeditlist.itemcaption,
      itemkeyword: videoeditlist.itemkeyword,
      itemrating: videoeditlist.itemrating,
      itemid: videoeditlist.itemid,
    });
  }

  UpdateVideoPopup(videolist) {
    this.videoupdate = true;
    this.selectedvideo = videolist;
    this.updatevideo(videolist);
  }

  updatepopup(itemid, name, e, path) {
    if (e.ctrlKey == false && e.metaKey == false) {
      if (path.toLowerCase().indexOf("home/itg_events") > -1) {
        path = "event";
      } else {
        path = "normal";
      }
      this.videoupdate = true;
      this.docfileforup = name;
      this.idforedit = itemid;
      this.edittable = path;
      //this.updatedocument(documentList)
    }
  }

  SaveVideoAfterUpdate(formval) {
    this.videouploadinformation = formval.value;
    this.videoservice
      .UpdateVideo(this.videouploadinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });

          if (this.glbupdate == 0) {
            this.getTodayVideo();
          } else {
            this.getallvideos("", "");
          }
          this.Video_Edit_form.reset();
          this.videoupdate = false;
        }
      });
  }

  CreateVideoEditForm() {
    this.Video_Edit_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      itemcaption: [null],
      itemkeyword: [null],
      itemrating: [null],
      itemid: [null],
    });
  }

  trashdata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.videoservice.deleteVideo(itemid).subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "info",
                summary: "Success Message",
                detail: "Delete successfully",
              });
              // if (this.glbDelete == 0) {
              //   this.getTodayVideo()
              // } else {
              //   this.getallvideo()
              // }
              this.getallvideos("", "");
            }
          });
        },
        reject: () => {},
      });
    }
  }

  deletemultipledoc() {
    if (this.deletebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const lst = this.selectedIndexs;
          if (lst.length > 0) {
            this.videoservice
              .deletemultiplevideo(lst)
              .subscribe((data: any[]) => {
                let returnMsg: any = data;
                if (returnMsg == "Success") {
                  this.messageservice.add({
                    key: "message",
                    severity: "info",
                    summary: "Success Message",
                    detail: "Delete successfully",
                  });
                  this.rightclickmenuopen = false;
                  this.ismenuclick = false;
                  this.getallvideos("", "");
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "File Not Deleted",
            });
            this.rightclickmenuopen = false;
            this.ismenuclick = false;
          }
        },
        reject: () => {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        },
      });
    }
  }

  getExportData(itemid, itemname) {
    this.exportitemid = itemid;
    this.exportitemname = itemname;
    this.videoservice
      .GetExportButton()
      .subscribe((data: exportConfigurationModel[]) => {
        if (data != null) {
          this.ExportList = data;
        }
      });
  }

  ExportVideo(exportid, filename, filepath) {
    // console.log("export: ",exportid,filename,filepath,'---',this.restoreSelectFormat)
    if (
      this.restoreSelectFormat == null ||
      this.restoreSelectFormat == undefined
    ) {
      this.restoreSelectFormat = 0;
    }
    this.exportitemid = 0;
    this.videoservice
      .InsertExportData(
        this.exportitemid,
        exportid,
        filename,
        filepath,
        this.restoreSelectFormat
      )
      .subscribe((data: any) => {
        // console.log(data);
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded Will Start Soon...",
          });
          this.ExportEntry(this.exportitemid, filename, exportid);
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Failed Message",
            detail: "Original File Does Not Exist.",
          });
        }
      });
    this.restoreSelectFormat = 0;
    //this.downloadonebyone(exportid, filename, filepath)
  }

  downloadonebyone(exportid, filename, filepath) {
    // var fname = document.getElementById(filepath).children[2].innerHTML.trim()
    let unsub = this.videoservice
      .Testdownload(this.exportitemid, filename, filepath)
      .subscribe(async (data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();
      });
  }

  showNoOfDownload(itemid) {
    // this.getdowloadvideolist=downloadList
    this.videoservice
      .GetDownloadedVideoData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadVideoList = data;
          this.showVideoDownload = true;
        }
      });
  }

  ExportEntry(itemid, filename, exportid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.videoservice
      .ExportEntry(itemid, exportid, filename, userid)
      .subscribe((data: any[]) => {});
  }
  getTodayVideo() {
    this.videoservice.getTodayVideo().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.videolist = data;
      } else {
        this.videolist = [];
      }
    });
  }
  popupClose() {
    this.viewVideo = false;
    document.getElementById("play").style.backgroundImage =
      "url('/assets/image/play.png')";
  }
  cropvideo(path, orgname) {
    if (this.durationarray.length > 0) {
      var person = prompt("Please enter filename", "");
      if (person == null || person == "") {
      } else {
        var ext = orgname.split(".")[orgname.split(".").length - 1];
        var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId;
        // this.videogif.nativeElement.style.display = 'block'
        // this.videoElement.nativeElement.pause()
        this.vid.target.nativeElement.pause();
        // this.videogif.nativeElement.src='assets/image/2.gif'
        orgname = person.split(".")[0] + "." + ext;
        // console.log("video crop");
        this.messageservice.add({
          key: "message",
          severity: "info",
          summary: "Success Message",
          detail: "File Croping started",
        });
        this.videoservice
          .ffmpeg(path, orgname, this.durationarray, userid)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              this.messageservice.add({
                key: "message",
                severity: "info",
                summary: "Success Message",
                detail: "Successfully Export",
              });
              //this.downloadVideo(orgname,path,userid)
              // this.videogif.nativeElement.style.display = 'none'
              // this.close1();
            }
          });
      }
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        detail: "Please Mark IN and Mark Out Video...",
      });
    }
  }

  downloadVideo(filename, filepath, userid) {
    this.videoservice
      .Testdownload(this.exportitemid, filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "video/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.videoservice.deleteTempFile(userid).subscribe((data: any) => {
            if (data != null) {
            }
          });
        }
      });
  }

  markin() {
    if (!this.flagForMarkIn) {
      this.flagForMarkIn = true;
      $(".vjs-progress-holder").append(
        '<div id="markslider" style="display: block;"></div>'
      );
    }
    if (this.ButtonStatus == "MarkIn") {
      this.ButtonStatus = "MarkOut";
      var ttl_obj_width = 0;
      var tt = $("#markslider").find("div");
      for (let i = 0; i < tt.length; i++) {
        ttl_obj_width = ttl_obj_width + parseFloat($($(tt)[i]).css("width"));
      }
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markinvl" +
        this.markinvl +
        "'class='markin' style='height:29px; width:13px;border-right: 4px solid rgba(115, 133, 159, 0.75);margin: -12px 0px 0px 0px;float:left'></div>";
      var timebar = $(".vjs-play-progress.vjs-slider-bar").css("width");
      var markinwidth = $("#markinvl" + this.markinvl).css("width");
      var markoutwidth = $("#markoutval" + this.markinvl).css("width");
      var markSetwidth = Number(0) + Number(timebar.replace("px", ""));
      $("#markinvl" + this.markinvl).css("width", markSetwidth - ttl_obj_width);
      this.tooltip("markinvl" + this.markinvl);
      this.getProgressCurrentTime(markSetwidth) + ",";
      this.markinvl = this.markinvl + 1;
    } else {
      this.ButtonStatus = "MarkIn";
      var ttl_obj_width = 0;
      var tt = $("#markslider").find("div");
      for (let i = 0; i < tt.length; i++) {
        ttl_obj_width = ttl_obj_width + parseFloat($($(tt)[i]).css("width"));
      }
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markoutval" +
        this.markoutval +
        "' class='markout' style='height:29px; width:13px; border-right:4px solid rgba(115, 133, 159, 0.75);background: rgba(102, 153, 200,0.7);margin:-12px 0px 0px 0px;float:left'></div>";
      var timebar = $(".vjs-play-progress.vjs-slider-bar").css("width");
      var markinwidth = $("#markinvl" + this.markoutval).css("width");
      var markSetwidth =
        Number(0) + Number(timebar.replace("px", "")) - Number(ttl_obj_width);
      $("#markoutval" + this.markoutval).css("width", markSetwidth);
      // $('#markoutval' + this.markoutval).css('margin-left', markinwidth);
      this.tooltip("markoutval" + this.markoutval);
      this.markoutval = this.markoutval + 1;
      this.getProgressCurrentTime(markSetwidth) + ",";
    }
  }

  tooltip(id) {
    var videoCurrentTime12 = this.vid.target.nativeElement.currentTime;
    this.minutes = "0" + Math.floor(videoCurrentTime12 / 60);
    var seconds = "0" + Math.floor(videoCurrentTime12 - this.minutes * 60);
    var dur = this.minutes.substr(-2) + ":" + seconds.substr(-2);
    document.getElementById(id).innerHTML +=
      "<span style='float:right;font-size:13px;'>" + dur + "</span>";
    $(id).attr("title", dur);
    if (id == "markinvl" + this.markinvl) {
      this.MarkInValue = videoCurrentTime12;
    }
    if (id == "markoutval" + this.markoutval) {
      this.MarkOutValue = videoCurrentTime12 + 1;
      console.log("markout timing is", this.MarkOutValue);
      this.totalDuration = this.MarkOutValue - this.MarkInValue;
      this.markinArray.push(this.MarkInValue.toFixed(3));
      this.totaldurarray.push(this.totalDuration.toFixed(3));
      this.durationarray = this.markinArray.concat(this.totaldurarray);
    }
  }

  getProgressCurrentTime(x) {
    var video = $("#myVideo");
    var progress = $(".vjs-play-progress.vjs-slider-bar")[0];

    console.log(this.vid.target.nativeElement.duration);
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.vid.target.nativeElement.duration;
    var position = x - progress.offsetLeft;
    var percentage = (100 * position) / progress.clientWidth;
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    var Ctime = (maxduration * percentage) / 100;
    return Ctime;
  }

  play() {
    var videoCurrentTime1 = this.videoElement.nativeElement.currentTime;
    var totalduration1 = this.videoElement.nativeElement.duration;
    var video = $("#myMovie");
    video.bind("timeupdate", function () {
      var videoCurrentTime = videoCurrentTime1;
      var totalduration = totalduration1;
      var minutes: any;
      minutes = "0" + Math.floor(videoCurrentTime / 60);
      var seconds = "0" + Math.floor(videoCurrentTime - minutes * 60);
      var dur = minutes.substr(-2) + ":" + seconds.substr(-2);
      var minutes_d: any;
      minutes_d = "0" + Math.floor(totalduration / 60);
      var seconds_d = "0" + Math.floor(totalduration1 - minutes_d * 60);
      var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
      $("#currentduration").html(dur);
      $("#totalduration").html(dur_d);
    });

    var CurrentPosition = this.videoElement.nativeElement.currentTime;
    var dur = this.videoElement.nativeElement.duration;
    video.on("timeupdate", function () {
      var currentPos = CurrentPosition;
      var maxduration: any;
      maxduration = dur;
      var perc = (100 * currentPos) / maxduration;
      $(".timeBar").css("width", perc + "%");
      $(".current").text(timeFormat(currentPos));
    });
    var timeFormat = function (seconds) {
      var m: any;
      m =
        Math.floor(seconds / 60) < 10
          ? "0" + Math.floor(seconds / 60)
          : Math.floor(seconds / 60);
      var s =
        Math.floor(seconds - m * 60) < 10
          ? "0" + Math.floor(seconds - m * 60)
          : Math.floor(seconds - m * 60);
      return m + ":" + s;
    };
    var perc = (100 * CurrentPosition) / totalduration1;
    $(".timeBar").css("width", perc + "%");

    if (perc == 100) {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
    }
  }

  timer() {
    if (this.playStatus == "Pause") {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/pause.png')";
      this.playStatus = "Play";
      this.videoElement.nativeElement.play();
    } else {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
      this.playStatus = "Pause";
      this.videoElement.nativeElement.pause();
    }
    setInterval(() => {
      this.play();
    }, 1000);
  }

  fullscreen() {
    var videofullscreen = document.getElementById("myMovie");
    videofullscreen.requestFullscreen();
  }
  MuteUnmute() {
    //  var videofullscreen=document.getElementById('myMovie')
    if (this.volumeStatus == "unmute") {
      this.videoElement.nativeElement.muted = false;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/volume.png')";
      this.volumeStatus = "mute";
    } else {
      this.videoElement.nativeElement.muted = true;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/mute.png')";
      this.volumeStatus = "unmute";
    }
  }

  updateprogressbar(e) {
    var progress = $(".progress");
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    this.videoElement.nativeElement.currentTime =
      (maxduration * percentage) / 100;
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  openvideocomment(id) {
    window.open("#/comment?q=" + id, "_blank");
  }
  openvideokeywords(id) {
    window.open("#/keywords?q=" + id, "_blank");
  }

  activeLtoLink(ltoIdVideoData) {
    this.videoservice.activeLtoLink().subscribe((data: any) => {
      if (data.length != 0) {
        var ltoIdArchive = data[0].ltoid;
        //console.log("ltoIdArchive",ltoIdArchive);
        if (ltoIdArchive == ltoIdVideoData) {
          this.activeLtoDot = 1;
        } else {
          this.activeLtoDot = 0;
        }
      }
    });
  }
  //getting microphone text from its service
  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startMicroPhoneService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopMicroPhoneService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }
  setsearchkey(e) {
    this.Searchbyfield = this.Searchbyfield.substring(
      0,
      this.Searchbyfield.lastIndexOf(" ")
    );
    this.Searchbyfield = this.Searchbyfield.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashser.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }
  CloseDialogUpdate(event: any) {
    this.videoupdate = false;
  }
}

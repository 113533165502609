import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ImageuploadService } from "src/app/services/files/imageupload.service";

@Component({
  selector: "app-master-relation",
  templateUrl: "./master-relation.component.html",
  styleUrls: ["./master-relation.component.css"],
})
export class MasterRelationComponent implements OnInit {
  @ViewChild("dt", { static: false }) table1: any;
  keywordlist: any[] = [];
  keywordinformation: any;
  keywordForm: FormGroup;
  msgs: Message[] = [];
  Header: string = "Add Keyword";
  ButtonStatus: string = "Save";
  id: string;
  allrelation: any[] = [];
  keyword: string = "";
  first: number = 0;
  constructor(
    private imageService: ImageuploadService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService,
    public dashboadservice: ImageuploadService
  ) {}

  ngOnInit() {
    this.getallrelation();
    this.CreatekeywordForm();
  }

  getallrelation() {
    this.imageService.allrealtionlist().subscribe((data: any[]) => {
      // console.log(data);

      if (data.length > 0) {
        this.allrelation = data;
      }
    });
  }
  CreatekeywordForm() {
    this.keywordForm = this.formBuilder.group({
      groupname: [null, [Validators.required, this.removeSpaces]],
      id: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveCountry(formval) {
    this.keywordinformation = formval.value;
    this.keywordinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.keywordinformation.id > 0) {
      this.imageService
        .checkduplicaterelation(this.keywordinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.imageService
              .Updaterelation(this.keywordinformation)
              .subscribe((_data: any) => {
                this.getallrelation();
                this.keywordForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Updated successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "RelationName Already Exist",
            });
          }
        });
    } else {
      this.imageService
        .checkduplicaterelation(this.keywordinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.imageService
              .Createrelation(this.keywordinformation)
              .subscribe((_data: any) => {
                this.getallrelation();
                this.keywordForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "RelationName Already Exist",
            });
          }
        });
    }
  }
  updatekeyword(allrelation: any) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Relation";
    this.keywordForm.setValue({
      groupname: allrelation.groupname,
      id: allrelation.id,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  deletekeyword(id: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "masterrelationkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.imageService.Deleterelation(id).subscribe((data: any[]) => {
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.getallrelation();
          this.keywordForm.reset();
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.keywordForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add keyword";
  }

  searchkeywords() {
    var keyword = this.keyword;
    if (this.table1 != undefined) {
      this.table1.first = 0;
    }
    if (keyword == "") {
      this.getallrelation();
    } else {
      this.imageService.getrelationsrch(keyword).subscribe((data: any[]) => {
        this.allrelation = data;
      });
    }
  }
}

import { EventEmitter, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { forkJoin, Observable, Subject, Subscriber, throwError } from "rxjs";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { catchError, mapTo, retry } from "rxjs/operators";
import { TreeNode } from "primeng/api";
import { resolve } from "url";
import { map } from "jquery";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private subject = new Subject<any>();
  private keywordsubject = new Subject<any>();
  private bysubjectsubject = new Subject<any>();
  private usersubject = new Subject<any>();
  private publicationsubject = new Subject<any>();
  private pdftypesubject = new Subject<any>();
  private cartlistsubject = new Subject<any>();
  private relationsubject = new Subject<any>();
  treeDataComp=new EventEmitter();
  keywordlist: any[] = [];
  bysubjectlist: any[] = [];
  userlist: any[] = [];
  publicationlist: any[] = [];
  pdftypelist: any[] = [];
  cartlist: any[] = [];
  relationlist: any[] = [];
  constructor(private httpclient: HttpClient) {}
  baseurl = environment.ApiUrl;
  openSideMenuPopups = new EventEmitter();
  openNewFolder = new EventEmitter();
  openAddYearpopup = new EventEmitter();
  openEditCompopup = new EventEmitter();
  openNewMonthpopup = new EventEmitter();
  openEditYearpopup = new EventEmitter();
  openEditMonthpopup = new EventEmitter();
  openCheckInFIlepopup = new EventEmitter();
  openCheckInFolderpopup = new EventEmitter();
  saveImageDataEmit = new EventEmitter();
  treeriglckEmit = new EventEmitter();
  saveCollectionEmit = new EventEmitter();
  checkAllPermissionsemit = new EventEmitter();
  deleteCollectionemit = new EventEmitter();
  editCollectionEmit = new EventEmitter();
  droppedOnTreeEmit = new EventEmitter();
  appendFolderEmit = new EventEmitter();
  bindTreeContectEmit = new EventEmitter();
  getTreeJsonEmit = new EventEmitter();
  gettreeonuploadEmit = new EventEmitter();
  getTreeJson1Emit = new EventEmitter();
  showPerpopupemit = new EventEmitter();
  downloadSelectedImg = new EventEmitter();
  addCompany = new EventEmitter();
  NotificationEmitter = new EventEmitter();
  closeimagedialogEE = new EventEmitter();

  public eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  emitEvent(data: any) {
    this.eventEmitter.emit(data);
  }

  treeCall(param){
    this.treeDataComp.emit(param);
  }

  getFolderData(obj){
    this.treeDataComp.emit(obj);
  }

  openAddCompany(e){
    this.openSideMenuPopups.emit(e);
  }

  openAddFolder(e){
    this.openNewFolder.emit(e);
  }

  openAddYear(newYearcolpop){
    this.openAddYearpopup.emit(newYearcolpop);
  }

  openEditCom(editcolpop){
    this.openEditCompopup.emit(editcolpop);
  }

  openNewMonth(newMonthcolpop){
    this.openNewMonthpopup.emit(newMonthcolpop);
  }

  openEditYear(editYearcolpop){
    this.openEditYearpopup.emit(editYearcolpop)
  }

  openEditMonth(editMonthcolpop){
    this.openEditMonthpopup.emit(editMonthcolpop);
  }

  openCheckInFile(checkinpop){
    this.openCheckInFIlepopup.emit(checkinpop);
  }

  openCheckInFolder(checkinfldrpop){
    this.openCheckInFolderpopup.emit(checkinfldrpop);
  }

  // saveImageData(){
  //   this.saveImageDataEmit.emit();
  // }

  treeriglckEvent(e){
    this.treeriglckEmit.emit(e);
  }

  saveCollection(dialogType){
    this.saveCollectionEmit.emit(dialogType);
  }

  editCollection(dialogType){
    this.editCollectionEmit.emit(dialogType);
  }

  checkAllPermissions(){
    this.checkAllPermissionsemit.emit();
  }

  droppedOnTree(dataforup){
    this.droppedOnTreeEmit.emit(dataforup);
  }

  deletecollection(){
    this.deleteCollectionemit.emit();
  }

  appendFolder(obj){
    this.appendFolderEmit.emit(obj);
  }

  bindTreeContext(){
    this.bindTreeContectEmit.emit();
  }

  getTreejson(e){
    this.getTreeJsonEmit.emit(e);
  }

  gettreeonupload(){
    this.gettreeonuploadEmit.emit();
  }

  getTreejson1(e){
    this.getTreeJson1Emit.emit(e);
  }

  downloadSelectedImage(obj){
    this.downloadSelectedImg.emit(obj);
  }

  closeimagedialog(){
    this.closeimagedialogEE.emit();
  }

  sendtoNotification(rtnarr){
    this.NotificationEmitter.emit(rtnarr);
  }

  addnewCompany(){
    this.addCompany.emit(true);
  }

  showPerpopup(){
    this.showPerpopupemit.emit();
  }

  visibletreedrop(val: boolean) {
    this.subject.next(val);
  }
  gettreedropstatus(): Observable<any> {
    return this.subject.asObservable();
  }

  keywordropdata(data) {
    this.keywordlist = data;
    this.keywordsubject.next(data);
  }
  getkeywordlist(): Observable<any> {
    this.keywordsubject.next(this.keywordlist);
    return this.keywordsubject.asObservable();
  }

  bysubjectdata(data) {
    this.bysubjectlist = data;
    this.bysubjectsubject.next(data);
  }
  getbysubjectlist(): Observable<any> {
    this.bysubjectsubject.next(this.bysubjectlist);
    return this.bysubjectsubject.asObservable();
  }

  userdata(data) {
    this.userlist = data;
    //console.log("data service",data);

    this.usersubject.next(data);
  }
  getuserlist(): Observable<any> {
    this.usersubject.next(this.userlist);
    return this.usersubject.asObservable();
  }

  publicationdata(data) {
    this.publicationlist = data;
    this.publicationsubject.next(data);
  }
  getpublicationdata(): Observable<any> {
    this.publicationsubject.next(this.publicationlist);
    return this.publicationsubject.asObservable();
  }

  pdftypedata(data) {
    this.pdftypelist = data;
    this.pdftypesubject.next(data);
  }
  getpdftypedata(): Observable<any> {
    this.pdftypesubject.next(this.pdftypelist);
    return this.pdftypesubject.asObservable();
  }

  cartlistdata(data) {
    this.cartlist = data;
    this.cartlistsubject.next(data);
  }
  getcartlistdata(): Observable<any> {
    this.cartlistsubject.next(this.cartlist);
    return this.cartlistsubject.asObservable();
  }

  relationlistdata(data) {
    this.relationlist = data;
    this.relationsubject.next(data);
  }
  getrelationlist(): Observable<any> {
    this.relationsubject.next(this.relationlist);
    return this.relationsubject.asObservable();
  }

  //this API is not working
  searchfolder(srchtxt): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/searchfolder",
      {
        srchtxt: srchtxt,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  GetAllDocument(): Observable<DocumentUploadFormModel[]> {
    return this.httpclient.get<DocumentUploadFormModel[]>(
      this.baseurl + "Dashboard/getAllDocument"
    );
  }
  GetAllImages(type): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + 
      "Dashboard/getAllImages?type=" + type
    );
  }

  
  // GetAllImagesNew(type,company_id:string): Observable<any[]> {
  //   return this.httpclient.get<any[]>(
  //     this.baseurl + 
  //     `Dashboard/getAllImagesNew?type=${type}&company_id=${company_id}`
  //   );
  // }

  GetAllCompanies(userId:string): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + 
      `Dashboard/getAllCompanies?userId=${userId}`
    );
  }

  editCompanyShortName(compshortname,companyName): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + 
      "Dashboard/editCompanyShortName",
      {
        compshortname:compshortname,
        companyName:companyName
      }
    );
  }

  GetAllBackgroundImages(userId:string): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + 
      `Dashboard/getAllBackgroundImages?userId=${userId}`
    );
  }

  uploadCompanyImage(formData: FormData,companyName: string,imageType: string): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + `Dashboard/uploadCompanyImage?&companyName=${companyName}&imageType=${imageType}`, formData
    )
  }


  GetAllMagazineCount(): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + "Dashboard/GetAllMagazineCount"
    );
  }
  getAllstory(): Observable<StoryFormModel[]> {
    return this.httpclient.get<StoryFormModel[]>(
      this.baseurl + "Dashboard/getAllStory"
    );
  }

  GetAllVideo(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "Dashboard/getallvideo");
  }
  GetAllAudio(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "Dashboard/getallaudio");
  }

  blindsearch(tabstr, limit): Observable<any[]> {
    //console.log("tabstr",tabstr)
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/blindsearch", {
      tabstr: tabstr,
      limit: limit,
      maxlimit: maxlimit,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  blindsearchwithdata(srchtext, type, tabstr, limit): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/blindsearchwithdata",
      {
        srchtext: srchtext,
        type: type,
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  // this api is not in node
  advancesearch(advcsrchdata: ImageUploadFormModel): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/advancesearch",
      advcsrchdata
    );
  }

  //this function called for search in dashboard
  newadvancesearch(srchtxt, tabstr, limit): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/newadvancesearch",
      {
        srchtxt: srchtxt,
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  //this function called for search in collection
  newadvancesearchtree(srchtxt, tabstr, limit, collpath): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/newadvancesearch",
      {
        srchtxt: srchtxt,
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        collpath: collpath,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  blindsearchwithdatatree(
    srchtext,
    type,
    tabstr,
    limit,
    searchin
  ): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/blindsearchwithdata",
      {
        srchtext: srchtext,
        type: type,
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        searchin: searchin,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }


  // getCustomContextMenuItems():Observable<any[]>{
  //   return this.httpclient.post<any[]>(
  //     this.baseurl + "treeviewupload/customContextMenu",
  //     {
  //       userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
  //     }
  //   )
  // }

  getacounttree(advcsrchdata, searchin, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/getncount",
      {
        advcsrchdata: advcsrchdata,
        searchin: searchin,
        tabstr: tabstr,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getfulltxttreecount(advcsrchdata): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/fulltextsrchcount",
      {
        advcsrchdata: advcsrchdata,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getfulltxttree(srchtext, type, tabstr, limit): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/fulltextsrch",
      {
        srchtext: srchtext,
        type: type,
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getncount(srchtext, tabstr, collpath): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getacount", {
      srchtext: srchtext,
      tabstr: tabstr,
      collpath: collpath,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  getacount(advcsrchdata, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getncount", {
      advcsrchdata: advcsrchdata,
      tabstr: tabstr,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  getdocumentdata(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getdocument", {
      itemid: itemid,
      path: path,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }
  getimagedata(itemid, path): Observable<any[]> {
    // console.log("path", path);
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getimage", {
      itemid: itemid,
      path: path,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }
  getstorydata(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getstory", {
      itemid: itemid,
      path: path,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }
  getaudiodata(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getaudio", {
      itemid: itemid,
      path: path,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }
  getvideodata(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/getvideo", {
      itemid: itemid,
      path: path,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  //this API is not working
  downloadfromallitems(arr): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/downloadfromallitems",
      {
        arr: arr,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  exportvideoforlocal(path, id, filename): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/exportvideoforlocal",
      {
        path: path,
        id: id,
        filename: filename,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getitemtypes(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "Dashboard/getitemtype");
  }

  getpdftype(): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + "DocumentUpload/getpdftype"
    );
  }

  gettreejson(lvl1, lvl2, key, prkey): Observable<any[]> {
    return this.httpclient.post<TreeNode[]>(
      this.baseurl + "treeviewupload/gettree",
      {
        level1: lvl1,
        level2: lvl2,
        key: key,
        prvs_key: prkey,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getfolderdata(key, path): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/getfolderdata",
      {
        fkey: key,
        path: path,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  // CustomTreeView(): Observable<any[]> {
  //   return this.httpclient.get<any[]>(
  //     this.baseurl + "treeviewupload/CustomTreeView",
  //   );
  // }



  foldersearch(srchtext, type, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/blindsearchwithdata",
      {
        srchtext: srchtext,
        type: type,
        tabstr: tabstr,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  //this function with API is not working
  folderadvncsrch(srchtxt, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/newadvancesearch",
      {
        srchtxt: srchtxt,
        tabstr: tabstr,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  createcoll(pkey, path,compshortname): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/createcol",
      {
        pkey: pkey,
        path: path,
        compshortname: compshortname,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  editcoll(pkey, path, colname): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/editcol",
      {
        pkey: pkey,
        path: path,
        colname: colname,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  dltcoll(pkey, name): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/deltecol",
      {
        pkey: pkey,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        name: name,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  customcmShow(): Observable<any[]> {
    return this.httpclient.post<any>(
      this.baseurl + "treeviewupload/customcmShow",
      {
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      }
    )
  }

  sendRqstfordownload(rtnarr: any): Observable<any[]> {
    return this.httpclient.post<any>(
      this.baseurl + "treeviewupload/sendRqstfordownload", 
      {
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        rtnarr
      }
    )
  }


  checkdir(pkey): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/checkdir",
      {
        pkey: pkey,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  //this API is not working currently
  checkfiledup(dirpath): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/checkfiledup",
      {
        dirpath: dirpath,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  createlog(itemid, action, userid, table): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/createlog", {
      itemid: itemid,
      action: action,
      table: table,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  // hitted on drag and drop file in collection
  // uploadfiles(tmppath, formdata, filelength): Observable<any> {
  //   return this.httpclient.post<string>(this.baseurl + "treeviewupload/api/upload2?tmppath=raw/" + tmppath + "&filelength=" + filelength, formdata, { responseType: 'json' }).pipe(retry(3), catchError(this.handleError))
  // }

  uploadfiles(tmppath, formdata, filelength): Observable<any> {
    return this.httpclient.post<string>(
      this.baseurl +
        "treeviewupload/api/upload2?tmppath=raw/" +
        tmppath +
        "&filelength=" +
        filelength,
      formdata,
      { reportProgress: true, observe: "events" }
    );
  }

  uploaddata(fpath, filename, userid, data, pathkey, parentTreeid,companyIdValue): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "treeviewupload/api/upload",
      {
        fpath: fpath,
        filename: filename,
        userid: userid,
        data: data,
        pathkey: pathkey,
        parentTreeid: parentTreeid,
        companyIdValue:companyIdValue,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }
  // working in collection while uploading the files
  // uploadfilesanddata(tmppath, formdata, filelength, fpath, filename, userid, dataUpload, pathkey): Promise<Observable<any[]>> {
  //   //  console.log(tmppath, formdata, filelength, fpath, filename, userid, data, pathkey);

  //     let uploaddata
  //   let uploadfiles

  //   return new Promise((resolve, reject) => {
  //     this.uploadfiles(tmppath, formdata, filelength).toPromise().then( uploadfiles => {
  //          console.log(uploadfiles); //should output connected
  //          resolve(this.uploaddata(fpath, filename, userid, dataUpload, pathkey));
  //          return forkJoin([uploadfiles, uploaddata])
  //      });
  //  });

  //working on drag and drop of file to upload
  // let uploaddata
  // let uploadfiles
  // this.uploadfiles(tmppath, formdata, filelength).subscribe((data: any) => {
  //   console.log(data);
  //   uploadfiles = data
  //   this.uploaddata(fpath, filename, userid, dataUpload, pathkey).subscribe((data: any) => {
  //     console.log(data);
  //     uploaddata = data

  //   })
  // })

  //     let promise = new Promise((resolve, reject)=>{
  //        this.httpclient.post<string>(this.baseurl + "treeviewupload/api/upload2?tmppath=raw/" + tmppath + "&filelength=" + filelength, formdata, { responseType: 'json' }).pipe(retry(3), catchError(this.handleError)).toPromise().then((data: any) => {
  //       console.log(data);
  //       uploadfiles = data
  //       this.httpclient.post<any[]>(this.baseurl + "treeviewupload/api/upload", { fpath: fpath, filename: filename, userid: userid, data: data, pathkey: pathkey }).toPromise().then((data: any) => {
  //         console.log(data);
  //         uploaddata = data

  //         console.log("uploadfiles, uploaddata", uploadfiles, uploaddata);
  //       })
  //     })
  //     }).then(
  //       return forkJoin<any>([uploadfiles, uploaddata])
  // )
  // return forkJoin([this.uploadfiles(tmppath, formdata, filelength).pipe().toPromise(), this.uploaddata(fpath, filename, userid, dataUpload, pathkey).pipe().toPromise() ])

  // let uploadfiles = this.uploadfiles(tmppath, formdata, filelength).toPromise().then((data) => {
  //   uploadfiles = data
  //   uploaddata = this.uploaddata(fpath, filename, userid, dataUpload, pathkey).toPromise().then((data)=>{
  //     uploaddata = data
  //     return Observable.forkJoin([uploadfiles, uploaddata]).subject
  //   })

  // })

  // return new Promise((resolve, reject) => {
  //   this.uploadfiles(tmppath, formdata, filelength).toPromise().then(uploadfiles => {
  //     console.log(uploadfiles); //should output connected
  //     return new Promise((resolve, reject) => {
  //       this.uploaddata(fpath, filename, userid, dataUpload, pathkey).toPromise().then((uploaddata) => {
  //         console.log(uploaddata); //should output connected

  //         return resolve(forkJoin([uploadfiles, uploaddata]))

  //         // return forkJoin([uploadfiles, uploaddata])
  //       })
  //     })
  //   });
  // });

  //   return new Promise((resolve, reject) => {
  //     this.uploadfiles(tmppath, formdata, filelength).toPromise().then( uploadfiles => {
  //          console.log(uploadfiles); //should output connected
  //          this.uploaddata(fpath, filename, userid, dataUpload, pathkey).toPromise().then((uploaddata)=>{
  //           console.log(uploaddata); //should output connected
  //           return forkJoin([uploadfiles, uploaddata])
  //          })

  //      });
  //  });

  // return this.uploadfiles(tmppath, formdata, filelength).toPromise().then((data: any) => {
  //   uploadfiles = data
  //   console.log(uploadfiles);

  //   return this.uploaddata(fpath, filename, userid, dataUpload, pathkey).toPromise().then((data: any) => {
  //     uploaddata = data
  //     console.log(uploaddata);

  //     return forkJoin([uploadfiles, uploaddata])
  //   })
  // })

  // let uploadfiles = this.uploadfiles(tmppath, formdata, filelength).pipe()
  // let uploaddata =  this.uploaddata(fpath, filename, userid, dataUpload, pathkey).pipe()
  // return  forkJoin<any>([uploadfiles, uploaddata]).pipe()
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  getkeywords(name): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "category/getallkeywords",
      { name: name }
    );
  }

  getallbysubject(): Observable<any[]> {
    return this.httpclient.get<any[]>(
      this.baseurl + "DocumentUpload/getbysubject"
    );
  }

  getallusers(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "user/userData");
  }

  getdictionary(value): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "category/getdictionary",
      { value: value }
    );
  }

  getcartlist(userid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "agency/getautodata", {
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }

  savetreepermission(userid, collname): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "user/savetreepermission",
      {
        collectionname: collname,
        updatedby: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
        useridnewuser: userid,
      }
    );
  }

  gettreeper(collid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "user/gettreeper", {
      treeid: collid,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
    });
  }
  deletetempstatusfile(value): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/deletefilestatus",
      {
        idvalue: value,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }
  getfilestauts(): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/getfilestatus",
      {
        updatedby: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getAllDeletedData(tabstr, limit): Observable<any[]> {
    var maxlimit = "54";
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows;
      maxlimit = (Number(maxlimit) * 2).toString();
    }
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/getdeleteddata",
      {
        tabstr: tabstr,
        limit: limit,
        maxlimit: maxlimit,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  getCollectionPath(collpath: any) {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/GET_COLLECTION",
      {
        collpath: collpath,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        CenterId: JSON.parse(localStorage.getItem("userdetails"))[0].CenterId,
      }
    );
  }

  Loguserdata(data: any) {
    return this.httpclient.post<any[]>(this.baseurl + "Dashboard/loguserdata", {
      data: data,
      UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
        .UserFirstName,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  // getlogUserdata
  getlogUserdata(taskpermission, Start_Date: any, End_Date: any) {
    if (Start_Date == undefined || Start_Date == null || Start_Date == "") {
      return this.httpclient.post<any[]>(
        this.baseurl + "Dashboard/getlogUserdata",
        {
          UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
            .UserFirstName,
          userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
          taskid: taskpermission,
          Start_Date: Start_Date,
        }
      );
    } else {
      return this.httpclient.post<any[]>(
        this.baseurl + "Dashboard/getlogUserdataondate",
        {
          UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
            .UserFirstName,
          userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
          taskid: taskpermission,
          Start_Date: Start_Date,
          End_Date: End_Date,
        }
      );
    }
  }
  getlogUserdataonstatus(
    taskpermission,
    status,
    Start_Date: any,
    End_Date: any
  ) {
    if (Start_Date == undefined || Start_Date == null || Start_Date == "") {
      return this.httpclient.post<any[]>(
        this.baseurl + "Dashboard/getlogUserdataonstatus",
        {
          UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
            .UserFirstName,
          userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
          taskid: taskpermission,
          Status: status,
        }
      );
    } else {
      return this.httpclient.post<any[]>(
        this.baseurl + "Dashboard/getlogUserdataondateandstatus",
        {
          UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
            .UserFirstName,
          userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
          taskid: taskpermission,
          Start_Date: Start_Date,
          End_Date: End_Date,
          Status: status,
        }
      );
    }
  }

  updatestatus(status: any, clip_id: any) {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/updatestatus",
      {
        UserName: JSON.parse(localStorage.getItem("userdetails"))[0].LoginId,
        status: status,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        clip_id: clip_id,
      }
    );
  }
  updatestatusreject(status: any, clip_id: any, caption: any) {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/updatestatusreject",
      {
        UserName: JSON.parse(localStorage.getItem("userdetails"))[0].LoginId,
        status: status,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        clip_id: clip_id,
        caption: caption,
      }
    );
  }
  approverejecthistory(clip_id: any) {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/approverejecthistory",
      {
        UserName: JSON.parse(localStorage.getItem("userdetails"))[0]
          .UserFirstName,
        userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
        clip_id: clip_id,
      }
    );
  }
  updateuserlog(description: any, clip_iddata: any) {
    return this.httpclient.post<any[]>(
      this.baseurl + "Dashboard/updateuserlog",
      {
        description: description,
        clip_iddata: clip_iddata,
        UserName: JSON.parse(localStorage.getItem("userdetails"))[0].LoginId,
      }
    );
  }
}

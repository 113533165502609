import {
  Component,
  OnInit,
  Input,
  ɵPlayer,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { Router } from "@angular/router";
import { CategoryService } from "src/app/services/masters/category.service";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { environment } from "src/environments/environment";
import { BaseAudioPlayerFunctions } from "ngx-audio-player/lib/component/base/base-audio-player-components";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { MessageService, ConfirmationService, MenuItem } from "primeng/api";
import { Paginator } from "primeng/paginator";
import { ContextMenu } from "primeng/contextmenu";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
@Component({
  selector: "app-audio-upload",
  templateUrl: "./audio-upload.component.html",
  styleUrls: ["./audio-upload.component.css"],
})
export class AudioUploadComponent implements OnInit {
  @ViewChild("audio", { static: true }) Player: ElementRef;
  // @ViewChild("conetxtmenudiv", { static: false }) public conetxtmenudiv: ContextMenu
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  display: boolean = false;
  audioList: audiouploadModel[] = [];
  showAudioSearch: boolean = false;
  Searchbyfield: string;
  fileurl;
  viewAudio: boolean = false;
  currentFileUpload: File;
  //  ExportList:exportConfigurationModel[]=[]
  downloadAudioList: downloadimagesmodel[] = [];
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0;
  Audio_Edit_form: FormGroup;
  selectedaudio: audiouploadModel;
  audiouploadinformation: audiouploadModel;
  first: number;
  view: boolean = false;
  showAudioDownload: boolean = false;
  fullAudiolist: audiouploadModel;
  ApiServerPath = environment.ApiServerPath;
  ratingitem: any;
  Audiopreviewpath: string;
  audioimage: string = "assets/image/Wave_bg.jpg";
  audioupdate: boolean = false;
  glbDelete: number = 0;
  glbupdate: number = 0;
  permissionlist: PermissionModel[];
  deletebtn: number = 0;
  editbtn: number = 0;
  uploadbtn: number = 0;
  downloadbtn: number = 0;
  adnvacesrchfields: any[];
  selectedIndexs: audiouploadModel[] = [];
  selectedIndexspermis: any[] = [];
  rightclickmenuopen: boolean = false;
  ismenuclick: boolean = false;
  AudioUpdated: boolean = false;
  contextmenuitem: MenuItem[];
  addtocartbtn: number = 0;
  approvebtn: number = 0;
  showcart: boolean = false;
  idforedit: string = "";
  nameforedit: string = "";
  edittable: string = "";
  orgname: string = "";
  loading: boolean = true;
  showmax: number = 0;
  showmin: number = 1;
  cartitemid: string;
  cartname: string;
  results: string[] = [];
  rowperpage: Number = 18;
  tablestyle: string = "grid";
  filteredBrands: any[];
  microphone: number = 0;
  autoCompletArr: any[] = [];
  indexPrev: any = null;
  indexCurrent: any;

  dropdownSelectedCity: any;
  selectedCity: any = 0;

  constructor(
    public imageservice: ImageuploadService,
    private storyservice: StoryserviceService,
    private router: Router,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    public subcategoryService: SubCategoryService,
    private messageservice: MessageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private confirmationService: ConfirmationService,
    public audiouploadservice: AudiouplaodService,
    private dashboarservice: DashboardService,
    private speechTextService: SpeechTextService,
    public dashser: DashboardService
  ) {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
  }

  ngOnInit() {
    // this.getallAudio();
    this.selectedCity = localStorage.getItem("selectedCity");
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    // console.log("Server path is", this.ApiServerPath);
    this.dashser.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });

    this.checkpermissions();
    // this.Searchbyfield = environment.globalsearchtext
    // this.adnvacesrchfields = environment.advancesrchel
    this.Searchbyfield = "";

    this.adnvacesrchfields = [];

    if (this.Searchbyfield == null) {
      this.Searchbyfield = "";
    }
    if (this.Searchbyfield == "") {
      if (this.adnvacesrchfields.length < 1) {
        this.SearchByFields();
      } else {
        this.SearchAllAudio(this.adnvacesrchfields);
      }
    } else {
      this.SearchByFields();
    }
    //this.getTodayAudio()

    this.dashser.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });
    this.CreateAudioEditForm();
    this.getcategorydata();
    this.fullAudiolist = new audiouploadModel();
    this.selectedaudio = new audiouploadModel();
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });

    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Add to cart",
        icon: "fa fa-shopping-cart",
        command: (event) => {
          this.showmulcartdiv();
        },
      },
      {
        label: "Edit",
        icon: "fa fa-edit",
        command: (event) => {
          this.multipleedit();
        },
      },
      // {
      //   label: 'Delete',
      //   icon: 'fa fa-trash',
      //   command: (event) => { this.deletemultipleimg(); }
      // },
      {
        label: "Add ACL",
        icon: "fa fa-ban",
        command: (event) => {},
      },
      {
        label: "Remove ACL",
        icon: "fa fa-times-circle-o",
        command: (event) => {},
      },

      {
        label: "Approve",
        icon: "fa fa-check",
        command: (event) => {},
      },

      // {
      //   label: "Set Relationship",
      //   icon: "fa fa-link",
      //   command: (event) => {},
      // },
    ];

    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.speechTextService.init();
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
  }
  async downloadmultiple() {
    this.ismenuclick = true;
    // console.log("download clicked");

    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var imageid = "";
    var rtnarr = [];
    var lst = this.selectedIndexs;
    for (let i = 0; i < lst.length; i++) {
      imageid = lst[i].toString();
      var iid = document.getElementById(imageid);
      var filepath = iid.children[0].innerHTML.trim();
      var fname = iid.children[2].innerHTML.trim();
      // filepath = filepath.replace("lowres/", "raw/")
      //  console.log(lst.length)
      if (lst.length > 1) {
        rtnarr.push({ filepath: filepath, filename: fname, itemid: imageid });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      } else {
        this.downloadFullImage(fname, filepath, imageid);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async downloadFullImage(filename, filepath, itemid) {
    let unsub = this.audiouploadservice
      .Testdownload(filepath)
      .subscribe(async (data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();

        // let unsub = this.audiouploadservice.Testdownload(filepath).subscribe(async (data: any) => {
        //    console.log("data");

        //   if (data != null) {
        //     var b64Data1 = data.split(";")[1]
        //     const byteCharacters = atob(b64Data1);

        //     const byteNumbers = new Array(byteCharacters.length);
        //     for (let i = 0; i < byteCharacters.length; i++) {
        //       byteNumbers[i] = byteCharacters.charCodeAt(i);
        //     }
        //     const byteArray = new Uint8Array(byteNumbers);
        //     const blob = new Blob([byteArray], { type: data.split(";")[0].split(":")[1] });
        //     //let byteCharacters = atob(data);

        //     var url = window.URL.createObjectURL(blob);
        //     var a = document.createElement('a');
        //     document.body.appendChild(a);
        //     a.setAttribute('style', 'display: none');
        //     a.href = url;
        //     a.download = filename;
        //     a.click();
        //     window.URL.revokeObjectURL(url);
        //     a.remove(); // remove the element
        //     const file = new File([blob], filename);
        //     this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'File Downloaded...' });
        //   } else {
        //     this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Error Message', detail: 'Original File Does Not Exist.' });

        //   }
        //   unsub.unsubscribe()
      });
    // var x = document.querySelector(".downloadinggif1")
    // //x.classList.add("downloadinggif")
    // x["style"].display = "none";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "none";
    this.removeselection();
    this.dashser
      .createlog(
        itemid,
        "Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "transaction_audio"
      )
      .subscribe((data1: any[]) => {});
  }

  downloadmultiplezip(arr) {
    this.audiouploadservice.downloadmultiplefn(arr).subscribe((data: any) => {
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  showmulcartdiv() {
    if (this.addtocartbtn == 1) {
      this.showcart = true;
      this.ismenuclick = true;
    }
  }

  // showacldaterangediv() {
  //   this.ismenuclick = true
  //   this.showaclrange = true
  // }

  deletemultipleimg() {
    if (this.deletebtn == 1) {
      this.ismenuclick = true;
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "audiouploadkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const lst = this.selectedIndexs;
          // console.log("lst",lst);
          if (lst.length > 0) {
            this.audiouploadservice
              .deletemultipleimg(lst)
              .subscribe((data: any[]) => {
                let returnMsg: any = data;
                if (returnMsg == "Success") {
                  this.messageservice.add({
                    key: "message",
                    severity: "info",
                    summary: "Success Message",
                    detail: "Delete successfully",
                  });
                  this.rightclickmenuopen = false;
                  this.ismenuclick = false;
                  this.SearchByFields();
                }
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "warn",
              summary: "Warn Message",
              detail: "File Not Deleted",
            });
            this.rightclickmenuopen = false;
            this.ismenuclick = false;
          }
        },
        reject: () => {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        },
      });
    }
  }

  multipleedit() {
    // console.log(this.selectedIndexs, "multiple edit clicked");
    if (this.editbtn == 1) {
      this.ismenuclick = true;
      var itemid = "";
      var nameforedit1 = "";
      var orgname = "";
      var path = "normal";
      var lst = this.selectedIndexs;
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        itemid += this.selectedIndexs[i] + "~";

        orgname +=
          document
            .getElementById(lst[i].toString())
            .children[2].innerHTML.trim() + "~";
        if (i == this.selectedIndexs.length - 1) {
          this.rightclickmenuopen = false;
          this.ismenuclick = false;
        }
      }
      this.audioupdate = true;
      this.idforedit = itemid;
      this.nameforedit = nameforedit1;
      this.edittable = path;
      this.orgname = orgname;
    }
  }

  cancel() {
    this.idforedit = "";
  }
  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;
    //this.audiouploadservice.testzipdownload(filename).subscribe((data: any) => {
    // var x = document.querySelector(".downloadinggif1")
    // //x.classList.add("downloadinggif")
    // x["style"].display = "none";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "none";
    this.removeselection();
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    // window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    this.audiouploadservice.deletezip(cartname).subscribe((data: any[]) => {});

    //    })
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }
  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 4) {
        this.uploadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 5) {
        this.editbtn = 1;
      }
      if (this.permissionlist[i].taskid == 6) {
        this.deletebtn = 1;
      }
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
    }
  }
  onHide1() {
    this.display = false;
  }

  close() {
    this.viewAudio = false;
    this.Player.nativeElement.src = "";
  }

  Cancel() {
    this.Player.nativeElement.src = "";
  }

  showDialog() {
    this.display = true;
  }

  playfunction() {
    this.audioimage = "assets/image/waveform_easset.gif";
  }
  pausefunction() {
    this.audioimage = "assets/image/Wave_bg.jpg";
  }

  updatepopup(audioList, itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.audioupdate = true;
      this.selectedaudio = audioList;
      this.nameforedit = name;
      this.idforedit = itemid;
      this.orgname = name;
      this.updateaudio(audioList);
    }
  }

  SaveAudioAfterUpdate(formval) {
    this.audiouploadinformation = formval.value;
    this.audiouploadinformation.itemid = this.selectedIndexs;
    // console.log("this.audiouploadinformation", this.audiouploadinformation);

    this.audiouploadservice
      .UpdateAudio(this.audiouploadinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          if (this.glbupdate == 0) {
            this.getTodayAudio();
          } else {
            this.getallAudio();
          }
          this.Audio_Edit_form.reset();
          this.audioupdate = false;
        }
      });
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService
      .getSubcategoryData(c)
      .subscribe((data: Subcategoryformmodel[]) => {
        if (data != null) {
          this.subcategorylist = data;
        }
      });
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  updateaudio(audioeditlist) {
    this.getSubcategorydata(audioeditlist.categoryid);
    this.Audio_Edit_form.patchValue({
      CategoryId: audioeditlist.categoryid,
      SubCategoryId: audioeditlist.subcategoryid,
      itemcaption: audioeditlist.itemcaption,
      itemkeyword: audioeditlist.itemkeyword,
      itemrating: audioeditlist.itemrating,
      itemid: audioeditlist.itemid,
    });
  }
  CreateAudioEditForm() {
    this.Audio_Edit_form = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      itemcaption: [null],
      itemkeyword: [null],
      itemrating: [null],
      itemid: [null],
    });
  }

  fullAudiopopup(audioList, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.Player.nativeElement.src = this.ApiServerPath + audioList.HiresPath;
      this.audioimage = "assets/image/Wave_bg.jpg";
      // this.fullAudiolist=null
      this.ratingitem = audioList.itemrating;
      this.Audiopreviewpath = this.ApiServerPath + audioList.HiresPath;
      this.fullAudiolist = audioList;
      this.viewAudio = true;
    }
  }

  SearchAllAudio(formvalues) {
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }

    this.audiouploadservice
      .SearchAllAudio(formvalues)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.audioList = data;
        } else {
          this.audioList = [];
        }
      });
  }
  getTodayAudio() {
    this.audiouploadservice.getTodayAudio().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.audioList = data;
      } else {
        this.audioList = [];
      }
    });
  }

  SearchByFields() {
    if (this.microPhoneText != "") {
      this.Searchbyfield = this.microPhoneText;
    }

    if (this.Searchbyfield != null) {
      this.audiouploadservice
        .SearchByFields(this.Searchbyfield.trim())
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.audioList = data;
            // console.log(this.audioList);
          } else {
            this.audioList = [];
          }
        });
    } else {
      this.getallAudio();
    }
    this.stopMicroPhoneService();
    // this.Searchbyfield = ""
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.Searchbyfield = "";
    //this.microPhoneText = ""
    environment.globalsearchtext = "";
    this.SearchByFields();
  }

  getallAudio() {
    this.audiouploadservice.GetAllAudio().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.audioList = data;
        this.glbDelete = 1;
        this.glbupdate = 1;
      }
    });
  }

  advanceSearch() {
    this.showAudioSearch = true;

    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }
  CloseDialogUpdate(event: any) {
    this.audioupdate = false;
  }
  hideadvncsrch() {
    this.showAudioSearch = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
    this.Searchbyfield = "";
  }

  trashdata(itemid, e) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "audiouploadkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.audiouploadservice.deleteAudio(itemid).subscribe((data: any[]) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            //  if(this.glbDelete==0){
            //    this.getTodayAudio()
            //  }else{
            //    this.getallAudio();
            //  }
            this.SearchByFields();
          }
        });
      },
      reject: () => {},
    });
  }
  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.audiouploadservice
      .GetDownloadedAudioData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadAudioList = data;
          this.showAudioDownload = true;
        }
      });
  }

  downloadAudio(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filepath, filepath, itemid)
      .subscribe((data: any) => {
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        this.downloadentry(itemid);
      });
  }

  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.audiouploadservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.AudioUpdated = false;
    }
    if (event.keyCode == 27) {
      this.view = false;
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
    }
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .nextUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $("#" + tmpid)
            .prevUntil("#" + _index)
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $("#" + id1)
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $("#" + id1)
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  removeselection() {
    if (this.selectedIndexs.length > 0) {
      for (let i = 0; i < this.selectedIndexs.length; i++) {
        document.getElementById(
          this.selectedIndexs[i].toString()
        ).style.background = "transparent";
        if (i == this.selectedIndexs.length - 1) {
          this.selectedIndexs.splice(0, this.selectedIndexs.length);
          this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
        }
      }
      this.rightclickmenuopen = false;
      this.ismenuclick = false;
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        // this.conetxtmenudiv.hide()
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }

  cmshow() {
    // console.log("cmshow clicked");
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    this.contextmenuitem[2].disabled = false;
    this.contextmenuitem[3].disabled = false;
    this.contextmenuitem[4].disabled = false;
    this.contextmenuitem[5].disabled = false;
    this.contextmenuitem[6].disabled = false;
    if (this.downloadbtn == 0) {
      this.contextmenuitem[0].disabled = true;
    }
    if (this.addtocartbtn == 0) {
      this.contextmenuitem[1].disabled = true;
    }
    if (this.deletebtn == 0) {
      this.contextmenuitem[2].disabled = true;
    }
    if (this.editbtn == 0) {
      this.contextmenuitem[3].disabled = true;
      this.contextmenuitem[4].disabled = true;
      this.contextmenuitem[6].disabled = true;
    }
    if (this.approvebtn == 0) {
      this.contextmenuitem[5].disabled = true;
    }
    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        this.contextmenuitem[3].disabled = true;
        this.contextmenuitem[4].disabled = true;
        this.contextmenuitem[5].disabled = true;
        this.contextmenuitem[6].disabled = true;
        break;
      }
    }
  }

  showcartdiv(id, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.showcart = true;
    }
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        lst.push(this.cartitemid);
        this.audiouploadservice
          .addtocart(this.cartname, lst, "audio", userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.audiouploadservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexs;
    if (this.selectedIndexs.length > 0) {
      this.audiouploadservice
        .addtocart(this.cartname, lst, "audio", userid, "archieve")
        .subscribe((data: any) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "Add to Cart successfully",
            });
            this.clearcartname();
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "wanr",
        summary: "Wanr Message",
        detail: "Add to Cart Failed",
      });
    }
  }

  // hideedit() {
  //   this.idforedit = ""
  //   this.orgname = ""
  //   this.audioupdate = false
  //   this.showfulldiv()
  // }

  // showfulldiv() {
  //   const x = document.querySelector(".ui-dialog-content")
  //   x.classList.remove("hidemanual")
  //   this.showmin = 1
  //   this.showmax = 0
  // }

  // hidefulldiv() {
  //   const x = document.querySelector(".ui-dialog-content")
  //   x.classList.add("hidemanual")
  //   this.showmin = 0
  //   this.showmax = 1
  // }

  //getting microphone text from its service
  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startMicroPhoneService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopMicroPhoneService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }
  setsearchkey(e) {
    this.Searchbyfield = this.Searchbyfield.substring(
      0,
      this.Searchbyfield.lastIndexOf(" ")
    );
    this.Searchbyfield = this.Searchbyfield.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashser.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }
}

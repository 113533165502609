import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentUploadFormModel } from 'src/app/model/file/documentUploadModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentuploadService {


  documentuplaodmodel:DocumentUploadFormModel=new DocumentUploadFormModel()
  constructor(private httpclient:HttpClient) { }

  baseurl=environment.ApiUrl;

  AddNewDocument(documentinfo:DocumentUploadFormModel):Observable<DocumentUploadFormModel[]>{
    return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl + "DocumentUpload/insertDocument",documentinfo)
  }
   getDocumentmetadata(nme):Observable<DocumentUploadFormModel[]>{
     return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl+"DocumentUpload/DocumentMetadata",{itemfilename:nme,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
   }

   GetAllDocument(limit):Observable<DocumentUploadFormModel[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
     return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl+"DocumentUpload/getAllDocument",{limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
   }
   SearchByFields(Searchstring,limit,isfulltxt):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/SearchByFields",{Searchstring:Searchstring,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,isfulltext:isfulltxt,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  fulltextsrch(Searchstring,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/fulltextsrch",{Searchstring:Searchstring,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addacl(userid,idlst,date):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/addacl",{idlst:idlst,date:date,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  removeacl(userid,idlst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/removeacl",{idlst:idlst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  
  UpdateDocument(documinfo:DocumentUploadFormModel):Observable<DocumentUploadFormModel[]>{
    return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl+"DocumentUpload/updatedocuments",documinfo)
  }
  SearchAllDocument(documentinfo:DocumentUploadFormModel):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/SearchDocument",documentinfo)
  }
  
  deleteDocument(itemid:number):Observable<DocumentUploadFormModel[]>{
    return this.httpclient.post<DocumentUploadFormModel[]>(this.baseurl + "DocumentUpload/deleteDocument",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deletemultipledoc(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/deletemultipledoc",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getitemtype():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"DocumentUpload/getitemtype")
  }
  getpdftypes():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"DocumentUpload/getpdftypes")
  }
  
  GetDownloadedDocumentData(itemid:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getdownloadDocumentdata",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  Testdownload(fname,attachmentpath,itemid):Observable<Blob>{
    
    return this.httpclient.post(this.baseurl+"DocumentUpload/testdownload", {fname:fname,attachmentpath:attachmentpath,itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId}, {responseType: "blob"});
}


  downloadmultiplefn(arr):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"DocumentUpload/downloadmultiplefn",{arr:arr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId},{responseType: "blob"})
  }
   
  downloadentry(itemid,userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/downloadentry",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  
  getTodayDocument():Observable<any[]>{
    let maxlimit="54"
    return this.httpclient.get<any[]>(this.baseurl+"DocumentUpload/getTodayDocument")
  }

  getTextFileContend(filename,filepath):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getTextFileContend",{filename:filename,filepath:filepath,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  readrtf(filepath):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getrtfdata",{filepath:filepath,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getpdfimg(itemid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getpdfpages",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getrealtedimg(cat,sdate,source):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getrealtedimg",{cat:cat,sdate:sdate,source:source,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  advancesearch(srchtxt,limit):Observable<any[]>{
    var maxlimit="54"
    if(JSON.parse(localStorage.getItem("userperference")).length>0){
      maxlimit=JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit=(Number(maxlimit)*2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/newadvancesearch",{srchtxt:srchtxt,limit:limit,maxlimit:maxlimit,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getfulltxtcount(srchtxt):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/fulltextsrchcount",{Searchstring:srchtxt,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getncount(srchtxt,isfulltxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getncount",{Searchstring:srchtxt,isfulltext:isfulltxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  
  getacount(srchtxt,tabstr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getacount",{srchtxt:srchtxt,tabstr:tabstr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  //API not working
  getcartdata(id,itemtype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/getcartdata",{itemid:id,itemtype:itemtype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
//API not working
  downloadmultipleimg(imageid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/downloadmultipleimg",{imageid:imageid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  

  deletecollectionfiles(arr):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/deletecoldoc",{arr:arr,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deleteDocumentRstr(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/deleterestoreddocument",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  restoreDocument(itemid){
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/restore",{itemid:itemid,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  approveimg(lst):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"DocumentUpload/approvedoc",{lst:lst,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }




  getrelationsrch(groupname):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/searchrelation",{groupname:groupname,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  allrealtionlist():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"relation/allrealtionlist")
  }

  setrealtionshipmul(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/setrelationship",{relationname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  checkrelationid(cartname):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"relation/getrelationid",{groupname:cartname,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  //this API is not working
  deletezip(filename):Observable<any[]>{
    return this.httpclient.post<any>(this.baseurl+"DocumentUpload/deletezip",{cartname:filename,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getResults(userid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/getautodata",{userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocart(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocart",{cartname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocartmul(cartname,itemid,itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/addtocartmul",{cartname:cartname,itemid:itemid,itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  bindcart(itemtype,userid,carttype):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"agency/bindcart",{itemtype:itemtype,carttype:carttype,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
}

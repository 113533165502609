import { Component, OnInit } from "@angular/core";
import { CityFormModel } from "src/app/model/master/city.form.model";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { stateFormModel } from "src/app/model/master/state.formmodel";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { CityService } from "src/app/services/masters/city.service";
import { CountryFormModel } from "src/app/model/master/country.formmodel";

@Component({
  selector: "app-city",
  templateUrl: "./city.component.html",
  styleUrls: ["./city.component.css"],
})
export class CityComponent implements OnInit {
  citylist: CityFormModel[] = [];
  cityinformation: CityFormModel;
  CityForm: FormGroup;
  Statelist: stateFormModel[] = [];
  countrylist: CountryFormModel[] = [];
  msgs: Message[] = [];
  Header: string = "Add City";
  countryid: number = 0;
  ButtonStatus: string = "Save";
  constructor(
    private cityservice: CityService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getAllCity();
    this.CreateCityForm();
    this.getCountryDropdown();
  }
  getAllCity() {
    this.cityservice.getAllCity().subscribe((data: CityFormModel[]) => {
      if (data != null) {
        this.citylist = data;
      }
    });
  }
  CreateCityForm() {
    this.CityForm = this.formBuilder.group({
      CityName: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ]*$"),
          this.removeSpaces,
        ],
      ],
      StateId: [null],
      CountryID: [null],
      CityId: [null],
      userid: [null],
    });
    this.CityForm.patchValue({ StateId: 0 });
    this.CityForm.patchValue({ CountryID: 0 });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveCity(formval) {
    this.cityinformation = formval.value;
    this.cityinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.cityinformation.CityId > 0) {
      if (
        this.cityinformation.CountryID == 0 ||
        this.cityinformation.StateId == 0
      ) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Country or State",
        });
      } else {
        this.cityservice
          .checkduplicate(this.cityinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.cityservice
                .UpdateCity(this.cityinformation)
                .subscribe((data: any) => {
                  this.getAllCity();
                  this.CityForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "update successfully",
                  });
                  this.ButtonStatus = "Save";
                  this.CityForm.patchValue({ StateId: 0 });
                  this.CityForm.patchValue({ CountryID: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warning Message",
                detail: "City Already Exist",
              });
            }
          });
      }
    } else {
      if (
        this.cityinformation.CountryID == 0 ||
        this.cityinformation.StateId == 0
      ) {
        this.messageService.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please select Country or State",
        });
      } else {
        this.cityservice
          .checkduplicate(this.cityinformation)
          .subscribe((data: any) => {
            if (data.length < 1) {
              this.cityservice
                .CreateCity(this.cityinformation)
                .subscribe((data: any) => {
                  this.getAllCity();
                  this.CityForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Warning Message",
                    detail: "Save successfully",
                  });
                  this.CityForm.patchValue({ StateId: 0 });
                  this.CityForm.patchValue({ CountryID: 0 });
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warning Message",
                detail: "City Already Exist ",
              });
            }
          });
      }
    }
  }

  getStateDropDown(countryid: number) {
    this.cityservice
      .getStateDropDown(countryid)
      .subscribe((data: stateFormModel[]) => {
        if (data != null) {
          this.Statelist = data;
        }
      });
  }
  getCountryDropdown() {
    this.cityservice
      .getCountryDropDown()
      .subscribe((data: CountryFormModel[]) => {
        if (data != null) {
          this.countrylist = data;
        }
      });
  }
  updatecity(citylist: CityFormModel) {
    this.getStateDropDown(citylist.CountryID);
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete City";
    this.CityForm.setValue({
      CountryID: citylist.CountryID,
      StateId: citylist.StateId,
      CityName: citylist.CityName,
      CityId: citylist.CityId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }

  DeleteCity(CityId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "citykey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.cityservice.DeleteCity(CityId).subscribe((data: any[]) => {
          this.getAllCity();
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.CityForm.reset();
          this.CityForm.patchValue({ StateId: 0 });
          this.CityForm.patchValue({ CountryID: 0 });
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  clear() {
    this.CityForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add City";
    this.CityForm.patchValue({ StateId: 0 });
    this.CityForm.patchValue({ CountryID: 0 });
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    this.countryid = selctedcountryid;
    this.getStateDropDown(selctedcountryid);
  }
}

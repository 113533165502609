import { Component, OnInit } from "@angular/core";
import { PublicationFormModel } from "src/app/model/master/publication.formmodel";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { PublicationService } from "src/app/services/masters/publication.service";

@Component({
  selector: "app-publication",
  templateUrl: "./publication.component.html",
  styleUrls: ["./publication.component.css"],
})
export class PublicationComponent implements OnInit {
  publicationlist: PublicationFormModel[] = [];
  publicationinformation: PublicationFormModel;
  PublicationForm: FormGroup;
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  CountryId: string;
  Header: string = "Add Publication";
  selectedpublication: string;
  constructor(
    private publicservice: PublicationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getpublication();
    this.CreatePublicationForm();
  }

  getpublication() {
    this.publicservice.getAllPublication().subscribe((data: any[]) => {
      if (data != null) {
        this.publicationlist = data;
      }
    });
  }
  CreatePublicationForm() {
    this.PublicationForm = this.formBuilder.group({
      PublicationName: [null, [Validators.required, this.removeSpaces]],
      PubShortName: [null, [Validators.required, this.removeSpaces]],
      PublicationId: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SavePublication(formval) {
    this.publicationinformation = formval.value;
    this.publicationinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.publicationinformation.PublicationId > 0) {
      if (
        this.publicationinformation.PublicationName != this.selectedpublication
      ) {
        this.publicservice
          .checkduplicate(this.publicationinformation)
          .subscribe((data: any) => {
            if (data.length == 0) {
              this.publicservice
                .Updatepublication(this.publicationinformation)
                .subscribe((data: any) => {
                  this.getpublication();
                  this.PublicationForm.reset();
                  this.messageService.add({
                    key: "message",
                    severity: "success",
                    summary: "Success Message",
                    detail: "update successfully",
                  });
                  this.ButtonStatus = "Save";
                });
            } else {
              this.messageService.add({
                key: "message",
                severity: "error",
                summary: "Warn Message",
                detail: "Publication Already Exist",
              });
            }
          });
      } else {
        this.publicservice
          .Updatepublication(this.publicationinformation)
          .subscribe((data: any) => {
            this.getpublication();
            this.PublicationForm.reset();
            this.messageService.add({
              key: "message",
              severity: "success",
              summary: "Success Message",
              detail: "update successfully",
            });
            this.ButtonStatus = "Save";
          });
      }
    } else {
      this.publicservice
        .checkduplicate(this.publicationinformation)
        .subscribe((data: any) => {
          if (data.length == 0) {
            this.publicservice
              .Cretaepublication(this.publicationinformation)
              .subscribe((data: any) => {
                this.getpublication();
                this.PublicationForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Publication  Already Exist",
            });
          }
        });
    }
  }
  Update(publicationlist: PublicationFormModel) {
    this.selectedpublication = publicationlist.PublicationName;
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Publication";
    this.PublicationForm.setValue({
      PublicationName: publicationlist.PublicationName,
      PubShortName: publicationlist.PubShortName,
      PublicationId: publicationlist.PublicationId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  DeletePublication(PublicationId: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "publicationkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.publicservice
          .Deletepublication(PublicationId)
          .subscribe((data: any[]) => {
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getpublication();
            this.PublicationForm.reset();
            this.ButtonStatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  Clear() {
    this.PublicationForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Publication";
  }
}

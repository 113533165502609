import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { userformmodel } from 'src/app/model/master/user.form.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  baseurl=environment.ApiUrl
  UserFormModel:userformmodel=new userformmodel()
  constructor(private httpclient:HttpClient) { }


  ChangePassword(oldpassword,userid,newpassword):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"password/ChangePassword",{newpassword:newpassword,oldpassword:oldpassword,userid:userid})
    }
}

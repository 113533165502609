import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { Galleria } from "primeng/galleria";
import { Lightbox } from "ngx-lightbox";
import { LightboxConfig } from "ngx-lightbox";
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
  NgxGalleryComponent,
} from "ngx-gallery";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-pdfpreview",
  templateUrl: "./pdfpreview.component.html",
  styleUrls: ["./pdfpreview.component.css"],
})
export class PdfpreviewComponent implements OnInit {
  @ViewChild("onlyPreviewGalleryHtml", { static: false })
  onlyPreviewGallery: NgxGalleryComponent;
  images: NgxGalleryImage[];
  realtedimages: any[];
  itemid: string;
  pageno: string = "Page ";
  galleryOptions: NgxGalleryOptions[];
  ApiServerPath = environment.ApiServerPath;
  imageObject: any = [];
  filename: string = "";
  visibleSidebar4: boolean = false;
  pdfpath: string = "";
  relatedpath: any = [];
  cate: string = "";
  sdate: string = "";
  source: string = "";
  constructor(
    public documentservice: DocumentuploadService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private _lightbox: Lightbox,
    private _lightbox1: Lightbox,
    private _lightboxConfig: LightboxConfig
  ) {
    this.itemid = this.route.snapshot.paramMap.get("id");
    //this.filename= this.route.snapshot.paramMap.get('fname');
    this.getpdfimg();
    //this.getrealtedimages();
  }

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.galleryOptions = [
      {
        width: "100%",
        height: "100vh",
        thumbnailsColumns: 7,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSize: "contain",
        thumbnailSize: "contain",
        imagePercent: 95,
        thumbnailsPercent: 0,
        fullWidth: true,
        preview: true,
        thumbnailMargin: 2,
        thumbnailsMargin: 2,
        previewZoom: true,
        previewKeyboardNavigation: true,
        previewForceFullscreen: false,
      },
    ];
  }
  ngAfterViewInit() {
    //this.open(1)
  }
  getpdfimg() {
    this.images = [];
    var sub1 = this.documentservice
      .getpdfimg(this.itemid)
      .subscribe((data: any) => {
        if (data != null) {
          this.pdfpath = this.ApiServerPath + data[0]["OriginalPath"];
          sub1.unsubscribe();
          // this.cate=data[0]["originalpath"]
          // this.sdate=data[0]["sdate"]
          // this.source=data[0]["Source"]
          // this.getrealtedimages(data[0]["categoryid"],data[0]["sdate"],data[0]["Source"])
        }
      });
  }

  //function is not working
  getrealtedimages(cat, sdate, source) {
    this.realtedimages = [];
    this.documentservice
      .getrealtedimg(cat, sdate, source)
      .subscribe((data: any) => {
        if (data != null) {
          //this.filename=data[0]
          for (let i = 0; i < data.length; i++) {
            this.relatedpath.push(this.ApiServerPath + data[i].OriginalPath);
            this.imageObject.push({
              image: this.ApiServerPath + data[i].thumbpath,
              thumbImage: this.ApiServerPath + data[i].thumbpath,
            });
          }
        }
      });
  }
  showNext(): void {}

  show(index: number): void {
    this.pdfpath = this.relatedpath[index];
  }

  close() {
    // close lightbox programmatically
    //this._lightbox.close();
    window.close();
  }
  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 27;
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.close();
    }
  }

  changelayout() {}
}

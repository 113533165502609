import { Component, OnInit } from "@angular/core";
import {
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { CategoryService } from "src/app/services/masters/category.service";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { Message, ConfirmationService, MessageService } from "primeng/api";
@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.css"],
})
export class CategoryComponent implements OnInit {
  msgs: Message[] = [];
  buttonstatus: string = "Save";
  categorylist: categoryformmodel[] = [];
  CategoryForm: FormGroup;
  categoryinformation: categoryformmodel;
  test: any;
  Header: string = "Add Category";
  constructor(
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService
  ) {}

  ngOnInit() {
    this.getcategorydata();
    this.CreateCategoryForm();
  }
  CreateCategoryForm() {
    this.CategoryForm = this.formBuilder.group({
      CategoryName: [null, [Validators.required, this.removeSpaces]],
      CategoryId: [null],
      userid: [null],
    });
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  savecategoryinfo(formval) {
    this.categoryinformation = formval.value;
    this.categoryinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.categoryinformation.CategoryId > 0) {
      this.categoryService
        .checkduplicate(this.categoryinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.categoryService
              .UpdateCategory(this.categoryinformation)
              .subscribe((data: any) => {
                this.getcategorydata();
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Update successfully",
                });
                this.CategoryForm.reset();
                this.buttonstatus = "Save";
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Category Already Exist",
            });
          }
        });
    } else {
      this.categoryService
        .checkduplicate(this.categoryinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.categoryinformation.ActiveStatus = "A";
            this.categoryService
              .CreateCategory(this.categoryinformation)
              .subscribe((data: any) => {
                this.getcategorydata();
                this.messageservice.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "save successfully",
                });
                this.CategoryForm.reset();
              });
          } else {
            this.messageservice.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Category Already Exist",
            });
          }
        });
    }
  }
  deleteCategory(CategoryId: number) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "categorykey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.categoryService
          .DeleteCategory(CategoryId)
          .subscribe((data: any[]) => {
            this.getcategorydata();
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.CategoryForm.reset();
            this.buttonstatus = "Save";
          });
      },
      reject: () => {},
    });
  }
  clear() {
    this.CategoryForm.reset();
    this.buttonstatus = "Save";
    this.Header = "Add Category";
  }

  updateCategorydata(categorylist: categoryformmodel) {
    this.buttonstatus = "Update";
    this.Header = "Edit/Delete Category";
    this.CategoryForm.setValue({
      CategoryName: categorylist.CategoryName,
      CategoryId: categorylist.CategoryId,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
}

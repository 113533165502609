import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http'
import { CategoryService } from 'src/app/services/masters/category.service';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { FormGroup, FormBuilder, NgForm, Validators, AbstractControl } from '@angular/forms';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { CountryService } from 'src/app/services/masters/country.service';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { CityService } from 'src/app/services/masters/city.service';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { CityFormModel } from 'src/app/model/master/city.form.model';
import { EventService } from 'src/app/services/masters/event.service';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/primeng';
import { environment } from '../../../environments/environment'
import { DomSanitizer } from '@angular/platform-browser';
import { VideouploadService } from 'src/app/services/files/videoupload.service';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { AudiouplaodService } from 'src/app/services/files/audiouplaod.service';
import { AudioUploadComponent } from '../audio-upload/audio-upload.component';

@Component({
  selector: 'app-audio-upload-popup',
  templateUrl: './audio-upload-popup.component.html',
  styleUrls: ['./audio-upload-popup.component.css']
})
export class AudioUploadPopupComponent implements OnInit {
  @ViewChild('audio1', { static: true }) audioElement: ElementRef;
  @ViewChild('fileInput', { static: true }) fileInput: FileUpload;
  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  AudioUploadForm: FormGroup
  categoryid: number = 0
  eventlist: Eventformmodel[] = [];
  countrylist: CountryFormModel[] = []
  Statelist: stateFormModel[] = []
  citylist: CityFormModel[] = []
  audiolist: audiouploadModel[] = []
  audioinformation: audiouploadModel;
  display: boolean = false
  x: string
  check: boolean = true;
  audiouploadurl = environment.ApiUrl
  msbapTitle = "";
  msbapAudioUrl = "";
  display1: boolean = false
  msbapDisplayTitle = ""
  msbapDisplayVolumeControls = true;
  i: number
  toggle=false
  value:number=0
  constructor(private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private countryservice: CountryService, private cityservice: CityService, private eventservice: EventService, private messageservice: MessageService, private videouploadservice: VideouploadService, private sanitizer: DomSanitizer, private audiouploadservice: AudiouplaodService, private audiocomponent: AudioUploadComponent) { }




  ngOnInit() {
    this.getcategorydata()
    this.CreateAudioUploadForm()
    this.getCountryData()
    this.getEventData()

  }

  clear() {
    // this.fileInput._files = []
    this.AudioUploadForm.reset()
    this.audiocomponent.onHide1()
    this.value=0
  }
  remove(name) {
    for(let i=0;i<this.fileInput.files.length;i++){
      if(this.fileInput.files[i].name==name){
        // this.fileInput._files.splice(i,1)
      }
    }
    this.AudioUploadForm.reset()
    // this.audiocomponent.onHide1()
  }

  hide(){
    this.audioElement.nativeElement.src=''
  }

  showDialog() {
    this.display = true
  }
  showaudioplayer() {
    this.display1 = true
    this.audioElement.nativeElement.type='audio/mp3'
    this.audioElement.nativeElement.src="assets/audio/"+this.msbapAudioUrl
    // alert(this.msbapAudioUrl)
   

   
  }

  getfilename(e) {
    var nme = e.files[0].name

    this.selectedFiles.push(e.files)

    if ((e.files[0].size) / 1000 > 1024) {
      var filesize = (((e.files[0].size) / 1000) / 1024).toFixed(2) + "mb"
    }
    else {
      var filesize = ((e.files[0].size) / 1000).toFixed(3) + "kb"
    }
    var indx = nme.lastIndexOf(".")
    var format = nme.substring(indx + 1, nme.length)
    this.AudioUploadForm.patchValue({ itemfilename: nme })
    this.AudioUploadForm.patchValue({ itemsize: filesize })
    this.AudioUploadForm.patchValue({ itemformat: format })
    this.fileInput.upload();
  }

  getmetadata(nme) {
    for(let i=0; i<this.fileInput.files.length; i++)
    {
        document.getElementById(this.fileInput.files[i].name).style.background='white'
    }

    document.getElementById(nme).style.background="lavender"
    let indx
    for(let i=0;i<this.fileInput.files.length;i++){
      var tmp=this.fileInput.files[i].name
      if(tmp==nme){
        indx=i
      }
    }
    if ((this.fileInput.files[indx].size) / 1000 > 1024) {
      var filesize = (((this.fileInput.files[indx].size) / 1000) / 1024).toFixed(2) + "mb"
    }
    else {
      var filesize = ((this.fileInput.files[indx].size) / 1000).toFixed(3) + "kb"
    }
    this.AudioUploadForm.patchValue({ itemsize: filesize })



    var p = environment.folderpath + nme
    this.audiouploadservice.getaudiometadata(p).subscribe((data: any) => {
      if (data != null) {
        var dataparse=JSON.parse(data)
        var audioname = dataparse[0].FileName
        var x = audioname.lastIndexOf("/")
        audioname = audioname.substring(x + 1, data[0].length)
        this.msbapAudioUrl=audioname
        this.msbapTitle=nme
        this.msbapDisplayTitle=nme
        this.msbapTitle=nme
        // this.audioElement.nativeElement.src="assets/audio/"+this.msbapAudioUrl
        // this.toggle=true

        // this.showDialog()
        //console.log(data)
        var dt = dataparse[0]
        this.audiolist = dt
        this.x = JSON.stringify(this.audiolist)
        this.audiolist = JSON.parse(this.x)

        var indx = nme.lastIndexOf(".")
        var format = nme.substring(indx + 1, nme.length)
        this.AudioUploadForm.patchValue({ itemlength: this.audiolist["Duration"].replace("(approx)", "") })
        this.AudioUploadForm.patchValue({ itemformat: format })
        this.AudioUploadForm.patchValue({ itemfilename: nme })
        // this.AudioUploadForm.patchValue({itemsize:filesize})
      }

    })
  }

  CreateAudioUploadForm() {
    this.AudioUploadForm = this.formBuilder.group({
      CategoryId: [null, [Validators.required]],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword: [null, [Validators.required, this.removeSpaces]],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
    })

  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  getcategorydata() {
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getCountryData() {
    this.countryservice.GetCountryData().subscribe((data: CountryFormModel[]) => {
      if (data.length > 0) {
        this.countrylist = data;
      }
    })
  }
  getStateDropDown(c) {
    this.cityservice.getStateDropDown(c).subscribe((data: stateFormModel[]) => {
      if (data.length > 0) {
        this.Statelist = data;
      }
      else {
        this.Statelist = []
        this.AudioUploadForm.patchValue({ StateId: "null" })
        this.citylist = []
        this.AudioUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    // this.countryid=selctedcountryid;
    this.getStateDropDown(selctedcountryid)
  }

  getCityDropdown(s) {
    this.cityservice.getCityData(s).subscribe((data: CityFormModel[]) => {
      if (data.length > 0) {
        this.citylist = data;
      }
      else {
        this.citylist = []
        this.AudioUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getCity(e) {
    let selectedstateid = e.target.selectedOptions[0].value;
    // this.countryid=selectedstateid;
    this.getCityDropdown(selectedstateid)
  }

  getEventData() {
    //this.userinfomation=new userformmodel();
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }

    })

  }


  audioupload(formval) {

    
    this.audioinformation = formval.value
    if(this.fileInput.files.length==0){
      this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please Browse Files' });
    }
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var name = this.fileInput.files[i].name;
      var name = this.fileInput.files[i].name.replace(new RegExp("'", 'g'), "''")
      var p = environment.folderpath + name
      this.audiouploadservice.getaudiometadata(p).subscribe((data: any) => {
        if (data != null) {
          //console.log(data)
          var dataparse=JSON.parse(data)
          var dt = dataparse[0]
          this.audiolist = dt
           this.x = JSON.stringify(this.audiolist)
           this.audiolist = JSON.parse(this.x)
          if ((this.fileInput.files[i].size) / 1000 > 1024) {
            var filesize = (((this.fileInput.files[i].size) / 1000) / 1024).toFixed(2) + "mb"
          }
          else {
            var filesize = ((this.fileInput.files[i].size) / 1000).toFixed(3) + "kb"
          }
          var format = this.fileInput.files[i].type.substring(6, this.fileInput.files[0].type.length)
          this.audioinformation.itemfilename = this.fileInput.files[i].name;
          this.audioinformation.itemformat = format
          this.audioinformation.itemsize = filesize
          this.audioinformation.itemlength = this.audiolist["Duration"].replace("(approx)", "")
          this.audioinformation.AudioBitrate = this.audiolist["AudioBitrate"]
          this.audioinformation.ChannelMode = this.audiolist["ChannelMode"]
          this.audioinformation.SampleRate = this.audiolist["SampleRate"]
          this.audioinformation.allmetadata = JSON.stringify(this.audiolist).replace(new RegExp("'", 'g'), "''")

          if (this.audioinformation.CategoryId == null || this.audioinformation.itemkeyword == null) {
            if (i == this.fileInput.files.length - 1) {
              this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please select Category or Keywords' });
            }
          } else {
            this.audiouploadservice.AddNewAudio(this.audioinformation).subscribe((data: any) => {
          
              if (i == this.fileInput.files.length - 1) {
                this.AudioUploadForm.reset();
                // this.fileInput._files = []
                this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' })
                setTimeout(() => this.audiocomponent.getTodayAudio(), 3500)
                let interval = setInterval(() => {
                  this.value =this.value+  Math.floor(Math.random() * 10) + 1;
                  if(this.value >= 100) {
                      this.value = 100;
                      // this.messageService.add({severity: 'info', summary: 'Success', detail: 'Process Completed'});
                      clearInterval(interval);
                    
                      // this.value=0
                     
                  }
              });
              }
            });
          }
        }
      })
    }
  }
}

import { SecurityContext } from "@angular/compiler/src/core";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { fromEvent } from "rxjs";
import { pairwise, switchMap, takeUntil } from "rxjs/operators";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-videocomment",
  templateUrl: "./videocomment.component.html",
  styleUrls: ["./videocomment.component.css"],
})
export class VideocommentComponent implements OnInit {
  // title = 'videocomment';
  pencolor: string = "#e70d0d";
  textareas: string = "";
  datalist: any[] = [];
  sendstatus: boolean = false;
  loginuser: string = "53";
  prvsreplydivid: string = "";
  prvscmntdivid: string = "";
  replyvalue: string = "";
  videoid: string = "ewqsygw1y7617ghu1twywg1611";
  videourl: SafeResourceUrl;
  brushsize: number = 5;
  videoname: string = "";
  colordiv: number = 0;
  serverfilepath: string = "";
  playbtn: number = 1;
  pausebtn: number = 0;
  addcommentper: number = 0;
  ApiServerPath: any;
  @ViewChild("c1", { static: false }) public canvasEl1: ElementRef;
  @ViewChild("vid1", { static: true }) public videoel1: ElementRef;

  @Input() public width = 400;
  @Input() public height = 400;

  private cx: CanvasRenderingContext2D;
  thumbpath: string;
  constructor(
    public getdataser: VideouploadService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private messageservice: MessageService
  ) {}
  public ngOnInit() {
    this.checkpermissions();
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    // this.serverfilepath = environment.ApiServerPath
    this.serverfilepath = this.ApiServerPath;
    this.videoid = this.route.snapshot.queryParams.q;
    this.loginuser = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.getvideodata();
    this.getcomments();
    //this.timer()
    this.seekbar();
    var vid = document.getElementById("vid") as HTMLVideoElement;
    $("#custom-seekbar").on("click", function (e) {
      var offset = $(this).offset();
      var left = e.pageX - offset.left;
      var totalWidth = $("#custom-seekbar").width();
      var percentage = left / totalWidth;
      var vidTime = vid.duration * percentage;
      vid.currentTime = vidTime;
    });
  }

  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  async checkpermissions() {
    var permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < permissionlist.length; i++) {
      if (permissionlist[i].taskid == 93) {
        this.addcommentper = 1;
      }
    }
  }

  public ngAfterViewInit() {
    //const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    //const videoel= document.getElementById("vid") as HTMLVideoElement;
    //this.drawcanvas()
    //this.videoel.play()

    var canvas1 = document.getElementById("canvas") as HTMLCanvasElement;
    var context = canvas1.getContext("2d");
    var video = document.getElementById("vid") as HTMLVideoElement;

    canvas1.width = video.clientWidth;
    canvas1.height = video.clientHeight;

    video.addEventListener("loadedmetadata", function () {
      var tmpmargin =
        (Number(document.getElementById("videodiv").parentElement.clientWidth) -
          video.clientWidth) /
        2;
      if (tmpmargin < 0) {
        tmpmargin = 0;
      }
      document.getElementById("videodiv").style.marginLeft = tmpmargin + "px";
      document.getElementById("vid").style.marginTop =
        "calc(35vh - " + video.clientHeight / 2 + "px)";
      document.getElementById("canvas").style.marginTop =
        "calc(35vh - " + video.clientHeight / 2 + "px)";
      canvas1.width = video.clientWidth;
      canvas1.height = video.clientHeight;
    });
    video.addEventListener("play", function () {
      var $this = this;
      (function loop() {
        if (!$this.paused && !$this.ended) {
          context.drawImage($this, 0, 0, video.clientWidth, video.clientHeight);
          //context.rect( 0, 0,video.offsetWidth,video.offsetHeight);
          setTimeout(loop, 1000 / 30);
        }
      })();
    });
    this.drawcanvas(canvas1);
  }

  pausevideo() {
    this.playbtn = 1;
    this.pausebtn = 0;
    var video = document.getElementById("vid") as HTMLVideoElement;
    if (video.paused) {
      // video.play()
    } else {
      video.pause();
    }
  }
  playvideo() {
    this.playbtn = 0;
    this.pausebtn = 1;
    var video = document.getElementById("vid") as HTMLVideoElement;
    video.play();

    this.sendstatus = false;
    this.textareas = "";
  }
  drawcanvas(canvasEl) {
    //const canvasEl: HTMLCanvasElement = this.canvasEl1.nativeElement;
    //const videoel= document.getElementById("vid") as HTMLVideoElement;
    this.cx = canvasEl.getContext("2d");

    this.cx.lineWidth = 10;
    this.cx.lineCap = "round";
    this.cx.strokeStyle = "#d9e42a";

    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, "mousedown")
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, "mousemove").pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvasEl, "mouseup")),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvasEl, "mouseleave")),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ) {
    if (!this.cx) {
      return;
    }
    if (this.sendstatus) {
      return;
    }
    this.cx.lineWidth = this.brushsize;
    this.cx.lineCap = "round";
    this.cx.strokeStyle = this.pencolor;
    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      //this.cx.rect(prevPos.x,prevPos.y,currentPos.x,currentPos.y)
      this.cx.stroke();
    }
  }

  changecolor(t) {
    alert(t);
  }

  private seekbar() {
    var _self = this;
    var vid = document.getElementById("vid") as HTMLVideoElement;
    vid.ontimeupdate = function () {
      var percentage = (vid.currentTime / vid.duration) * 100;
      document.getElementById("progressbar").style.width =
        percentage.toString() + "%";
      _self.timer(vid.currentTime, vid.duration);
    };
  }

  updateprogressbar(e) {
    var vid = document.getElementById("vid") as HTMLVideoElement;
    var progress = $(".progress");
    var maxduration = vid.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    vid.currentTime = (maxduration * percentage) / 100;
  }

  //   draw(v,c,bc,w,h) {
  //     // if(v.paused || v.ended) return false;
  //     // First, draw it into the backing canvas
  //     bc.drawImage(v,0,0,w,h);
  //     // Grab the pixel data from the backing canvas
  //     var idata = bc.getImageData(0,0,w,h);
  //     var data = idata.data;
  //     // Loop through the pixels, turning them grayscale
  //     for(var i = 0; i < data.length; i+=4) {
  //         var r = data[i];
  //         var g = data[i+1];
  //         var b = data[i+2];
  //         var brightness = (3*r+4*g+b)>>>3;
  //         data[i] = brightness;
  //         data[i+1] = brightness;
  //         data[i+2] = brightness;
  //     }
  //     idata.data = data;
  //     // Draw the pixels onto the visible canvas
  //     c.putImageData(idata,0,0);

  //     console.log(c.putImageData(idata,0,0));
  // }

  shoot() {
    console.log("shoot");
    var vid = document.getElementById("vid");
    //  var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var canvas = this.canvasEl1.nativeElement;

    // canvas.crossOrigin = "Use-Credentials";
    // try {
    //   canvas.crossOrigin = "anonymous"
    // } catch { }
    // try {
    //   canvas.crossOrigin = origin
    // } catch { }
    // try {
    //   canvas.origin = 'anonymous';
    // } catch { }

    // const crossorigin="anonymous"

    canvas.setAttribute("crossOrigin", "anonymous");

    // try {
    var filepath = this.videoel1.nativeElement.src;
    var filename = filepath.split("/")[filepath.split("/").length - 1];
    var frame = this.videoel1.nativeElement.currentTime;
    var itemid = this.videoid;
    var userid = this.loginuser;
    var comment = this.textareas;

    //       var context = canvas.getContext('2d');
    //       var back = document.createElement('canvas');
    //       var backcontext = back.getContext('2d');

    //       var cw = Math.floor(canvas.clientWidth / 100);
    //       var ch = Math.floor(canvas.clientHeight / 100);
    //       canvas.width = cw;
    //       canvas.height = ch;

    //  cw = vid.clientWidth;
    //         ch = vid.clientHeight;
    //         canvas.width = cw;
    //         canvas.height = ch;
    //         back.width = cw;
    //         back.height = ch;
    //         this.draw(vid,context,backcontext,cw,ch);

    // var w = this.videoel1.nativeElement.videoWidth;
    // var h = this.videoel1.nativeElement.videoHeight;
    // var canvas = document.createElement('canvas');
    // canvas.width = w;
    // canvas.height = h;
    // var ctx = canvas.getContext('2d');
    // console.log(ctx.drawImage(this.videoel1.nativeElement, 0, 0, w, h));
    // ctx.drawImage(this.videoel1.nativeElement, 0, 0, w, h);
    // var base64 = canvas.toDataURL("image/jpg");

    // console.log(base64)
    // screens.push(data)

    // var base64 = canvas.toDataURL("image/png")

    try {
      var base64 = canvas.toDataURL("image/png");
    } catch (err) {}
    // var base64 = canvas.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    // var base64 = canvas.getImageData("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');

    console.log(
      "base64, comment, filepath, filename, frame, itemid, userid",
      base64,
      comment,
      filepath,
      filename,
      frame,
      itemid,
      userid
    );

    if (comment != "") {
      this.getdataser
        .postdata(base64, comment, filepath, filename, frame, itemid, userid)
        .subscribe((data: any[]) => {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Inserted",
          });
          this.getcomments();
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please add some comment",
      });
    }
  }
  catch(error) {
    this.messageservice.add({
      key: "message",
      severity: "warn",
      summary: "Warning Message",
      detail: "Comment Failed",
    });
    // console.log("error",error);
  }

  getcomments() {
    var itemid = this.videoid;
    this.getdataser.getcomment(itemid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.datalist = [];
        for (let i = 0; i < data.length; i++) {
          var timeFormat = data[i].frame;
          var m =
            Math.floor(timeFormat / 60) < 10
              ? "0" + Math.floor(timeFormat / 60)
              : Math.floor(timeFormat / 60);
          var s =
            Math.floor(timeFormat - Number(m) * 60) < 10
              ? "0" + Math.floor(timeFormat - Number(m) * 60)
              : Math.floor(timeFormat - Number(m) * 60);
          var tmpframe = m + ":" + s;
          if (data[i].replies == null) {
            data[i].replies = "";
          }
          if (data[i].replyby == null) {
            data[i].replyby = "";
          }
          var tmpreplyarr = data[i].replies.split("µ");
          var tmpreplybyarr = data[i].replyby.split("µ");
          var replyarr1 = [];
          var replybyarr1 = [];
          for (let j = 0; j < tmpreplyarr.length - 1; j++) {
            replyarr1.push({
              reply: tmpreplyarr[j],
              replyby: tmpreplybyarr[j],
            });
            //replybyarr1.push(tmpreplybyarr[j])
          }
          this.datalist.push({
            username: data[i].username,
            frame: tmpframe,
            datetime: data[i].datetime,
            comment: data[i].comment,
            snappath: data[i].snappath,
            userid: data[i].userid,
            replyarr: replyarr1,
            id: data[i].id,
            replies: data[i].replies,
            replyby: data[i].replyby,
            videoid: data[i].videoguid,
            frame1: data[i].frame,
          });
        }
      }
    });
  }

  commentdata(itemlist, divid) {
    if (this.prvscmntdivid != "") {
      document
        .querySelector("#" + this.prvscmntdivid)
        .classList.remove("replycmnt");
    }
    document.querySelector("#" + divid).classList.add("replycmnt");
    this.prvscmntdivid = divid;

    this.pausevideo();
    var canvasel = document.getElementById("canvas") as HTMLCanvasElement;

    this.videoel1.nativeElement.currentTime = itemlist.frame1;
    var ctx = canvasel.getContext("2d");
    var img = new Image();
    //img.width=canvasel.width
    //img.height=canvasel.height
    img.src = this.ApiServerPath + itemlist.snappath;
    ctx.drawImage(img, 0, 0, canvasel.width, canvasel.height);
    this.textareas = itemlist.comment;
    if (this.loginuser != itemlist.userid) {
      this.sendstatus = true;
    } else {
      this.sendstatus = false;
    }
  }
  replycomment(itemlist, divid) {
    var replies = this.replyvalue + "µ" + itemlist.replies;
    var username =
      JSON.parse(localStorage.getItem("userdetails"))[0].UserCompleteName +
      "µ" +
      itemlist.replyby;
    if (this.replyvalue != "") {
      this.getdataser
        .postreply(itemlist.id, replies, username, itemlist.videoid)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.replyvalue = "";
            document.getElementById(this.prvsreplydivid).style.display = "none";
            this.datalist = [];
            for (let i = 0; i < data.length; i++) {
              var timeFormat = data[i].frame;
              var m =
                Math.floor(timeFormat / 60) < 10
                  ? "0" + Math.floor(timeFormat / 60)
                  : Math.floor(timeFormat / 60);
              var s =
                Math.floor(timeFormat - Number(m) * 60) < 10
                  ? "0" + Math.floor(timeFormat - Number(m) * 60)
                  : Math.floor(timeFormat - Number(m) * 60);
              var tmpframe = m + ":" + s;
              if (data[i].replies == null) {
                data[i].replies = "";
              }
              if (data[i].replyby == null) {
                data[i].replyby = "";
              }
              var tmpreplyarr = data[i].replies.split("µ");
              var tmpreplybyarr = data[i].replyby.split("µ");
              var replyarr1 = [];
              var replybyarr1 = [];
              for (let j = 0; j < tmpreplyarr.length - 1; j++) {
                replyarr1.push({
                  reply: tmpreplyarr[j],
                  replyby: tmpreplybyarr[j],
                });
                //replybyarr1.push(tmpreplybyarr[j])
              }
              this.datalist.push({
                username: data[i].username,
                frame: tmpframe,
                datetime: data[i].datetime,
                comment: data[i].comment,
                snappath: data[i].snappath,
                userid: data[i].userid,
                replyarr: replyarr1,
                id: data[i].id,
                replies: data[i].replies,
                replyby: data[i].replyby,
                videoid: data[i].videoguid,
                frame1: data[i].frame,
              });
            }
          }
        });
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        summary: "Warning Message",
        detail: "Please add some reply",
      });
    }
  }
  showreplybox(divid) {
    if (this.prvsreplydivid != "") {
      document.getElementById(this.prvsreplydivid).style.display = "none";
    }
    document.getElementById(divid).style.display = "block";
    this.prvsreplydivid = divid;
  }

  timer(videocurrent, totalduration) {
    var minutes = "0" + Math.floor(videocurrent / 60);
    var seconds = "0" + Math.floor(videocurrent - Number(minutes) * 60);
    var dur = minutes.substr(-2) + ":" + seconds.substr(-2);

    var minutes_d = "0" + Math.floor(totalduration / 60);
    var seconds_d = "0" + Math.floor(totalduration - Number(minutes_d) * 60);
    var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
    var timer = dur + "/" + dur_d;
    $("#timer").html(timer);
  }
  getvideodata() {
    this.getdataser.getvideoinfo(this.videoid).subscribe((data: any[]) => {
      if (data.length > 0) {
        // var binaryData = [];
        // binaryData.push(environment.ApiServerPath + data[0].OriginalPath);
        // this.videourl= window.URL.createObjectURL(new Blob(binaryData))

        // var blobObj = new Blob([atob(environment.ApiServerPath + data[0].OriginalPath)], { type: "video/*" });
        // this.videourl = window.URL.createObjectURL(blobObj);

        // this.videourl=window.URL.createObjectURL((environment.ApiServerPath + data[0].OriginalPath).toString())
        let videoPath = this.ApiServerPath + data[0].HiresPath;
        this.thumbpath = this.ApiServerPath + data[0].thumbpath;
        this.videourl =
          this.sanitizer.bypassSecurityTrustResourceUrl(videoPath);
        // this.videourl = environment.ApiServerPath + data[0].HiresPath
        this.videoname = data[0].orgfilename;
      }
    });
  }

  opencolordiv() {
    this.colordiv = 1;
  }
  closecolordiv() {
    this.colordiv = 0;
  }
}

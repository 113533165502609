import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { Message, MessageService, ConfirmationService } from "primeng/api";
import { editionformmodel } from "src/app/model/master/edition.model";
import { EditionService } from "src/app/services/masters/edition.service";

@Component({
  selector: "app-masteredition",
  templateUrl: "./masteredition.component.html",
  styleUrls: ["./masteredition.component.css"],
})
export class MastereditionComponent implements OnInit {
  Editionlist: editionformmodel[] = [];
  Editioninformation: editionformmodel;
  EditionForm: FormGroup;
  msgs: Message[] = [];
  ButtonStatus: string = "Save";
  CountryId: string;
  Header: string = "Add Edition";
  selectedEdition: string;
  constructor(
    private Editionservice: EditionService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getEdition();
    this.CreateEditionForm();
  }

  getEdition() {
    this.Editionservice.getAllEdition().subscribe((data: any[]) => {
      if (data != null) {
        this.Editionlist = data;
      }
    });
  }
  CreateEditionForm() {
    this.EditionForm = this.formBuilder.group({
      edition_name: [null, [Validators.required, this.removeSpaces]],
      edition_tech_code: [null, [Validators.required, this.removeSpaces]],
      edition_id: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveEdition(formval) {
    this.Editioninformation = formval.value;
    this.Editioninformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.Editioninformation.edition_id > 0) {
      this.Editionservice.checkduplicate(this.Editioninformation).subscribe(
        (data: any) => {
          if (data.length == 0) {
            this.Editionservice.UpdateEdition(
              this.Editioninformation
            ).subscribe((data: any) => {
              this.getEdition();
              this.EditionForm.reset();
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "update successfully",
              });
              this.ButtonStatus = "Save";
            });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Edition Already Exist",
            });
          }
        }
      );
    } else {
      this.Editionservice.checkduplicate(this.Editioninformation).subscribe(
        (data: any) => {
          if (data.length == 0) {
            this.Editionservice.CreateEdition(
              this.Editioninformation
            ).subscribe((data: any) => {
              this.getEdition();
              this.EditionForm.reset();
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Save successfully",
              });
            });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Edition  Already Exist",
            });
          }
        }
      );
    }
  }
  Update(Editionlist: editionformmodel) {
    this.selectedEdition = Editionlist.edition_name;
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Edition";
    this.EditionForm.setValue({
      edition_name: Editionlist.edition_name,
      edition_tech_code: Editionlist.edition_tech_code,
      edition_id: Editionlist.edition_id,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  DeleteEdition(edition_id: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "mastereditionkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.Editionservice.DeleteEdition(edition_id).subscribe(
          (data: any[]) => {
            this.messageService.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            this.getEdition();
            this.EditionForm.reset();
            this.ButtonStatus = "Save";
          }
        );
      },
      reject: () => {},
    });
  }
  Clear() {
    this.EditionForm.reset();
    this.ButtonStatus = "Save";
    this.Header = "Add Edition";
  }
}

import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { userformmodel } from 'src/app/model/master/user.form.model';
import { environment } from 'src/environments/environment';
import { analyzeAndValidateNgModules } from '@angular/compiler';
 

@Injectable()
export class UserserviceService {
  private profileimg: any = null;
  updateProfileImage = new EventEmitter();

  updateprofileimg(profileimg: any) {
   this.updateProfileImage.emit(profileimg);
  }

userformmodel:userformmodel=new userformmodel();

  constructor(private httpclient:HttpClient) { }
  baseurl=environment.ApiUrl;
  //baseurl: string = "http://localhost:5000/user";
  
  getAllUsers():Observable<userformmodel[]>{
    return this.httpclient.get<userformmodel[]>(this.baseurl+"user/userData");
  }
  getAllVaultData():Observable<userformmodel[]>{
    return this.httpclient.get<userformmodel[]>(this.baseurl+"user/getAllVaultData");
  }
  CreateUser(userInfo:userformmodel):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/insert" ,userInfo);
  }
  generateBarcode(text:string):Observable<Blob>{
    return this.httpclient.post(this.baseurl+"user/generateBarcode",{text:text},{responseType: "blob"})
  }
  CreateAltoVault(userInfo:userformmodel):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/insertAltoVault" ,userInfo);
  }
  DelteUser(UserId:number,name):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/delete",{UserId:UserId,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,name:name})
  }
  DeleteAltoVault(Vault_number:number):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/DeleteAltoVault",{Vault_number:Vault_number,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,})
  }
  UpdateUser(userInfo:userformmodel):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/update" ,userInfo);
  }
  UpdateAltoVault(vaultForm:userformmodel):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/UpdateAltoVault" ,vaultForm);
  }
  getuserpermissions(userid:number):Observable<any[]>{
  let assignepermission = this.httpclient.post<any[]>(this.baseurl+"user/assignedpermission",{UserId:userid});
  let availablepermission =this.httpclient.post<any[]>(this.baseurl+"user/availablepermission",{UserId:userid});
    return forkJoin(assignepermission,availablepermission)
  }
  getuserdata():Observable<userformmodel[]>{
    return this.httpclient.get<userformmodel[]>(this.baseurl+"user/userselect");
  }

  mail(from,frompass,to,subject,link, cartid):Observable<any[]>{   
    return this.httpclient.post<any[]>(this.baseurl+"Login/mailto",{frommail:from,frompass:frompass,tomail:to,subject:subject,link:link,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId, cartid:cartid})
  }
  saveuserpermission(userid:number,taskid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl +"user/saveuserpermission",{UserId:userid,TaskId:taskid,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId});
  }
  deleteuserpermission(UserId:number):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"user/deleteuserpermission",{UserId:UserId,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
  checkduplicate(userInfo:userformmodel):Observable<userformmodel[]>{
    return this.httpclient.post<userformmodel[]>(this.baseurl +"user/checkduplicate" ,userInfo);
  }

  saveperference(row,tabstyle,tablecol):Observable<any[]>{
    // console.log("row",row);
    // console.log("tabstyle",tabstyle);
    // console.log("tablecol",tablecol);
    
    return this.httpclient.post<any[]>(this.baseurl+"user/saveperference",{row:row,tabstyle:tabstyle,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId,name:JSON.parse(localStorage.getItem("userdetails"))[0].UserCompleteName,tablecol:tablecol})
  }

  getLicenceDetail(){
    return this.httpclient.get<any[]>(this.baseurl+"user/licenceDetail")
  }
  
  getCenter(){
    return this.httpclient.get<any[]>(this.baseurl + "user/userCenter")
  }

  getgrouplist():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"group/groupData")
  }

  getgroupavlper(groupid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/availablepermission",{groupid:groupid})
  }

  getgroupassper(groupid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/assignedpermission",{groupid:groupid})
  }

  getgrouppermissions(groupid){
    let avlbper= this.getgroupavlper(groupid)
    let assgnper=this.getgroupassper(groupid)
    return forkJoin([avlbper, assgnper]);
  }

  updategroup(groupid,groupname,activestatus,permlist):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/update",{groupid:groupid,groupname:groupname,activestatus:activestatus,permissionlist:permlist,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  creategroup(groupname,permlist):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/insert",{name:groupname,permissionlist:permlist,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }

  getpermission():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl+"group/getpermission")
  }

  checkduplicategroup(groupname):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/checkduplicate",{name:groupname})
  }
  deletegroup(groupid):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl+"group/deletegroup",{groupid:groupid,updateby:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
 
 
}

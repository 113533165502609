import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopHeaderComponent } from "./common/top-header/top-header.component";
import { BootomFooterComponent } from "./common/bootom-footer/bootom-footer.component";
import { LeftMenuComponent } from "./common/left-menu/left-menu.component";
import { UserComponent } from "./masters/user/user.component";
import { TableModule } from "primeng/table";
import { UserserviceService } from "./services/masters/userservice.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CategoryComponent } from "./masters/category/category.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatSelectModule } from "@angular/material";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CityComponent } from "./masters/city/city.component";
import { CountryComponent } from "./masters/country/country.component";
import { StateComponent } from "./masters/state/state.component";
import { EventComponent } from "./masters/event/event.component";
import { SubCategoryComponent } from "./masters/Sub-Category/sub-category.component";
import { PublicationComponent } from "./masters/publication/publication.component";
import { PoolComponent } from "./masters/pool/pool.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CategoryService } from "./services/masters/category.service";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { EventService } from "./services/masters/event.service";
import { UserRightsComponent } from "./masters/user/user-rights/user-rights.component";
import { DropdownModule } from "primeng/dropdown";
import { DialogModule } from "primeng/dialog";
import { ImageUploadComponent } from "./file/image-upload/image-upload.component";
import { UploadPopupComponent } from "./file/image-upload-popup/upload-popup.component";
import { AccordionModule } from "primeng/accordion";
import { CardModule } from "primeng/card";
import { RatingModule } from "primeng/rating";
import { CalendarModule } from "primeng/calendar";
import { FileUploadModule } from "primeng/fileupload";
import { InputSwitchModule } from "primeng/inputswitch";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { VideoUploadPopupComponent } from "./file/video-upload-popup/video-upload-popup.component";
import { AudioUploadComponent } from "./file/audio-upload/audio-upload.component";
import { AudioUploadPopupComponent } from "./file/audio-upload-popup/audio-upload-popup.component";
import { NgxAudioPlayerModule } from "ngx-audio-player";
import { DocumentUploadComponent } from "./file/document-upload/document-upload.component";
import { DocumentUploadPopupComponent } from "./file/document-upload-popup/document-upload-popup.component";
import { ImageSearchComponent } from "./file/Searching_Image/image-search/image-search.component";
import { LightboxModule } from "ngx-lightbox";
import { DataViewModule } from "primeng/dataview";
import { PanelModule } from "primeng/panel";
import { PickListModule } from "primeng/picklist";
import { ImageCropperModule } from "ngx-image-cropper";
import { VideoSearchComponent } from "./file/Search_Video/video-search/video-search.component";
import { AudioSearchComponent } from "./file/Search_Audio/audio-search/audio-search.component";
import { SearchDocumentComponent } from "./file/Search_Document/search-document/search-document.component";
import { LoginComponent } from "./login/login.component";
import { SharedcomponentComponent } from "./common/sharedcomponent/sharedcomponent.component";
import { DashboardComponent } from "./common/dashboard/dashboard.component";
import { ExportConfigComponent } from "./masters/export-config/export-config.component";
import { ProgressBarModule } from "primeng/progressbar";
import { MatVideoModule } from "mat-video";
import { StoryArchiveComponent } from "./file/story-archive/story-archive.component";
import { StoryArchiveUploadComponent } from "./file/story-archive-upload/story-archive-upload.component";
import { SearchingStoryComponent } from "./file/searching-story/searching-story.component";
import { SettingsComponent } from "./settings/settings/settings.component";
import { ProfileComponent } from "./profile/profile/profile.component";
import { TooltipModule } from "primeng/tooltip";
import { CarouselModule } from "primeng/carousel";
import { RadioButtonModule } from "primeng/radiobutton";
import { AllitemsComponent } from "./common/allitems/allitems.component";
import { PdfpreviewComponent } from "./common/pdfpreview/pdfpreview.component";
import { GalleriaModule } from "primeng/galleria";
import { AllsearchComponent } from "./file/allsearch/allsearch.component";
import { AgencystoryComponent } from "./file/agencystory/agencystory.component";
import { AgencyimagesComponent } from "./file/agencyimages/agencyimages.component";
import { SearchAgencyimgComponent } from "./file/search-agencyimg/search-agencyimg.component";
import { SearchAgencystryComponent } from "./file/search-agencystry/search-agencystry.component";
import { SidebarModule } from "primeng/sidebar";
import { TabViewModule } from "primeng/tabview";
import { NgHighlightModule } from "ngx-text-highlight";
import { AutoCompleteModule } from "primeng/autocomplete";
// import { ToastUiImageEditorModule } from 'ngx-tui-image-editor';
import { TreeTableModule } from "primeng/treetable";
import { TreeNode } from "primeng/api";
import { SliderModule } from "primeng/slider";
import { MysharecartComponent } from "./file/Cart/mysharecart/mysharecart.component";
import { SharedtomecartComponent } from "./file/Cart/sharedtomecart/sharedtomecart.component";
import { CheckboxModule } from "primeng/checkbox";
import { NgxGalleryModule } from "ngx-gallery";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { SplitButtonModule } from "primeng/splitbutton";
import { ContextMenuModule } from "primeng/contextmenu";
import { MenuItem } from "primeng/api";
import { CartComponent } from "./common/cart/cart.component";
import { ImageEditFormComponent } from "./file/image-edit-form/image-edit-form.component";
import { DocumentEditFormComponent } from "./file/document-edit-form/document-edit-form.component";
import { NgImageSliderModule } from "ng-image-slider";
import { CartlinkComponent } from "./common/cartlink/cartlink.component";
import { PurgingComponent } from "./settings/purging/purging.component";
import { TreeviewModule } from "ngx-treeview";
import { TreeModule } from "primeng/tree";
// import { BadgeModule } from 'primeng/badge';
import { TreeviewcomComponent } from "./common/treeviewcom/treeviewcom.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { TreeviewadsrchComponent } from "./common/treeviewadsrch/treeviewadsrch.component";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { EditorModule } from "primeng/editor";
import { UserIdleModule } from "angular-user-idle";
import { environment } from "src/environments/environment";
import { MastereventsComponent } from "./file/masterevents/masterevents.component";
import { TreeuploadComponent } from "./common/treeupload/treeupload.component";
import { UserperferenceComponent } from "./masters/userperference/userperference.component";
import { LogexportComponent } from "./common/logexport/logexport.component";
import { FieldsetModule } from "primeng/primeng";
import { ServerstatusComponent } from "./masters/serverstatus/serverstatus.component";
import { MastergroupComponent } from "./masters/mastergroup/mastergroup.component";
import { MasteragencyComponent } from "./masters/agency/masteragency/masteragency.component";
import { AgencycategoryComponent } from "./masters/agency/agencycategory/agencycategory.component";
import { TreedropdownComponent } from "./masters/treedropdown/treedropdown.component";
import { VideoeditformComponent } from "./file/videoeditform/videoeditform.component";
import { HttpConfigInterceptor } from "./httpconfig.interceptor";
import { MultiSelectModule } from "primeng/multiselect";
import { VideocommentComponent } from "./file/videocomment/videocomment.component";
import { ColorPickerModule } from "primeng/colorpicker";
import { KeywordsComponent } from "./masters/keywords/keywords.component";
import { BysubjectComponent } from "./masters/bysubject/bysubject.component";
import { VideokeywordsComponent } from "./file/videokeywords/videokeywords.component";
import { DailypagesComponent } from "./file/dailypages/dailypages.component";
import { DailypagesearchComponent } from "./file/dailypagesearch/dailypagesearch.component";
import { MastereditionComponent } from "./masters/masteredition/masteredition.component";
import { EditionrightComponent } from "./masters/masteredition/editionright/editionright.component";
import { MasterRelationComponent } from "./masters/master-relation/master-relation.component";
import { VideoUploadComponent } from "./file/video-upload/video-upload.component";
import { GraphicsUploadComponent } from "./file/graphics-upload/graphics-upload.component";
import { GraphicsEditFormComponent } from "./file/graphics-edit-form/graphics-edit-form.component";
import { GraphicsUploadPopupComponent } from "./file/graphics-upload-popup/graphics-upload-popup.component";
import { SearchGraphicsComponent } from "./file/search-graphics/search-graphics.component";
import { RecycleBinComponent } from "./common/recycle-bin/recycle-bin.component";
import { LicenceComponent } from "./profile/licence/licence.component";
import { AudioEditFormComponent } from "./file/audio-edit-form/audio-edit-form.component";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { ExternalDeviceDetailComponent } from "./file/external-device-detail/external-device-detail.component";
import { AgencyleftbarComponent } from "./masters/agency/agencyleftbar/agencyleftbar.component";
import { SubsubcategoryComponent } from "./masters/sub-sub-category/subsubcategory.component";
import { HrCornerComponent } from "./file/hr-corner/hr-corner.component";
import { HrCornerFormComponent } from "./file/hr-corner-form/hr-corner-form.component";
import { HrUploadPopupComponent } from "./file/hr-upload-popup/hr-upload-popup.component";
import { TreeDragDropService } from "primeng/api";
import { HighlighttextPipe } from "./highlighttext.pipe";
import { VideoplayerComponent } from "./file/video-upload/videoplayer/videoplayer.component";
import { VaultManagementComponent } from "./masters/vault-management/vault-management.component";
import { LogingClipComponent } from "./masters/loging-clip/loging-clip.component";
import { ItemPreviewComponent } from './item-preview/item-preview.component';
import { CompanyMasterComponent } from './masters/CompanyMaster/company-master/company-master.component';
import { TreeviewdatacomComponent } from './common/treeviewdatacom/treeviewdatacom.component';
import { TreedataComponent } from './common/treedata/treedata/treedata.component';
import { DatePipe } from '@angular/common';
import { UserLogsComponent } from './common/ApprovalLogs/user-logs/user-logs.component';
import { ManagerLogsComponent } from './common/ApprovalLogs/manager-logs/manager-logs.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    BootomFooterComponent,
    LeftMenuComponent,
    HrCornerComponent,
    UserComponent,
    HrCornerFormComponent,
    CategoryComponent,
    HrUploadPopupComponent,
    CityComponent,
    CountryComponent,
    StateComponent,
    EventComponent,
    SubCategoryComponent,
    PublicationComponent,
    PoolComponent,
    UserRightsComponent,
    ImageUploadComponent,
    UploadPopupComponent,
    VideoUploadPopupComponent,
    VideoUploadComponent,
    AudioUploadComponent,
    AudioUploadPopupComponent,
    DocumentUploadComponent,
    DocumentUploadPopupComponent,
    ImageSearchComponent,
    VideoSearchComponent,
    AudioSearchComponent,
    SearchDocumentComponent,
    LoginComponent,
    SharedcomponentComponent,
    DashboardComponent,
    ExportConfigComponent,
    StoryArchiveComponent,
    StoryArchiveUploadComponent,
    SearchingStoryComponent,
    SettingsComponent,
    ProfileComponent,
    AllitemsComponent,
    PdfpreviewComponent,
    AllsearchComponent,
    AgencystoryComponent,
    AgencyimagesComponent,
    SearchAgencyimgComponent,
    SearchAgencystryComponent,
    MysharecartComponent,
    SharedtomecartComponent,
    CartComponent,
    ImageEditFormComponent,
    DocumentEditFormComponent,
    CartlinkComponent,
    PurgingComponent,
    TreeviewcomComponent,
    TreeviewadsrchComponent,
    MastereventsComponent,
    TreeuploadComponent,
    UserperferenceComponent,
    LogexportComponent,
    ServerstatusComponent,
    MastergroupComponent,
    MasteragencyComponent,
    AgencycategoryComponent,
    TreedropdownComponent,
    VideoeditformComponent,
    VideocommentComponent,
    KeywordsComponent,
    BysubjectComponent,
    VideokeywordsComponent,
    DailypagesComponent,
    DailypagesearchComponent,
    MastereditionComponent,
    EditionrightComponent,
    MasterRelationComponent,
    GraphicsUploadComponent,
    GraphicsEditFormComponent,
    GraphicsUploadPopupComponent,
    SearchGraphicsComponent,
    RecycleBinComponent,
    VaultManagementComponent,
    LicenceComponent,
    AudioEditFormComponent,
    ExternalDeviceDetailComponent,
    AgencyleftbarComponent,
    SubsubcategoryComponent,
    HighlighttextPipe,
    VideoplayerComponent,
    LogingClipComponent,
    ItemPreviewComponent,
    CompanyMasterComponent,
    TreeviewdatacomComponent,
    TreedataComponent,
    UserLogsComponent,
    ManagerLogsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxAudioPlayerModule,
    TreeTableModule,
    ContextMenuModule,
    TreeModule,
    NgxFileDropModule,
    BreadcrumbModule,
    EditorModule,
    // BadgeModule,
    UserIdleModule.forRoot({
      idle: environment.sessiontimeout,
      timeout: environment.sessiontimeout,
      ping: 120,
    }),
    FieldsetModule,
    AppRoutingModule,
    TableModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    TreeviewModule.forRoot(),
    MultiSelectModule,
    ColorPickerModule,
    MatButtonModule,
    VirtualScrollerModule,
    DragDropModule,
    MatSelectModule,
    ConfirmDialogModule,
    ToastModule,
    DropdownModule,
    DialogModule,
    AccordionModule,
    CardModule,
    RatingModule,
    CalendarModule,
    FileUploadModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    LightboxModule,
    DataViewModule,
    PanelModule,
    PickListModule,
    ImageCropperModule,
    ProgressBarModule,
    MatVideoModule,
    TooltipModule,
    CarouselModule,
    RadioButtonModule,
    GalleriaModule,
    SidebarModule,
    TabViewModule,
    NgHighlightModule,
    AutoCompleteModule,
    SliderModule,
    CheckboxModule,
    NgxGalleryModule,
    OverlayPanelModule,
    SplitButtonModule,
  ],
  providers: [
    UserserviceService,
    CategoryService,
    ConfirmationService,
    MessageService,
    EventService,
    TreeDragDropService,
    DatePipe,
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import {EditionService} from 'src/app/services/masters/edition.service';
import{userformmodel} from 'src/app/model/master/user.form.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-editionright',
  templateUrl: './editionright.component.html',
  styleUrls: ['./editionright.component.css']
})
export class EditionrightComponent implements OnInit {
  userid: number = 0;
  username: string;
  assignedpermission: any[];
  availablepermission: any[];
  userlist: userformmodel[] = [];
  DDdisabled: boolean = true;
  UserCompleteName: any;
  constructor(private userser: EditionService, private messageService: MessageService) { }

  ngOnInit() {
    // this.getdragdrop();
    this.getuserdata();
  }
  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //                       event.container.data,
  //                       event.previousIndex,
  //                       event.currentIndex);
  //   } 
  // }
  getdragdropdata(userid: number) {
    this.userser.getuserpermissions(userid).subscribe((data) => {
      if (data != null) {
        this.assignedpermission = data[0]
        this.availablepermission = data[1]
      }
    })
  }
  getpermission(e) {
    let selecteduserid = e.target.selectedOptions[0].value;
    this.userid = selecteduserid;

    this.getdragdropdata(selecteduserid)

  }


  getuserdata() {
    this.userser.getuserdata().subscribe((data: any[]) => {
      if (data != null) {
        this.userlist = data
        for (let i = 0; i < data.length; i++) {
          if (data[i].LoginId.toLowerCase() == "admin") {
            this.userlist.splice(i, 1)
          }
        }
      }
    })
  }

  SaveUserpermission() {
    let i = 0
    this.DeleteUserPermission();
    //for (i = 0; i < this.assignedpermission.length; i++) {
      this.userser.saveuserpermission(this.userid, this.assignedpermission).subscribe((response: any[]) => {
        this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Saved successfully' });
        this.getdragdropdata(this.userid)
      })
    //}
  
  }


  DeleteUserPermission() {
    this.userser.deleteuserpermission(this.userid).subscribe((response: any[]) => {
    })
  }


  clear() {
    this.userid = 0
    this.assignedpermission = []
    this.availablepermission = []
  }

}

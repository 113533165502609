import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  NgForm,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { MessageService, ConfirmationService } from "primeng/api";
import { FileUpload } from "primeng/primeng";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { ProfileService } from "src/app/services/settings_service/profile.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) fileInput: FileUpload;
  @ViewChild("imagetemp", { static: true }) ngtemp: ElementRef;
  ImageUrl = environment.ApiUrl;
  selectedFiles: any[] = [];
  UserName: string;
  UpdateForm: FormGroup;
  userlist: userformmodel[] = [];
  Name: string;
  UpdatUserInformation: userformmodel;
  profileimgpath: string;
  profileimg: string = "";
  selectedFilesToSave: any;
  ApiServerPath: any;
  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private userser: UserserviceService,
    private Confirmservice: ConfirmationService,
    private profileservice: ProfileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // this.profileimg=environment.ApiServerPath + JSON.parse(localStorage.getItem("userdetails"))[0].UserImagePath
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.profileimg =
      this.ApiServerPath +
      JSON.parse(localStorage.getItem("userdetails"))[0].UserImagePath;
    this.createUpdateuserform();
    this.UserUpdateValue(JSON.parse(localStorage.getItem("userdetails")));
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  createUpdateuserform() {
    this.UpdateForm = this.formBuilder.group({
      UserFirstName: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ]*$"),
          this.removeSpaces,
        ],
      ],
      UserLastName: [
        null,
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z ]*$"),
          this.removeSpaces,
        ],
      ],
      UserEmailId: [
        null,
        [
          Validators.required,
          Validators.pattern("^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$"),
          this.removeSpaces,
        ],
      ],
      Mobile: [null, [Validators.required]],
      UserId: [null],
      UserImagePath: [null],
    });
  }
  UserUpdateValue(userlist: userformmodel) {
    (this.Name = userlist[0].UserCompleteName),
      this.UpdateForm.setValue({
        UserFirstName: userlist[0].UserFirstName,
        UserLastName: userlist[0].UserLastName,
        UserEmailId: userlist[0].UserEmailId,
        Mobile: userlist[0].Mobile,
        UserId: userlist[0].UserId,
        UserImagePath: userlist[0].UserImagePath,
      });
  }

  UpdateUser(formvalue) {
    this.UpdatUserInformation = formvalue.value;
    var userid = this.UpdatUserInformation.UserId;
    this.UpdatUserInformation.UserImagePath = this.selectedFilesToSave;
    this.fileInput.upload();
    this.profileservice
      .updateProfile(this.UpdatUserInformation)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.profileservice.getuserdata(userid).subscribe((data1: any[]) => {
            if (data1.length > 0) {
              this.profileimg = this.ApiServerPath + data1[0].UserImagePath;
              localStorage.removeItem("userdetails");
              localStorage.setItem("userdetails", JSON.stringify(data1));
              this.UserUpdateValue(
                JSON.parse(localStorage.getItem("userdetails"))
              );
              this.messageService.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Update Successfully",
              });
              this.Name = data1[0].UserCompleteName;
              this.userser.updateprofileimg(this.profileimg);
            }
          });
        }
      });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }

  uploadtemp(event) {
    this.selectedFilesToSave = event.files;
    for (const file of this.selectedFilesToSave) {
      // console.log("Selected file name:", file.name);
      this.selectedFilesToSave = file.name;
      // You can now use the file name as needed
    }
    // this.fileInput.upload();
  }

  gettempname(e) {
    //this.profileimgpath=e.originalEvent.body.success
    // this.UpdateForm.patchValue({ temppath: e.originalEvent.body.success })
  }
}

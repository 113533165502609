import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ConfirmationService, MessageService, MenuItem } from "primeng/api";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import Cropper from "cropperjs";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { environment } from "src/environments/environment";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ActivatedRoute } from "@angular/router";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { ContextMenu, Dialog } from "primeng/primeng";
import { Lightbox } from "ngx-lightbox";
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from "ngx-gallery";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SpeechTextService } from "src/app/services/speech/speech-text.service";
import { saveAs } from "file-saver";
import { VideoplayerComponent } from "src/app/file/video-upload/videoplayer/videoplayer.component";

@Component({
  selector: "app-allitems",
  templateUrl: "./allitems.component.html",
  styleUrls: ["./allitems.component.css"],
})
export class AllitemsComponent implements OnInit {
  @ViewChild("ytplayer", null) ytPlayer: ElementRef;
  @ViewChild("ytplayer1", null) ytPlayer1: ElementRef;
  @ViewChild("cropimagecanvas", { static: false }) cropimagecanvas: ElementRef;
  @ViewChild("audio", { static: true }) Player: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: DataView;
  @ViewChild("vid", { static: false }) vid: VideoplayerComponent;
  ctx: CanvasRenderingContext2D;
  audioimage: string = "assets/image/Wave_bg.jpg";
  elementarr: ImageUploadFormModel[];
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexstype: any[] = [];
  selectedIndexsid: any[] = [];
  permissionlist: PermissionModel[];
  downloaditems: MenuItem[];
  showfilter: boolean = false;
  downloadbtn: number = 0;
  imgcrppbtn: number = 0;
  imgdownloadbtn: number = 0;
  exportbtn: number = 0;
  videocropbtn: number = 0;
  storydownloadbtn: number = 0;
  audiodownloadbtn: number = 0;
  pdfimages: any[];
  viewDocument: boolean = false;
  glbDeletedoc: number = 0;
  dpxbtn: number = 1;
  downloadDocumentList: downloadimagesmodel[] = [];
  showDocumentDownload: boolean = false;
  text: string;
  fullDocumentpathlist: DocumentUploadFormModel;
  ratingitemdoc: any;
  downloadAudioList: downloadimagesmodel[] = [];
  showAudioDownload: boolean = false;
  fullAudiolist: audiouploadModel;
  ratingitemaudio: any;
  Audiopreviewpath: string;
  viewAudio: boolean = false;
  AudioUpdated: boolean = false;
  private cropper: Cropper;
  public imageDestination = "";
  ButtonStatusimg = "Download";
  ApiServerPath = environment.ApiServerPath;
  @ViewChild("image", { static: false }) public imageElement: ElementRef;
  downloadListimg: downloadimagesmodel[] = [];
  showDownloadimg: boolean = false;
  fullpathlist: ImageUploadFormModel;
  ratingitemimg: any;
  imagePath: any;
  view: boolean = false;
  @ViewChild("video", { static: true }) private videoElement: ElementRef;
  @ViewChild("videogif", { static: true }) private videogif: ElementRef;
  ratingitemvideo: any;
  fullvideolist: VideoUploadModel;
  viewVideo: boolean = false;
  Buttons: string = "Play";
  durationarray = [];
  ButtonStatus: string = "MarkIn";
  playStatus: string = "Pause";
  volumeStatus: string = "unmute";
  markinvl = 1;
  markoutval = 1;
  MarkInValue;
  MarkOutValue;
  totalDuration;
  markinArray = [];
  totaldurarray = [];
  minutes: any;
  minutes_d: any;
  ExportList: exportConfigurationModel[] = [];
  downloadVideoList: downloadimagesmodel[] = [];
  exportitemid: number;
  exportitemname: string;
  videopreviewpath: string;
  video: string;
  showVideoDownload: boolean = false;
  fullstorylist: StoryFormModel;
  headline: string;
  ratingitemstory: any;
  viewStory: boolean = false;
  selectedAttachmentlist: attachmentFormModel[] = [];
  attachmentlist: attachmentFormModel[] = [];
  srchtext: string = "";
  brightness: number = 0;
  contrast: number = 0;
  saturate: number = 0;
  watermark: string = "India Today";
  metadatadiv: number = 1;
  editdiv: number = 0;
  grin: string = "";
  totalcount: string;
  advnsrchfil: any;
  loading: boolean = true;
  watermarkposition: string = "topright";
  showeditor: boolean = false;
  iframepath: string = "";
  idforimageedit: string = "";
  docfileforup: string = "";
  docidforedit: string = "";
  DocumentUpdated: boolean = false;
  ImageUpdated: boolean = false;
  imagenameforedit: string = "";
  videoupdate: boolean = false;
  videoidforedit: string = "";
  videofileforup: string = "";
  contextmenuitem: MenuItem[];
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  cartname: string = null;
  cartitemid: string;
  cartitemtype: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  results: string[] = [];
  filteredBrands: any[];
  addtocartimage: number = 0;
  addtocartstory: number = 0;
  addtocartaudio: number = 0;
  addtocartvideo: number = 0;
  addtocartdoc: number = 0;
  editdocmeta: number = 0;
  editimgmeta: number = 0;
  selectedIndexspermis: any[] = [];
  tablestyle: string = "grid";
  rowperpage: Number = 18;
  imagecount: string = "";
  graphicscount: string = "";
  doccount: string = "";
  videocount: string = "";
  audiocount: string = "";
  storycount: string = "";
  ismenuclick: boolean = false;
  rightclickmenuopen: boolean = false;
  treedropvisible: boolean = false;
  @ViewChild("conetxtmenudiv", { static: false })
  public conetxtmenudiv: ContextMenu;
  @ViewChild("editdialog", { static: true }) editdialog: Dialog;
  @ViewChild("editdialogAudio", { static: true }) editdialogAudio: Dialog;
  autoCompletArr: any[] = [];
  orgname: string = "";
  relationlist: string[] = [];
  filteredrelation: any[];
  showrelation: boolean = false;
  relationname: string;
  images: any[];
  _album: any[] = [];
  grouplist: any[] = [];
  filepath: string = "";
  slideIndex = 0;
  galleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  audioidforedit: string;
  audiofileforup: string;
  activeLtoDot: number = 0;
  microphone: number = 0;
  indexPrev: any = null;
  indexCurrent: any;
  collpath: string = "";
  poster: any;
  videodata: any;
  videoDuration: any;
  highlightsearch: string;
  videositemtypes: MenuItem[];
  restoreSelectFormat: any;
  constructor(
    private _lightbox: Lightbox,
    private dashboarservice: DashboardService,
    private documentservice: DocumentuploadService,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    public speechTextService: SpeechTextService,
    public audiouploadservice: AudiouplaodService,
    private formBuilder: FormBuilder,
    public subcategoryService: SubCategoryService,
    public imageservice: ImageuploadService,
    public sanitizer: DomSanitizer,
    public videoservice: VideouploadService,
    private storyservice: StoryserviceService
  ) {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
    this.speechTextService.init();
  }

  ngAfterViewInit() {
    let cartlistsub = this.dashboarservice
      .getcartlistdata()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.results = data;
        }
        cartlistsub.unsubscribe();
      });
    let relationlistsub = this.dashboarservice
      .getrelationlist()
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.relationlist = data;
        }
        relationlistsub.unsubscribe();
      });
  }

  ngOnInit() {
    this.dashboarservice.eventEmitter.subscribe((data) => {
      this.fullpathlist = data.message;
      // console.log(this.fullpathlist);
    });
    this.dashboarservice.eventEmitter.subscribe((data) => {
      this.fullDocumentpathlist = data.message;
      // console.log(this.fullDocumentpathlist);
    });
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      this.rowperpage = JSON.parse(
        localStorage.getItem("userperference")
      )[0].noofrows;
      this.tablestyle = JSON.parse(
        localStorage.getItem("userperference")
      )[0].tablestyle;
      this.rowperpage = Number(this.rowperpage);
    }
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.checkpermissions();
    this.getdownloaditemtype();
    //this.search()
    this.totalcount = localStorage.getItem("totalcount");
    this.srchtext = this.route.snapshot.paramMap.get("text");
    // console.log(this.srchtext);

    if (this.srchtext == null) {
      this.srchtext = "";
    }
    this.dashboarservice.visibletreedrop(false);
    this.editdialog.onShow.subscribe({
      next: this.handleOnShow.bind(this),
    });
    this.blindsearchwithdata("");
    this.fullDocumentpathlist = new DocumentUploadFormModel();
    this.fullAudiolist = new audiouploadModel();
    this.fullpathlist = new ImageUploadFormModel();
    this.fullvideolist = new VideoUploadModel();
    this.fullstorylist = new StoryFormModel();
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      // {
      //   label: "Add to cart",
      //   icon: "fa fa-shopping-cart",
      //   command: (event) => {
      //     this.showmulcartdiv();
      //   },
      // },
      {
        label: "Edit",
        icon: "fa fa-edit",
        command: (event) => {
          this.multipleedit();
        },
      },
      // {
      //   label: 'Set Relationship',
      //   icon: 'fa fa-link',
      //   command: (event) => { this.showmulrelationpopup(); }
      // }
    ];
    this.dashboarservice.gettreedropstatus().subscribe((data: boolean) => {
      this.loading = data;
    });

    $(document).on("click", function (event) {
      if (!$(event.target).closest("#spnauto").length) {
        $("#ulautocomplete").hide();
      } else {
        // $('#ulautocomplete').show();
      }
    });
    this.galleryOptions = [
      {
        image: false,
        width: "100%",
        height: "100px",
        thumbnailsColumns: 8,
        previewDownload: true,
      },
      { breakpoint: 700, width: "100%" },
    ];
  }
  open(index: number): void {
    this._lightbox.open(this._album, index);
  }
  close1() {
    document.getElementById("markslider").innerHTML = "";
    this.durationarray = [];
    this.markinArray = [];
    this.totaldurarray = [];
    this.ButtonStatus = "MarkIn";
    this.markinvl = null;
    this.markoutval = null;
  }

  // close1(): void {
  //   this._lightbox.close();
  // }
  getgroupimage(groupid) {
    this.images = [];
    this.videoservice.GetAllgroupimage(groupid).subscribe((data: any[]) => {
      if (data.length > 0) {
        this.grouplist = data;
        this.galleryImages = [];
        this._album = [];
        for (let i = 0; i < this.grouplist.length; i++) {
          this.filepath = this.grouplist[i].thumbpath;
          var filename = this.grouplist[i].orgfilename;
          const album = {
            src: this.ApiServerPath + this.grouplist[i].hirespath,
            caption: filename,
            thumb: this.ApiServerPath + this.grouplist[i].hirespath,
          };

          this._album.push(album);
          this.galleryImages.push({
            small: this.ApiServerPath + this.filepath,
            medium: this.ApiServerPath + this.grouplist[i].hirespath,
            big: this.ApiServerPath + this.grouplist[i].hirespath,
            description: filename,
          });
          //this.images.push({source:[this.ApiServerPath+this.filepath], alt:'', title:filename});
        }
      }
    });
  }
  imageclick(index) {
    var selectfile = index.path[0].src;
    var path1 = decodeURIComponent(selectfile.replace(this.ApiServerPath, ""));
    var index1 = this.grouplist.findIndex((p) => p.thumbpath == path1);
    this.open(index1);
    //alert(index1)
    //document.getElementById('imgModal').style.display = "block";
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 51) {
        this.imgdownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 44) {
        this.imgcrppbtn = 1;
      }
      if (this.permissionlist[i].taskid == 45) {
        this.exportbtn = 1;
      }
      if (this.permissionlist[i].taskid == 46) {
        this.videocropbtn = 1;
      }
      if (this.permissionlist[i].taskid == 56) {
        this.storydownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 48) {
        this.audiodownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 55) {
        this.addtocartdoc = 1;
      }
      if (this.permissionlist[i].taskid == 54) {
        this.addtocartimage = 1;
      }
      if (this.permissionlist[i].taskid == 49) {
        this.addtocartaudio = 1;
      }
      if (this.permissionlist[i].taskid == 50) {
        this.addtocartvideo = 1;
      }
      if (this.permissionlist[i].taskid == 57) {
        this.addtocartstory = 1;
      }
      if (this.permissionlist[i].taskid == 14) {
        this.editdocmeta = 1;
      }
      if (this.permissionlist[i].taskid == 11) {
        this.editimgmeta = 1;
      }

      if (this.permissionlist[i].taskid == 134) {
        // this.getAllHRData()
        // this.hrCornerTab = 1;
      }
    }
  }

  handleOnShow() {
    this.editdialog.contentViewChild.nativeElement.addEventListener(
      "scroll",
      this.scroll.bind(this)
    );
  }

  scroll(e: Event) {
    //alert("scroll")
    this.dashboarservice.visibletreedrop(false);
  }

  cmshow() {
    // console.log("cmshow clicked");
    this.rightclickmenuopen = true;
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    this.contextmenuitem[2].disabled = false;
    if (
      this.downloadbtn == 0 ||
      this.imgdownloadbtn == 0 ||
      this.storydownloadbtn == 0 ||
      this.audiodownloadbtn == 0 ||
      this.videocropbtn == 0
    ) {
      this.contextmenuitem[0].disabled = false;
      for (let i = 0; i < this.selectedIndexstype.length; i++) {
        if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
          if (this.imgdownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
          if (this.videocropbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
          if (this.audiodownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
          if (this.storydownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else {
          if (this.downloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        }
      }
    }
    if (
      this.addtocartdoc == 0 ||
      this.addtocartimage == 0 ||
      this.addtocartaudio == 0 ||
      this.addtocartvideo == 0 ||
      this.addtocartstory == 0
    ) {
      this.contextmenuitem[1].disabled = false;
      for (let i = 0; i < this.selectedIndexstype.length; i++) {
        if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
          if (this.addtocartimage == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
          if (this.addtocartvideo == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
          if (this.addtocartaudio == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
          if (this.addtocartstory == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else {
          if (this.addtocartdoc == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        }
      }
    }
    if (this.editdocmeta == 0 || this.editimgmeta == 0) {
      this.contextmenuitem[2].disabled = false;
      for (let i = 0; i < this.selectedIndexstype.length; i++) {
        if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
          if (this.editimgmeta == 0) {
            this.contextmenuitem[2].disabled = true;
            break;
          }
        } else {
          if (this.editdocmeta == 0) {
            this.contextmenuitem[2].disabled = true;
            break;
          }
        }
      }
    }
    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        this.contextmenuitem[2].disabled = true;
        break;
      }
    }
  }

  blindsearch(type) {
    // this.highlightsearch = this.srchtext
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    // if(this.microPhoneText != ''){
    //   this.srchtext = this.microPhoneText.trim()
    // }
    if (this.srchtext.trim() != "") {
      this.blindsearchwithdata(type);
    } else {
      environment.globalsearchtext = "";
      environment.glbsearchstring = "";
      var tabstr = localStorage.getItem("tabperstring");
      this.dashboarservice
        .blindsearch(tabstr, "0~0~0~0~0~0~0~0")
        .subscribe((data: any) => {
          if (data != null) {
            var data1 = JSON.parse(
              data.toString().split("~")[0].replace(/\ʁ/g, "~")
            );
            data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
            this.elementarr = data1;
            // console.log("data",data1);
            this.loading = false;
            this.imagecount = data.toString().split("~")[1];
            this.doccount = data.toString().split("~")[2];
            this.videocount = data.toString().split("~")[3];
            this.audiocount = data.toString().split("~")[4];
            this.storycount = data.toString().split("~")[5];
            this.graphicscount = data.toString().split("~")[6];
            this.totalcount = localStorage.getItem("totalcount");
          }
        });
      this.loading = false;
    }
    this.loading = false;
    this.stopMicroPhoneService();
    // console.log(this.srchtext);
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }
    // console.log(MergeParamater,'console.log(MergeParamater)')
    this.highlightsearch = MergeParamater.replace(/\+/g, " ");
    //  console.log(MergeParamater,'console.log(MergeParamater)')
    return MergeParamater;
  }

  blindsearchwithdata(type) {
    this.imagecount = "";
    this.doccount = "";
    this.videocount = "";
    this.audiocount = "";
    this.storycount = "";
    this.graphicscount = "";
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.srchtext == "undefined") {
      this.srchtext = "";
      let searchText = environment.glbsearchstring;
      searchText = "";
      this.newadvancesearch(searchText);
    } else {
      // console.log(this.srchtext)
      this.srchtext = this.srchtext.trim();
      // console.log(this.srchtext)
      if (this.srchtext.trim() != "") {
        // environment.globalsearchtext = this.srchtext
        environment.globalsearchtext = "";
        environment.glbsearchstring = "";
        var srtxt = this.ExtractSearchingKeyword(this.srchtext);

        var tabstr = localStorage.getItem("tabperstring");
        this.dashboarservice
          .getacount(srtxt, tabstr)
          .subscribe((data2: any[]) => {
            if (data2 != null) {
              this.totalcount = data2.toString();
            }
          });
        this.dashboarservice
          .blindsearchwithdata(srtxt, "", tabstr, "0~0~0~0~0~0~0~0")
          .subscribe((data: any[]) => {
            if (data != null) {
              if (type == "") {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.elementarr = data1;
                this.imagecount = data.toString().split("~")[1];
                this.doccount = data.toString().split("~")[2];
                this.videocount = data.toString().split("~")[3];
                this.audiocount = data.toString().split("~")[4];
                this.storycount = data.toString().split("~")[5];
                this.graphicscount = data.toString().split("~")[6];
                this.loading = false;
                this.srchtext = srtxt;
              } else {
                this.totalcount = data[0];
              }

              //this.totalcount=this.elementarr.length.toString()
            }
          });
      } else {
        let searchText = environment.glbsearchstring;
        searchText = "";
        if (environment.glbsearchstring != "") {
          // this.newadvancesearch(environment.glbsearchstring)
          this.newadvancesearch(searchText);
        }
        // else if (environment.globalsearchtext.trim() != "") {
        else if (searchText.trim() != "") {
          // this.srchtext = environment.globalsearchtext.trim()
          this.srchtext = searchText.trim();
          var srtxt = this.ExtractSearchingKeyword(this.srchtext);
          var tabstr = localStorage.getItem("tabperstring");
          this.dashboarservice
            .getacount(srtxt, tabstr)
            .subscribe((data2: any[]) => {
              if (data2 != null) {
                this.totalcount = data2.toString();
              }
            });
          this.dashboarservice
            .blindsearchwithdata(srtxt, "", tabstr, "0~0~0~0~0~0~0~0")
            .subscribe((data: any[]) => {
              if (data != null) {
                if (type == "") {
                  var data1 = JSON.parse(
                    data.toString().split("~")[0].replace(/\ʁ/g, "~")
                  );
                  data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                  this.elementarr = data1;
                  this.imagecount = data.toString().split("~")[1];
                  this.doccount = data.toString().split("~")[2];
                  this.videocount = data.toString().split("~")[3];
                  this.audiocount = data.toString().split("~")[4];
                  this.storycount = data.toString().split("~")[5];
                  this.graphicscount = data.toString().split("~")[6];

                  // this.totalcount=this.elementarr.length.toString()
                  this.loading = false;
                  this.srchtext = srtxt;
                } else {
                  //this.totalcount=data[0]
                }
                //this.totalcount=this.elementarr.length.toString()
              }
            });
        } else {
          this.blindsearch("");
        }
      }
    }
    this.loading = false;
  }
  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  getcount() {
    var tabstr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .getncount(this.srchtext, tabstr, this.collpath)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.totalcount = data.toString();
        }
      });
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.srchtext = "";
    this.highlightsearch = "";
    //this.microPhoneText = ""
    environment.globalsearchtext = "";
    this.blindsearch("");
    this.totalcount = localStorage.getItem("totalcount");
  }

  searchalldata(formvalues, fn) {
    //this.advnsrchfil=formvalues
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    this.srchtext = "";
    environment.globalsearchtext = "";
    environment.glbsearchstring = "";
    if (formvalues.itemkeyword != null) {
      formvalues.itemkeyword = formvalues.itemkeyword.replace(
        new RegExp(",", "g"),
        " "
      );
    }
    this.hideadvncsrch();
    if (formvalues.CategoryId == "null") {
      formvalues.CategoryId = null;
      formvalues.SubCategoryId = null;
    }
    if (fn != "") {
      this.dashboarservice
        .advancesearch(formvalues)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
            this.elementarr = data;
            if (formvalues.totalcount != "") {
              if (formvalues.totalcount != undefined) {
                this.totalcount = formvalues.totalcount;
              } else {
                this.totalcount = this.elementarr.length.toString();
              }
            } else {
              this.totalcount = this.elementarr.length.toString();
            }
          } else {
            this.elementarr = [];
          }
        });
    } else {
      this.blindsearch("");
    }

    this.loading = false;
  }

  advanceSearch() {
    this.showfilter = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.showfilter = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
    this.srchtext = "";
  }

  newadvancesearch(srctxt) {
    this.imagecount = "";
    this.doccount = "";
    this.videocount = "";
    this.audiocount = "";
    this.storycount = "";
    this.graphicscount = "";

    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    // environment.glbsearchstring = srctxt
    environment.glbsearchstring = "";
    environment.globalsearchtext = "";
    this.hideadvncsrch();
    var tabstr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .getncount(srctxt, tabstr, this.collpath)
      .subscribe((data2: any[]) => {
        if (data2 != null) {
          this.totalcount = data2.toString();
        }
      });
    this.dashboarservice
      .newadvancesearch(srctxt, tabstr, "0~0~0~0~0~0~0~0")
      .subscribe((data: any[]) => {
        if (data != null) {
          var data1 = JSON.parse(
            data.toString().split("~")[0].replace(/\ʁ/g, "~")
          );
          data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
          this.elementarr = data1;
          this.imagecount = data.toString().split("~")[1];
          this.doccount = data.toString().split("~")[2];
          this.videocount = data.toString().split("~")[3];
          this.audiocount = data.toString().split("~")[4];
          this.storycount = data.toString().split("~")[5];
          this.graphicscount = data.toString().split("~")[6];

          //this.totalcount=this.elementarr.length.toString()
        } else {
          this.elementarr = [];
        }
        this.loading = false;
      });
  }

  pagenochange(e) {
    // console.log("page changed");
    this.indexPrev = null;
    this.removeselection();

    // environment.globalsearchtext = environment.globalsearchtext.trim()
    environment.globalsearchtext = "";
    if (Number(this.dataview1.totalRecords) >= Number(this.totalcount)) {
    } else {
      var x = document.querySelector(".ui-paginator-bottom");
      x["style"].cursor = "not-allowed";
      x["style"]["pointer-events"] = "none";
      this.loading = true;
      var tabstr = localStorage.getItem("tabperstring");
      var count =
        this.imagecount +
        "~" +
        this.doccount +
        "~" +
        this.videocount +
        "~" +
        this.audiocount +
        "~" +
        this.storycount +
        "~" +
        this.graphicscount;
      if (environment.glbsearchstring != "") {
        // this.dashboarservice.newadvancesearch(environment.glbsearchstring, tabstr, count).subscribe((data: any[]) => {
        let searchText = environment.glbsearchstring;
        searchText = "";
        this.dashboarservice
          .newadvancesearch(searchText, tabstr, count)
          .subscribe((data: any[]) => {
            if (data != null) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data1.length;
                var len = data1.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data1[i]);
                  // console.log("this.dataview1 1",this.dataview1);
                }
                this.imagecount = (
                  Number(data.toString().split("~")[1]) +
                  Number(this.imagecount)
                ).toString();
                this.doccount = (
                  Number(data.toString().split("~")[2]) + Number(this.doccount)
                ).toString();
                this.videocount = (
                  Number(data.toString().split("~")[3]) +
                  Number(this.videocount)
                ).toString();
                this.audiocount = (
                  Number(data.toString().split("~")[4]) +
                  Number(this.audiocount)
                ).toString();
                this.storycount = (
                  Number(data.toString().split("~")[5]) +
                  Number(this.storycount)
                ).toString();
                this.graphicscount = (
                  Number(data.toString().split("~")[6]) +
                  Number(this.graphicscount)
                ).toString();
                // this.cartooncount = (Number(data.toString().split('~')[7]) + Number(this.cartooncount)).toString()
                // this.illustaioncount = (Number(data.toString().split('~')[8]) + Number(this.illustaioncount)).toString()

                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
              //this.elementarr=[]
            }
          });
      } else if (environment.globalsearchtext.trim() != "") {
        var srtxt = this.ExtractSearchingKeyword(environment.globalsearchtext);
        srtxt = "";
        this.dashboarservice
          .blindsearchwithdata(srtxt, "", tabstr, count)
          .subscribe((data: any[]) => {
            if (data != null) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data1.length;
                var len = data1.length;
                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data1[i]);
                  // console.log("this.dataview1 2",this.dataview1);
                }
                this.imagecount = (
                  Number(data.toString().split("~")[1]) +
                  Number(this.imagecount)
                ).toString();
                this.doccount = (
                  Number(data.toString().split("~")[2]) + Number(this.doccount)
                ).toString();
                this.videocount = (
                  Number(data.toString().split("~")[3]) +
                  Number(this.videocount)
                ).toString();
                this.audiocount = (
                  Number(data.toString().split("~")[4]) +
                  Number(this.audiocount)
                ).toString();
                this.storycount = (
                  Number(data.toString().split("~")[5]) +
                  Number(this.storycount)
                ).toString();
                this.graphicscount = (
                  Number(data.toString().split("~")[6]) +
                  Number(this.storycount)
                ).toString();

                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      } else {
        this.dashboarservice
          .blindsearch(tabstr, count)
          .subscribe((data: any) => {
            if (data != null) {
              if (
                Number(this.dataview1.totalRecords) >= Number(this.totalcount)
              ) {
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              } else {
                var data1 = JSON.parse(
                  data.toString().split("~")[0].replace(/\ʁ/g, "~")
                );
                data1.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.dataview1.totalRecords =
                  this.dataview1.totalRecords + data1.length;
                var len = data1.length;
                // console.log("this.dataview1 data1",data1);

                for (let i = 0; i < len; i++) {
                  this.dataview1._value.push(data1[i]);
                }
                // console.log("this.dataview1 3",this.dataview1._value);

                this.imagecount = (
                  Number(data.toString().split("~")[1]) +
                  Number(this.imagecount)
                ).toString();
                this.doccount = (
                  Number(data.toString().split("~")[2]) + Number(this.doccount)
                ).toString();
                this.videocount = (
                  Number(data.toString().split("~")[3]) +
                  Number(this.videocount)
                ).toString();
                this.audiocount = (
                  Number(data.toString().split("~")[4]) +
                  Number(this.audiocount)
                ).toString();
                this.storycount = (
                  Number(data.toString().split("~")[5]) +
                  Number(this.storycount)
                ).toString();
                this.graphicscount = (
                  Number(data.toString().split("~")[6]) +
                  Number(this.storycount)
                ).toString();

                this.totalcount = localStorage.getItem("totalcount");
                x["style"].cursor = "pointer";
                x["style"]["pointer-events"] = "auto";
                this.loading = false;
              }
            } else {
              x["style"].cursor = "pointer";
              x["style"]["pointer-events"] = "auto";
              this.loading = false;
            }
          });
      }
    }

    this.removeselection();
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }

    if (e.shiftKey == true) {
      if (this.selectedIndexs.length > 0) {
        var tmpid = this.selectedIndexs[0];
        this.removeselection();
        this.selectionwithshift(tmpid);
        if (this.checknext(tmpid, _index)) {
          var $that = this;
          $(document.getElementById(tmpid.toString()))
            .nextUntil(document.getElementById(_index.toString()))
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        } else if (this.checkprev(tmpid, _index)) {
          var $that = this;
          $(document.getElementById(tmpid.toString()))
            .prevUntil(document.getElementById(_index.toString()))
            .each(function () {
              $that.selectionwithshift(this.id);
            });
          this.selectionwithshift(_index);
        }
      } else {
        this.selectionwithshift(_index);
      }
    }
  }

  checknext(id1, id2) {
    var result = false;
    $(document.getElementById(id1.toString()))
      .nextAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }
  checkprev(id1, id2) {
    var result = false;
    $(document.getElementById(id1.toString()))
      .prevAll()
      .each(function () {
        if (this.id == id2) {
          result = true;
          return false;
        }
      });
    return result;
  }

  selectionwithshift(_index) {
    if (this.selectedIndexs.indexOf(_index) === -1) {
      this.selectedIndexs.push(_index);
      var per = document.getElementById(_index).children[1].innerHTML.trim();
      this.selectedIndexspermis.push(Number(per));
      document.getElementById(_index).style.background = "gainsboro";
    } else {
      // let index = this.selectedIndexs.indexOf(_index);
      // this.selectedIndexs.splice(index, 1);
      // this.selectedIndexspermis.splice(index, 1)
      // document.getElementById(_index).style.background = "transparent"
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      _index = _index.trim();
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        this.selectedIndexstype.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[1]
            .trim()
        );
        this.selectedIndexsid.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[0]
            .trim()
        );
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per.trim()));
        document.getElementById(_index).style.background = "gainsboro";
      } else {
        let index = this.selectedIndexs.indexOf(_index.trim());
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexstype.splice(index, 1);
        this.selectedIndexsid.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index.trim()).style.background = "transparent";
      }
    }
  }

  multipleedit() {
    this.ismenuclick = true;
    var itemid = "";
    var nameforedit1 = "";
    var path = "normal";
    var orgname = "";
    var type = this.selectedIndexstype[0];
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      itemid += this.selectedIndexsid[i] + "~";
      orgname +=
        document
          .getElementById(this.selectedIndexs[i].toString())
          .children[2].innerHTML.trim() + "~";
      if (type != this.selectedIndexstype[i]) {
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Selected files are not similar..",
        });
        break;
      }
      type = this.selectedIndexstype[i];
      if (i == this.selectedIndexs.length - 1) {
        if (type.toLowerCase().trim() == "image") {
          this.ImageUpdated = true;
          this.idforimageedit = itemid;
          this.imagenameforedit = nameforedit1;
          this.orgname = orgname;
        } else if (type.toLowerCase().trim() == "audio") {
          this.AudioUpdated = true;
          this.audioidforedit = itemid;
          this.audiofileforup = nameforedit1;
          this.orgname = orgname;
        } else if (type.toLowerCase().trim() == "video") {
          this.videoupdate = true;
          this.videoidforedit = itemid;
          this.videofileforup = nameforedit1;
          this.orgname = orgname;
        } else if (type.toLowerCase().trim() == "story") {
        } else {
          this.DocumentUpdated = true;
          this.docidforedit = itemid;
          this.docfileforup = nameforedit1;
          this.orgname = orgname;
        }
      }
      if (i == this.selectedIndexs.length - 1) {
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  hidevideoedit() {
    this.videoidforedit = "";
    this.orgname = "";
    this.videoupdate = false;
    this.showfulldiv();
  }

  async downloadmultiple() {
    this.ismenuclick = true;
    // var x = document.querySelector(".downloadinggif1")
    // x["style"].display = "block";
    // var y = document.querySelector(".downloadback")
    // y["style"].display = "block";
    var lst: any[] = this.selectedIndexs;
    var lstid = this.selectedIndexsid;
    var rtnarr = [];
    if (lst.length > 1) {
      for (let i = 0; i < lst.length; i++) {
        //await this.downloadonebyone(lst[i])
        //await this.sleep(2000)
        rtnarr.push({
          filepath: lst[i],
          filename: document
            .getElementById(lst[i])
            .children[2].innerHTML.trim(),
          itemid: lstid[i],
        });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      }
    } else {
      this.downloadonebyone(lst[0], lstid[0]);
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  downloadmultiplezip(arr) {
    this.documentservice.downloadmultiplefn(arr).subscribe((data: any) => {
      // if (data != null) {
      //   this.downloadzipflder(data)
      // }
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  async downloadonebyone(filepath, itemid) {
    var fname = document.getElementById(filepath).children[2].innerHTML.trim();
    this.messageservice.add({
      key: "message",
      severity: "info",
      summary: "Success Message",
      detail: "File Downloaded Will Start Soon",
    });
    let unsub = this.storyservice
      .Testdownload(fname, filepath, itemid)
      .subscribe((data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, fname);
          //  var data1=data
          //   var b64Data1 = data1.split(";")[1]
          //   const byteCharacters = atob(b64Data1);

          //   const byteNumbers = new Array(byteCharacters.length);
          //   for (let i = 0; i < byteCharacters.length; i++) {
          //     byteNumbers[i] = byteCharacters.charCodeAt(i);
          //   }
          //   const byteArray = new Uint8Array(byteNumbers);
          //   const blob = new Blob([byteArray], { type: data1.split(";")[0].split(":")[1] });
          //   //let byteCharacters = atob(data);

          //   var url = window.URL.createObjectURL(blob);
          //   var a = document.createElement('a');
          //   document.body.appendChild(a);
          //   a.setAttribute('style', 'display: none');
          //   a.href = url;
          //   a.download = fname;
          //   a.click();
          //   window.URL.revokeObjectURL(url);
          //   a.remove();
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();
        // var x = document.querySelector(".downloadinggif1")
        // x["style"].display = "none";
        // var y = document.querySelector(".downloadback")
        // y["style"].display = "none";
        this.removeselection();
        //.DOC ,.DOCX,.ODT,.PDF,.XLS,. XLSX,.ODS, .PPTX,.PPT,.TXT,.INDD,.RTF
        // console.log(
        //   document
        //     .getElementById(filepath)
        //     .children[0].innerHTML.split("~")[1]
        //     .trim()
        // );

        if (
          document
            .getElementById(filepath)
            .children[0].innerHTML.split("~")[1]
            .trim() == "image"
        ) {
          this.dashboarservice
            .createlog(
              itemid,
              "Download",
              JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
              "transaction_image"
            )
            .subscribe((data: any) => {});
        } else if (
          document
            .getElementById(filepath)
            .children[0].innerHTML.split("~")[1]
            .trim() == "pdf"
        ) {
          this.dashboarservice
            .createlog(
              itemid,
              "Download",
              JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
              "transaction_document"
            )
            .subscribe((data: any) => {});
        } else {
          this.dashboarservice
            .createlog(
              itemid,
              "Download",
              JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
              "transaction_audio"
            )
            .subscribe((data: any) => {});
        }
        //this.dashboarservice.createlog(itemid,"Download",JSON.parse(localStorage.getItem("userdetails"))[0].UserId,"transaction_document")
        //this.downloadentry(itemid)
      });
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    var filepath = this.ApiServerPath + "downloadzip/" + filename;
    //this.imageservice.testzipdownload(filename).subscribe((data: any) => {
    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    // if (data != null) {
    //   var b64Data1 = data.split(";")[1]
    //   const byteCharacters = atob(b64Data1);

    //   const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], { type: "application/x-zip-compressed" });
    //   //let byteCharacters = atob(data);

    //   var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = filepath;
    a.download = "Assets";
    a.click();
    //window.URL.revokeObjectURL(url);
    a.remove(); // remove the element
    //}
    this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});

    //    })
  }

  removeselection() {
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      document.getElementById(
        this.selectedIndexs[i].toString()
      ).style.background = "transparent";
      if (i == this.selectedIndexs.length - 1) {
        this.selectedIndexs.splice(0, this.selectedIndexs.length);
        this.selectedIndexstype.splice(0, this.selectedIndexstype.length);
        this.selectedIndexsid.splice(0, this.selectedIndexsid.length);
        this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
      }
    }
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.keyCode == 27) {
      this.DocumentUpdated = false;
    }
    if (event.keyCode == 27) {
      this.viewDocument = false;
    }
    if (event.keyCode == 27) {
      this.ImageUpdated = false;
    }
    if (event.keyCode == 27) {
      this.view = false;
    }
    if (event.keyCode == 27) {
      this.videoupdate = false;
    }
    if (event.keyCode == 27) {
      this.viewVideo = false;
    }
    if (event.keyCode == 27) {
      this.AudioUpdated = false;
    }
    if (event.keyCode == 27) {
      this.viewAudio = false;
    }
    if (event.keyCode == 27) {
      this.hideadvncsrch();
    }

    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      if (
        this.rightclickmenuopen == false ||
        (this.conetxtmenudiv.el.nativeElement.innerHTML.indexOf(
          "display: block"
        ) > 0 &&
          this.rightclickmenuopen == true &&
          this.ismenuclick == false)
      ) {
        try {
          this.conetxtmenudiv.hide();
        } catch {}
        this.rightclickmenuopen = false;
        this.ismenuclick = false;
      }
    }
  }

  @HostListener("dblclick", ["$event"]) dblClick(event2: KeyboardEvent) {
    // let _index = event2['path'][2]['id']
    // if (_index.length === 0) {
    // console.log("if removeselection");
    this.removeselection();
    // }
  }

  selectOneIndex(index, event) {
    if (
      event.ctrlKey == false &&
      event.metaKey == false &&
      event.shiftKey == false
    ) {
      this.removeselection();
      let _index = index;
      if (_index.length != 0) {
        this.indexCurrent = _index;
        document.getElementById(this.indexCurrent).style.background =
          "gainsboro";
        if (this.indexPrev != null) {
          document.getElementById(this.indexPrev).style.background =
            "transparent";
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs[0] = _index;
        } else {
          this.indexPrev = this.indexCurrent;
          this.selectedIndexs.push(_index);
        }
      }
    }
  }
  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  showcartdiv(id, type, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.cartitemtype = type;
      this.showcart = true;
    }
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        const lst1 = [];
        lst.push(this.cartitemid);
        lst1.push(this.cartitemtype);
        this.imageservice
          .addtocartmul(this.cartname, lst, lst1, userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexsid;
    var lst1 = this.selectedIndexstype;
    this.imageservice
      .addtocartmul(this.cartname, lst, lst1, userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Add to Cart successfully",
          });
          this.clearcartname();
        }
      });
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  // filterBrands(event) {
  //   this.filteredBrands = [];
  //   for (let i = 0; i < this.results.length; i++) {
  //     let brand = this.results[i];
  //     if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
  //       this.filteredBrands.push(brand);
  //     }
  //   }
  // }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulcartdiv() {
    this.showcart = true;
    this.ismenuclick = true;
  }

  //document popup//

  downloadDocument(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data.size > 0) {
          saveAs(data, filename);
          // var b64Data1 = data.split(";")[1]
          // const byteCharacters = atob(b64Data1);

          // const byteNumbers = new Array(byteCharacters.length);
          // for (let i = 0; i < byteCharacters.length; i++) {
          //   byteNumbers[i] = byteCharacters.charCodeAt(i);
          // }
          // const byteArray = new Uint8Array(byteNumbers);
          // const blob = new Blob([byteArray], { type: "audio/*" });
          // //let byteCharacters = atob(data);

          // var url = window.URL.createObjectURL(blob);
          // var a = document.createElement('a');
          // document.body.appendChild(a);
          // a.setAttribute('style', 'display: none');
          // a.href = url;
          // a.download = filename;
          // a.click();
          // window.URL.revokeObjectURL(url);
          // a.remove();
        }
        this.downloadentry(itemid);
      });
  }
  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  close() {
    this.ytPlayer.nativeElement.src = "";
    this.viewDocument = false;
    this.showfulldiv();
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.documentservice
      .GetDownloadedDocumentData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadDocumentList = data;
          this.showDocumentDownload = true;
        }
      });
  }

  Textread(filename, filepath) {
    this.documentservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getpdfimg(itemid) {
    this.documentservice.getpdfimg(itemid).subscribe((data: any) => {
      if (data != null) {
        this.pdfimages = data;
      }
    });
  }

  showzoom(itemid, fname) {
    window.open(this.ApiServerPath + fname, "_blank", "popupwindow");
    // window.open("#/pdfpreview;id="+itemid+";fname="+fname,"_blank")
  }

  getdocumentdata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashboarservice
        .getdocumentdata(itemid, "")
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.fullDocumentpopup(data[0], e);
          }
        });
    }
  }

  fullDocumentpopup(documentList, e) {
    this.fullDocumentpathlist = documentList;
    this.ratingitemdoc = documentList.itemrating;

    setTimeout(() => {
      try {
        this.ytPlayer.nativeElement.src =
          this.ApiServerPath + documentList.OriginalPath;
        // console.log(this.ApiServerPath + documentList.OriginalPath);
      } catch (err) {}
    }, 300);

    //this.pdfpath=this.ApiServerPath+documentList.OriginalPath
    // if(this.fullDocumentpathlist.itemtype.toLowerCase() =="pdf"){
    // this.getpdfimg(this.fullDocumentpathlist.itemid)
    // }
    if (this.fullDocumentpathlist.itemtype.toLowerCase() == "txt") {
      this.Textread(documentList.itemfilename, documentList.OriginalPath);
    }
    this.viewDocument = true;
  }

  updatedocumentpopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.DocumentUpdated = true;
      this.docidforedit = itemid;
      this.docfileforup = name;
      this.orgname = name;
    }
  }

  trashdocumentpopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "allitemkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.documentservice
            .deleteDocument(itemid)
            .subscribe((data: any[]) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "info",
                  summary: "Success Message",
                  detail: "Delete successfully",
                });
                this.blindsearchwithdata("");
                this.blindsearch("");
              }
            });
        },
        reject: () => {},
      });
    }
  }

  hidedocumentedit() {
    this.docidforedit = "";
    this.orgname = "";
    this.DocumentUpdated = false;
    this.treedropvisible = false;
    this.showfulldiv();
  }

  updatevideopopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.videoupdate = true;
      this.videofileforup = name;
      this.videoidforedit = itemid;
      this.orgname = name;
      //this.updatedocument(documentList)
    }
  }

  trashvideopopup(itemid, name, e) {
    this.confirmationService.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "allitemkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.videoservice.deleteVideo(itemid).subscribe((data: any[]) => {
          if (data != null) {
            this.messageservice.add({
              key: "message",
              severity: "info",
              summary: "Success Message",
              detail: "Delete successfully",
            });
            // if (this.glbDelete == 0) {
            //   this.getTodayVideo()
            // } else {
            //   this.getallvideo()
            // }
            this.blindsearchwithdata("");
            this.blindsearch("");
          }
        });
      },
      reject: () => {},
    });
  }

  //document popup//
  //audio popup//
  audioshowNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    this.audiouploadservice
      .GetDownloadedAudioData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadAudioList = data;
          this.showAudioDownload = true;
        }
      });
  }

  downloadAudio(filename, filepath, itemid) {
    this.audiouploadservice.Testdownload(filepath).subscribe((data: any) => {
      if (data != null) {
        var b64Data1 = data.split(";")[1];
        const byteCharacters = atob(b64Data1);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/*" });
        //let byteCharacters = atob(data);

        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      this.audiodownloadentry(itemid);
    });
  }

  audiodownloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.audiouploadservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  getaudiodata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashboarservice.getaudiodata(itemid, "").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.fullAudiopopup(data[0], e);
        }
      });
    }
  }

  fullAudiopopup(audioList, e) {
    this.Player.nativeElement.src = this.ApiServerPath + audioList.HiresPath;
    this.audioimage = "assets/image/Wave_bg.jpg";
    // this.fullAudiolist=null
    this.ratingitemaudio = audioList.itemrating;
    this.Audiopreviewpath = this.ApiServerPath + audioList.HiresPath;
    this.fullAudiolist = audioList;
    this.viewAudio = true;
  }

  closeaudio() {
    this.viewAudio = false;
    this.Player.nativeElement.src = "";
  }

  playfunction() {
    this.audioimage = "assets/image/waveform_easset.gif";
  }
  pausefunction() {
    this.audioimage = "assets/image/Wave_bg.jpg";
  }

  hideimageedit() {
    this.idforimageedit = "";
    this.orgname = "";
    this.ImageUpdated = false;
    this.showfulldiv();
  }
  Cancel() {
    this.Player.nativeElement.src = "";
  }

  cancelcrp() {
    this.editdiv = 0;
    this.metadatadiv = 1;
    this.showfulldiv();
    this.view = false;
    this.imagePath = "";
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      //   document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  Reset() {
    this.cropper.clear();
    this.editdiv = 0;
    this.metadatadiv = 1;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      // document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }
  CropedImageDownload(filename, filepath, itemid) {
    if (this.imageDestination != "") {
      // this.ButtonStatus='Cropped Download '
      //var downloadpath = this.imageDestination
      this.ctx = this.cropimagecanvas.nativeElement.getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.cropimagecanvas.nativeElement.width,
        this.cropimagecanvas.nativeElement.height
      );
      var image = new Image();
      image.src = this.imageDestination;
      this.cropimagecanvas.nativeElement.height = image.naturalHeight;
      this.cropimagecanvas.nativeElement.width = image.naturalWidth;
      if (this.contrast == 0) {
        this.ctx.filter = "contrast(none)";
      } else {
        this.ctx.filter = "contrast(" + this.contrast / 10 + ")";
      }
      if (this.brightness == 0) {
        this.ctx.filter = "brightness(none)";
      } else {
        this.ctx.filter = "brightness(" + this.brightness / 10 + ")";
      }
      if (this.saturate == 0) {
        this.ctx.filter = "saturate(none)";
      } else {
        this.ctx.filter = "saturate(" + this.saturate / 10 + ")";
      }
      if (this.grin == "") {
        this.ctx.filter = this.grin + "(none)";
      } else {
        this.ctx.filter = this.grin + "(1)";
      }
      //this.ctx.filter="brightness(25.5)";

      this.ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      if (this.watermark != "") {
        this.ctx.globalAlpha = 0.5;
        if (this.watermarkposition == "topright") {
          var fntsize = image.naturalWidth / 20;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.textAlign = "end";
          this.ctx.fillText(
            this.watermark,
            image.naturalWidth - 10,
            image.naturalHeight * 0.06,
            image.naturalWidth
          );
        } else {
          var fntsize = image.naturalWidth / 5;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.translate(0, image.naturalWidth * 0.12);
          this.ctx.rotate(
            Math.atan(
              image.naturalHeight /
                (image.naturalWidth + image.naturalWidth * 0.05)
            )
          );
          this.ctx.fillText(this.watermark, 0, 0, image.naturalWidth);
        }
      }
      var downloadpath =
        this.cropimagecanvas.nativeElement.toDataURL("image/jpeg");
      this.LoadImageCrop(downloadpath, this.SaveCropImage, filename, itemid);
      this.Reset();
      this.downloadentryimg(itemid);
    } else {
      this.downloadFullImage(filename, filepath, itemid);
      this.downloadentryimg(itemid);
    }
  }
  private SaveCropImage(content: Blob, filename, itemid): void {
    saveAs(content, filename);
  }
  private LoadImageCrop(
    url: string,
    callback: Function,
    filename,
    itemid
  ): void {
    saveAs(url, filename);
  }

  downloadentryimg(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }
  changecolor(id1) {
    var filter1 = "";
    var style = document
      .querySelector(".cropper-container")
      .getAttribute("style");
    var x = style.split(";");
    //var y=x[2]
    x[2] = "";
    var bvalue = this.brightness / 10;
    var cvalue = this.contrast / 10;
    var svalue = this.saturate / 10;
    if (bvalue > 0) {
      filter1 += "brightness(" + bvalue + ") ";
    }
    if (cvalue > 0) {
      filter1 += "contrast(" + cvalue + ") ";
    }
    if (svalue > 0) {
      filter1 += "saturate(" + svalue + ")";
    }
    if (this.grin != "") {
      filter1 += this.grin + "(1)";
    }
    x[2] = "filter:" + filter1;
    //filter=id1+"("+bvalue+");"
    if (filter1 != "") {
      style = x[0] + ";" + x[1] + ";" + x[2] + ";";
    } else {
      style = x[0] + ";" + x[1] + ";";
    }

    document.querySelector(".cropper-container").removeAttribute("style");
    document
      .querySelector(".cropper-container")
      .setAttribute("style", "" + style + "");
  }
  cropimages() {
    this.ButtonStatusimg = "Cropped Download ";
    document.querySelector(".cropbtn").setAttribute("disabled", "true");
    // document.querySelector(".toolbox").removeAttribute("style")
    // document.querySelector(".toolbox").setAttribute("style","display:block")
    this.metadatadiv = 0;
    this.editdiv = 1;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 0,
      autoCropArea: 1,
      checkCrossOrigin: false,
      movable: false,
      viewMode: 2,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/jpeg");
      },
    });
  }
  async downloadFullImage(filename, filepath, itemid) {
    // console.log("download image")

    let unsub = this.storyservice
      .Testdownload(filename, filepath.trim(), itemid)
      .subscribe(async (data: any) => {
        //await this.sleep(5000)
        if (data.size > 0) {
          saveAs(data, filename);
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded...",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Original File Does Not Exist.",
          });
        }
        unsub.unsubscribe();
        // var b64Data1 = data.split(";")[1]
        // const byteCharacters = atob(b64Data1);

        // const byteNumbers = new Array(byteCharacters.length);
        // for (let i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        // const byteArray = new Uint8Array(byteNumbers);
        // const blob = new Blob([byteArray], { type: data.split(";")[0].split(":")[1] });
        // //let byteCharacters = atob(data);

        // var url = window.URL.createObjectURL(blob);
        // var a = document.createElement('a');
        // document.body.appendChild(a);
        // a.setAttribute('style', 'display: none');
        // a.href = url;
        // a.download = filename;
        // a.click();
        // window.URL.revokeObjectURL(url);
        // a.remove(); // remove the element
        // const file = new File([blob], filename);
      });

    var x = document.querySelector(".downloadinggif1");
    //x.classList.add("downloadinggif")
    x["style"].display = "none";
    var y = document.querySelector(".downloadback");
    y["style"].display = "none";
    this.removeselection();
    this.dashboarservice
      .createlog(
        itemid,
        "Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "transaction_image"
      )
      .subscribe((data1: any[]) => {});
  }

  showNoOfDownloadimg(itemid) {
    // this.getdowloadimagelist=downloadList
    this.imageservice
      .GetDownloadedImageData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadListimg = data;
          this.showDownloadimg = true;
        }
      });
  }

  getimagedata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashboarservice.getimagedata(itemid, "").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.fullimagepopup(data[0], e);
        }
      });
    }
  }

  fullimagepopup(imageList, e) {
    this.downloaditems = [
      {
        label: "Low",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.thumbpath,
            imageList.itemid
          );
        },
      },
      {
        label: "Medium",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.HiresPath,
            imageList.itemid
          );
        },
      },
      {
        label: "High",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.OriginalPath,
            imageList.itemid
          );
        },
      },
    ];
    this.fullpathlist = imageList;
    this.ratingitemimg = imageList.itemrating;
    this.imageservice
      .Testdownload(imageList.itemfilename, imageList.HiresPath)
      .subscribe((data: any) => {
        if (data != null) {
          // var b64Data1 = data.split(";")[1]
          // this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          //   + b64Data1);
          if (data != "error") {
            this.imagePath = this.ApiServerPath + imageList.OriginalPath;
          } else {
            this.imagePath = "assets/image/noimagehires.png";
          }
        }
        this.view = true;
      });
  }

  showeditdiv(path, itemid) {
    this.dashboarservice
      .createlog(
        itemid,
        "Edit_Download",
        JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
        "transaction_image"
      )
      .subscribe((data: any) => {});
    this.showeditor = true;
    this.ytPlayer1.nativeElement.src = environment.editorpath + path;
  }

  hideeditor() {
    this.showeditor = false;
    this.iframepath = "";
  }

  updateimagepopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.ImageUpdated = true;
      this.idforimageedit = itemid;
      this.imagenameforedit = name;
      this.orgname = name;
    }
  }

  trashimagepopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        key: "allitemkey",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.imageservice.TrashImages(itemid).subscribe((data: any[]) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "info",
                summary: "Success Message",
                detail: "Delete successfully",
              });
              // if(this.glbDelte==1){
              //   this.SearchByFields("")
              // }
              // else{
              //   //this.SearchByFields()
              // }
              this.blindsearchwithdata("");
              this.blindsearch("");
            }
          });
        },
        reject: () => {},
      });
    }
  }
  //image popup//

  //video popup//

  getvideodata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashboarservice.getvideodata(itemid, "").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.fullvideopopup(data[0], e);
        }
      });
    }
  }

  fullvideopopup(videolist, e) {
    this.restoreSelectFormat = undefined;
    if (videolist.orgfilename.includes(".dpx")) {
      this.dpxbtn = 0;
    }
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.videopreviewpath = this.ApiServerPath + videolist.HiresPath;
      // console.log(videolist, 'videodata');
      this.videodata = videolist;
      this.poster = this.ApiServerPath + videolist.thumbpath;
      //console.log("data",data);
      var totalduration = videolist.itemlength;
      var totalduration = totalduration.split(":");
      var hour = totalduration[0];
      var minute = totalduration[1];
      var second = totalduration[2];
      second = Math.trunc(second);
      this.videoDuration = hour + ":" + minute + ":" + second;
      this.getgroupimage(videolist.groupid);
      this.fullvideolist = videolist;
      this.Buttons = "Play";
      this.viewVideo = true;
    }
  }
  popupClose() {
    this.viewVideo = false;
    document.getElementById("play").style.backgroundImage =
      "url('/assets/image/play.png')";
  }
  //   cropvideo(path, orgname, itemid) {
  //     if (this.durationarray.length > 0) {
  //   var person = prompt("Please enter filename", "");
  //   if (person == null || person == "") {
  //   }
  //   else {
  //     var ext = orgname.split('.')[orgname.split('.').length - 1]
  //     var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId
  //     // this.videogif.nativeElement.style.display = 'block'
  //     // this.videoElement.nativeElement.pause()
  //     this.vid.target.nativeElement.pause()
  //     // this.videogif.nativeElement.src='assets/image/2.gif'
  //     orgname = person.split('.')[0] + "." + ext
  //     // console.log("video crop");
  //     this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'File Croping started' });
  //     this.videoservice.ffmpeg(path, orgname, this.durationarray, userid).subscribe((data: any[]) => {
  //       if (data.length > 0) {
  //         this.messageservice.add({ key: 'message', severity: 'info', summary: 'Success Message', detail: 'Successfully Export' });
  //         //this.downloadVideo(orgname,path,userid)
  //         // this.videogif.nativeElement.style.display = 'none'
  //         // this.close1();
  //       }
  //     })
  //   }  } else {
  //     this.messageservice.add({key: "message",severity: "warn", detail: "Please Mark IN and Mark Out Video...",});
  //   }
  // }

  cropvideo(path, orgname) {
    if (this.durationarray.length > 0) {
      var person = prompt("Please enter filename", "");
      if (person == null || person == "") {
      } else {
        var ext = orgname.split(".")[orgname.split(".").length - 1];
        var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId;
        // this.videogif.nativeElement.style.display = 'block'
        // this.videoElement.nativeElement.pause()
        this.vid.target.nativeElement.pause();
        // this.videogif.nativeElement.src='assets/image/2.gif'
        orgname = person.split(".")[0] + "." + ext;
        // console.log("video crop");
        this.messageservice.add({
          key: "message",
          severity: "info",
          summary: "Success Message",
          detail: "File Croping started",
        });
        this.videoservice
          .ffmpeg(path, orgname, this.durationarray, userid)
          .subscribe((data: any[]) => {
            if (data.length > 0) {
              this.messageservice.add({
                key: "message",
                severity: "info",
                summary: "Success Message",
                detail: "Successfully Export",
              });
              //this.downloadVideo(orgname,path,userid)
              // this.videogif.nativeElement.style.display = 'none'
              // this.close1();
            }
          });
      }
    } else {
      this.messageservice.add({
        key: "message",
        severity: "warn",
        detail: "Please Mark IN and Mark Out Video...",
      });
    }
  }
  //whole function with API is not working
  downloadVideo(filename, filepath, userid, itemid) {
    this.videoservice
      .Testdownload(itemid, filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "video/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.videoservice.deleteTempFile(userid).subscribe((data: any) => {
            if (data != null) {
            }
          });
        }
        this.dashboarservice
          .createlog(itemid, "Download", userid, "transaction_video")
          .subscribe((data: any[]) => {});
      });
  }

  flagForMarkIn: any;
  markin() {
    if (!this.flagForMarkIn) {
      this.flagForMarkIn = true;
      $(".vjs-progress-holder").append(
        '<div id="markslider" style="display: block;"></div>'
      );
    }
    if (this.ButtonStatus == "MarkIn") {
      this.ButtonStatus = "MarkOut";
      var ttl_obj_width = 0;
      var tt = $("#markslider").find("div");
      for (let i = 0; i < tt.length; i++) {
        ttl_obj_width = ttl_obj_width + parseFloat($($(tt)[i]).css("width"));
      }
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markinvl" +
        this.markinvl +
        "'class='markin' style='height:29px; width:13px;border-right: 4px solid rgba(115, 133, 159, 0.75);margin: -12px 0px 0px 0px;float:left'></div>";
      var timebar = $(".vjs-play-progress.vjs-slider-bar").css("width");
      var markinwidth = $("#markinvl" + this.markinvl).css("width");
      var markoutwidth = $("#markoutval" + this.markinvl).css("width");
      var markSetwidth = Number(0) + Number(timebar.replace("px", ""));
      $("#markinvl" + this.markinvl).css("width", markSetwidth - ttl_obj_width);
      this.tooltip("markinvl" + this.markinvl);
      this.getProgressCurrentTime(markSetwidth) + ",";
      this.markinvl = this.markinvl + 1;
    } else {
      this.ButtonStatus = "MarkIn";
      var ttl_obj_width = 0;
      var tt = $("#markslider").find("div");
      for (let i = 0; i < tt.length; i++) {
        ttl_obj_width = ttl_obj_width + parseFloat($($(tt)[i]).css("width"));
      }
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markoutval" +
        this.markoutval +
        "' class='markout' style='height:29px; width:13px; border-right:4px solid rgba(115, 133, 159, 0.75);background: rgba(102, 153, 200,0.7);margin:-12px 0px 0px 0px;float:left'></div>";
      var timebar = $(".vjs-play-progress.vjs-slider-bar").css("width");
      var markinwidth = $("#markinvl" + this.markoutval).css("width");
      var markSetwidth =
        Number(0) + Number(timebar.replace("px", "")) - Number(ttl_obj_width);
      $("#markoutval" + this.markoutval).css("width", markSetwidth);
      // $('#markoutval' + this.markoutval).css('margin-left', markinwidth);
      this.tooltip("markoutval" + this.markoutval);
      this.markoutval = this.markoutval + 1;
      this.getProgressCurrentTime(markSetwidth) + ",";
    }
  }

  tooltip(id) {
    var videoCurrentTime12 = this.vid.target.nativeElement.currentTime;
    this.minutes = "0" + Math.floor(videoCurrentTime12 / 60);
    var seconds = "0" + Math.floor(videoCurrentTime12 - this.minutes * 60);
    var dur = this.minutes.substr(-2) + ":" + seconds.substr(-2);
    document.getElementById(id).innerHTML +=
      "<span style='float:right;font-size:13px;'>" + dur + "</span>";
    $(id).attr("title", dur);
    if (id == "markinvl" + this.markinvl) {
      this.MarkInValue = videoCurrentTime12;
    }
    if (id == "markoutval" + this.markoutval) {
      this.MarkOutValue = videoCurrentTime12;
      this.totalDuration = this.MarkOutValue - this.MarkInValue;
      this.markinArray.push(this.MarkInValue.toFixed(3));
      this.totaldurarray.push(this.totalDuration.toFixed(3));
      this.durationarray = this.markinArray.concat(this.totaldurarray);
    }
  }

  getProgressCurrentTime(x) {
    var video = $("#myVideo");
    var progress = $(".vjs-play-progress.vjs-slider-bar")[0];

    // console.log(this.vid.target.nativeElement.duration);
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.vid.target.nativeElement.duration;
    var position = x - progress.offsetLeft;
    var percentage = (100 * position) / progress.clientWidth;
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    var Ctime = (maxduration * percentage) / 100;
    return Ctime;
  }

  play() {
    var videoCurrentTime1 = this.videoElement.nativeElement.currentTime;
    var totalduration1 = this.videoElement.nativeElement.duration;
    var video = $("#myMovie");
    video.bind("timeupdate", function () {
      var videoCurrentTime = videoCurrentTime1;
      var totalduration = totalduration1;
      var minutes: any;
      minutes = "0" + Math.floor(videoCurrentTime / 60);
      var seconds = "0" + Math.floor(videoCurrentTime - minutes * 60);
      var dur = minutes.substr(-2) + ":" + seconds.substr(-2);
      var minutes_d: any;
      minutes_d = "0" + Math.floor(totalduration / 60);
      var seconds_d = "0" + Math.floor(totalduration1 - minutes_d * 60);
      var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
      $("#currentduration").html(dur);
      $("#totalduration").html(dur_d);
    });

    var CurrentPosition = this.videoElement.nativeElement.currentTime;
    var dur = this.videoElement.nativeElement.duration;
    video.on("timeupdate", function () {
      var currentPos = CurrentPosition;
      var maxduration: any;
      maxduration = dur;
      var perc = (100 * currentPos) / maxduration;
      $(".timeBar").css("width", perc + "%");
      $(".current").text(timeFormat(currentPos));
    });
    var timeFormat = function (seconds) {
      var m: any;
      m =
        Math.floor(seconds / 60) < 10
          ? "0" + Math.floor(seconds / 60)
          : Math.floor(seconds / 60);
      var s =
        Math.floor(seconds - m * 60) < 10
          ? "0" + Math.floor(seconds - m * 60)
          : Math.floor(seconds - m * 60);
      return m + ":" + s;
    };
    var perc = (100 * CurrentPosition) / totalduration1;
    $(".timeBar").css("width", perc + "%");

    if (perc == 100) {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
    }
  }

  timer() {
    if (this.playStatus == "Pause") {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/pause.png')";
      this.playStatus = "Play";
      this.videoElement.nativeElement.play();
    } else {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
      this.playStatus = "Pause";
      this.videoElement.nativeElement.pause();
    }
    setInterval(() => {
      this.play();
    }, 1000);
  }

  fullscreen() {
    var videofullscreen = document.getElementById("myMovie");
    videofullscreen.requestFullscreen();
  }
  MuteUnmute() {
    //  var videofullscreen=document.getElementById('myMovie')
    if (this.volumeStatus == "unmute") {
      this.videoElement.nativeElement.muted = false;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/volume.png')";
      this.volumeStatus = "mute";
    } else {
      this.videoElement.nativeElement.muted = true;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/mute.png')";
      this.volumeStatus = "unmute";
    }
  }

  updateprogressbar(e) {
    var progress = $(".progress");
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    this.videoElement.nativeElement.currentTime =
      (maxduration * percentage) / 100;
  }

  getExportData(itemid, itemname) {
    this.exportitemid = itemid;
    this.exportitemname = itemname;
    this.videoservice
      .GetExportButton()
      .subscribe((data: exportConfigurationModel[]) => {
        if (data != null) {
          this.ExportList = data;
        }
      });
  }

  // ExportVideo(exportid,filepath) {

  //   this.videoservice.InsertExportData(exportid, this.exportitemid, this.exportitemname,filepath).subscribe((data: any[]) => {
  //     if (data != null) {
  //       this.ExportEntry(this.exportitemid,this.exportitemname, exportid)
  //       this.exportitemid = 0
  //       this.exportitemname = ''
  //       this.messageservice.add({ key: 'message1', severity: 'info', summary: 'Success Message', detail: 'Successfully Export' });
  //     }
  //   })
  // }
  ExportVideo(exportid, filename, filepath) {
    // console.log("export: ",exportid,filename,filepath,'---',this.restoreSelectFormat)
    if (
      this.restoreSelectFormat == null ||
      this.restoreSelectFormat == undefined
    ) {
      this.restoreSelectFormat = 0;
    }
    this.exportitemid = 0;
    this.videoservice
      .InsertExportData(
        this.exportitemid,
        exportid,
        filename,
        filepath,
        this.restoreSelectFormat
      )
      .subscribe((data: any) => {
        // console.log(data);
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "File Downloaded Will Start Soon...",
          });
          this.ExportEntry(this.exportitemid, filename, exportid);
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Failed Message",
            detail: "Original File Does Not Exist.",
          });
        }
      });
    this.restoreSelectFormat = 0;
    //this.downloadonebyone(exportid, filename, filepath)
  }

  showNoOfDownloadvideo(itemid) {
    // this.getdowloadimagelist=downloadList
    this.videoservice
      .GetDownloadedVideoData(itemid)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.downloadVideoList = data;
          this.showVideoDownload = true;
        }
      });
  }
  getdownloaditemtype() {
    this.videoservice.getdownloaditemtype().subscribe((data: any[]) => {
      if (data != null) {
        const formats = data[0].extension.split(",");
        // Create an array of objects based on the 'this.items' structure
        this.videositemtypes = formats.map((format) => ({
          label: format,
          icon: "pi pi-video", // Set your desired icon here
          command: () => {
            // Handle the command for each format if needed
            // console.log(`Selected format: ${format}`);
            this.restoreSelectFormat = format;
          },
        }));
        // console.log(this.videositemtypes);
      }
    });
  }

  ExportEntry(itemid, exportid, filename) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.videoservice
      .ExportEntry(itemid, exportid, filename, userid)
      .subscribe((data: any[]) => {});
  }
  clearvideo() {
    // document.getElementById("markslider").innerHTML = ''
    this.durationarray = [];
    this.markinArray = [];
    this.totaldurarray = [];
    this.ButtonStatus = "MarkIn";
  }

  hidevideo() {
    // this.videoElement.nativeElement.src = ''
    // this.videopreviewpath=''
    // this.poster=''
    // document.getElementById("markslider").innerHTML = ''
    // this.ButtonStatus = 'Mark in'
    // this.viewVideo = false
    // this.showfulldiv()
    this.ButtonStatus = "MarkIn";
    this.poster = null;
    this.viewVideo = false;
    this.showfulldiv();
    this.videopreviewpath = null;
    this.restoreSelectFormat == undefined;
    $("#markslider").remove();
    this.flagForMarkIn = false;

    $(".timeBar").css("width", "1px");
    $(".timeBar").css("display", "none");
    this.playStatus = "Pause";
  }

  //video popup//

  getstorydata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false && e.shiftKey == false) {
      this.dashboarservice.getstorydata(itemid, "").subscribe((data: any[]) => {
        if (data.length > 0) {
          this.fullstory(data[0], e);
        }
      });
    }
  }

  fullstory(storylist, e) {
    // this.headlineFont=storylist.cssclass
    // this.storyfont=storylist.cssclass
    this.fullstorylist = storylist;
    this.headline = storylist.itemcaption;
    this.ratingitemstory = storylist.itemrating;
    this.storyread(storylist.itemfilename, storylist.HiresPath);
    this.viewStory = true;
    this.getAttachment(storylist.itemid, this.attachmentlist);
  }

  closestory() {
    this.viewStory = false;
  }
  storyread(filename, filepath) {
    this.storyservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getAttachment(parentid, attachmentlist) {
    // this.selectedAttachmentlist=attachmentlist
    // document.getElementById("markslider").innerHTML +=;
    this.storyservice.getAttachment(parentid).subscribe((data: any) => {
      if (data != null) {
        this.selectedAttachmentlist = data;
        //alert(data)
      }
    });
  }

  downloadAttachment(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "document/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        this.dashboarservice
          .createlog(
            itemid,
            "Download",
            JSON.parse(localStorage.getItem("userdetails"))[0]["UserId"],
            "transaction_story"
          )
          .subscribe((data: any) => {});
        //this.downloadentry(itemid)
      });
  }

  getBackground(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }

  autoComplete($event) {
    $("#ulautocomplete").show();
    let search1 = (<HTMLInputElement>document.getElementById("userIdFirstWay"))
      .value;
    var search = search1.split(" ")[search1.split(" ").length - 1];
    if (search.length > 2) {
      this.dashboarservice.getdictionary(search).subscribe((response) => {
        this.autoCompletArr = response.map((item) => {
          return item.dictword;
        });
      });
    }
  }

  setsearchkey(e) {
    this.srchtext = this.srchtext.substring(0, this.srchtext.lastIndexOf(" "));
    this.srchtext = this.srchtext.trim() + " " + e.target.innerText;
    $("#ulautocomplete").hide();
  }

  checkrelationid() {
    this.imageservice
      .checkrelationid(this.relationname)
      .subscribe((data: any) => {
        if (data.length > 0) {
          if (
            confirm(
              "Group exist do you want to merge these item(s) into this group?"
            )
          ) {
            this.setrealtionshipmul();
          } else {
          }
        } else {
          this.setrealtionshipmul();
        }
      });
  }

  setrealtionshipmul() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexsid;
    var lst1 = this.selectedIndexstype;
    this.imageservice
      .setrealtionshipall(this.relationname, lst, lst1, userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Relationed Successfully",
          });
          this.clearrelation();
        }
      });
  }

  clearrelation() {
    this.relationname = null;
    this.showrelation = false;
    this.removeselection();
    this.rightclickmenuopen = false;
    this.ismenuclick = false;
  }

  showmulrelationpopup() {
    this.showrelation = true;
    this.ismenuclick = true;
  }

  getrelationlist() {
    this.relationlist = [];
    this.imageservice.allrealtionlist().subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.relationlist.push(data[i].groupname);
        }
      }
    });
  }

  filterrealtion(event) {
    this.filteredrelation = [];
    if (event.query != "") {
      //this.glbkeywordsgst=event.query
      this.imageservice
        .getrelationsrch(event.query)
        .subscribe((data: any[]) => {
          if (data.length > 0) {
            this.relationlist = [];

            for (let i = 0; i < data.length; i++) {
              let brand = data[i].groupname;
              this.filteredrelation.push(brand);
              this.relationlist.push(brand);
            }
          }
        });
    } else {
      for (let i = 0; i < this.relationlist.length; i++) {
        let brand = this.relationlist[i];
        if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
          this.filteredrelation.push(brand);
        }
      }
    }
  }

  exportvideoforlocal(path, id, filename) {
    this.dashboarservice
      .exportvideoforlocal(path, id, filename)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Successfully Export",
          });
        }
      });
  }

  openvideocomment(id) {
    window.open("#/comment?q=" + id, "_blank");
  }
  openvideokeywords(id) {
    window.open("#/keywords?q=" + id, "_blank");
  }

  trashaudiopopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.confirmationService.confirm({
        message: "Are you sure that you want to Delete?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.audiouploadservice
            .deleteAudio(itemid)
            .subscribe((data: any[]) => {
              if (data != null) {
                this.messageservice.add({
                  key: "message",
                  severity: "info",
                  summary: "Success Message",
                  detail: "Delete successfully",
                });
                //  if(this.glbDelete==0){
                //    this.getTodayAudio()
                //  }else{
                //    this.getallAudio();
                //  }
                this.blindsearchwithdata("");
              }
            });
        },
        reject: () => {},
      });
    }
  }

  updateaudioopopup(itemid, orgfilename, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.AudioUpdated = true;
      this.audioidforedit = itemid;
      this.audiofileforup = orgfilename;
      this.orgname = orgfilename;
    }
  }

  hideaudioedit() {
    this.audioidforedit = "";
    this.orgname = "";
    this.AudioUpdated = false;
    this.treedropvisible = false;
    this.showfulldiv();
  }

  activeLtoLink(ltoIdVideoData) {
    this.videoservice.activeLtoLink().subscribe((data: any) => {
      var ltoIdArchive = data[0].ltoid;
      //console.log("ltoIdArchive",ltoIdArchive);
      if (ltoIdArchive == ltoIdVideoData) {
        this.activeLtoDot = 1;
      } else {
        this.activeLtoDot = 0;
      }
    });
  }

  //  valur = {
  //    get microPhoneText(): string {
  //     return this.speechTextService.text
  //   }
  // }

  // getting microphone text from its service
  public get microPhoneText(): string {
    return this.speechTextService.text;
  }

  startMicroPhoneService() {
    this.speechTextService.start();
    this.microphone = 1;
  }

  stopMicroPhoneService() {
    this.speechTextService.stop();
    this.microphone = 0;
  }
  CloseDialogUpdate(event: any) {
    this.videoupdate = false;
    this.ImageUpdated = false;
    this.DocumentUpdated = false;
    this.AudioUpdated = false;
  }
}

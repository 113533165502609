import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LeftMenuComponent } from 'src/app/common/left-menu/left-menu.component';
import { AgencyService } from 'src/app/services/files/agency.service';

@Component({
  selector: 'app-agencyleftbar',
  templateUrl: './agencyleftbar.component.html',
  styleUrls: ['./agencyleftbar.component.css']
})
export class AgencyleftbarComponent implements OnInit {
  agencylist: any[] = [];
  agencyinformation: any;
  agencyform: FormGroup
  agencyformMaster: FormGroup
  Header: string = "Add/Edit Agency"
  ButtonStatus: string = 'Save'
  tmpagencyid: string;
  agencycatlist: any[]


  constructor(public leftComponent:LeftMenuComponent ,private agencyser: AgencyService, private formBuilder: FormBuilder, private messageService: MessageService, private Confirmservice: ConfirmationService) { }

  ngOnInit() {

    this.getagency()
    this.CreateCountryForm()
    this.CreateCountryFormMaster()
    this.getallagencycat()
  }

  getagency() {
    this.agencylist =[]
    this.agencyser.getallagency().subscribe((data: any[]) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].ActiveStatus == "A") {
            this.agencylist.push(data[i])
          }
        }
      }
    })
  }

  CreateCountryForm() {
    this.agencyform = this.formBuilder.group({
      catname: [null, [Validators.required]],
      agencyid: [0, [Validators.required]],
      userid: [null],
      agencyname: [null],
      catid: [null]
    })
  }

  CreateCountryFormMaster() {
    this.agencyformMaster = this.formBuilder.group({
      agencyname: [null, [Validators.required, this.removeSpaces, Validators.pattern('^[a-zA-Z ]*$')]],
      agencyid: [null],
      userid: [null],
      agencytype: ['Image']
    })
  }

  getallagencycat() {
    this.agencyser.getallagencycat().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.agencycatlist = data
      }
    })
  }

  Clear() {
    this.agencyform.reset()
    this.ButtonStatus = "Save"
    this.agencyform.patchValue({ agencyid: "0" })
  }

  ClearMaster() {
    this.agencyformMaster.reset()
    this.ButtonStatus = "Save"
    this.agencyformMaster.patchValue({ agencyid: "0" })
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }




  SaveCountry() {
    // console.log("SaveCountry formval.value",this.agencyformMaster.value);
    if (this.agencyformMaster.valid) {
      this.agencyinformation = this.agencyformMaster.value
      if (this.agencyinformation.agencytype == "0") {
        this.agencyinformation.agencytype = "Image"
      }
      // else {
      //   this.agencyinformation.agencytype = "Story"
      // }
      this.agencyinformation.userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId
      if (this.agencyinformation.agencyid > 0) {
        this.agencyser.checkduplicateagncy(this.agencyinformation).subscribe((data: any) => {
          if (data.length < 1) {
            this.agencyser.updateagency(this.agencyinformation).subscribe((_data: any) => {
              this.getallagencycat()
              this.getagency()
              this.ClearMaster()
              this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'update successfully' });
              this.ButtonStatus = "Save"
            });
          } else {

            this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
          }
        })

      }

      else {
        this.agencyser.checkduplicateagncy(this.agencyinformation).subscribe((data: any) => {
          if (data.length < 1) {
            this.agencyser.Createagency(this.agencyinformation).subscribe((_data: any) => {
              this.getallagencycat()
              this.getagency()
              this.ClearMaster()
              this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });
            });
          } else {
            this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
          }
        })
      }
    }
  }

  savecategory(formval) {
    this.agencyinformation = formval.value
    this.agencyinformation.agencyname = formval.directives[0].valueAccessor._elementRef.nativeElement.selectedOptions[0].innerText
    if (this.agencyinformation.agencyid == "0") {
      this.messageService.add({ key: 'message', severity: 'error', summary: 'Error Message', detail: 'Please select agency..' });
    }
    else {
      this.agencyinformation.userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId
      this.agencyser.checkduplicateagncycat(this.agencyinformation).subscribe((data: any) => {
        if (data.length < 1) {
          this.agencyser.createagencycat(this.agencyinformation).subscribe((_data: any) => {
            this.messageService.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: 'Save successfully' });
            this.getallagencycat()
            this.getagency()
            this.Clear()
            this.leftComponent.bindagency()
          });
        } else {
          this.messageService.add({ key: 'message', severity: 'error', summary: 'Warn Message', detail: 'Agency Already Exist' });
        }
      })
    }
    //}
  }
}

import { Component, OnInit } from "@angular/core";
import { Eventformmodel } from "src/app/model/master/event.form.model";
import { EventService } from "src/app/services/masters/event.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  NgForm,
  AbstractControl,
} from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.css"],
})
export class EventComponent implements OnInit {
  EventForm: FormGroup;
  eventlist: EventComponent[] = [];
  eventinformation: Eventformmodel;
  ButtonStatus: string = "Save";
  Header: string = "Add Event";
  constructor(
    private eventservice: EventService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private Confirmservice: ConfirmationService
  ) {}

  ngOnInit() {
    this.getevent();
    this.CreateEventForm();
  }

  getevent() {
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data != null) {
        this.eventlist = data;
      }
    });
  }

  CreateEventForm() {
    this.EventForm = this.formBuilder.group({
      eventname: [
        null,
        [
          Validators.required,
          this.removeSpaces,
          Validators.pattern("^[a-zA-Z ]*$"),
        ],
      ],
      eventid: [null],
      userid: [null],
    });
  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, "").length) {
      control.setValue("");
    }
    return null;
  }
  SaveEvents(formval) {
    this.eventinformation = formval.value;
    this.eventinformation.userid = JSON.parse(
      localStorage.getItem("userdetails")
    )[0].UserId;
    if (this.eventinformation.eventid > 0) {
      this.eventservice
        .checkduplicate(this.eventinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.eventservice
              .updateEvent(this.eventinformation)
              .subscribe((data: any) => {
                this.getevent();
                this.EventForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "update successfully",
                });
                this.ButtonStatus = "Save";
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Event Already Exist",
            });
          }
        });
    } else {
      this.eventservice
        .checkduplicate(this.eventinformation)
        .subscribe((data: any) => {
          if (data.length < 1) {
            this.eventservice
              .insertEvent(this.eventinformation)
              .subscribe((data: any) => {
                this.getevent();
                this.EventForm.reset();
                this.messageService.add({
                  key: "message",
                  severity: "success",
                  summary: "Success Message",
                  detail: "Save successfully",
                });
              });
          } else {
            this.messageService.add({
              key: "message",
              severity: "error",
              summary: "Warn Message",
              detail: "Event Already Exist",
            });
          }
        });
    }
  }
  updateevent(eventlist: Eventformmodel) {
    this.ButtonStatus = "Update";
    this.Header = "Edit/Delete Event";
    this.EventForm.setValue({
      eventname: eventlist.eventname,
      eventid: eventlist.eventid,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
    });
  }
  deleteevent(eventid: number) {
    this.Confirmservice.confirm({
      message: "Are you sure that you want to Delete?",
      header: "Confirmation",
      key: "eventkey",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.eventservice.deleteevent(eventid).subscribe((data: any[]) => {
          this.messageService.add({
            key: "message",
            severity: "info",
            summary: "Success Message",
            detail: "Delete successfully",
          });
          this.getevent();
          this.EventForm.reset();
          this.ButtonStatus = "Save";
        });
      },
      reject: () => {},
    });
  }
  clear() {
    this.EventForm.reset();
    this.Header = "Add Event";
    this.ButtonStatus = "Save";
  }
}

import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, retry } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  baseurl = environment.ApiUrl;
  constructor(private httpclient: HttpClient) {}

  getMachineId() {
    return this.httpclient.get<any>(this.baseurl + "Login/machineId");
  }

  LoginUser(Username, encryptedPassword): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Login/loginUser", {
      loginid: Username,
      encryptedPassword: encryptedPassword,
      serverip: environment.serverip,
    }); //.pipe(catchError(this.handleError))
  }
  fileRead() {
    return this.httpclient.get<any>(this.baseurl + "Login/fileRead");
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    alert("something went wrong try again later");
    return throwError("Something bad happened; please try again later.");
  }

  assignedpermission(userid): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/assignedpermission",
      { UserId: userid }
    );
  }

  assignedpermissionforViewer(userid): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/assignedpermissionforViewer",
      { UserId: userid }
    );
  }

  getperference(userid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Login/getperference", {
      userid: userid,
    });
  }

  getperferenceforViewer(): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "Login/getperferenceforViewer", {});
  }

  getserstatus(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "Login/getserverstatus");
  }

  uploadlicenseFile(licensefile: any, licenseName: any) {
    const formDataFile: FormData = new FormData();
    formDataFile.append("license", licensefile, licenseName);
    return this.httpclient.post<any>(
      this.baseurl + "Login/upload",
      formDataFile
    );
  }
  setconfigvariable(center_id): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/setconfigvariable",
      { center_id: center_id }
    );
  }

  licenseActivate(): Observable<any[]> {
    // console.log("license service",);
    return this.httpclient.get<any>(this.baseurl + "Login/license");
  }

  clientDetail(): Observable<any[]> {
    // console.log("client detail service",);
    return this.httpclient.get<any>(this.baseurl + "Login/clientDetail");
  }

  sendOtp(email): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/sendOtp",
      { email:email }
    );
  }

  loginDetails(email,otp): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/loginDetails",
      { id:email, passkey:otp }
    );
  }

  verifyOtp(logindata): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/verifyOtp",
      { logindata }
    );
  }

  insertNewUserAuto(email): Observable<any[]> {
    return this.httpclient.post<any[]>(
      this.baseurl + "Login/insertNewUserAuto",
      { email }
    );
  }

  
}


import { Component, OnInit, Input } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { MessageService } from "primeng/api";
@Component({
  selector: "app-user-rights",
  templateUrl: "./user-rights.component.html",
  styleUrls: ["./user-rights.component.css"],
})
export class UserRightsComponent implements OnInit {
  userid: number = 0;
  username: string;
  assignedpermission: PermissionModel[];
  availablepermission: PermissionModel[];
  userlist: userformmodel[] = [];
  DDdisabled: boolean = true;
  UserCompleteName: any;
  mainuser: any;
  constructor(
    private userser: UserserviceService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.getdragdrop();
    this.mainuser = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.getuserdata();
  }
  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //                       event.container.data,
  //                       event.previousIndex,
  //                       event.currentIndex);
  //   }
  // }
  getdragdropdata(userid: number) {
    this.userser.getuserpermissions(userid).subscribe((data) => {
      if (data != null) {
        this.assignedpermission = data[0];
        if (this.mainuser == "53") {
          this.availablepermission = data[1];
        } else {
          this.availablepermission = data[1].filter((val) => {
            return val.taskid != 47;
          });
        }

        console.log("Available Permission is", this.availablepermission);
      }
      // if (data != null) {
      //   this.assignedpermission = data[0];
      //   this.availablepermission = data[1];
      // }
    });
  }
  getpermission(e) {
    let selecteduserid = e.target.selectedOptions[0].value;
    this.userid = selecteduserid;

    this.getdragdropdata(selecteduserid);
  }

  getuserdata() {
    this.userser.getuserdata().subscribe((data: userformmodel[]) => {
      if (data != null) {
        this.userlist = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].LoginId.toLowerCase() == "admin") {
            this.userlist.splice(i, 1);
          }
        }
      }
    });
  }

  SaveUserpermission() {
    let i = 0;
    this.DeleteUserPermission();
    //for (i = 0; i < this.assignedpermission.length; i++) {
    this.userser
      .saveuserpermission(this.userid, this.assignedpermission)
      .subscribe((response: any[]) => {
        this.messageService.add({
          key: "message",
          severity: "success",
          summary: "Success Message",
          detail: "Saved successfully",
        });
        this.getdragdropdata(this.userid);
      });
    //}
  }

  DeleteUserPermission() {
    this.userser
      .deleteuserpermission(this.userid)
      .subscribe((response: any[]) => {});
  }

  clear() {
    this.userid = 0;
    this.assignedpermission = [];
    this.availablepermission = [];
  }
}

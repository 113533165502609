import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { FormGroup, FormBuilder, NgForm, Validators, AbstractControl } from '@angular/forms';
import { Eventformmodel } from 'src/app/model/master/event.form.model';
import { CountryFormModel } from 'src/app/model/master/country.formmodel';
import { stateFormModel } from 'src/app/model/master/state.formmodel';
import { CityFormModel } from 'src/app/model/master/city.form.model';
import { audiouploadModel } from 'src/app/model/file/audiouploadModel';
import { environment } from 'src/environments/environment';
import { CategoryService } from 'src/app/services/masters/category.service';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { CountryService } from 'src/app/services/masters/country.service';
import { CityService } from 'src/app/services/masters/city.service';
import { EventService } from 'src/app/services/masters/event.service';
import { MessageService } from 'primeng/api';
import { DocumentuploadService } from 'src/app/services/files/documentupload.service';
import { FileUpload } from 'primeng/primeng';
import { DocumentUploadFormModel } from 'src/app/model/file/documentUploadModel';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-document-upload-popup',
  templateUrl: './document-upload-popup.component.html',
  styleUrls: ['./document-upload-popup.component.css']
})
export class DocumentUploadPopupComponent implements OnInit {
  selectedFiles: any[] = []
  fileData: File = null;
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  DocumentUploadForm: FormGroup
  categoryid: number = 0
  eventlist: Eventformmodel[] = [];
  countrylist: CountryFormModel[] = []
  Statelist: stateFormModel[] = []
  citylist: CityFormModel[] = []
  audiolist: audiouploadModel[] = []
  documentlist: DocumentUploadFormModel[] = []
  documentinformation: DocumentUploadFormModel;
  audioinformation: audiouploadModel;
  display: boolean = false
  x: string
  check: boolean = true;
  Documenturl = environment.ApiUrl
  i: number
  value: number = 0
  limitexceed:number=0
  itemtypes:string=""
  constructor(private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService, private countryservice: CountryService, private cityservice: CityService, private eventservice: EventService, private messageservice: MessageService, private documentuploadservice: DocumentuploadService, private documentcomponent: DocumentUploadComponent) { }
  @ViewChild('fileInput', { static: true }) fileInput: FileUpload;
  @ViewChild('uploadbutton', { static: true }) uploadbutton: ElementRef;

  ngOnInit() {
    //this.getcategorydata()
    this.CreateDocumentForm()
    //this.getCountryData()
    //this.getEventData()
    this.getitemtype()
  }


  getitemtype(){
    this.documentuploadservice.getitemtype().subscribe((data:any[])=>{
      if(data !=null){
        this.itemtypes=data[0].typeformat
      }
    })
  }

  clear() {
    // this.fileInput._files = []
    this.DocumentUploadForm.reset()
    this.documentcomponent.onHide1()
    this.value = 0
    this.limitexceed=0
  }
  remove(name) {
    for(let i=0;i<this.fileInput.files.length;i++){
      if(this.fileInput.files[i].name==name){
        // this.fileInput._files.splice(i,1)
      }
    }
    this.DocumentUploadForm.reset()
  }
  getfilename(e) {

    // if(this.fileInput.progress==0){
    //   this.uploadbutton.nativeElement.disabled=true
    // }
    var nme = e.files[0].name
    if(e.files.length>0 && e.files.length<6){
      this.selectedFiles.push(e.files)

      if ((e.files[0].size) / 1000 > 1024) {
        var filesize = (((e.files[0].size) / 1000) / 1024).toFixed(2) + "mb"
      }
      else {
        var filesize = ((e.files[0].size) / 1000).toFixed(3) + "kb"
      }
      var indx = nme.lastIndexOf(".")
      var format = nme.substring(indx + 1, nme.length)
      this.DocumentUploadForm.patchValue({ itemfilename: nme })
      this.DocumentUploadForm.patchValue({ itemsize: filesize })
      this.DocumentUploadForm.patchValue({ itemformat: format })
      this.fileInput.upload();

    }
    else{
      // this.fileInput._files=[]
      this.limitexceed=1
    }
    
    //this.getmetadata(nme)
  }

  getmetadata(nme) {
    for (let i = 0; i < this.fileInput.files.length; i++) {
      document.getElementById(this.fileInput.files[i].name).style.background = 'white'
    }

    // if(nme.substring(nme.lastIndexOf(".") + 1, nme.length)=="rtf"){
    //   this.readrtf(nme)
    // }
    

    document.getElementById(nme).style.background = "lavender"
    let indx
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var tmp = this.fileInput.files[i].name
      if (tmp == nme) {
        indx = i
      }
    }
    if ((this.fileInput.files[indx].size) / 1000 > 1024) {
      var filesize = (((this.fileInput.files[indx].size) / 1000) / 1024).toFixed(2) + " MB"
    }
    else {
      var filesize = ((this.fileInput.files[indx].size) / 1000).toFixed(3) + " KB"
    }
    this.DocumentUploadForm.patchValue({ itemsize: filesize })


    var p = environment.folderpath + nme
    this.documentuploadservice.getDocumentmetadata(p).subscribe((data: any) => {
      if (data != null) {

        var dataparse=JSON.parse(data)
        var dt = dataparse[0]
        this.documentlist = dt
        this.x = JSON.stringify(this.documentlist)
        this.documentlist = JSON.parse(this.x)
        var indx = nme.lastIndexOf(".")
        var format = nme.substring(indx + 1, nme.length)
        //this.DocumentUploadForm.patchValue({itemlength:this.documentlist["Duration"]})
        this.DocumentUploadForm.patchValue({ itemformat: format })
        this.DocumentUploadForm.patchValue({ itemfilename: nme })
        // this.AudioUploadForm.patchValue({itemsize:filesize})
      }

    })
  }

  Textread(filename,filepath){
    this.documentuploadservice.getTextFileContend(filename,filepath).subscribe((data:any)=>{
      if(data!=null){
      //this.text=data
      var txt=data
      }
    })
    }

    readrtf(filename){
      this.documentuploadservice.readrtf(filename).subscribe((data:any)=>{
          if(data !=null){
            // console.log(data)
          }
      })
    }

  CreateDocumentForm() {
    this.DocumentUploadForm = this.formBuilder.group({
      itemid:[null],
      title: [null],
      itemcaption: [null],
      itemauthor: [null],
      noofpages: [null],
      Pages: [null],
      magsection: [null],
      bysubject: [null],
      authorinput: [null],
      source: [null],
      storytype: [null],
      itemkeyword: [null],
      Photographer: [null],
      eventdate:[null],
      itemrating:[null]
    })

  }
  removeSpaces(control: AbstractControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
    }
    return null;
  }
  getcategorydata() {
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }
  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getCountryData() {
    this.countryservice.GetCountryData().subscribe((data: CountryFormModel[]) => {
      if (data.length > 0) {
        this.countrylist = data;
      }
    })
  }
  getStateDropDown(c) {
    this.cityservice.getStateDropDown(c).subscribe((data: stateFormModel[]) => {
      if (data.length > 0) {
        this.Statelist = data;
      }
      else {
        this.Statelist = []
        this.DocumentUploadForm.patchValue({ StateId: "null" })
        this.citylist = []
        this.DocumentUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getstate(e) {
    let selctedcountryid = e.target.selectedOptions[0].value;
    // this.countryid=selctedcountryid;
    this.getStateDropDown(selctedcountryid)
  }

  getCityDropdown(s) {
    this.cityservice.getCityData(s).subscribe((data: CityFormModel[]) => {
      if (data.length > 0) {
        this.citylist = data;
      }
      else {
        this.citylist = []
        this.DocumentUploadForm.patchValue({ CityId: "null" })
      }
    })
  }
  getCity(e) {
    let selectedstateid = e.target.selectedOptions[0].value;
    // this.countryid=selectedstateid;
    this.getCityDropdown(selectedstateid)
  }

  getEventData() {
    //this.userinfomation=new userformmodel();
    this.eventservice.getAllEvent().subscribe((data: any[]) => {
      if (data.length > 0) {
        this.eventlist = data;
      }

    })

  }
  DocumentUpload(formval) {
    var stduplicate=0
    var stinsert=0
    this.documentinformation = formval.value
     if(this.fileInput.files.length==0){
      this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please Browse Files' });
     }
    for (let i = 0; i < this.fileInput.files.length; i++) {
      var name = this.fileInput.files[i].name;
      var name = this.fileInput.files[i].name.replace(new RegExp("'", 'g'), "''")
      var p = environment.folderpath + name
      // this.documentuploadservice.getDocumentmetadata(p).subscribe((data: any) => {
      //   if (data != null) {
      //     //console.log(data)
      // // var dataparse=JSON.parse(data)
      //     var dt = data
      //     this.documentlist = dt.data[0]
      //     this.x = JSON.stringify(this.documentlist)
      //     this.documentlist = JSON.parse(this.x)
          if ((this.fileInput.files[i].size) / 1000 > 1024) {
            var filesize = (((this.fileInput.files[i].size) / 1000) / 1024).toFixed(2) + " MB"
          }
          else {
            var filesize = ((this.fileInput.files[i].size) / 1000).toFixed(3) + " KB"
          }
          var format = this.fileInput.files[i]["name"].substring(this.fileInput.files[i]["name"].lastIndexOf(".")+1, this.fileInput.files[i]["name"].length)

          this.documentinformation.itemfilename = this.fileInput.files[i].name;
          this.documentinformation.itemformat = format
          this.documentinformation.itemsize = filesize
          // if (this.documentinformation.CategoryId == null || this.documentinformation.itemkeyword == null) {
          //   if (i == this.fileInput.files.length - 1) {
          //     this.messageservice.add({ key: 'message', severity: 'warn', summary: 'Warning Message', detail: 'Please select Category or Keywords' });
          //   }
          // } else {
            this.documentuploadservice.AddNewDocument(this.documentinformation).subscribe((data: any) => {
              let interval = setInterval(() => {
                this.value = this.value + Math.floor(Math.random() * 10) + 1;
                if (this.value >= 100) {
                  this.value = 100;
                  // this.messageService.add({severity: 'info', summary: 'Success', detail: 'Process Completed'});
                  clearInterval(interval);

                  // this.value=0

                }
              });
              if(data=="Duplicate"){
                stduplicate=stduplicate+1
              }
              if(data=="inserted"){
                stinsert=stinsert+1
              }
              if (i == this.fileInput.files.length - 1) {
                this.messageservice.add({ key: 'message', severity: 'success', summary: 'Success Message', detail: stinsert.toString()+" Inserted And "+ stduplicate.toString()+" Duplicate"  })
                //this.fileInput._files = []
                setTimeout(() => this.documentcomponent.getAllDocument(), 2000)
                this.clear()
              }
            });
          //}
        }
      //})
    //}
  }

}

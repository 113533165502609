import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { categoryformmodel } from 'src/app/model/master/category.form.model';
import { Subcategoryformmodel } from 'src/app/model/master/subcategory.form.model';
import { StoryFormModel } from 'src/app/model/file/storyformModel';
import { Router } from '@angular/router';
import { SubCategoryService } from 'src/app/services/masters/sub-category.service';
import { CategoryService } from 'src/app/services/masters/category.service';
import { AgencystoryComponent } from '../agencystory/agencystory.component';
import { Eventformmodel } from 'src/app/model/master/event.form.model';

@Component({
  selector: 'app-search-agencystry',
  templateUrl: './search-agencystry.component.html',
  styleUrls: ['./search-agencystry.component.css']
})
export class SearchAgencystryComponent implements OnInit {
  StorySearchingForm:FormGroup
  previewUrl: any = null;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  categoryid: number = 0
  storylist:StoryFormModel[]=[]
  eventlist:Eventformmodel[]=[]
  constructor(private categoryService: CategoryService, private formBuilder: FormBuilder, private subcategoryService: SubCategoryService,private route:Router,private agencystorycom:AgencystoryComponent) { }

  ngOnInit() {
    this.CreateStorySearchForm()
    this.getcategorydata()
    this.getevent()
  }

  CreateStorySearchForm() {
    this.StorySearchingForm = this.formBuilder.group({
      CategoryId: [null],
      SubCategoryId: [null],
      CountryId: [null],
      StateId: [null],
      CityId: [null],
      EventId: [null],
      OriginalPath: [null],
      itemfilename: [null],
      itemsize: [null],
      itemlength: [null],
      itemformat: [null],
      resolution: [null],
      itemcaption: [null],
      itemkeyword:[null],
      location: [null],
      itemauthor: [null],
      eventdate: [null],
      itemrating: [null],
      AvgBitrate:[null],
      startdate:[null],
      endDate:[null],
      orgfilename:[null],
      framerate:[null],
      Story:[null],
      Searching_Method:['n'],
      source:['0'],
      timerange:['0'],
      event:[null]
    })

  }
  SearchAllStory(formvalues){
    this.agencystorycom.SearchAllStory(formvalues.value)
    this.StorySearchingForm.reset()
    this.StorySearchingForm.patchValue({Searching_Method:'n'})
    this.StorySearchingForm.patchValue({source:'0'})
    this.StorySearchingForm.patchValue({timerange:'0'})

  
  }
  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getagenystorycat().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }

    })

  }

  getSubcategorydata(c) {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getSubcategoryData(c).subscribe((data: Subcategoryformmodel[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }

    })
  }
  getsubcategory(e) {
    let selectedcategoryid = e.target.selectedOptions[0].value;
    this.categoryid = selectedcategoryid;
    this.getSubcategorydata(selectedcategoryid);
  }

  getevent() {
    //this.userinfomation=new userformmodel();
    this.subcategoryService.getevent().subscribe((data: Eventformmodel[]) => {
      if (data != null) {
        this.eventlist = data;
      }

    })
  }
}

import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { cartoncartname, cartmodel } from "src/app/model/file/cartmodel";
import { AgencyService } from "src/app/services/files/agency.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import { userformmodel } from "src/app/model/master/user.form.model";
import { UserserviceService } from "src/app/services/masters/userservice.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { environment } from "src/environments/environment";
import { ThrowStmt } from "@angular/compiler";
import { loadAsync } from "jszip";
import { LoginService } from "src/app/services/login/login.service";
import { HrcornerService } from "src/app/services/files/hrcorner.service";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.css']
})
export class UserLogsComponent implements OnInit {

  displayApprovalDialog: boolean = false;
  displayItemApprovalDialog: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private loginservice: LoginService,
    private authservice: AuthService,
    private agencyser: AgencyService,
    private hrcornerService: HrcornerService,
    private messageservice: MessageService,
    public router: Router,
    private imageservice: ImageuploadService,
    private userser: UserserviceService,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.getNotificationApprovalforUser();
  }


  listOfImagesforUser:any[]=[];
  getNotificationApprovalforUser() {
  this.imageservice.getNotificationApprovalforUser().subscribe((data: any[]) => {
    this.listOfImagesforUser = data;
    this.tempList = data;
    // console.log("user logs list of image approvals for normal user",this.listOfImagesforUser);
  });
}

  formatActionTime(action_time: string | null): string {
    if (!action_time) {
      return 'Pending Time';
    }
    const formattedTime = this.datePipe.transform(action_time, 'shortTime');
    const formattedDate = this.datePipe.transform(action_time, 'mediumDate');
    return `${formattedDate}  ${formattedTime}`;
  }

  imageHiresPreview: boolean = false;
  HiresimageSrc:any;
  basepathh = environment.CommpanyBgImagePath;
  previewHiresImage(imagePath){

    // console.log("preview hires",imagePath);
    
    this.HiresimageSrc = imagePath; 
    this.imageHiresPreview = true;
    this.displayItemApprovalDialog = false;
    this.displayApprovalDialog = false;
  }

  onCloseHiresPreview(): void {
    const isMediaManager = this.allTaskIds.some((task: any) => task.taskid === '200');
    this.imageHiresPreview = false; 
    if(isMediaManager){
      // this.displayApprovalDialog = true;
    } else {
      this.displayItemApprovalDialog = true;
    }
}

allTaskIds:any;
getAllTaskforUser(){
  this.dashboardService.customcmShow().subscribe((data:any) =>{
    this.allTaskIds = data;

    const isMediaManager = this.allTaskIds.some((task: any) => task.taskid === '200');
    // console.log("is media manager in all tasks in user",isMediaManager);
    
    // console.log("all task ids",this.allTaskIds);
    // if(isMediaManager){
    //   this.getNotificationApprovalforManager();
    //   this.displayApprovalDialog = true;
    // } else {
      this.getNotificationApprovalforUser();
      this.displayItemApprovalDialog = true;
    // }
  });
}

  downloadSelectedImage(filename, filepath, itemid){
    // const basePath = 'http://192.168.1.185/mams/';
    // const updatedFilePath = filepath.toLowerCase().replace(basePath, '');
    
    // console.log("download selected image",filename,filepath,itemid);
    
    var obj = {
      filename: filename,
      filepath: filepath,
      itemid: itemid
    }
    this.dashboardService.downloadSelectedImage(obj);
  }



  searchData:string = '';
  tempList:any;
  getFilterdLogs(){
    const searchTerm = this.searchData.trim().toLowerCase();
      if(this.tempList.length>0){
       this.listOfImagesforUser=this.tempList.filter((data)=>{
        // console.log("filtered user logss",data);
        return (
          data.OrgFileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          data.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (data.RequestBy && data.RequestBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (data.action_by_name && data.action_by_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (data.reject_remark && data.reject_remark.toLowerCase().includes(searchTerm.toLowerCase())) ||
          ('approved'.includes(searchTerm.toLowerCase()) && data.status === 'A') ||
          ('rejected'.includes(searchTerm.toLowerCase()) && data.status === 'R') ||
          ('pending'.includes(searchTerm.toLowerCase()) && data.status === 'P')
        );
               
      });
    }
  }





}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { bysubjectModel } from 'src/app/model/master/bysubject.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class bysubjectService {

  bysubjectModel:bysubjectModel=new bysubjectModel();
  baseurl=environment.ApiUrl;
  //baseurl:string="http://localhost:5000/bysubject"
  constructor(private httpclient:HttpClient) { }

      GetbysubjectData():Observable<bysubjectModel[]>{
        return this.httpclient.get<bysubjectModel[]>(this.baseurl+"bysubject/getbysubject")
      }
      Createbysubject(bysubjectinfo:bysubjectModel):Observable<bysubjectModel[]>{
        return this.httpclient.post<bysubjectModel[]>(this.baseurl + "bysubject/insertbysubject",bysubjectinfo)
      }
      Deletebysubject(id:number):Observable<bysubjectModel[]>{
        return this.httpclient.post<bysubjectModel[]>(this.baseurl + "bysubject/deletebysubject",{id:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
      }
      Updatebysubject(bysubjectinfo:bysubjectModel):Observable<bysubjectModel[]>{
        return this.httpclient.post<bysubjectModel[]>(this.baseurl + "bysubject/updatebysubject",bysubjectinfo)
      }
      checkduplicate(bysubjectinfo:bysubjectModel):Observable<bysubjectModel[]>{
        return this.httpclient.post<bysubjectModel[]>(this.baseurl + "bysubject/checkduplicate",bysubjectinfo)
      }
    }

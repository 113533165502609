import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse  } from '@angular/common/http';
import { HRUploadFormModel } from 'src/app/model/file/hrFormUploadModel';
import { promise } from 'protractor';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HrcornerService {

  private nameSource = new BehaviorSubject<string>('');
  uploadFilesName = this.nameSource.asObservable()

  sendUploadFileList(uploadFilesName: any): void {
    this.nameSource.next(uploadFilesName);
   // console.log("uploadFilesName",uploadFilesName);
  }


private uploadFileStatus = new BehaviorSubject<string>('')
fileStatus = this.uploadFileStatus.asObservable()
  sendUploadFileStatus(status:any):void{
  //  console.log(status);
    this.uploadFileStatus.next(status)
  }



  





  HRUploadFormModel: HRUploadFormModel = new HRUploadFormModel()
  constructor(private httpclient: HttpClient) { }

  baseurl = environment.ApiUrl;

  AddNewDocument(documentinfo: HRUploadFormModel): Observable<HRUploadFormModel[]> {
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HRUpload/insertDocument", documentinfo)
  }
  getDocumentmetadata(nme): Observable<HRUploadFormModel[]> {
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HRUpload/DocumentMetadata", { itemfilename: nme ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getdocumentdata(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getdocument", { itemid: itemid, path: path, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  getdictionary(value): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "category/getdictionary", { value: value })
  }

  // GetAllDocumentCount() {
  //   return this.httpclient.get(this.baseurl + "HRUpload/getAllDocumentCount")
  // }

  GetAllDocument(limit): Observable<HRUploadFormModel[]> {
    var maxlimit = "54"
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit = (Number(maxlimit) * 2).toString()
    }
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HRUpload/getAllHRDocument", { limit: limit, maxlimit: maxlimit, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  SearchByFields(Searchstring, limit, isfulltxt): Observable<any[]> {
    var maxlimit = "54"
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit = (Number(maxlimit) * 2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/SearchByFields", { Searchstring: Searchstring, limit: limit, maxlimit: maxlimit, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId, isfulltext: isfulltxt,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  fulltextsrch(Searchstring, limit): Observable<any[]> {
    var maxlimit = "54"
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit = (Number(maxlimit) * 2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/fulltextsrch", { Searchstring: Searchstring, limit: limit, maxlimit: maxlimit, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  addacl(userid, idlst, date): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/addacl", { userid: userid, idlst: idlst, date: date,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  removeacl(userid, idlst): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/removeacl", { userid: userid, idlst: idlst, CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  UpdateDocument(documinfo: HRUploadFormModel): Observable<HRUploadFormModel[]> {
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HRUpload/updatedocuments", documinfo)
  }
  SearchAllDocument(documentinfo: HRUploadFormModel): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "DocumentUpload/SearchDocument", documentinfo)
  }

  deleteDocument(itemid: number): Observable<HRUploadFormModel[]> {
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HRUpload/deleteDocument", { itemid: itemid, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deletemultipledoc(itemid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/deletemultipledoc", { itemid: itemid, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getitemtype(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "HRUpload/getitemtype")
  }
  getpdftypes(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "HRUpload/getpdftypes")
  }

  GetDownloadedDocumentData(itemid: number): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getdownloadDocumentdata", { itemid: itemid,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  Testdownload(filename, attachmentpath): Observable<Blob> {
    return this.httpclient.post(this.baseurl + "HRUpload/testdownload", { documentname: filename, attachmentpath: attachmentpath,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId },{responseType: "blob"})
  }

  downloadentry(itemid, userid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/downloadentry", { itemid: itemid, userid: userid ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getTodayDocument(): Observable<any[]> {
    let maxlimit = "54"
    return this.httpclient.get<any[]>(this.baseurl + "HRUpload/getTodayDocument")
  }

  getTextFileContend(filename, filepath): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getTextFileContend", { filename: filename, filepath: filepath ,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  readrtf(filepath): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getrtfdata", { filepath: filepath,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId  })
  }

  getpdfimg(itemid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getpdfpages", { itemid: itemid,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  getrealtedimg(cat, sdate, source): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getrealtedimg", { cat: cat, sdate: sdate, source: source ,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  advancesearch(srchtxt, limit): Observable<any[]> {
    var maxlimit = "54"
    if (JSON.parse(localStorage.getItem("userperference")).length > 0) {
      maxlimit = JSON.parse(localStorage.getItem("userperference"))[0].noofrows
      maxlimit = (Number(maxlimit) * 2).toString()
    }
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/newadvancesearch", { srchtxt: srchtxt, limit: limit, maxlimit: maxlimit, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getfulltxtcount(srchtxt): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/fulltextsrchcount", { Searchstring: srchtxt, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getncount(srchtxt, isfulltxt, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getncount", { Searchstring: srchtxt, isfulltext: isfulltxt, tabstr: tabstr, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }


  getacount(srchtxt, tabstr): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getacount", { srchtxt: srchtxt, tabstr: tabstr, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  //API not working
  getcartdata(id, itemtype): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/getcartdata", { itemid: id, itemtype: itemtype,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  //API not working
  downloadmultipleimg(imageid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/downloadmultipleimg", { imageid: imageid,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId  })
  }

  downloadmultiplefn(arr): Observable<Blob> {
    return this.httpclient.post(this.baseurl + "HRUpload/downloadmultiplefn", { arr: arr, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId},{responseType: "blob"})
  }
  deletecollectionfiles(arr): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/deletecoldoc", { arr: arr, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  deleteDocumentRstr(itemid) {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/deleterestoreddocument", { itemid: itemid, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  restoreDocument(itemid) {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/restore", { itemid: itemid,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  approveimg(lst): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HRUpload/approvedoc", { lst: lst, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  getrelationsrch(groupname): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "relation/searchrelation", { groupname: groupname,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId  })
  }

  allrealtionlist(): Observable<any[]> {
    return this.httpclient.get<any[]>(this.baseurl + "relation/allrealtionlist")
  }

  setrealtionshipmul(cartname, itemid, itemtype, userid, carttype): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "relation/setrelationship", { relationname: cartname, itemid: itemid, itemtype: itemtype, userid: userid, carttype: carttype,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  checkrelationid(cartname): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "relation/getrelationid", { groupname: cartname,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId  })
  }

  //this API is not working
  deletezip(filename): Observable<any[]> {
    return this.httpclient.post<any>(this.baseurl + "HRUpload/deletezip", { cartname: filename ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getResults(userid): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "agency/getautodata", { userid: userid,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocart(cartname, itemid, itemtype, userid, carttype): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "agency/addtocart", { cartname: cartname, itemid: itemid, itemtype: itemtype, userid: userid, carttype: carttype ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  addtocartmul(cartname, itemid, itemtype, userid, carttype): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "agency/addtocartmul", { cartname: cartname, itemid: itemid, itemtype: itemtype, userid: userid, carttype: carttype,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }


  bindcart(itemtype, userid, carttype): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "agency/bindcart", { itemtype: itemtype, userid: userid, carttype: carttype})
  }




  GetAllDocumentCount(): Observable<any> {
    return this.httpclient.get<any[]>(this.baseurl + "HR_TREE_UPLOAD/GET_ALL_COUNT")
  }


  getTreeView(nodeLevel, TreeViewId): Observable<any> {
    return this.httpclient.post(this.baseurl + "HR_TREE_UPLOAD/GET_TREE", { nodeLevel: nodeLevel, TreeViewId: TreeViewId,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  searchFolderData(folderSearchText): Observable<any> {
    return this.httpclient.post(this.baseurl + "HR_TREE_UPLOAD/SEARCH_FOLDER", { folderSearchText: folderSearchText,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  searchFolderFiles(fileSearchText) : Observable<any>{
    return this.httpclient.post(this.baseurl + "HR_TREE_UPLOAD/SEARCH_FILES", { fileSearchText: fileSearchText,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  createNewNode(Level, ParentTreeId, CollectionPath) : Observable<any>{
    return this.httpclient.post(this.baseurl + "HR_TREE_UPLOAD/CREATE_NODE", { Level: Level, ParentTreeId: ParentTreeId, CollectionPath: CollectionPath, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }
  deleteNode(TreeViewId): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/DELETE_NODE", { TreeViewId: TreeViewId,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getFolderFiles(TreeViewId): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/GET_FOLDER_FILES", { TreeViewId: TreeViewId,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  downloadFileFromTree(itemid, orgfilename, OriginalPathFile): Observable<Blob> {
    return this.httpclient.post(this.baseurl + "HR_TREE_UPLOAD/DOWNLOAD_FILE", { itemid: itemid, orgfilename: orgfilename, OriginalPathFile: OriginalPathFile,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId }, { responseType: "blob", headers: new HttpHeaders().append("Content-Type", "application/json") })
  }

  uploadFileToServer(uploadFiles: any,collectionPath: any) : Observable<any> {
  //  console.log(uploadFiles.get("DATA"));
   const headers = new HttpHeaders().append("enctype", "multipart/form-data")
   return this.httpclient.post<any>(this.baseurl + "HR_TREE_UPLOAD/UPLOAD_FILES?collectionPath=" + collectionPath + "&length=" + '1', uploadFiles,{ headers:headers ,reportProgress: true, observe: 'events'})
  }


  
   uploadFilesMetaData(formDataArray): Observable<any>{
    return  this.httpclient.post<any>(this.baseurl + "HR_TREE_UPLOAD/UPLOAD_FILE_DATA", {formDataArray:formDataArray,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId} )
  }
  

  // insertFileDataAndFiles(formDataArray, uploadFiles): Promise<Observable<any>> {
  // //  console.log("formDataArray",formDataArray.length,"uploadFiles",uploadFiles.length);
  //   let uploaddata
  //   return new Promise((resolve, reject) => {
  //     this.uploadFileToServer(uploadFiles,formDataArray).toPromise().then(uploadfiles => {
  //       console.log(uploadfiles); //should output connected
  //       resolve( this.uploadFilesMetaData(formDataArray));
  //       return forkJoin([uploadfiles, uploaddata])
  //     });
  //   });
  // }


  updateFileData(formDataArray:any):Observable<any>{
    return this.httpclient.post<any>(this.baseurl + 'HR_TREE_UPLOAD/UPDATE_FILE_DATA', {formDataArray:formDataArray,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})

  }
  getDocumentData(itemid, path): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/GET_DOCUMENT_DATA", { itemid: itemid, path: path,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  deleteFile(itemid: number): Observable<HRUploadFormModel[]> {
    return this.httpclient.post<HRUploadFormModel[]>(this.baseurl + "HR_TREE_UPLOAD/DELETE_FILE", { itemid: itemid, userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  countFolders(TreeViewId): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/COUNT_FOLDER", { TreeViewId: TreeViewId,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  countFolderFiles(TreeViewId): Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/COUNT_FOLDER_FILES", { TreeViewId: TreeViewId ,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  downloadManyFiles(fileArray): Observable<Blob>{
    return this.httpclient.post(this.baseurl + 'HR_TREE_UPLOAD/DOWNLOAD_MANY_FILES', { fileArray: fileArray,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId}, { responseType: 'blob'})
  }

  deleteManyFiles(clickedFilesArray):Observable<any[]> {
    return this.httpclient.post<any[]>(this.baseurl + "HR_TREE_UPLOAD/DELETE_MANY_FILES", { clickedFilesArray: clickedFilesArray,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId ,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  getTotalFolderCount(): Observable<HttpResponse<any[]>>{
return this.httpclient.get<any>(this.baseurl + 'HR_TREE_UPLOAD/TOTAL_FOLDER_COUNT')
  }
  getTotalFileCount(): Observable<HttpResponse<any[]>>{
return this.httpclient.get<any>(this.baseurl + 'HR_TREE_UPLOAD/TOTAL_FILES_COUNT')
    
  }
  

  downloadFolderFiles(collectionPath: any): Observable<Blob>{
    return this.httpclient.post(this.baseurl + 'HR_TREE_UPLOAD/DOWNLOAD_FOLDER_FILES',{collectionPath:collectionPath,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId}, { responseType: 'blob' })
  }

  //API is not generated
  getCategory(): Observable<HttpResponse<any[]>>{
    return this.httpclient.get<any>(this.baseurl + 'HR_TREE_UPLOAD/GET_CATEGORY')
  }
  
//API is not generated
  getSubCategory(id:any): Observable<HttpResponse<any[]>>{
return this.httpclient.post<any>(this.baseurl +'HR_TREE_UPLOAD/GET_SUB_CATEGORY', {id:id,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId })
  }

  getCartList():Observable<any[]>{
    return this.httpclient.get<any[]>(this.baseurl + 'HR_TREE_UPLOAD/CART_NAME_LIST')
  }
  addToCartManyFiles(newCartName,clickedFilesArray: any):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + 'HR_TREE_UPLOAD/ADD_TO_CART_MANY_FILES',{newCartName:newCartName,clickedFilesArray:clickedFilesArray,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  addToCartSingleFile(newCartName,itemid, orgfilename, OriginalPath):Observable<any[]>{
    return this.httpclient.post<any[]>(this.baseurl + 'HR_TREE_UPLOAD/ADD_TO_CART_SINGLE_FILE',{newCartName:newCartName,itemid:itemid,orgfilename:orgfilename,OriginalPath:OriginalPath,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }

  showRejectedFiles(){
    return this.httpclient.get<any>(this.baseurl +'HR_TREE_UPLOAD/SHOW_REJECTED_FILES')
  }

  downloadRejectedFile(file_name:any):Observable<Blob>{
    return this.httpclient.post(this.baseurl +'HR_TREE_UPLOAD/DOWNLOAD_REJECTED_FILES',{file_name:file_name,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId},{ responseType: 'blob'})
  }

  showRejectedFilesAPI(){
    return this.httpclient.get<any>(this.baseurl +'HR_TREE_UPLOAD/SHOW_REJECTED_FILES')
  }
  showHrFilesListAPI(){
    return this.httpclient.get<any>(this.baseurl +'HR_TREE_UPLOAD/SHOW_HR_FILESLIST')
  }
  deleteRejectedFile(itemid:any){
    return this.httpclient.post<any>(this.baseurl +'HR_TREE_UPLOAD/DELETE_REJECTED_FILES',{itemid:itemid,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,CenterId:JSON.parse(localStorage.getItem("userdetails"))[0].CenterId})
  }
  getDirectoriesList(): Observable<HttpResponse<any[]>>{
    return this.httpclient.get<any>(this.baseurl + 'HR_TREE_UPLOAD/get_Directories')
  }
  
  fileViewdByUser(itemid,orgfilename){
    return this.httpclient.post(this.baseurl +"HR_TREE_UPLOAD/FILE_VIEW_BY",{itemid:itemid,orgfilename:orgfilename,userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
  }
}
 
export class agencymodel{
    CategoryId:string
        SubCategoryId: string
        CountryId:string
        StateId:string
        CityId:''
        EventId:string
        orgfilename:string
        OriginalPath:string
        itemfiename:string
        itemsize:string
        itemlength:string
        itemformat:string
        itemcaption:string
        itemkeyword:string
        location:string
        itemauthor:string
        eventdate:string
        startdate:Date
        endDate:Date
        itemrating:string
        resolution:string
        Caption:string
        ImageSize:string
        Keywords:string
        allvalues:string
        Udate:string
        AvgBitrate:string
        framerate:string
        Searching_Method:string
        Width:string
        Height:string
        itemid:number
        File_Status:string
        categoryname:string
        subcategoryname:string
        eventname:string
        countryname:string
        statename:string
        cityname:string
        itemfilename:string
        downloadcount:string
        length:string
        firstframe:string
        Source:string
        ImageName:string
        ImageKeyWords:string
        ImageCategory:string
        Country:string
        City:string
        ImageSource:string
        ImageCaption:string
        ImageLowresPath:string
        ImageId:string
        ImageHighresPath:string
        ImagePath:string
}
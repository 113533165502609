export class DocumentUploadFormModel{

    CategoryId:string
        SubCategoryId: string
        CountryId:string
        StateId:string
        CityId:''
        EventId:string
        orgfilename:string
        OriginalPath:string
        itemfiename:string
        itemsize:string
        itemlength:string
        itemformat:string
        itemcaption:string
        itemkeyword:string
        location:string
        itemauthor:string
        eventdate:string
        startdate:Date
        endDate:Date
        itemrating:string
        resolution:string
        Caption:string
        ImageSize:string
        Keywords:string
        allvalues:string
        Udate:string
        AvgBitrate:string
        framerate:string
        Searching_Method:string
        Width:string
        Height:string
        itemid:string
        File_Status:string
        categoryname:string
        subcategoryname:string
        eventname:string
        countryname:string
        statename:string
        cityname:string
        itemfilename:string
        downloadcount:string
        length:string
        firstframe:string
        shootdate:string
        creator:string
        countryid:string
        stateid:string
        cityid:string
        title:string
        Source:string
        categoryid:string
        subcategoryid:string
        recordcount:string
        source:string
        photogrphername:string
        copyright:string
        orietation:string
        shootdatemod:string
        itempermission:string
        itemtype:string
        HiresPath:string
        totalbitrate:string
        samplerate:string
        channels:string
        channeltype:string
        datarate:string
        bitrate:string
        fullstory:string
        aclby:string
        aclbyname:string
        acldate:string
        eventdate1:string
        collpath:string
        thumbpath:string
        composer:string
        storytype:string
        bysubject:string
        noofpages:any
        Pages:any
        magsection:any
        authorinput:any
        category:any
        subcategory:any
        eventid:any
}
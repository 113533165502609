import { Component, OnInit } from "@angular/core";
import { LoginService } from "../services/login/login.service";
import { AuthService } from "../services/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { MessageService } from "primeng/api";
import { UserserviceService } from "../services/masters/userservice.service";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  Loginid: string;
  Password: string;
  incorrectpass: number = 0;
  clientName: any;
  name: any;
  userDate: any;
  systemDate: string;
  show: number = 0;
  showLicenseExpiredDate: number = 0;
  file: any;
  display: boolean = false;
  licenceFile: any[] = [];
  licenceName: any;
  yearSystem: number;
  monthSystem: number;
  daySystem: number;
  machineId: any;
  fileData: any;
  clientDetail: any;
  ExpirydaysRemaining: any;
  Activestatus: any;
  private intervalId: any;
  minutes:number = environment.OtpTimerMin;   // Start at 5 minutes
  seconds: number = 0;     // Start at 0 seconds
  private timerInterval: any;
  // machineid: any
  constructor(
    private loginservice: LoginService,
    private authservice: AuthService,
    private route: Router,
    private messageservice: MessageService,
    private userser: UserserviceService
  ) {}

  ngOnInit() {
    
    // this.getMachineId();
    this.fileRead();
    this.intervalId = setInterval(() => {
      this.fileRead();
    }, 5000);

    this.route.events.subscribe((event) => {
      // console.log("Route events:", event);
      if (event instanceof NavigationEnd) {
        // console.log("event url before", event.url);
        if (event.url.includes("/login")) {
          // console.log("Navigation End Event:", event.url);
          this.fileRead();
        } else {
          this.clearInterval();
        }
      }
    });

    // this.getLicenceDetail()
    // accessign system current date
    var nowDate = new Date();
    this.yearSystem = nowDate.getFullYear();
    this.monthSystem = nowDate.getMonth() + 1;
    this.daySystem = nowDate.getDate();
    this.systemDate =
      nowDate.getFullYear() +
      "-" +
      (nowDate.getMonth() + 1) +
      "-" +
      nowDate.getDate();
    // console.log("systemDate ", this.systemDate);

  }

  ngOnDestroy() {
    this.clearInterval();
    this.clearTimer();
  }

  private clearInterval() {
    clearInterval(this.intervalId);
  }
  // getMachineId(){
  //   this.loginservice.getMachineId().subscribe((data: any)=>{
  //     // console.log("machine id", data);
  //     this.machineId = data

  //   })
  // }

  encryptText(value: any) {
    const key = "mams"; // SECRET KEY FOR ENCRYPTION
    value = value instanceof String ? value : JSON.stringify(value);
    const encrypted = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.CBC,
    }).toString();
    // console.log("encrypted",encrypted);

    return encrypted;
  }

  async LoginUser() {
    let location = window.location.hostname;
    // console.log("location",location);

    // if(location == environment.hostPreference)
    // {
    // let encryptedText = this.encryptText("amit katiyar");
    let encryptedPassword = await this.encryptText(this.Password);
    // let encryptedText = this.encryptText("amit katiyar");

    this.loginservice
      .LoginUser(this.Loginid, encryptedPassword)
      .subscribe((data: any) => {
        // console.log("login user", data);

        if (data == "Expired") {
          this.show = 1;
        } else if (data == "Exceded") {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "User limit has been exceded",
          });
        } else if (data == "Incorrect") {
          this.incorrectpass = 1;
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "User Name or Password not correct",
          });
        } else {
          var userdata = JSON.parse(data.split("µ")[0]);
          this.ExpirydaysRemaining = JSON.parse(data.split("µ")[2]);

          // var userdata=JSON.parse(data.split("µ")[0])
          // this.ExpirydaysRemaining=JSON.parse(data.split("µ")[2])
          //  console.log(this.ExpirydaysRemaining);
          // console.log("User data is", userdata);
          var center_id = userdata[0].CenterId;
          this.loginservice.setconfigvariable(center_id).subscribe((data) => {
            // console.log("enviornment path is", data[0]);
            const apiServerPath = data[0].virtual_path;
            const apiBarcodePath = data[0].virtual_path + "BarcodeImage/";
            environment.ApiServerPath = data[0].virtual_path;
            environment.ApiBarcodePath = data[0].virtual_path + "BarcodeImage/";
            environment.lowresThumbPath = data[0].lowresThumbPath;
            environment.download_path = data[0].download_path;
            environment.original_path = data[0].original_path;
            sessionStorage.setItem("ApiServerPath", apiServerPath);
            sessionStorage.setItem("lowresThumbPath", data[0].lowresThumbPath);
            sessionStorage.setItem("download_path", data[0].download_path);
            sessionStorage.setItem("original_path", data[0].original_path);
            sessionStorage.setItem("ApiBarcodePath", apiBarcodePath);

            // environment.downloadpathLocal = data[0].
            // console.log("Set config data is", environment.ApiServerPath);
          });

          //  console.log(this.ExpirydaysRemaining);
          this.loginservice
            .assignedpermission(userdata[0].UserId)
            .subscribe((data1: any[]) => {
              if (data1.length > 0) {
                this.loginservice
                  .getperference(userdata[0].UserId)
                  .subscribe((data2: any[]) => {
                    this.authservice.sendToken(
                      userdata,
                      data1,
                      data2,
                      data.split("µ")[1]
                    );
                    if (this.ExpirydaysRemaining <= 3) {
                      this.showLicenseExpiredDate = 1;
                    } else {
                      // console.log("User will be logged in from here - old");
                      this.route.navigate(["home"]);
                    }
                  });
              }
            });
        }
      });
    // }
  }

  private fileRead() {
    this.loginservice.fileRead().subscribe(
      (data: any) => {
        // console.log("file read data", data);
        this.fileData = data;
        this.Activestatus = "SERVER ONLINE";
      },
      (error) => {
        console.error("Error connecting to the server:", error);
        this.Activestatus = "SERVER OFFLINE";
      }
    );
  }

  // getLicenceDetail(){
  //   this.userser.getLicenceDetail().subscribe((data: any)=>{
  //     this.clientDetail = data
  //     console.log("this.clientName",this.clientDetail);
  //     this.clientName = this.clientDetail[0].itemfilename
  //     console.log("this.clientName",this.clientName);

  //   })
  // }

  showDialog() {
    this.display = true;
  }
  ContinueLogin() {
    this.route.navigate(["home"]);
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.licenceFile = event.target.files[0];
      this.licenceName = event.target.files[0].name;
    }
  }

  activateLicence() {
    // console.log("licence file", this.licenceFile);
    this.loginservice
      .uploadlicenseFile(this.licenceFile, this.licenceName)
      .subscribe((data: any) => {
        if (data != 0) {
          // console.log("upload file")
          // this.messageservice.add({ key: 'message', severity:'success', summary: 'Upload Message', detail:'Licence file uploaded'});
          this.loginservice.licenseActivate().subscribe((data: any) => {
            // console.log(data);
            if (data != 0) {
              // console.log("submitted")
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Licence renewed, Enjoy the product",
              });
            }
          });
        }
      });
  }

  otpSent = false;
  otp = "";
  isLoading: boolean = false;
  sendotp() {
    // console.log("send otp userid", this.Loginid);

    this.isLoading = true;

    this.loginservice.sendOtp(this.Loginid).subscribe(
      (response: any) => {
        // console.log("otp response log",response);
        
        if (response.success) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "OTP sent to your email.",
          });
          this.otpSent = true;
          this.startTimer();
        } else if (response.message == "Invalid User") {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Invalid User",
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "Failed to send OTP. Try again.",
          });
        }
        this.loginservice.loginDetails(this.Loginid,response.value).subscribe((Logindata:any) => {
          // console.log("logindetails submitted",Logindata);
        });
        this.isLoading = false;
      },
      (err) => {
        // console.log("new errrrrr",err);
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Please enter correct email.",
        });
        this.isLoading = false;
      }
    );
  }

  tokenkey = environment.TokenKey;
  verifyOTP() {
    var obj = {
      email: this.Loginid,
      otp: this.otp,
    };

    this.loginservice.verifyOtp(obj).subscribe(
      (response: any) => {
        // console.log("verify otp service", response);
        if (response.valid) {
          // this.route.navigate(["home"]);

          this.loginservice.LoginUser(obj.email, "").subscribe((data: any) => {
            // console.log("login user", data);

            if (data == "Expired") {
              this.show = 1;
            } else if (data == "Exceded") {
              this.messageservice.add({
                key: "message",
                severity: "warn",
                summary: "Warning Message",
                detail: "User limit has been exceded",
              });
            } else if (data == "User Not Exists") {
              this.loginservice
                .insertNewUserAuto(obj.email)
                .subscribe((dataa: any) => {
                  if (dataa.Alert == "Created") {
                    this.loginservice
                      .LoginUser(obj.email, "")
                      .subscribe((data: any) => {
                        const userdata = JSON.parse(data.split("µ")[0]);
                        this.ExpirydaysRemaining = JSON.parse(
                          data.split("µ")[2]
                        );
                        const tokenNumber = data.split("µ")[3];
                        const center_id = userdata[0].CenterId;
                        this.loginservice
                          .setconfigvariable(center_id)
                          .subscribe((data) => {
                            // console.log("enviornment path is", data[0]);
                            const apiServerPath = data[0].virtual_path;
                            const apiBarcodePath =
                              data[0].virtual_path + "BarcodeImage/";
                            environment.ApiServerPath = data[0].virtual_path;
                            environment.ApiBarcodePath =
                              data[0].virtual_path + "BarcodeImage/";
                            environment.lowresThumbPath =
                              data[0].lowresThumbPath;
                            environment.download_path = data[0].download_path;
                            environment.original_path = data[0].original_path;
                            sessionStorage.setItem(
                              "ApiServerPath",
                              apiServerPath
                            );
                            sessionStorage.setItem(
                              "lowresThumbPath",
                              data[0].lowresThumbPath
                            );
                            sessionStorage.setItem(
                              "download_path",
                              data[0].download_path
                            );
                            sessionStorage.setItem(
                              "original_path",
                              data[0].original_path
                            );
                            sessionStorage.setItem(
                              "ApiBarcodePath",
                              apiBarcodePath
                            );
                          });

                        this.loginservice
                          .assignedpermission(userdata[0].UserId)
                          .subscribe((data1: any[]) => {
                            if (data1.length > 0) {
                              this.loginservice
                                .getperference(userdata[0].UserId)
                                .subscribe((data2: any[]) => {
                                  this.authservice.sendToken(
                                    userdata,
                                    data1,
                                    data2,
                                    data.split("µ")[1]
                                  );
                                  if (this.ExpirydaysRemaining <= 3) {
                                    this.showLicenseExpiredDate = 1;
                                  } else {
                                    this.route.navigate(["home"]);
                                    localStorage.setItem("uniqueNumber",this.encrypt(tokenNumber));
                                  }
                                });
                            }
                          });
                      });
                  }
                });
            } else {
              const userdata = JSON.parse(data.split("µ")[0]);
              this.ExpirydaysRemaining = JSON.parse(data.split("µ")[2]);
              const tokenNumber = data.split("µ")[3];

              const center_id = userdata[0].CenterId;
              this.loginservice
                .setconfigvariable(center_id)
                .subscribe((data) => {
                  // console.log("enviornment path is", data[0]);
                  const apiServerPath = data[0].virtual_path;
                  const apiBarcodePath = data[0].virtual_path + "BarcodeImage/";
                  environment.ApiServerPath = data[0].virtual_path;
                  environment.ApiBarcodePath =
                    data[0].virtual_path + "BarcodeImage/";
                  environment.lowresThumbPath = data[0].lowresThumbPath;
                  environment.download_path = data[0].download_path;
                  environment.original_path = data[0].original_path;
                  sessionStorage.setItem("ApiServerPath", apiServerPath);
                  sessionStorage.setItem(
                    "lowresThumbPath",
                    data[0].lowresThumbPath
                  );
                  sessionStorage.setItem(
                    "download_path",
                    data[0].download_path
                  );
                  sessionStorage.setItem(
                    "original_path",
                    data[0].original_path
                  );
                  sessionStorage.setItem("ApiBarcodePath", apiBarcodePath);
                  // console.log("Set config data is", environment.ApiServerPath);
                });

              this.loginservice
                .assignedpermission(userdata[0].UserId)
                .subscribe((data1: any[]) => {
                  if (data1.length > 0) {
                    this.loginservice
                      .getperference(userdata[0].UserId)
                      .subscribe((data2: any[]) => {
                        this.authservice.sendToken(
                          userdata,
                          data1,
                          data2,
                          data.split("µ")[1]
                        );
                        if (this.ExpirydaysRemaining <= 3) {
                          this.showLicenseExpiredDate = 1;
                        } else {
                          this.route.navigate(["home"]);
                          localStorage.setItem("uniqueNumber",this.encrypt(tokenNumber));
                          // console.log("final org token",tokenNumber);
                          // localStorage.setItem("uniqueNumber",tokenNumber);
                        }
                      });
                  }
                });
            }
          });
        } else {
          this.messageservice.add({
            key: "message",
            severity: "warn",
            summary: "Warning Message",
            detail: "There's some error occured. Try Again!",
          });
        }
      },
      (error) => {
        console.error(error);
        this.messageservice.add({
          key: "message",
          severity: "warn",
          summary: "Warning Message",
          detail: "Invalid OTP. Try Again!",
        });
      }
    );
  }


  startTimer() {
    this.clearTimer(); // Ensure no other timer is running
    this.timerInterval = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.clearTimer(); // Stop the timer
          // this.redirectToHome(); // Redirect when the timer hits 0
          this.otpSent = false
          // this.route.navigate(["login"]); 
          window.location.reload();
        } else {
          this.minutes--; // Decrement minutes
          this.seconds = 59; // Reset seconds to 59
        }
      } else {
        this.seconds--; // Decrement seconds
      }
    }, 1000); // Update every second
  }


  clearTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval); // Clear the interval
      this.timerInterval = null;
    }
  }

  redirectToHome() {
    console.log('Redirecting to home page...');
  }
  
  fixedKey = JSON.parse(environment.TokenKey);
  encrypt(str:any) {
    let result = '';
    
    for (let i = 0; i < str.length; i++) {
        let charCode = str.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) { // uppercase letters (A-Z)
            result += String.fromCharCode((charCode - 65 + this.fixedKey[i % this.fixedKey.length]) % 26 + 65);
        } else if (charCode >= 97 && charCode <= 122) { // lowercase letters (a-z)
            result += String.fromCharCode((charCode - 97 + this.fixedKey[i % this.fixedKey.length]) % 26 + 97);
        } else {
            result += str[i]; // non-alphabetic characters remain unchanged
        }
    }
    
    return result; // Return both encrypted string and fixed key
}


  


}

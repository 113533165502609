import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  HostListener,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { MessageService, TreeNode } from "primeng/api";
import { Subcategoryformmodel } from "src/app/model/master/subcategory.form.model";
import { CategoryService } from "src/app/services/masters/category.service";
import { SubCategoryService } from "src/app/services/masters/sub-category.service";
import { categoryformmodel } from "src/app/model/master/category.form.model";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { formatDate } from "@angular/common";
import { TreeviewConfig } from "ngx-treeview";
import { TreedropdownComponent } from "src/app/masters/treedropdown/treedropdown.component";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-videoeditform",
  templateUrl: "./videoeditform.component.html",
  styleUrls: ["./videoeditform.component.css"],
})
export class VideoeditformComponent implements OnInit {
  @Output() CloseDialogUpdate = new EventEmitter();
  Document_Edit_form: FormGroup;
  documentuploadinformation: DocumentUploadFormModel;
  categorylist: categoryformmodel[] = [];
  subcategorylist: Subcategoryformmodel[] = [];
  eventlist: Subcategoryformmodel[] = [];
  idforupdate: string = "";
  categoryid: number = 0;
  collpath: string = "";
  pdftypelst: any;
  filteredkeywords: any[];
  allkeywords: any[];
  glbkeywordsgst: string = "";
  keywords: any[] = [];
  fileformt: string = "";
  items: any[] = [];
  values: number[];
  orgnamearr: string = "";
  categoryname: any[] = [];
  videourl: string = "";
  isvideoprvw: boolean = true;
  ApiServerPath: any;
  constructor(
    public formBuilder: FormBuilder,
    public documentservice: DocumentuploadService,
    private messageservice: MessageService,
    public categoryService: CategoryService,
    public subcategoryService: SubCategoryService,
    public dashboarservice: DashboardService,
    public videoser: VideouploadService
  ) {}

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    // console.log("Server path is", this.ApiServerPath);
    this.createvideoeditform();
    this.getcategorydata();
    this.getSubcategorydata();
    this.geteventnamedata();
  }

  @Input()
  selecttab: string;
  @Input()
  selectValue: string;
  @Input()
  orgname: string;
  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectValue"]) {
      if (this.selectValue != "") {
        this.idforupdate = this.selectValue;
        this.orgnamearr = this.orgname;
        this.getdocumentdata(this.idforupdate, this.selecttab);
      }
    }
  }

  getdocumentdata(itemid, path) {
    var ismultiple = false;
    this.isvideoprvw = true;
    if (itemid.split("~").length > 2) {
      ismultiple = true;
      this.isvideoprvw = false;
    }
    if (itemid.indexOf("~") > -1) {
      itemid = itemid.split("~")[0];
    }
    this.dashboarservice
      .getvideodata(itemid.trim(), path)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.updatedocument(data[0]);
          if (ismultiple == false) {
            this.videourl = this.ApiServerPath + data[0].HiresPath;
          }
        }
      });
  }

  onDataSelection(e) {
    alert(e);
  }

  createvideoeditform() {
    this.Document_Edit_form = this.formBuilder.group({
      itemid: [""],
      title: [""],
      itemcaption: [""],
      itemauthor: [""],
      noofpages: [""],
      Pages: [""],
      magsection: [""],
      bysubject: [""],
      authorinput: [""],
      source: [""],
      storytype: ["0"],
      itemkeyword: [""],
      Photographer: [""],
      eventdate: [""],
      itemrating: [""],
      itemfilename: [""],
      orgpath: [""],
      eventname: [""],
      copyright: [""],
      category: [""],
      subcategory: [""],
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: [""],
      location: [""],
    });
  }
  updatedocument(documenteditlist) {
    //this.getSubcategorydata(documenteditlist.categoryid)
    if (
      this.idforupdate.split("~")[1] == "" ||
      this.idforupdate.split("~")[1] == undefined
    ) {
      this.collpath = documenteditlist.collpath;
    } else {
      documenteditlist.orgfilename = "";
      this.collpath = "";
    }
    this.categoryname = [];
    if (documenteditlist.categoryname != "") {
      for (let i = 0; i < documenteditlist.length; i++) {
        this.categoryname.push({
          categoryname: documenteditlist.categoryname
            .split(";")
            [i].toString()
            .trim(),
        });
      }
    }

    this.Document_Edit_form.setValue({
      itemid: documenteditlist.itemid,
      title: documenteditlist.title,
      itemcaption: documenteditlist.itemcaption,
      itemauthor: documenteditlist.itemauthor,
      noofpages: "",
      Pages: "",
      magsection: "",
      bysubject: "",
      authorinput: "",
      source: documenteditlist.Source,
      storytype: "",
      itemkeyword: documenteditlist.itemkeyword,
      Photographer: "",
      eventname: documenteditlist.eventid,
      copyright: documenteditlist.copyright,
      eventdate: documenteditlist.eventdate,
      itemrating: documenteditlist.itemrating,
      itemfilename: documenteditlist.orgfilename,
      orgpath: documenteditlist.OriginalPath,
      userid: JSON.parse(localStorage.getItem("userdetails"))[0].UserId,
      alldata: documenteditlist.MergeMultipleCol,
      category: documenteditlist.categoryid,
      subcategory: documenteditlist.subcategoryid,
      location: documenteditlist.location,
    });
    // const titleel=document.getElementById("videottitle") as HTMLInputElement
    // titleel.focus();
  }

  SaveAfterUpdate(event: any) {
    this.documentuploadinformation = this.Document_Edit_form.value;
    this.documentuploadinformation.itemid = this.idforupdate;
    this.documentuploadinformation.orgfilename = this.orgnamearr;
    if (this.documentuploadinformation.eventdate == "") {
      this.documentuploadinformation.eventdate = formatDate(
        new Date(),
        "yyyy-MM-dd",
        "en"
      );
    }

    // if (this.documentuploadinformation.storytype == undefined || this.documentuploadinformation.storytype == "0") {
    //   this.documentuploadinformation.storytype = ""
    // }
    // var tmpkeywords = ""
    // for (let i = 0; i < this.keywords.length; i++) {
    //   tmpkeywords += this.keywords[i].keywordname + ";"
    // }
    // this.documentuploadinformation.itemkeyword = tmpkeywords.trim().substring(0, tmpkeywords.trim().length - 1)

    this.videoser
      .UpdateVideo(this.documentuploadinformation)
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Successfully Updated",
          });
          this.cleardata();
          this.CloseDialogUpdate.emit(event);
        }
      });
  }

  getcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.categorylist = data;
      }
    });
  }
  getSubcategorydata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAllSubCategory().subscribe((data: any[]) => {
      if (data != null) {
        this.subcategorylist = data;
      }
    });
  }
  geteventnamedata() {
    //this.userinfomation=new userformmodel();
    this.categoryService.getAlleventname().subscribe((data: any[]) => {
      if (data != null) {
        this.eventlist = data;
      }
    });
  }

  cleardata() {
    this.Document_Edit_form.reset();
    //this.Document_Edit_form.patchValue({acldaterange:this.today})
    //this.Document_Edit_form.patchValue({itempermission:"1"})
    this.Document_Edit_form.patchValue({ storytype: "0" });
    this.glbkeywordsgst = "";
    this.keywords = [];
    this.selectValue = "";
  }

  // getallkeyword(){
  //   this.dashboadservice.getkeywords("").subscribe((data:any[])=>{
  //     if(data.length>0){
  //       this.allkeywords=data
  //     }
  //   })
  // }

  filtercategory(e) {
    if (e.query != "") {
      this.glbkeywordsgst = e.query;
      this.categoryService.getAllCategory().subscribe((data: any[]) => {
        if (data.length > 0) {
          this.categorylist = data;
        }
      });
    }

    let query = e.query;
    this.filteredkeywords = this.filterCountry(query, this.categorylist);
  }
  filterCountry(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (
        country.CategoryName.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(country);
      }
    }
    return filtered;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { keywordmodule } from 'src/app/model/master/keyword.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class keywordService {

  keywordFormModel:keywordmodule=new keywordmodule();
  baseurl=environment.ApiUrl;
  
  constructor(private httpclient:HttpClient) { }

      Getkeyword():Observable<keywordmodule[]>{
        return this.httpclient.get<keywordmodule[]>(this.baseurl+"keywords/getkeywords")
      }
      Createkeyword(keywordinfo:keywordmodule):Observable<keywordmodule[]>{
        return this.httpclient.post<keywordmodule[]>(this.baseurl + "keywords/insertkeyword",keywordinfo)
      }
      Deletekeyword(id:number):Observable<keywordmodule[]>{
        return this.httpclient.post<keywordmodule[]>(this.baseurl + "keywords/deletekeyword",{id:id,userid:JSON.parse(localStorage.getItem("userdetails"))[0].UserId})
      }
      Updatekeyword(keywordinfo:keywordmodule):Observable<keywordmodule[]>{
        return this.httpclient.post<keywordmodule[]>(this.baseurl + "keywords/updatekeyword",keywordinfo)
      }
      checkduplicate(keywordinfo:keywordmodule):Observable<keywordmodule[]>{
        return this.httpclient.post<keywordmodule[]>(this.baseurl + "keywords/checkduplicate",keywordinfo)
      }
      searchkeyword(keyword):Observable<keywordmodule[]>{
        return this.httpclient.post<keywordmodule[]>(this.baseurl + "keywords/searchkeyword",{keyword:keyword})
      }
    }

import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { PermissionModel } from "src/app/model/master/Permission.model";
import { DocumentuploadService } from "src/app/services/files/documentupload.service";
import { ConfirmationService, MessageService, MenuItem } from "primeng/api";
import { downloadimagesmodel } from "src/app/model/file/downloadimagemodel";
import { DocumentUploadFormModel } from "src/app/model/file/documentUploadModel";
import { AudiouplaodService } from "src/app/services/files/audiouplaod.service";
import { audiouploadModel } from "src/app/model/file/audiouploadModel";
import { ImageuploadService } from "src/app/services/files/imageupload.service";
import Cropper from "cropperjs";
import { DomSanitizer } from "@angular/platform-browser";
import { VideoUploadModel } from "src/app/model/file/VideoUploadModel";
import { VideouploadService } from "src/app/services/files/videoupload.service";
import { exportConfigurationModel } from "src/app/model/file/exportconfigurationmodel";
import { StoryFormModel } from "src/app/model/file/storyformModel";
import { StoryserviceService } from "src/app/services/files/storyservice.service";
import { attachmentFormModel } from "src/app/model/file/attachmentFormModel";
import { ImageUploadFormModel } from "src/app/model/file/imgaeuploadformModel";
import { environment } from "src/environments/environment";
import { DashboardService } from "src/app/services/files/dashboard.service";
import { ActivatedRoute } from "@angular/router";
import {
  DataViewModule,
  DataViewLayoutOptions,
  DataView,
} from "primeng/dataview";
import { TraneventsService } from "src/app/services/files/tranevents.service";

@Component({
  selector: "app-masterevents",
  templateUrl: "./masterevents.component.html",
  styleUrls: ["./masterevents.component.css"],
})
export class MastereventsComponent implements OnInit {
  @ViewChild("cropimagecanvas", { static: false }) cropimagecanvas: ElementRef;
  @ViewChild("audio", { static: true }) Player: ElementRef;
  @ViewChild("dv", { static: false }) dataview1: DataView;
  ctx: CanvasRenderingContext2D;
  audioimage: string = "assets/image/Wave_bg.jpg";
  elementarr: ImageUploadFormModel[];
  selectedIndexs: ImageUploadFormModel[] = [];
  selectedIndexstype: any[] = [];
  selectedIndexsid: any[] = [];
  permissionlist: PermissionModel[];
  downloaditems: MenuItem[];
  showfilter: boolean = false;
  downloadbtn: number = 0;
  imgcrppbtn: number = 0;
  imgdownloadbtn: number = 0;
  videoexportbtn: number = 0;
  videocropbtn: number = 0;
  storydownloadbtn: number = 0;
  audiodownloadbtn: number = 0;
  pdfimages: any[];
  viewDocument: boolean = false;
  glbDeletedoc: number = 0;
  downloadDocumentList: downloadimagesmodel[] = [];
  showDocumentDownload: boolean = false;
  text: string;
  fullDocumentpathlist: DocumentUploadFormModel;
  ratingitemdoc: any;
  downloadAudioList: downloadimagesmodel[] = [];
  showAudioDownload: boolean = false;
  fullAudiolist: audiouploadModel;
  ratingitemaudio: any;
  Audiopreviewpath: string;
  viewAudio: boolean = false;
  private cropper: Cropper;
  public imageDestination = "";
  ButtonStatusimg = "Download";
  ApiServerPath = environment.ApiServerPath;
  @ViewChild("image", { static: false }) public imageElement: ElementRef;
  downloadListimg: downloadimagesmodel[] = [];
  showDownloadimg: boolean = false;
  fullpathlist: ImageUploadFormModel;
  ratingitemimg: any;
  imagePath: any;
  view: boolean = false;
  @ViewChild("video", { static: true }) private videoElement: ElementRef;
  @ViewChild("videogif", { static: true }) private videogif: ElementRef;
  ratingitemvideo: any;
  fullvideolist: VideoUploadModel;
  viewVideo: boolean = false;
  Buttons: string = "Play";
  durationarray = [];
  ButtonStatus: string = "MarkIn";
  playStatus: string = "Pause";
  volumeStatus: string = "unmute";
  markinvl = 1;
  markoutval = 1;
  MarkInValue;
  MarkOutValue;
  totalDuration;
  markinArray = [];
  totaldurarray = [];
  minutes: any;
  minutes_d: any;
  ExportList: exportConfigurationModel[] = [];
  downloadVideoList: downloadimagesmodel[] = [];
  exportitemid: number;
  exportitemname: string;
  videopreviewpath: string;
  video: string;
  showVideoDownload: boolean = false;
  fullstorylist: StoryFormModel;
  headline: string;
  ratingitemstory: any;
  viewStory: boolean = false;
  selectedAttachmentlist: attachmentFormModel[] = [];
  attachmentlist: attachmentFormModel[] = [];
  srchtext: string = "";
  brightness: number = 0;
  contrast: number = 0;
  saturate: number = 0;
  watermark: string = "India Today";
  metadatadiv: number = 1;
  editdiv: number = 0;
  grin: string = "";
  totalcount: string;
  advnsrchfil: any;
  loading: boolean = true;
  watermarkposition: string = "topright";
  showeditor: boolean = false;
  iframepath: string = "";
  idforimageedit: string = "";
  docfileforup: string = "";
  docidforedit: string = "";
  DocumentUpdated: boolean = false;
  ImageUpdated: boolean = false;
  imagenameforedit: string = "";
  contextmenuitem: MenuItem[];
  pdfpath: string = "";
  showmax: number = 0;
  showmin: number = 1;
  cartname: string;
  cartitemid: string;
  cartitemtype: string;
  showcart: boolean = false;
  showcartbox: boolean = false;
  results: string[] = [];
  filteredBrands: any[];
  addtocartimage: number = 0;
  addtocartstory: number = 0;
  addtocartaudio: number = 0;
  addtocartvideo: number = 0;
  addtocartdoc: number = 0;
  editdocmeta: number = 0;
  editimgmeta: number = 0;
  selectedIndexspermis: any[] = [];
  constructor(
    private dashboarservice: TraneventsService,
    private documentservice: DocumentuploadService,
    private confirmationService: ConfirmationService,
    private messageservice: MessageService,
    private route: ActivatedRoute,
    public audiouploadservice: AudiouplaodService,
    public imageservice: ImageuploadService,
    public sanitizer: DomSanitizer,
    public videoservice: VideouploadService,
    private storyservice: StoryserviceService
  ) {}

  ngOnInit() {
    const savedApiServerPath = sessionStorage.getItem("ApiServerPath");
    if (savedApiServerPath) {
      this.ApiServerPath = savedApiServerPath;
    }
    this.checkpermissions();
    this.search();
    this.totalcount = localStorage.getItem("totalcount");
    this.srchtext = this.route.snapshot.paramMap.get("text");
    if (this.srchtext == null) {
      this.srchtext = "";
    }
    this.blindsearchwithdata("");
    this.fullDocumentpathlist = new DocumentUploadFormModel();
    this.fullAudiolist = new audiouploadModel();
    this.fullpathlist = new ImageUploadFormModel();
    this.fullvideolist = new VideoUploadModel();
    this.fullstorylist = new StoryFormModel();
    this.contextmenuitem = [
      {
        label: "Download",
        icon: "fa fa-download",
        command: (event) => {
          this.downloadmultiple();
        },
      },
      {
        label: "Add to cart",
        icon: "fa fa-shopping-cart",
        command: (event) => {
          this.showmulcartdiv();
        },
      },
    ];
  }

  async checkpermissions() {
    this.permissionlist = JSON.parse(localStorage.getItem("userpermissions"));
    for (let i = 0; i < this.permissionlist.length; i++) {
      if (this.permissionlist[i].taskid == 34) {
        this.downloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 51) {
        this.imgdownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 44) {
        this.imgcrppbtn = 1;
      }
      if (this.permissionlist[i].taskid == 45) {
        this.videoexportbtn = 1;
      }
      if (this.permissionlist[i].taskid == 46) {
        this.videocropbtn = 1;
      }
      if (this.permissionlist[i].taskid == 56) {
        this.storydownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 48) {
        this.audiodownloadbtn = 1;
      }
      if (this.permissionlist[i].taskid == 55) {
        this.addtocartdoc = 1;
      }
      if (this.permissionlist[i].taskid == 54) {
        this.addtocartimage = 1;
      }
      if (this.permissionlist[i].taskid == 49) {
        this.addtocartaudio = 1;
      }
      if (this.permissionlist[i].taskid == 50) {
        this.addtocartvideo = 1;
      }
      if (this.permissionlist[i].taskid == 57) {
        this.addtocartstory = 1;
      }
      if (this.permissionlist[i].taskid == 14) {
        this.editdocmeta = 1;
      }
      if (this.permissionlist[i].taskid == 11) {
        this.editimgmeta = 1;
      }
    }
  }

  cmshow() {
    this.contextmenuitem[0].disabled = false;
    this.contextmenuitem[1].disabled = false;
    if (
      this.downloadbtn == 0 ||
      this.imgdownloadbtn == 0 ||
      this.storydownloadbtn == 0 ||
      this.audiodownloadbtn == 0 ||
      this.videocropbtn == 0
    ) {
      this.contextmenuitem[0].disabled = false;
      for (let i = 0; i < this.selectedIndexstype.length; i++) {
        if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
          if (this.imgdownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
          if (this.videocropbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
          if (this.audiodownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
          if (this.storydownloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        } else {
          if (this.downloadbtn == 0) {
            this.contextmenuitem[0].disabled = true;
            break;
          }
        }
      }
    }
    if (
      this.addtocartdoc == 0 ||
      this.addtocartimage == 0 ||
      this.addtocartaudio == 0 ||
      this.addtocartvideo == 0 ||
      this.addtocartstory == 0
    ) {
      this.contextmenuitem[1].disabled = false;
      for (let i = 0; i < this.selectedIndexstype.length; i++) {
        if (this.selectedIndexstype[i].toLowerCase().trim() == "image") {
          if (this.addtocartimage == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "video") {
          if (this.addtocartvideo == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "audio") {
          if (this.addtocartaudio == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else if (this.selectedIndexstype[i].toLowerCase().trim() == "story") {
          if (this.addtocartstory == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        } else {
          if (this.addtocartdoc == 0) {
            this.contextmenuitem[1].disabled = true;
            break;
          }
        }
      }
    }
    for (let i = 0; i < this.selectedIndexspermis.length; i++) {
      if (this.selectedIndexspermis[i] == 0) {
        this.contextmenuitem[0].disabled = true;
        this.contextmenuitem[1].disabled = true;
        break;
      }
    }
  }

  blindsearch(type) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.srchtext != "") {
      this.blindsearchwithdata(type);
    } else {
      environment.globalsearchtext = "";
      environment.glbsearchstring = "";
      var tbastr = localStorage.getItem("tabperstring");
      this.dashboarservice.blindsearch(tbastr).subscribe((data: any[]) => {
        if (data != null) {
          data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
          this.elementarr = data;
          this.loading = false;
          this.totalcount = localStorage.getItem("totalcount");
        }
      });
      this.loading = false;
    }
    this.loading = false;
  }

  public ExtractSearchingKeyword(_TextVal: string): string {
    let MergeParamater: string = "";
    let Paramater: string = "";
    let split_Parma: string[];
    let startval: number = 1;
    let startind: number = 1;
    let endindex: number = 1;
    let Searchingparam: string = "";
    try {
      Paramater = _TextVal;
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\  /gi, " ");
      Paramater = Paramater.replace(/\,/gi, " ");
      Paramater = Paramater.replace(/\+/gi, " ");
      if (Paramater.indexOf('"') >= 0) {
        Paramater = Paramater.replace(/\"/gi, "~");
        while (1) {
          startind = Paramater.indexOf("~", startval - 1) + 1;
          if (startind <= 0) {
            break;
          }

          endindex = Paramater.indexOf("~", startind) + 1;
          if (endindex <= 0) {
            break;
          }

          if (Searchingparam.trim() == "") {
            Searchingparam = Paramater.substring(
              startind - 1,
              startind + (endindex - startind + 1)
            ).trim();
            Paramater = Paramater.split(Searchingparam).join("");
            Paramater = Paramater.trim();
          } else {
            Searchingparam = (
              Searchingparam +
              "+" +
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).trim();
            Paramater = Paramater.split(
              Paramater.substring(
                startind - 1,
                startind + (endindex - startind + 1)
              )
            ).join("");
          }

          // startval = endindex
        }

        if (Searchingparam != "") {
          MergeParamater = Searchingparam.replace(/\~/gi, "");
        }

        if (Paramater.trim() != "") {
          if (MergeParamater == "") {
            MergeParamater = Paramater.trim().replace(/\ /gi, "+");
          } else {
            MergeParamater =
              MergeParamater + ("+" + Paramater.trim().replace(/\ /gi, "+"));
          }
        }

        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        //  split_Parma = MergeParamater.Split("+")
      } else {
        MergeParamater = Paramater.replace(/\ /gi, "+");
        MergeParamater = MergeParamater.split("+++").join("+");
        MergeParamater = MergeParamater.split("++").join("+");
        // split_Parma = Paramater.Split(" ")
      }
    } catch (ex /*:Exception*/) {
      MergeParamater = _TextVal;
    }

    return MergeParamater;
  }

  blindsearchwithdata(type) {
    this.loading = true;
    if (this.dataview1 != undefined) {
      this.dataview1.first = 0;
    }
    if (this.srchtext == "undefined") {
      this.srchtext = "";
      this.newadvancesearch(environment.glbsearchstring);
    } else {
      if (this.srchtext != "") {
        environment.globalsearchtext = this.srchtext;
        environment.glbsearchstring = "";
        var srtxt = this.ExtractSearchingKeyword(this.srchtext);
        var tbastr = localStorage.getItem("tabperstring");
        this.dashboarservice
          .blindsearchwithdata(srtxt, "", tbastr)
          .subscribe((data: any[]) => {
            if (data != null) {
              if (type == "") {
                data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                this.elementarr = data;
                this.totalcount = this.elementarr.length.toString();
                this.loading = false;
              } else {
                this.totalcount = data[0];
              }

              //this.totalcount=this.elementarr.length.toString()
            }
          });
      } else {
        if (environment.glbsearchstring != "") {
          this.newadvancesearch(environment.glbsearchstring);
        } else if (environment.globalsearchtext != "") {
          this.srchtext = environment.globalsearchtext;
          var srtxt = this.ExtractSearchingKeyword(this.srchtext);
          var tbastr = localStorage.getItem("tabperstring");
          this.dashboarservice
            .blindsearchwithdata(srtxt, "", tbastr)
            .subscribe((data: any[]) => {
              if (data != null) {
                if (type == "") {
                  data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
                  this.elementarr = data;
                  this.totalcount = this.elementarr.length.toString();
                  this.loading = false;
                } else {
                  this.totalcount = data[0];
                }
                //this.totalcount=this.elementarr.length.toString()
              }
            });
        } else {
          this.blindsearch("");
        }
      }
    }
    this.loading = false;
  }
  settooltip(e) {
    $(function () {
      (<any>$('[data-toggle="tooltip"]')).tooltip();
    });
  }

  getcount() {
    var tbastr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .getncount(this.srchtext, tbastr)
      .subscribe((data: any[]) => {
        if (data != null) {
          this.totalcount = data.toString();
        }
      });
  }

  clearfilters() {
    environment.glbsearchstring = "";
    this.srchtext = "";
    environment.globalsearchtext = "";
    this.blindsearch("");
    this.totalcount = localStorage.getItem("totalcount");
  }
  // searchalldata(formvalues,fn){
  //   //this.advnsrchfil=formvalues
  //   if(this.dataview1 !=undefined){
  //     this.dataview1.first=0
  //   }
  //   this.srchtext=""
  //   environment.globalsearchtext=""
  //   if (formvalues.itemkeyword != null) {
  //     formvalues.itemkeyword = formvalues.itemkeyword.replace(new RegExp(",", 'g'), " ")
  //   }
  //   this.hideadvncsrch()
  //   if (formvalues.CategoryId == 'null') {
  //     formvalues.CategoryId = null
  //     formvalues.SubCategoryId = null
  //   }
  //   if(fn !=""){
  //   this.dashboarservice.advancesearch(formvalues).subscribe((data: any[]) => {
  //     if (data.length > 0) {
  //       data.sort((a,b) => b.Udate1.localeCompare(a.Udate1));
  //       this.elementarr = data
  //       if(formvalues.totalcount !=''){
  //         if(formvalues.totalcount !=undefined){
  //           this.totalcount=formvalues.totalcount
  //         }
  //         else{
  //           this.totalcount=this.elementarr.length.toString()
  //         }

  //       }
  //       else{
  //         this.totalcount=this.elementarr.length.toString()
  //       }

  //     }
  //     else {
  //       this.elementarr = []
  //     }
  //   })
  // }
  // else{
  //   this.blindsearch('')
  // }

  // this.loading=false
  // }

  advanceSearch() {
    this.showfilter = true;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "none";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "block";
  }

  hideadvncsrch() {
    this.showfilter = false;
    var el = document.querySelector(".downbtnadvnc");
    el["style"].display = "block";
    var el1 = document.querySelector(".upbtnadvnc");
    el1["style"].display = "none";
  }

  newadvancesearch(srctxt) {
    this.loading = true;
    environment.glbsearchstring = srctxt;
    this.hideadvncsrch();
    var tbastr = localStorage.getItem("tabperstring");
    this.dashboarservice
      .newadvancesearch(srctxt, tbastr)
      .subscribe((data: any[]) => {
        if (data != null) {
          data.sort((a, b) => b.Udate1.localeCompare(a.Udate1));
          this.elementarr = data;
          this.totalcount = this.elementarr.length.toString();
        } else {
          this.elementarr = [];
        }
        this.loading = false;
      });
  }

  public setRow(_index, e) {
    //_index=Number(_index)
    if (e.ctrlKey == true || e.metaKey == true) {
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        this.selectedIndexstype.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[1]
        );
        this.selectedIndexsid.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[0]
        );
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexstype.splice(index, 1);
        this.selectedIndexsid.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  public setRow1(_index, e) {
    if (this.selectedIndexs.length < 2) {
      //_index=Number(_index)
      this.removeselection();
      if (this.selectedIndexs.indexOf(_index) === -1) {
        this.selectedIndexs.push(_index);
        this.selectedIndexstype.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[1]
        );
        this.selectedIndexsid.push(
          document
            .getElementById(_index)
            .children[0].innerHTML.toString()
            .split("~")[0]
        );
        var per = document.getElementById(_index).children[1].innerHTML.trim();
        this.selectedIndexspermis.push(Number(per));
        document.getElementById(_index).style.background = "orange";
      } else {
        let index = this.selectedIndexs.indexOf(_index);
        this.selectedIndexs.splice(index, 1);
        this.selectedIndexstype.splice(index, 1);
        this.selectedIndexsid.splice(index, 1);
        this.selectedIndexspermis.splice(index, 1);
        document.getElementById(_index).style.background = "transparent";
      }
    }
  }

  async downloadmultiple() {
    var x = document.querySelector(".downloadinggif1");
    x["style"].display = "block";
    var y = document.querySelector(".downloadback");
    y["style"].display = "block";
    var lst = this.selectedIndexs;
    var rtnarr = [];
    if (lst.length > 1) {
      for (let i = 0; i < lst.length; i++) {
        //await this.downloadonebyone(lst[i])
        //await this.sleep(2000)
        rtnarr.push({
          filepath: lst[i],
          filename: lst[i].toString().trim().split("/")[
            lst[i].toString().trim().split("/").length - 1
          ],
        });
        if (i == lst.length - 1) {
          await this.sleep(1000);
          this.downloadmultiplezip(rtnarr);
        }
      }
    } else {
      this.downloadonebyone(lst[0], lst);
    }
  }

  downloadmultiplezip(arr) {
    this.documentservice.downloadmultiplefn(arr).subscribe((data: any) => {
      // if (data != null) {
      //   this.downloadzipflder(data)
      // }
      if (data.size > 0) {
        saveAs(data, `${data.size}.zip`);
      }
    });
  }

  async downloadonebyone(filepath, itemid) {
    var fname = filepath.substring(
      filepath.lastIndexOf("/") + 1,
      filepath.length
    );
    let unsub = this.storyservice
      .Testdownload(fname, filepath, itemid)
      .subscribe((data: any) => {
        //await this.sleep(5000)
        if (data != null) {
          var data1 = data;
          var b64Data1 = data1.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: data1.split(";")[0].split(":")[1],
          });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = fname;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        unsub.unsubscribe();
        var x = document.querySelector(".downloadinggif1");
        x["style"].display = "none";
        var y = document.querySelector(".downloadback");
        y["style"].display = "none";
        this.removeselection();
        //this.downloadentry(itemid)
      });
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  downloadzipflder(filename) {
    var cartname = filename;
    filename = filename + ".zip";
    this.imageservice.testzipdownload(filename).subscribe((data: any) => {
      var x = document.querySelector(".downloadinggif1");
      //x.classList.add("downloadinggif")
      x["style"].display = "none";
      var y = document.querySelector(".downloadback");
      y["style"].display = "none";
      this.removeselection();
      if (data != null) {
        var b64Data1 = data.split(";")[1];
        const byteCharacters = atob(b64Data1);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/x-zip-compressed",
        });
        //let byteCharacters = atob(data);

        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = "Assets";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }
      this.imageservice.deletezip(cartname).subscribe((data: any[]) => {});
    });
  }

  removeselection() {
    for (let i = 0; i < this.selectedIndexs.length; i++) {
      document.getElementById(
        this.selectedIndexs[i].toString()
      ).style.background = "transparent";
      if (i == this.selectedIndexs.length - 1) {
        this.selectedIndexs.splice(0, this.selectedIndexs.length);
        this.selectedIndexstype.splice(0, this.selectedIndexstype.length);
        this.selectedIndexsid.splice(0, this.selectedIndexsid.length);
        this.selectedIndexspermis.splice(0, this.selectedIndexspermis.length);
      }
    }
  }

  pagenochange(e) {
    this.removeselection();
  }

  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const ESCAPE_KEYCODE = 17;
    if (event.ctrlKey == true || event.metaKey == true) {
      if (event.keyCode === 65) {
        this.removeselection();
        var x = document.getElementsByClassName("ui-dataview-content");
        for (let i = 0; i < x[0].children[0].children.length; i++) {
          this.setRow(x[0].children[0].children[i].id, event);
        }
      }
      if (event.keyCode === 81) {
        this.removeselection();
      }
    }
  }

  @HostListener("click", ["$event"]) onClick(event: KeyboardEvent) {
    if (event.ctrlKey == false && event.metaKey == false) {
      //this.removeselection()
    }
  }

  showfulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.remove("hidemanual");
    this.showmin = 1;
    this.showmax = 0;
  }

  hidefulldiv() {
    const x = document.querySelector(".ui-dialog-content");
    x.classList.add("hidemanual");
    this.showmin = 0;
    this.showmax = 1;
  }

  showcartdiv(id, type, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.cartitemid = id;
      this.cartitemtype = type;
      this.showcart = true;
    }
  }

  addtocart() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    if (this.cartname != null) {
      if (this.cartitemid != null) {
        const lst = [];
        const lst1 = [];
        lst.push(this.cartitemid);
        lst1.push(this.cartitemtype);
        this.imageservice
          .addtocartmul(this.cartname, lst, lst1, userid, "archieve")
          .subscribe((data: any) => {
            if (data != null) {
              this.messageservice.add({
                key: "message",
                severity: "success",
                summary: "Success Message",
                detail: "Add to Cart successfully",
              });
              this.clearcartname();
              this.search();
            }
          });
      } else {
        this.addtocartmultiple();
        this.search();
      }
    }
  }

  async addtocartmultiple() {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    var lst = this.selectedIndexsid;
    var lst1 = this.selectedIndexstype;
    this.imageservice
      .addtocartmul(this.cartname, lst, lst1, userid, "archieve")
      .subscribe((data: any) => {
        if (data != null) {
          this.messageservice.add({
            key: "message",
            severity: "success",
            summary: "Success Message",
            detail: "Add to Cart successfully",
          });
          this.clearcartname();
        }
      });
  }

  search() {
    this.results = [];
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice.getResults(userid).subscribe((data: any[]) => {
      if (data != null) {
        for (let i = 0; i < data.length; i++) {
          this.results.push(data[i].cartname);
        }
      }
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.results.length; i++) {
      let brand = this.results[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  clearcartname() {
    this.cartname = null;
    this.cartitemid = null;
    this.showcart = false;
    this.removeselection();
  }

  showmulcartdiv() {
    this.showcart = true;
  }

  //document popup//

  //function is not working
  downloadDocument(filename, filepath, itemid) {
    this.documentservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "audio/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        //this.downloadentry(itemid)
      });
  }
  downloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.documentservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  close() {
    this.viewDocument = false;
    this.showfulldiv();
  }

  showNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    // this.documentservice.GetDownloadedDocumentData(itemid).subscribe((data:any[])=>{
    //   if(data!=null){
    //     this.downloadDocumentList=data
    //     this.showDocumentDownload=true
    //   }
    // })
  }

  Textread(filename, filepath) {
    this.documentservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getpdfimg(itemid) {
    this.documentservice.getpdfimg(itemid).subscribe((data: any) => {
      if (data != null) {
        this.pdfimages = data;
      }
    });
  }

  showzoom(itemid, fname) {
    window.open("#/pdfpreview;id=" + itemid + ";fname=" + fname, "_blank");
  }

  getdocumentdata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.dashboarservice.getdocumentdata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullDocumentpopup(data[0]);
        }
      });
    }
  }

  fullDocumentpopup(documentList) {
    this.fullDocumentpathlist = documentList;
    this.ratingitemdoc = documentList.itemrating;
    this.pdfpath = this.ApiServerPath + documentList.OriginalPath;

    // if(this.fullDocumentpathlist.itemtype.toLowerCase() =="pdf"){
    // this.getpdfimg(this.fullDocumentpathlist.itemid)
    // }
    if (this.fullDocumentpathlist.itemtype.toLowerCase() == "txt") {
      this.Textread(documentList.itemfilename, documentList.OriginalPath);
    }
    this.viewDocument = true;
  }

  updatedocumentpopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.DocumentUpdated = true;
      this.docidforedit = itemid;
      this.docfileforup = name;
    }
  }

  hidedocumentedit() {
    this.DocumentUpdated = false;
    this.showfulldiv();
  }

  //document popup//
  //audio popup//
  audioshowNoOfDownload(itemid) {
    // this.getdowloadimagelist=downloadList
    // this.audiouploadservice.GetDownloadedAudioData(itemid).subscribe((data:any[])=>{
    //   if(data!=null){
    //     this.downloadAudioList=data
    //     this.showAudioDownload=true
    //   }
    // })
  }

  downloadAudio(filename, filepath, itemid) {
    this.audiouploadservice.Testdownload(filepath).subscribe((data: any) => {
      if (data != null) {
        var b64Data1 = data.split(";")[1];
        const byteCharacters = atob(b64Data1);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/*" });
        //let byteCharacters = atob(data);

        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
      //this.audiodownloadentry(itemid)
    });
  }

  audiodownloadentry(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.audiouploadservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }

  getaudiodata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.dashboarservice.getaudiodata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullAudiopopup(data[0]);
        }
      });
    }
  }

  fullAudiopopup(audioList) {
    this.Player.nativeElement.src = this.ApiServerPath + audioList.HiresPath;
    this.audioimage = "assets/image/Wave_bg.jpg";
    // this.fullAudiolist=null
    this.ratingitemaudio = audioList.itemrating;
    this.Audiopreviewpath = this.ApiServerPath + audioList.HiresPath;
    this.fullAudiolist = audioList;
    this.viewAudio = true;
  }

  closeaudio() {
    this.viewAudio = false;
    this.Player.nativeElement.src = "";
  }

  playfunction() {
    this.audioimage = "assets/image/waveform_easset.gif";
  }
  pausefunction() {
    this.audioimage = "assets/image/Wave_bg.jpg";
  }
  //audio popup//
  //image popup//

  hideimageedit() {
    this.ImageUpdated = false;
    this.showfulldiv();
  }
  Cancel() {
    this.Player.nativeElement.src = "";
  }

  cancelcrp() {
    this.editdiv = 0;
    this.metadatadiv = 1;
    this.showfulldiv();
    this.view = false;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      //   document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }

  Reset() {
    this.cropper.clear();
    this.editdiv = 0;
    this.metadatadiv = 1;
    if (this.cropper != null) {
      this.cropper.destroy();
      this.imageDestination = "";
      this.ButtonStatusimg = "Download";
      // document.querySelector(".toolbox").removeAttribute("style")
      // document.querySelector(".toolbox").setAttribute("style","display:none")
      this.brightness = 0;
      this.contrast = 0;
      this.saturate = 0;
      this.grin = "";
      this.watermark = "India Today";
      this.watermarkposition = "topright";
      document.querySelector(".cropbtn").removeAttribute("disabled");
    }
  }
  CropedImageDownload(filename, filepath, itemid) {
    if (this.imageDestination != "") {
      // this.ButtonStatus='Cropped Download '
      //var downloadpath = this.imageDestination
      this.ctx = this.cropimagecanvas.nativeElement.getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.cropimagecanvas.nativeElement.width,
        this.cropimagecanvas.nativeElement.height
      );
      var image = new Image();
      image.src = this.imageDestination;
      this.cropimagecanvas.nativeElement.height = image.naturalHeight;
      this.cropimagecanvas.nativeElement.width = image.naturalWidth;
      if (this.contrast == 0) {
        this.ctx.filter = "contrast(none)";
      } else {
        this.ctx.filter = "contrast(" + this.contrast / 10 + ")";
      }
      if (this.brightness == 0) {
        this.ctx.filter = "brightness(none)";
      } else {
        this.ctx.filter = "brightness(" + this.brightness / 10 + ")";
      }
      if (this.saturate == 0) {
        this.ctx.filter = "saturate(none)";
      } else {
        this.ctx.filter = "saturate(" + this.saturate / 10 + ")";
      }
      if (this.grin == "") {
        this.ctx.filter = this.grin + "(none)";
      } else {
        this.ctx.filter = this.grin + "(1)";
      }
      //this.ctx.filter="brightness(25.5)";

      this.ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
      if (this.watermark != "") {
        this.ctx.globalAlpha = 0.5;
        if (this.watermarkposition == "topright") {
          var fntsize = image.naturalWidth / 20;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.textAlign = "end";
          this.ctx.fillText(
            this.watermark,
            image.naturalWidth - 10,
            image.naturalHeight * 0.06,
            image.naturalWidth
          );
        } else {
          var fntsize = image.naturalWidth / 5;
          this.ctx.font = fntsize + "px Comic Sans MS";
          this.ctx.fillStyle = "red";
          this.ctx.translate(0, image.naturalWidth * 0.12);
          this.ctx.rotate(
            Math.atan(
              image.naturalHeight /
                (image.naturalWidth + image.naturalWidth * 0.05)
            )
          );
          this.ctx.fillText(this.watermark, 0, 0, image.naturalWidth);
        }
      }
      var downloadpath =
        this.cropimagecanvas.nativeElement.toDataURL("image/jpeg");
      this.LoadImageCrop(downloadpath, this.SaveCropImage, filename, itemid);
      this.Reset();
      this.downloadentryimg(itemid);
    } else {
      this.downloadFullImage(filename, filepath);
      this.downloadentryimg(itemid);
    }
  }
  private SaveCropImage(content: Blob, filename, itemid): void {
    saveAs(content, filename);
  }
  private LoadImageCrop(
    url: string,
    callback: Function,
    filename,
    itemid
  ): void {
    saveAs(url, filename);
  }

  downloadentryimg(itemid) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.imageservice
      .downloadentry(itemid, userid)
      .subscribe((data: any[]) => {});
  }
  changecolor(id1) {
    var filter1 = "";
    var style = document
      .querySelector(".cropper-container")
      .getAttribute("style");
    var x = style.split(";");
    //var y=x[2]
    x[2] = "";
    var bvalue = this.brightness / 10;
    var cvalue = this.contrast / 10;
    var svalue = this.saturate / 10;
    if (bvalue > 0) {
      filter1 += "brightness(" + bvalue + ") ";
    }
    if (cvalue > 0) {
      filter1 += "contrast(" + cvalue + ") ";
    }
    if (svalue > 0) {
      filter1 += "saturate(" + svalue + ")";
    }
    if (this.grin != "") {
      filter1 += this.grin + "(1)";
    }
    x[2] = "filter:" + filter1;
    //filter=id1+"("+bvalue+");"
    if (filter1 != "") {
      style = x[0] + ";" + x[1] + ";" + x[2] + ";";
    } else {
      style = x[0] + ";" + x[1] + ";";
    }

    document.querySelector(".cropper-container").removeAttribute("style");
    document
      .querySelector(".cropper-container")
      .setAttribute("style", "" + style + "");
  }
  cropimages() {
    this.ButtonStatusimg = "Cropped Download ";
    document.querySelector(".cropbtn").setAttribute("disabled", "true");
    // document.querySelector(".toolbox").removeAttribute("style")
    // document.querySelector(".toolbox").setAttribute("style","display:block")
    this.metadatadiv = 0;
    this.editdiv = 1;
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 0,
      autoCropArea: 1,
      checkCrossOrigin: false,
      movable: false,
      viewMode: 2,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/jpeg");
      },
    });
  }
  downloadFullImage(filename, filepath) {
    this.imageservice
      .Testdownload(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          // const file = new File([blob], filename);
        }
      });
  }

  showNoOfDownloadimg(itemid) {
    // this.getdowloadimagelist=downloadList
    // this.imageservice.GetDownloadedImageData(itemid).subscribe((data: any[]) => {
    //   if (data != null) {
    //     this.downloadListimg = data
    //     this.showDownloadimg = true
    //   }
    // })
  }

  getimagedata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.dashboarservice.getimagedata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullimagepopup(data[0]);
        }
      });
    }
  }

  fullimagepopup(imageList) {
    this.downloaditems = [
      {
        label: "Low",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.thumbpath,
            imageList.itemid
          );
        },
      },
      {
        label: "Medium",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.HiresPath,
            imageList.itemid
          );
        },
      },
      {
        label: "High",
        command: () => {
          this.CropedImageDownload(
            imageList.itemfilename,
            imageList.OriginalPath,
            imageList.itemid
          );
        },
      },
    ];
    this.fullpathlist = imageList;
    this.ratingitemimg = imageList.itemrating;
    this.imageservice
      .Testdownload(imageList.itemfilename, imageList.OriginalPath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(
            "data:image/jpg;base64," + b64Data1
          );
        }
        this.view = true;
      });
  }

  showeditdiv(path) {
    this.showeditor = true;
    this.iframepath = environment.editorpath + path;
  }

  hideeditor() {
    this.showeditor = false;
    this.iframepath = "";
  }

  updateimagepopup(itemid, name, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.ImageUpdated = true;
      this.idforimageedit = itemid;
      this.imagenameforedit = name;
    }
  }
  //image popup//

  //video popup//

  getvideodata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.dashboarservice.getvideodata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullvideopopup(data[0]);
        }
      });
    }
  }
  fullvideopopup(videolist) {
    this.videoElement.nativeElement.src =
      this.ApiServerPath + videolist.HiresPath;
    this.ratingitemvideo = videolist.itemrating;
    //  this.videopreviewpath=videolist.HiresPath
    this.fullvideolist = videolist;
    this.viewVideo = true;
    this.Buttons = "Play";
  }

  popupClose() {
    this.viewVideo = false;
    document.getElementById("play").style.backgroundImage =
      "url('/assets/image/play.png')";
  }
  cropvideo(path, orgname) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].LoginId;

    this.videogif.nativeElement.style.display = "block";
    this.videoElement.nativeElement.pause();
    // this.videogif.nativeElement.src='assets/image/2.gif'
    this.videoservice
      .ffmpeg(path, orgname, this.durationarray, userid)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          this.downloadVideo(userid, orgname, path, userid);
          this.videogif.nativeElement.style.display = "none";
          this.close();
        }
      });
  }

  downloadVideo(itemid, filename, filepath, userid) {
    this.videoservice
      .Testdownload(itemid, filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "video/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
          this.videoservice.deleteTempFile(userid).subscribe((data: any) => {
            if (data != null) {
            }
          });
        }
      });
  }

  markin() {
    if (this.ButtonStatus == "MarkIn") {
      this.ButtonStatus = "MarkOut";
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markinvl" +
        this.markinvl +
        "' class='markin' style='height:40px; width:13px; border-right:4px solid #000;margin:-40px 0px 0px 0px;float:left'></div>";
      var timebar = $(".timeBar").css("width");
      var markinwidth = $("#markinvl" + this.markinvl).css("width");
      var markoutwidth = $("#markoutval" + this.markinvl).css("width");
      var markSetwidth = Number(13) + Number(timebar.replace("px", ""));
      $("#markinvl" + this.markinvl).css("width", markSetwidth);
      this.tooltip("markinvl" + this.markinvl);
      this.getProgressCurrentTime(markSetwidth) + ",";
      this.markinvl = this.markinvl + 1;
    } else {
      this.ButtonStatus = "MarkIn";
      document.getElementById("markslider").style.display = "block";
      document.getElementById("markslider").innerHTML +=
        "<div id='markoutval" +
        this.markoutval +
        "' class='markout' style='height:40px; width:13px; border-right:4px solid #000;background: rgba(102, 153, 200,0.7);margin:-40px 0px 0px 0px;float:left'></div>";
      var timebar = $(".timeBar").css("width");
      var markinwidth = $("#markinvl" + this.markoutval).css("width");
      var markSetwidth =
        Number(15) +
        Number(timebar.replace("px", "")) -
        Number(markinwidth.replace("px", ""));
      $("#markoutval" + this.markoutval).css("width", markSetwidth);
      $("#markoutval" + this.markoutval).css("margin-left", markinwidth);
      this.tooltip("markoutval" + this.markoutval);
      this.markoutval = this.markoutval + 1;
      this.getProgressCurrentTime(markSetwidth) + ",";
    }
  }

  tooltip(id) {
    // var MarkInValue;
    //  var MarkOutValue;
    var videoCurrentTime12 = this.videoElement.nativeElement.currentTime;
    this.minutes = "0" + Math.floor(videoCurrentTime12 / 60);
    var seconds = "0" + Math.floor(videoCurrentTime12 - this.minutes * 60);
    var dur = this.minutes.substr(-2) + ":" + seconds.substr(-2);
    document.getElementById(id).innerHTML +=
      "<span style='float:right;font-size:13px;'>" + dur + "</span>";
    $(id).attr("title", dur);
    if (id == "markinvl" + this.markinvl) {
      this.MarkInValue = videoCurrentTime12;
    }
    if (id == "markoutval" + this.markoutval) {
      this.MarkOutValue = videoCurrentTime12;
      this.totalDuration = this.MarkOutValue - this.MarkInValue;
      this.markinArray.push(this.MarkInValue);
      this.totaldurarray.push(this.totalDuration);
      this.durationarray = this.markinArray.concat(this.totaldurarray);
    }
    //  this.totalDuration=this.MarkOutValue-this.MarkInValue
    //  this.markinArray.push(this.MarkInValue)
    //  this.totaldurarray.push(this.totalDuration)
    //  this.durationarray.concat(this.markinArray,this.totaldurarray)
  }

  getProgressCurrentTime(x) {
    var video = $("#myVideo");
    var progress = $(".progress")[0];

    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = x - progress.offsetLeft;
    var percentage = (100 * position) / progress.clientWidth;
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    var Ctime = (maxduration * percentage) / 100;
    return Ctime;
  }

  play() {
    var videoCurrentTime1 = this.videoElement.nativeElement.currentTime;
    var totalduration1 = this.videoElement.nativeElement.duration;
    var video = $("#myMovie");
    video.bind("timeupdate", function () {
      var videoCurrentTime = videoCurrentTime1;
      var totalduration = totalduration1;
      var minutes: any;
      minutes = "0" + Math.floor(videoCurrentTime / 60);
      var seconds = "0" + Math.floor(videoCurrentTime - minutes * 60);
      var dur = minutes.substr(-2) + ":" + seconds.substr(-2);
      var minutes_d: any;
      minutes_d = "0" + Math.floor(totalduration / 60);
      var seconds_d = "0" + Math.floor(totalduration1 - minutes_d * 60);
      var dur_d = minutes_d.substr(-2) + ":" + seconds_d.substr(-2);
      $("#currentduration").html(dur);
      $("#totalduration").html(dur_d);
    });

    var CurrentPosition = this.videoElement.nativeElement.currentTime;
    var dur = this.videoElement.nativeElement.duration;
    video.on("timeupdate", function () {
      var currentPos = CurrentPosition;
      var maxduration: any;
      maxduration = dur;
      var perc = (100 * currentPos) / maxduration;
      $(".timeBar").css("width", perc + "%");
      $(".current").text(timeFormat(currentPos));
    });
    var timeFormat = function (seconds) {
      var m: any;
      m =
        Math.floor(seconds / 60) < 10
          ? "0" + Math.floor(seconds / 60)
          : Math.floor(seconds / 60);
      var s =
        Math.floor(seconds - m * 60) < 10
          ? "0" + Math.floor(seconds - m * 60)
          : Math.floor(seconds - m * 60);
      return m + ":" + s;
    };
    var perc = (100 * CurrentPosition) / totalduration1;
    $(".timeBar").css("width", perc + "%");

    if (perc == 100) {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
    }
  }

  timer() {
    if (this.playStatus == "Pause") {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/pause.png')";
      this.playStatus = "Play";
      this.videoElement.nativeElement.play();
    } else {
      document.getElementById("play").style.backgroundImage =
        "url('/assets/image/play.png')";
      this.playStatus = "Pause";
      this.videoElement.nativeElement.pause();
    }
    setInterval(() => {
      this.play();
    }, 1000);
  }

  fullscreen() {
    var videofullscreen = document.getElementById("myMovie");
    videofullscreen.requestFullscreen();
  }
  MuteUnmute() {
    //  var videofullscreen=document.getElementById('myMovie')
    if (this.volumeStatus == "unmute") {
      this.videoElement.nativeElement.muted = false;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/volume.png')";
      this.volumeStatus = "mute";
    } else {
      this.videoElement.nativeElement.muted = true;
      document.getElementById("volume").style.backgroundImage =
        "url('/assets/image/mute.png')";
      this.volumeStatus = "unmute";
    }
  }

  updateprogressbar(e) {
    var progress = $(".progress");
    //calculate drag position
    //and update video currenttime
    //as well as progress bar
    var maxduration = this.videoElement.nativeElement.duration;
    var position = e.pageX - progress.offset().left;
    var percentage = (100 * position) / progress.width();
    if (percentage > 100) {
      percentage = 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    $(".timeBar").css("width", percentage + "%");
    this.videoElement.nativeElement.currentTime =
      (maxduration * percentage) / 100;
  }

  getExportData(itemid, itemname) {
    this.exportitemid = itemid;
    this.exportitemname = itemname;
    this.videoservice
      .GetExportButton()
      .subscribe((data: exportConfigurationModel[]) => {
        if (data != null) {
          this.ExportList = data;
        }
      });
  }

  ExportVideo(exportid, filepath) {
    this.videoservice
      .InsertExportData(
        exportid,
        this.exportitemid,
        this.exportitemname,
        filepath,
        ""
      )
      .subscribe((data: any[]) => {
        if (data != null) {
          this.ExportEntry(this.exportitemid, exportid, this.exportitemname);
          this.exportitemid = 0;
          this.exportitemname = "";
          this.messageservice.add({
            key: "message1",
            severity: "info",
            summary: "Success Message",
            detail: "Successfully Export",
          });
        }
      });
  }

  showNoOfDownloadvideo(itemid) {
    // this.getdowloadimagelist=downloadList
    // this.videoservice.GetDownloadedVideoData(itemid).subscribe((data: any[]) => {
    //   if (data != null) {
    //     this.downloadVideoList = data
    //     this.showVideoDownload = true
    //   }
    // })
  }

  ExportEntry(itemid, exportid, filename) {
    var userid = JSON.parse(localStorage.getItem("userdetails"))[0].UserId;
    this.videoservice
      .ExportEntry(itemid, exportid, filename, userid)
      .subscribe((data: any[]) => {});
  }
  closevideo() {
    document.getElementById("markslider").innerHTML = "";
    this.durationarray = [];
    this.markinArray = [];
    this.totaldurarray = [];
    this.ButtonStatus = "MarkIn";
  }

  hidevideo() {
    this.videoElement.nativeElement.src = "";
    document.getElementById("markslider").innerHTML = "";
    this.ButtonStatus = "MarkIn";
  }

  //video popup//

  getstorydata(itemid, e) {
    if (e.ctrlKey == false && e.metaKey == false) {
      this.dashboarservice.getstorydata(itemid).subscribe((data: any[]) => {
        if (data != null) {
          this.fullstory(data[0]);
        }
      });
    }
  }

  fullstory(storylist) {
    // this.headlineFont=storylist.cssclass
    // this.storyfont=storylist.cssclass
    this.fullstorylist = storylist;
    this.headline = storylist.itemcaption;
    this.ratingitemstory = storylist.itemrating;
    this.storyread(storylist.itemfilename, storylist.HiresPath);
    this.viewStory = true;
    this.getAttachment(storylist.itemid, this.attachmentlist);
  }

  closestory() {
    this.viewStory = false;
  }
  storyread(filename, filepath) {
    this.storyservice
      .getTextFileContend(filename, filepath)
      .subscribe((data: any) => {
        if (data != null) {
          this.text = data.replace(new RegExp("\n", "g"), "<br/>");
          this.text = this.text.replace(new RegExp("\r\n", "g"), "<br/>");
        }
      });
  }

  getAttachment(parentid, attachmentlist) {
    // this.selectedAttachmentlist=attachmentlist
    // document.getElementById("markslider").innerHTML +=;
    this.storyservice.getAttachment(parentid).subscribe((data: any) => {
      if (data != null) {
        this.selectedAttachmentlist = data;
        //alert(data)
      }
    });
  }

  downloadAttachment(filename, filepath, itemid) {
    this.storyservice
      .Testdownload(filename, filepath, itemid)
      .subscribe((data: any) => {
        if (data != null) {
          var b64Data1 = data.split(";")[1];
          const byteCharacters = atob(b64Data1);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "document/*" });
          //let byteCharacters = atob(data);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        //this.downloadentry(itemid)
      });
  }

  getBackground(color) {
    return this.sanitizer.bypassSecurityTrustStyle(color);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlighttext'
})
export class HighlighttextPipe implements PipeTransform {

  transform(value: any, args: any): any {
    // console.log(value,'value')
    // console.log(args,'args')
    //   let colorText ='';
    //   args = args.trim();
    //   if (!args) { return value; }
    //   if(args.includes(' ')){
    //     for (let i = 0; i < args.split(' ').length; i++) {
    //       var re = new RegExp(args.split(' ')[i], 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive. 
    //       value = value.replace(re, `<span class='searchTextColor'>${args.split(' ')[i]}</span>`);
    //       colorText =  value;
    //     } 
    //   } else { 
    //     var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive. 
    //     try {
    //       colorText = value.replace(re, `<span class='searchTextColor'>${args}</span>`);
    //     } catch (error) {

    //     }

    //   }
    //   return colorText;
    // }

    let colorText = '';
    if (args != undefined) {
      args = args.trim()
    }
    if (!args) { return value; }
    if (args.includes(' ')) {
      for (let i = 0; i < args.split(' ').length; i++) {
        if ((args.split(' ')[i].trim()).length > 2) {
          if(value){

            var re = new RegExp(args.split(' ')[i], 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive. 
            value = value.replace(re, `<span class='searchTextColor'>${args.split(' ')[i]}</span>`);
            colorText = value;
          }

        }
      }
    } else {
      var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
      if(value){
        colorText = value.replace(re, `<span class='searchTextColor'>${args}</span>`);
      } 
    }
    return colorText;
  }
}
  


